import React, {useState, useRef, useEffect} from 'react'
import { Container, Form, FormContent, FormH1, FormInput, FormLabel, FormWrap, Icon, PrimaryBtn, Text } from '../components/Form/formElements'
import SideBar from '../components/Sidebar'
import ZeenahPay from '../assets/ZeenahPay.png'
import ReactPasswordToggleIcon from 'react-password-toggle-icon';
import {FaEye, FaEyeSlash} from 'react-icons/fa'
import useAuth from '../hooks/useAuth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from '../api/axios'
import AlertModal from './modal';
import BASE_URL from '../apiConfig'


const ResetPassword = () => {
    let inputRef = useRef();

    const navigate = useNavigate();

    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState("");
    const [reset_token, setResetToken] = useState("");
    const userRef = useRef();
    const errRef = useRef();
    const handlePassword = (e) => { setPassword(e.target.value)}
    const showIcon = () => <FaEye aria-hidden="true" />;
    const hideIcon = () => <FaEyeSlash aria-hidden="true" />
    let inputRef2 = useRef();
    let inputRef1 = useRef();
    const [email, setEmail] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const [errorPassword, setErrorPassword] = useState('');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [buttonText, setButtonText] = useState('Reset');


    useEffect(() => {
      userRef.current.focus();
    }, [])
    
    const handleSubmit = async (e) => {
      setButtonDisabled(true);
        setButtonText('Resetting...')
      e.preventDefault();

      try  {
        if (!errorPassword) {
        const response = await axios.post(`${BASE_URL}/reset-password`,
            {
                email: email,
                reset_token: reset_token,
                confirm_password: confirmPassword,
                password : password
            },
            {
                headers: { 'Content-Type': 'application/json' }
            }
        );
        setAlertMessage(response.data.message);
        setShowAlert(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        navigate('/login')
    }} catch (err) {
      setButtonDisabled(false);
        setButtonText('Reset')
        if (err.response) {
            setErrMsg(err.response.data.message)
        } else if (!err?.response) {
            setErrMsg('No server response');
        }
        errRef.current.focus();
    }
}
const validatePassword = () => {
    if (password !== confirmPassword) {
      setErrorPassword("Passwords do not match!");
    } else {
      setErrorPassword("");
    }
  }

  const handleAlertClose = () => {
    setShowAlert(false);
  };
    
  return (
    <>
    {showAlert && (
        <AlertModal
          isModalOpen={showAlert}
          alertMessage={alertMessage}
          onClose={handleAlertClose}
        />
      )}
        <SideBar />
        <Container>
            <FormWrap>
                <FormContent>
                    <Icon src={ZeenahPay}  width='160px'/>
                    <Form onSubmit={handleSubmit}>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive" style={{color: '#FF0000', margin: '20px'}} >{errMsg}</p>
                        <FormH1 style={{marginTop: '-30px', marginBottom: '10px'}}>Set New Password </FormH1>
                        <FormLabel htmlFor='email'>Email</FormLabel>
                        <FormInput id='email' placeholder='Johndoe@ZeenahPay.com' ref={userRef} type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                        <FormLabel htmlFor='email'>Reset Code</FormLabel>
                        <FormInput id='email' placeholder='input reset code'  type='text' value={reset_token} onChange={(e) => setResetToken(e.target.value)} />
                        <div style={{position:"relative", marginTop: '0px'}}>
                        <FormLabel for='password' type='password'>Password</FormLabel>
                        <FormInput ref={inputRef} type="password" placeholder="*******************" style={{width: '100%'}} value={password} onChange={handlePassword} />
                        <ReactPasswordToggleIcon inputRef={inputRef} showIcon={showIcon} hideIcon={hideIcon} style={{height: '100px'}}/>
                    </div>
                    <div style={{position:"relative", marginTop: '0px'}}>
                    <FormLabel for='confirmPassword' type='password'>Confirm Password</FormLabel>
                    <FormInput
                      ref={inputRef1}
                      type="password"
                      placeholder="*******************"
                      style={{width: '100%'}}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      onBlur={() => validatePassword(confirmPassword)}
                    />
                        <ReactPasswordToggleIcon inputRef={inputRef1} showIcon={showIcon} hideIcon={hideIcon} style={{height: '100px'}}/>
                    </div>
                    {errorPassword && <div style={{fontSize: '20px', color: '#FF0000', marginTop: '-20px', marginBottom: '15px', marginLeft: '10px'}}>{errorPassword}</div>}

                        <PrimaryBtn type='submit' disabled={buttonDisabled}>{buttonText}</PrimaryBtn>
                        <Text>No account yet? <a href='/signup' style={{color: '#4D0B6C', textDecoration:"none", textAlign:'center'}}>Register Here</a></Text>
                        <Text>Remeber password? <a href='/login' style={{color: '#4D0B6C', textDecoration:"none", textAlign:'center'}}>Login Here</a></Text>
                    </Form>
                </FormContent>
            </FormWrap>
        </Container>
    </>
  )
}

export default ResetPassword