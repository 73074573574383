import React, { useState, useEffect, useRef } from 'react'
import { Steps, StepsProvider, useSteps } from "react-step-builder";
import { Form2, Form4, FormArea, FormH1, FormH2, FormInput, FormLabel, PrimaryBtn } from '../components/Form/formElements';
import sample from '../assets/sample.png'
import { useNavigate } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import coin from '../assets/coin.png'
import { SelectInput } from '../components/Dashboard/content/dashElements';
import axios from 'axios';
import Loader from '../components/loader';
import {FaRegCopy} from 'react-icons/fa'
import styled from 'styled-components';
import AlertModal from './modal';
import BASE_URL from '../apiConfig'

const Container1 = styled.div`
  display: flex;
  margin-top: 20px;

`;

const Select = styled.select`
padding: 16px 16px;
margin-bottom: 32px;
border: 1px solid #979797;
border-radius: 6px;
background-color: #4D0B6C;
color: #fff;
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  outline: none;
`;

const Input = styled.input`
  padding: 16px 16px;
  margin-bottom: 32px;
  border: 1px solid #979797;
  border-radius: 6px;
  background-color: transparent;
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  outline: none;
  width:100%;
  @media screen and (max-width: 580px) {
        width: 80%;
    }
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopupCard = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 80%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  border: none;
  background: transparent;
`;
const DivPop = styled.div`
    height: 150px;
    @media screen and (max-width: 580px) {
        height: 195px;
    }
`
const B = styled.div`
    font-size: 17px;
    @media screen and (max-width: 580px) {
        font-size: 12px;
    }
`

const Checkout = () => {

    let navigate = useNavigate()

    const [copied, setCopied] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => {
        let timeoutId;
        if (copied) {
          timeoutId = setTimeout(() => setCopied(false), 3000);
        }
        return () => {
          clearTimeout(timeoutId);
        };
      }, [copied]);

    const handleCopy = async (text) => {
      try {
        await navigator.clipboard.writeText(text);
        setCopied(true);
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    };

    const [timeLeft, setTimeLeft] = useState(1820);

  useEffect(() => {
    let intervalId;
    if (timeLeft > 0) {
      intervalId = setInterval(() => setTimeLeft(timeLeft - 1), 1000);
    } else {
      clearInterval(intervalId);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [timeLeft]);

    let history = useNavigate();
    const { next, prev, total, current } = useSteps();
    const [customer_name, setCustomerName] = useState('')
    const [customer_email, setCustomerEmail] = useState('')
    const [coin, setCoin] = useState('BTC')
    const [quantity, setQuantity] = useState('1')
    const [price, setPrice] = useState('')
    const [payment_method, setPaymentMethod] = useState('')
    const [currency, setCurrency] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [errorEmail, setErrorEmail] = useState("");
    const [country, setCountry] = useState('');
    const [region, setRegion] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [cryptoResponse, setResponse] = useState([]);
    const [status, setStatus] = useState(null);
    const [trnxid, setTrnxId] = useState(null);
    const [buttonText, setButtonText] = useState('Paid');
    const [buttonDisabled, setButtonDisabled] = useState(false);

    const handleCustomerEmail = (e) => {
        setCustomerEmail(e.target.value)
      }
      const handleCustomerName = (e) => {
          setCustomerName(e.target.value)
      }
      const handleCoin = (e) => {
          setCoin(e.target.value)
      }
      const handleQuantity = (e) => {
          setQuantity(e.target.value)
      }
      const handlePaymentMethod = (e) => {
          setPaymentMethod(e.target.value)
      }
      const handlePhoneNumber = (value) => {
          setPhoneNumber(value)
      }

      const [checkoutDetails, setCheckoutDetails] = useState({});
      const currentUrl = window.location.href
      const newUrl = currentUrl.replace("www.zeenahpay.com", "payment.zinari.org/api")

      const fetchProductDetails = async () => {
        try {
          const response = await axios.get(newUrl);
    
          return response.data;
        } catch (error) {
          console.error(error);
        }
      };
      useEffect(() => {
        const getCheckOutDetails = async () => {
          const data = await fetchProductDetails();
          setCheckoutDetails(data.data);
          setPrice(data.data.product.product_price)
          setCurrency(data.data.product.product_currency)
        };
    
        getCheckOutDetails();
      }, []);

      const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [dim, setDim] = useState(false);
    const [error, setError] = useState(null)

      const validateEmail = customer_email => {
        const emailRegExp = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (!emailRegExp.test(customer_email)) {
          setErrorEmail("Invalid email address");
        } else {
          setErrorEmail("");
        }
      };

      const topUpCrypto = (event) => {
        event.preventDefault();
        if (!errorEmail) {
        axios.post(newUrl, {
            customer_email: customer_email,
            customer_name: customer_name,
            coin: coin,
            currency: currency,
            quantity: quantity,
            payment_method: payment_method
        }).then(response => {
          setResponse(response.data)
          next();
          setShowPopup(true);
          setTrnxId(response.data.data.id);
        })
          .catch(error => {
            const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
            setAlertMessage(jsonString);
            setShowAlert(true);
          })
        }
    }

    const handleAlertClose = () => {
      setShowAlert(false);
    };
    async function fetchStatus() {
      setButtonText('Checking status ...')
      setButtonDisabled(true)
      try {
        const response = await axios.get(`${BASE_URL}/checkout/${trnxid}/status`);
        const data = response.data;
        setStatus(data.data.status);
        if (data.data.status !== 'success' || data.data.status !== 'completed') {
          // If the status is not successful, call fetchStatus again after 5 seconds
          setTimeout(fetchStatus, 5000);
        }else {
          setButtonText('Check Status')
          setButtonDisabled(false)
        }
      } catch (error) {
        console.error(error);
      }
    }
    
    useEffect(() => {
      if (trnxid) {
        fetchStatus();
      }
    }, [trnxid]);
    
    useEffect(() => {
      if (status && (status === 'success' || status === 'completed')) {
        setShowPopup(false)
        setAlertMessage("Transaction Successful, a transaction receipt will be sent to the email provided!");
        setShowAlert(true);
        clearTimeout(fetchStatus);
      }
    }, [status]);
  return (
    <>
    {checkoutDetails.product ? (
        <div>
        <Steps>
            <div className='content'>
                <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><a className="close">
                <b style={{ marginTop: '10px', marginLeft: '10px', alignItems: 'center', cursor: 'pointer'}}>Zeenahpay | </b>
                </a> <b>Payment Link</b> <b style={{float: 'right', marginRight: '10px' }}>Step {current} of {total}</b></div>
                <Form2 style={{border: 'none', maxWidth: '800px'}}>
                    <FormH2 style={{marginTop: '-40px', fontWeight: 'bold'}}>{checkoutDetails.product.product_name}</FormH2>
                     <FormH2>{checkoutDetails.product.product_image ? (
                    <img src={checkoutDetails.product.product_image} alt='product image' width='450px' style={{borderRadius: '12px'}}/>
                    ) : (
                        <h2 style={{color: '#7416a0',borderRadius: '6px', textAlign:'center', background: '#dbdbdb', width: '100%', height:'100px', alignItems:'center', display:'grid'}}>No image</h2>
                      )}</FormH2>
                    <h2 style={{float: 'left', fontWeight: 'bold', marginTop: '20px'}} >{checkoutDetails.product.product_currency} {checkoutDetails.product.product_price}</h2>
                    <p style={{marginTop: '10px',marginBottom: '25px', textAlign: 'left'}}>{checkoutDetails.product.product_description}</p>
                    <PrimaryBtn style={{borderRadius: '12px'}} onClick={next}>Pay Now</PrimaryBtn>
                </Form2>
            </div>
            <div className='content'>
                <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><div style={{paddingTop: '12px'}}><a className="close" onClick={prev}>
                <b style={{fontSize: '26px', marginLeft: '10px', alignItems: 'center', cursor: 'pointer'}}>&larr; | </b>
                </a> <b>Customer Details</b> <b style={{float: 'right', marginRight: '10px' }}>Step {current} of {total}</b></div></div>
                <Form2 style={{border: 'none', maxWidth: '600px', padding: '20px'}} onSubmit={topUpCrypto}>
                    <FormH2 style={{color: '#4D0B6C'}}>Customer Details</FormH2>
                    
                        <FormLabel for='firstName' style={{marginBottom: '0px'}}>Full Name</FormLabel>
                        <FormInput id='firstName' placeholder='John Doe'  value={customer_name} onChange={handleCustomerName} />

                        <FormLabel for='email' style={{marginBottom: '0px'}}>Email</FormLabel>
                        {errorEmail && <div style={{fontSize: '10px', color: '#FF0000', marginTop: '10px', marginBottom: '5px', marginLeft: '10px'}}>{errorEmail}</div>}
                        <FormInput id='email' placeholder='Johndoe@ZeenahPay.com' type='email' value={customer_email} onChange={handleCustomerEmail} onBlur={() => validateEmail(customer_email)}  />
                        <FormLabel for='PhoneInput' style={{marginBottom: '0px'}}>Phone Number</FormLabel>
                        <PhoneInput country={'ng'} value={phoneNumber} onChange={handlePhoneNumber} />

                              <FormLabel style={{marginTop: '20px', marginBottom: '0px'}}>Country</FormLabel><br />
                              <CountryDropdown value={country} onChange={(val) => setCountry(val)} style={{ padding: "16px 16px", marginBottom: "32px", border: "1px solid #979797", borderRadius: "6px", backgroundColor: "transparent"}} />
                                <FormLabel style={{marginTop: '20px',marginBottom: '0px'}}>State</FormLabel><br />
                                <RegionDropdown country={country} value={region} onChange={(val) => setRegion(val)} style={{ padding: "16px 16px", marginBottom: "32px", border: "1px solid #979797", borderRadius: "6px", backgroundColor: "transparent"}} />
                            

                                <FormLabel style={{marginBottom: '0px'}}>City / Town</FormLabel>
                                <FormInput placeholder='City' />

                                <FormLabel style={{marginBottom: '0px'}}>Address</FormLabel>
                                <FormArea placeholder='Address' />
                                <FormH2 style={{color: '#4D0B6C', display: 'flex', lineHeight: 'normal', alignItems : 'center',justifyContent: 'center' }}>Select Payment Method</FormH2>
                                <FormH2 style={{color: '#7BC875', fontSize: '14px'}}>{currency} {price} / unit</FormH2>
                                
                                    <FormLabel style={{marginBottom: '2px'}}>Payment Method</FormLabel><br />
                                      <SelectInput value={payment_method} onChange={handlePaymentMethod}>
                                          <option>Select</option>
                                          <option value='crypto'>Crypto</option>
                                      </SelectInput>
                                    <FormLabel style={{marginBottom: '2px'}}>Payment Currency</FormLabel><br />
                                      <SelectInput value={coin} onChange={handleCoin}>
                                          <option value='BTC'> BTC</option>
                                          <option value='USDT'> USDT</option>
                                          <option value='LTC'> LTC</option>
                                          <option value='BNB'> BNB</option>
                                      </SelectInput> 
                                <FormLabel style={{marginBottom: '0px'}}>Quantity</FormLabel>
                                <FormInput value={quantity} onChange={handleQuantity} type='number' min={1}/>
                                <PrimaryBtn style={{borderRadius: '12px'}}>Proceed</PrimaryBtn>
                </Form2>
            {showPopup && (
                            <Background>
                            {cryptoResponse ? (
                            <PopupCard>
                                <CloseButton onClick={() => setShowPopup(false)}>&times;</CloseButton>
                                <Form4 style={{border : 'none', height: 'inherit', textAlign: 'center'}}>
                                <FormH2>Send Crypto</FormH2>
                                <p>Send crypto with the details below to complete payment.</p>
                                <FormH1><b style={{fontSize: '20px', fontWeight: 'lighter'}}>Time Left</b> : <b style={{color: '#791FA4'}}>{Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, '0')} M</b></FormH1>
                                <FormLabel><b style={{fontWeight: 'lighter'}}>Amount:</b> <b style={{fontSize: '16px'}} onClick={() => handleCopy(cryptoResponse.data.cryptoAmount)}>{cryptoResponse.data.coin} {cryptoResponse.data.cryptoAmount} <FaRegCopy/></b></FormLabel>
                                <FormLabel><b style={{fontWeight: 'lighter'}}>Crypto:</b> <b>{cryptoResponse.data.wallet_type}</b></FormLabel>
                                <FormLabel><b style={{fontWeight: 'lighter'}}>Network:</b><b> {cryptoResponse.data.wallet_type === 'BTC' && 'Bitcoin Network'}{cryptoResponse.data.wallet_type === 'USDT' && 'Binance SmartChain Network'}{cryptoResponse.data.wallet_type === 'LTC' && 'Litecoin Network'}{cryptoResponse.data.wallet_type === 'BNB' && 'Binance SmartChain Network'}</b></FormLabel>
                                <FormLabel><b style={{fontWeight: 'lighter'}}>Wallet Address:</b><br></br> <B  style={{ color: '#791FA4', cursor: 'pointer'}} onClick={() => handleCopy(cryptoResponse.data.address)}>{cryptoResponse.data.address} <FaRegCopy/></B></FormLabel>
                                {copied && <p>Copied to clipboard!</p>}
                                <FormLabel><b style={{fontWeight: 'lighter'}}>Transaction Status:</b> <b style={{color:'blue'}}>{status}</b></FormLabel>
                                <PrimaryBtn onClick={fetchStatus} style={{ cursor: buttonDisabled ? 'not-allowed' : 'pointer', borderRadius: '12px', backgroundColor: '#791FA4', textAlign: 'center', width: '100%'}} disabled={buttonDisabled}>{buttonText}</PrimaryBtn>
                                <div style={{fontSize: '10px', color: '#A300FF', marginTop: '20px', marginLeft: '10px'}}>* You can close this modal after payment </div>
                                </Form4>
                            </PopupCard>
                            ) : <Loader/> }
                            </Background>
                        )}
                        </div>
        </Steps>
        </div>
        ) : <Loader/> }
        {showAlert && (
        <AlertModal
          isModalOpen={showAlert}
          alertMessage={alertMessage}
          onClose={handleAlertClose}
        />
      )}
    </>
  )
}

export default Checkout