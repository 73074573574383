import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { IoShareOutline } from 'react-icons/io5';
import { SiAddthis } from 'react-icons/si';

const PromptContainer = styled.div`
  display: ${(props) => (props.show ? 'block' : 'none')};
  background-color: #ffffff;
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
`;

const PromptText = styled.p`
  font-size: 16px;
  margin-bottom: 8px;
`;

const AddToHomeScreenButton = styled.button`
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
`;

const AddToHomeScreenPrompt = () => {
  const [showPrompt, setShowPrompt] = useState(false);

  useEffect(() => {
    // Check if the environment is not a Progressive Web App (PWA)
    const isNotPWA = !window.matchMedia('(display-mode: standalone)').matches;

    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1;

    if (isIOS && isSafari && isNotPWA) {
      setShowPrompt(true);
    }
  }, []);

  const handleAddToHomeScreen = () => {
    // Provide instructions here, but you can't trigger it programmatically.
    // The user has to follow the prompts.
    if (navigator.share) {
      // Check if the Web Share API is available in the browser
      navigator
        .share({
          title: 'ZeenahPay', // Replace with your app's title
          text: 'ZeenahPay - Accept Global payments!', // Replace with your share message
          url: window.location.href, // Share the current URL
        })
        .then(() => {
          // Sharing was successful
          console.log('Shared successfully!');
        })
        .catch((error) => {
          // Sharing failed
          console.error('Error sharing:', error);
        });
    } else {
      // Fallback behavior for browsers that do not support Web Share API
      // You can provide alternative instructions here
      alert(
        'Web Share API is not supported in this browser. You can manually share the app.'
      );
    }
  };

  return (
    <PromptContainer show={showPrompt}>
      <PromptText>
        <SiAddthis style={{ color: 'grey' }} /> Add ZeenahPay to your iPhone home
        screen: tap <IoShareOutline style={{ color: 'blue' }} /> and then Add to
        homescreen
      </PromptText>
      <AddToHomeScreenButton onClick={handleAddToHomeScreen}>
        Share
      </AddToHomeScreenButton>
    </PromptContainer>
  );
};

export default AddToHomeScreenPrompt;