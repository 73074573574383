import React, {useState, useEffect} from 'react'
import { FaAngleDoubleRight, FaArrowRight } from 'react-icons/fa'
import {  Form2, Form3, FormContent, FormH1, FormH2, FormInput, FormLabel, Icon, PrimaryBtn2, Text, Text2, FormArea } from '../components/Form/formElements'
import Navbar from '../components/Navbar'
import { Content,H2, TextH2, ContentDiv, RoundedDiv2, P2pCard, P2pH, P2pT, PrimaryBtn, P2pInfo, P2pInfo2, DivLeft2, DivRight2, RoundedDiv3,SelectInput, InputArea,DivContainer, GridDiv, GridDivL, P2pGrid, TabText, PaymentCard, Tdreg1, SearchBar, TableRow, TableCell } from '../components/Dashboard/content/dashElements'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import axios from 'axios'
import { CancelBtn, EditBtn, P2pcontainer, P2pH1, P2pTable } from '../components/Dashboard/p2pElements/p2pElements'
import { NavLink } from 'react-router-dom'
import { Table as Tablle, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { CenterContent, Container, FormWrap, Tdreg, ThText } from '../components/Dashboard/content/dashElements';
import { useNavigate } from 'react-router-dom'
import Loader from '../components/loader'
import Popup from 'reactjs-popup'
import Nothing from '../assets/nothingtoshow.png'
import Empty from '../assets/empty.svg'
import userEvent from '@testing-library/user-event'
import Pin from './pin'
import AlertModal from './modal'
import {MdOutlineReportProblem} from 'react-icons/md'
import styled from 'styled-components';
import Select from 'react-select';
import Sidebar1, { MainContainer, MainContent } from './nav'
import {GrPrevious, GrNext } from 'react-icons/gr';
import BASE_URL from '../apiConfig'
import Countdown from '../components/countdown'

const Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopupCard = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 80%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  border: none;
  background: transparent;
`;
const DivPop = styled.div`
    height: 150px;
    @media screen and (max-width: 580px) {
        height: 195px;
    }
`


const Container1 = styled.div`
  display: flex;
  margin-top: 20px;

`;

const Select1 = styled.select`
padding: 16px 16px;
margin-bottom: 32px;
border: 1px solid #979797;
border-radius: 6px;
background-color: #4D0B6C;
color: #fff;
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  outline: none;
`;

const Input = styled.input`
  padding: 16px 16px;
  margin-bottom: 32px;
  border: 1px solid #979797;
  border-radius: 6px;
  background-color: transparent;
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  outline: none;
  width:100%;
  @media screen and (max-width: 580px) {
        width: 80%;
    }
`;
const Div9 = styled.div`
@media (max-width: 600px) {
    font-size: 10px;
  }
`

const P2pTransactions = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const navigate = useNavigate()
    const [buy, setBuy] = useState(null);
    const [sell, setSell] = useState(null);
    const [successful, setSuccessful] = useState(null);
    const [details, setDetails] = useState(null);
    const [disputes, setDisputes] = useState(null);
    const [transactions, setTransactions] = useState(null);
    const [deleted, setDeleted] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const [showDetails, setShowDetails] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [description, setDescription] = useState('');
    const [dispute_type, setDisputeType] = useState('');
    const [transaction_id, setTransactionID] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
  const [showNewDispute, setShowNewDispute] = useState(false);

  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleDisputeType = (selectedOption) => {
    const { value } = selectedOption;
    setDisputeType(value);
  };
  const handleDisputeModal = (ID) => {
    setTransactionID(ID)
    setShowNewDispute(!showNewDispute);
  }

  const NewDispute = (event) => {
    event.preventDefault();
      axios.post(`${BASE_URL}/dispute`, {
        transaction_id : transaction_id,
        dispute_type: dispute_type,
        description: description
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(async (response) => {
          setAlertMessage(response.data.message)
          setShowAlert(true)
          await new Promise((resolve) => setTimeout(resolve, 3000));
          navigate('/p2p')
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.error) {
            // Extract error message from response and alert it
            const errorMessage = error.response.data.errors.error;
            const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
            setAlertMessage(jsonString);;
            setShowAlert(true)
          } else {
            setAlertMessage('Service Error');
            setShowAlert(true);
          }
        });
  };

  const handleAlertClose = () => {
    setShowAlert(false);
  };

    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [dim, setDim] = useState(false);

    const token = localStorage.getItem('bearerToken');
    const [user, setUser] = useState(null);

    const fetchUser = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        return response.data;
      } catch (error) {
        console.error(error);
      }
    };
    useEffect(() => {
      const getUser = async () => {
        const data = await fetchUser();
        setUser(data);
      };
  
      getUser();
    }, []);

    const fetchTransactions = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/trade/history/buy-sell-history?status=pending`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
    
          return response.data;
        } catch (error) {
          console.error(error);
        }
      };
      useEffect(() => {
        const getTransactions = async () => {
          const data = await fetchTransactions();
          setTransactions(data);
        };
    
        getTransactions();
      }, []);
      const fetchDetails = async (id) => {
         axios.get(`${BASE_URL}/trade/history/get-order/${id}`,{
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          // Handle successful deletion, e.g. remove the trade from the table data and update the UI
          .then(res => {
            setDetails(res.data.data);
            setSelectedData(res.data.data);
            setShowDetails(true);
          })
          .catch(error => {
            console.error(error);
          });
      };

    const fetchDispute = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/dispute`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
    
          return response.data;
        } catch (error) {
          console.error(error);
        }
      };
      useEffect(() => {
        const getDisputes = async () => {
          const data = await fetchDispute();
          setDisputes(data);
        };
    
        getDisputes();
      }, []);

    const fetchDeleted = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/trade/deleted`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
    
          return response.data;
        } catch (error) {
          console.error(error);
        }
      };
      useEffect(() => {
        const getDeleted = async () => {
          const data = await fetchDeleted();
          setDeleted(data);
        };
    
        getDeleted();
      }, []);
  
    const fetchBuy = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/trade/buy`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        return response.data;
      } catch (error) {
        console.error(error);
      }
    };
    const fetchSell = async () => {
        const response = await axios.get(`${BASE_URL}/trade/sell`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return response.data;
      };
  
    useEffect(() => {
      const getTrade = async () => {
        const [buy, sell]= await Promise.all([fetchBuy(), fetchSell()]);
      setBuy(buy);
      setSell(sell);
      };
  
      getTrade();
    }, []);

    const handleDelete = async (id) => {
      try {
        setIsDeleting(true);
        await axios.delete(`${BASE_URL}/trade/delete/${id}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // Handle successful deletion, e.g. remove the trade from the table data and update the UI
        setAlertMessage('successfully deleted');
        setShowAlert(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        navigate(0);
      } catch (error) {
        console.error(error);
        const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
        setAlertMessage(jsonString);
        setShowAlert(true);
        // Handle error, e.g. display an error message to the user
      } finally {
        setIsDeleting(false);
      }
    };
    const CheckStatus = async (id) => {
      try {
        const response = await axios.get(`${BASE_URL}/trade/trade-status?trade=${id}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // Handle successful deletion, e.g. remove the trade from the table data and update the UI
        setAlertMessage(response.data.data.status);
        setShowAlert(true);
      } catch (error) {
        console.error(error);
        const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
        setAlertMessage(jsonString);
        setShowAlert(true);
        // Handle error, e.g. display an error message to the user
      }
    };
    const handleReceived = async (id) => {
      try {
        await axios.post(`${BASE_URL}/trade/received`,{
          trade_id : id
        },{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // Handle successful deletion, e.g. remove the trade from the table data and update the UI
        setAlertMessage('Trade completed Successfully!');
        setShowAlert(true);
      } catch (error) {
        console.error(error);
        const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
        setAlertMessage(jsonString);
        setShowAlert(true);
        // Handle error, e.g. display an error message to the user
      }
    };
    const handlePaid = async (id) => {
      try {
        await axios.post(`${BASE_URL}/trade/paid`,{
          trade_id : id
        },{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // Handle successful deletion, e.g. remove the trade from the table data and update the UI
        setAlertMessage('Marked as paid');
        setShowAlert(true);
      } catch (error) {
        console.error(error);
        const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
        setAlertMessage(jsonString);
        setShowAlert(true);
        // Handle error, e.g. display an error message to the user
      }
    };

    const handleRowClick = (trnx) => {
      fetchDetails(trnx);
      navigate("/p2p/transaction/details", { state: { id: trnx } });
    };
  
    const handleCloseDetails = () => {
      setShowDetails(false);
    };
    const [showPinVerification, setShowPinVerification] = useState(false);

    const handlePaidWithVerification = (id) => {
      setShowPinVerification(true);
      setVerificationCallback(() => handlePaid.bind(null, id));
    };
    
    const handleReceivedWithVerification = (id) => {
      setShowPinVerification(true);
      setVerificationCallback(() => handleReceived.bind(null, id));
    };
    
    const [verificationCallback, setVerificationCallback] = useState(null);
    const handleCancelPinVerification = () => {
      setShowPinVerification(false);
    };

    function handleDisputeClick(transactionId) {
      navigate('/dispute', { state: { id: transactionId } });
    }
    const options = [
      {
        label: 'Sell',
        options: [
          { value: 'Sender name doesn’t tally', label: 'Sender name doesn’t tally' },
          { value: 'Payment not received', label: 'Payment not received' },
          { value: 'User filed chargeback', label: 'User filed chargeback' },
          { value: 'User sent stolen funds', label: 'User sent stolen funds' },
          { value: 'Bad terms and conditions', label: 'Bad terms and conditions' }
        ]
      },
      {
        label: 'Top Up',
        options: [
          { value: 'Delay in release of funds', label: 'Delay in release of funds' },
          { value: 'Incorrect account details', label: 'Incorrect account details' },
          { value: 'Fraudulent Account details', label: 'Fraudulent Account details' },
          { value: 'Agent is unreachable', label: 'Agent is unreachable' },
          { value: 'Bad terms and conditions', label: 'Bad terms and conditions' }
        ]
      },
      {
        label: 'Payout',
        options: [
          { value: 'Sender name doesn’t tally', label: 'Sender name doesn’t tally' },
          { value: 'Payment not received', label: 'Payment not received' },
          { value: 'User filed chargeback', label: 'User filed chargeback' },
          { value: 'User sent stolen funds', label: 'User sent stolen funds' },
          { value: 'Bad terms and conditions', label: 'Bad terms and conditions' },
          { value: 'Agent is unreachable', label: 'Agent is unreachable' }
        ]
      },
      {
        label: 'Buy',
        options: [
          { value: 'Delay in release of funds', label: 'Delay in release of funds' },
          { value: 'Incorrect account details', label: 'Incorrect account details' },
          { value: 'Fraudulent Account details', label: 'Fraudulent Account details' },
          { value: 'User is unreachable', label: 'User is unreachable' }
        ]
      }
    ];

    const [isEmpty, setIsEmpty] = useState(false);
      const [searchQuery, setSearchQuery] = useState('');
      const filteredData = transactions?.data.data.filter((trnx) =>
      trnx?.trade_status.toLowerCase().includes(searchQuery.toLowerCase())||
      trnx?.trade_currency.toLowerCase().includes(searchQuery.toLowerCase())||
      trnx?.trade_amount.toLowerCase().includes(searchQuery.toLowerCase())||
      trnx?.trade_type.toLowerCase().includes(searchQuery.toLowerCase())||
      trnx?.payment_method?.payment_info?.method_name.toLowerCase().includes(searchQuery.toLowerCase())||
      trnx?.transaction_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
      new Date(trnx?.updated_at).toLocaleString().toLowerCase().includes(searchQuery.toLowerCase())
      )

      const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // reset to first page when search query changes
        setIsEmpty(filteredData.length === 0);
        if (searchQuery === 0) {
          setSearchQuery(null);
        }
      };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredData?.length / itemsPerPage); i++) {
      pageNumbers.push(i);
    }

   const handlePrevPage = () => {
      setCurrentPage( currentPage - 1);
    };
   const handleNextPage = () => {
      setCurrentPage(currentPage + 1);
    };
    const handleClick = e => {
      setCurrentPage(Number(e.target.id));
    };

    const isLastPage = currentPage === pageNumbers.length;
    const isFirstPage = currentPage === 1;
  return (
    <>
        <Navbar toggleSidebar={toggleSidebar}/>
        <MainContainer isOpen={isOpen}>
            <Sidebar1 isOpen={isOpen} toggleSidebar={toggleSidebar} />
            <MainContent>
        <Container style={{backgroundColor: '#fff', borderRadius:'6px'}}>
            <FormWrap>
      {/*<div style={{ marginTop: '40px',     background: '#fff', height: 'auto',width: 'auto',display: 'grid', margin: '0 auto'}}>
                        <RoundedDiv3>
                            <RoundedDiv2>
                                <P2pCard>
                                    <DivLeft2>
                                        <P2pH>BUY NGN</P2pH>
                                        <P2pInfo>Amount</P2pInfo>
                                        <P2pInfo>Profit</P2pInfo>
                                        <P2pInfo>Order ID</P2pInfo>
                                        <P2pInfo>Payment method</P2pInfo>
                                        <P2pInfo style={{marginTop: '20px', borderTop: '1px solid #D9D9D9', width: '100%'}}>Created: 10-11-22, 21:56</P2pInfo>
                                    </DivLeft2>
                                    <DivRight2>
                                        <P2pT>Cancel</P2pT>
                                        <P2pInfo2>NGN 100,000</P2pInfo2>
                                        <P2pInfo2>+1.0%</P2pInfo2>
                                        <P2pInfo2>561687368792619</P2pInfo2>
                                        <P2pInfo2>Bank Transfer</P2pInfo2>
                                    </DivRight2>
                                </P2pCard>
                            </RoundedDiv2>
                        </RoundedDiv3>
                        <RoundedDiv3>
                            <RoundedDiv2>
                                <P2pCard>
                                    <DivLeft2>
                                        <P2pH style={{color: '#FF4B4B'}}>SELL NGN</P2pH>
                                        <P2pInfo>Amount</P2pInfo>
                                        <P2pInfo>Profit</P2pInfo>
                                        <P2pInfo>Order ID</P2pInfo>
                                        <P2pInfo>Payment method</P2pInfo>
                                        <P2pInfo style={{marginTop: '20px', borderTop: '1px solid #D9D9D9', width: '100%'}}>Created: 10-11-22, 21:56</P2pInfo>
                                    </DivLeft2>
                                    <DivRight2>
                                        <P2pT>Cancel</P2pT>
                                        <P2pInfo2>NGN 100,000</P2pInfo2>
                                        <P2pInfo2>+1.0%</P2pInfo2>
                                        <P2pInfo2>561687368792619</P2pInfo2>
                                        <P2pInfo2>Bank Transfer</P2pInfo2>
                                    </DivRight2>
                                </P2pCard>
                            </RoundedDiv2>
                        </RoundedDiv3>
      </div> */}
      <div style={{marginLeft: '20px', marginTop:'10px'}}>
              <h2>Ongoing Transactions</h2>
              <div style={{position:"flex", marginTop: '20px', width: '100%'}}>
                  <SearchBar type="text" value={searchQuery} onChange={handleSearchInputChange} placeholder='search transactions'  />
              </div>
            </div>
      {transactions ? (
    <P2pcontainer>
    <PrimaryBtn Link style={{float: 'right'}}><NavLink to='/create-ad' style={{textDecoration: 'none', color: 'white'}}>Create AD</NavLink></PrimaryBtn>
    <FormWrap>
    {transactions.data.data.length  ? (
                <CenterContent>
                {isEmpty ? (
                  <div style={{justifyContent:'center', display: 'grid'}}>
                        <div style={{textAlign: 'center'}}>
                          <img src={Nothing} alt="Empty table" />
                        </div>
                        <div style={{textAlign: 'center'}}>
                          <h5 style={{marginBottom: '10px'}}>No search results found</h5>
                        </div>
                        <div>
                        </div>
                      </div>
                ) : (
                  <>
                  <Tablle style={{ textAlign: 'left', borderCollapse: 'collapse'}}>
                        <Thead style={{ backgroundColor: "#F3E8F7",
  color: "#1E1E1E",
  fontWeight: "600",
  lineHeight:"21px"}}>
                            <Tr>
                            <Th><ThText>Status</ThText></Th>
                            <Th><ThText>Transaction ID</ThText></Th>
                            <Th><ThText>Trade Amount</ThText></Th>
                            <Th><ThText>Time Left</ThText></Th>
                            <Th><ThText>Payment Status</ThText></Th>
                            <Th></Th>
                            </Tr>
                        </Thead>
                        
                        <Tbody>
                            {currentItems.map((trnx, index) => (
                            <Tr key={index} style={{borderBottom: '1px solid #DDD', cursor: 'pointer'}}>
                            <Td onClick={() => {setOpen1(true); setDim(true); handleRowClick(trnx.id)}}><Tdreg><P2pH1 style={{fontSize: '12px', color: '#0070FF'}}>{trnx.trade_status}</P2pH1></Tdreg></Td>
                            <Td onClick={() => {setOpen1(true); setDim(true); handleRowClick(trnx.id)}}><Tdreg1 style={{overflowWrap: 'break-word', margin:'10px'}}>{trnx.transaction_id.slice(0,13)}</Tdreg1></Td>
                            <Td onClick={() => {setOpen1(true); setDim(true); handleRowClick(trnx.id)}}><Tdreg>{trnx.trade_currency} {Number(trnx.trade_amount)}</Tdreg></Td>
                            <Td onClick={() => {setOpen1(true); setDim(true); handleRowClick(trnx.id)}}><Tdreg><Countdown endDate={new Date(trnx.updated_at).getTime() + Number(trnx?.trade?.time_limit) * 60 * 1000} trnx={trnx.id}/></Tdreg></Td>
                            <Td onClick={() => {setOpen1(true); setDim(true); handleRowClick(trnx.id)}}><Tdreg>{trnx.is_paid == 1 ? 'Marked as Paid' : 'Payment not made'} {`>`}</Tdreg></Td>
                            <Td onClick={() => {setOpen1(true); setDim(true); handleRowClick(trnx.id)}}><Tdreg style={{color:'purple'}}>{`►`}</Tdreg></Td>
                            </Tr> ))}
                        </Tbody>
                    </Tablle>
                    <div>
                    <ul style={{display: 'flex', listStyle: 'none', margin:'10px'}}>
                    <button onClick={handlePrevPage} disabled={isFirstPage} style={{ cursor: isFirstPage ? 'not-allowed' : 'pointer', backgroundColor: isFirstPage ? '#8080808c' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                          <GrPrevious />
                        </button>
                        
                        {pageNumbers.map(number => (
                          <li key={number}>
                            <button id={number} onClick={handleClick} style={{ backgroundColor: currentPage === number ? '#FCF3FF' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                              {number}
                            </button>
                          </li> 
                        ))}
                        <button onClick={handleNextPage} disabled={isLastPage} style={{ cursor: isLastPage ? 'not-allowed' : 'pointer', backgroundColor: isLastPage ? '#8080808c' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                          <GrNext/>
                        </button> 
                      </ul>
                    </div>
                    </>)}
                    
                </CenterContent>
                ) : (
                        <div style={{justifyContent:'center', display: 'grid'}}>
                        <div style={{textAlign: 'center'}}>
                          <img src={Empty} alt="Empty table" />
                        </div>
                        <div style={{textAlign: 'center'}}>
                          <h5 style={{marginBottom: '10px'}}>You have no ongoing transaction</h5>
                        </div>
                        <div>
                        <div style={{display:'flex'}}>
                          <div style={{width: '97%', marginRight: '10px'}}>
                            <NavLink to='/topup'> 
                                <PrimaryBtn2 style={{background: 'linear-gradient(180deg, #F3E8F7 0%, #EBDAF1 100%)',color: '#791FA4', borderRadius: '6px', width: '100%'}}>Top Up</PrimaryBtn2>
                            </NavLink>
                          </div>
                          <div style={{width: '100%'}}>
                            <NavLink to='/payout'>
                                <PrimaryBtn2 style={{backgroundColor: '#9316BF', borderRadius: '6px', width: '100%'}}>Pay Out</PrimaryBtn2>
                            </NavLink>
                          </div>
                        </div>
                        </div>
                      </div>
                      )}
            </FormWrap>
  </P2pcontainer>) : <Loader/>}
    
                        
            </FormWrap>
        </Container>
        </MainContent>
        </MainContainer>
        {showAlert && (
        <AlertModal
          isModalOpen={showAlert}
          alertMessage={alertMessage}
          onClose={handleAlertClose}
        />
      )}
    </>
  )
}

export default P2pTransactions