import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { AuthProvider } from './context/AuthProvider';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { Workbox } from 'workbox-window'; 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
    <ProSidebarProvider>
      <AuthProvider>
        <Routes>
          <Route path="/*" element={<App />} />
        </Routes>
      </AuthProvider>
      </ProSidebarProvider>
    </BrowserRouter>
  </React.StrictMode>
);

if ('serviceWorker' in navigator) {
  const wb = new Workbox('/service-worker.js'); // Specify the path to your Workbox-generated service worker file

  wb.addEventListener('activated', (event) => {
    if (!event.isUpdate) {
      // The service worker is newly registered
      console.log('Service Worker is registered for the first time.');
    } else {
      // The service worker was updated
      console.log('Service Worker is updated.');
    }
  });

  wb.register()
    .then((registration) => {
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch((error) => {
      console.error('Service Worker registration failed:', error);
    });
}