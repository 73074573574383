import React, {useState, useEffect} from 'react'
import { CenterContent, Container, FormWrap,PayoutCard,PayoutH,PayoutP,PayoutP2,PayoutCardWrapper, SelectInput } from '../components/Dashboard/content/dashElements'
import DashboardSidebar from '../components/Dashboard/sidebar'
import Navbar from '../components/Navbar'
import { Form2, FormArea, FormH2, FormInput, FormLabel, PrimaryBtn } from '../components/Form/formElements'
import Wallet from '../assets/wallet.png'
import bankAlt from '../assets/bank-alt.png'
import bank1 from '../assets/bank1.png'
import Vector from '../assets/Vector.png'
import Popup from 'reactjs-popup'
import styled from 'styled-components';
import axios from 'axios'
import USDT from '../assets/usdt.png'
import BTC from '../assets/btc.png'
import BCH from '../assets/bch.png'
import LTC from '../assets/LTC.png'
import coin from '../assets/coin.png'
import SwitchSelector from "react-switch-selector";
import { useNavigate, useLocation } from 'react-router-dom'
import Pin from './pin'
import AlertModal from './modal'
import Sidebar1, { MainContainer, MainContent } from './nav'
import BASE_URL from '../apiConfig'
import { ToastContainer, toast } from 'react-toastify'


const Container1 = styled.div`
  display: flex;
  margin-top: 20px;

`;

const Select = styled.select`
padding: 16px 16px;
margin-bottom: 32px;
border: 1px solid #979797;
border-radius: 6px;
background-color: #4D0B6C;
color: #fff;
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  outline: none;
`;

const Input = styled.input`
  padding: 16px 16px;
  margin-bottom: 32px;
  border: 1px solid #979797;
  border-radius: 6px;
  background-color: transparent;
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  outline: none;
  width:100%;
  @media screen and (max-width: 580px) {
        width: 80%;
    }
`;


const Swap = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(true);
    const [open2, setOpen2] = useState(false);
    const [dim, setDim] = useState(true);
    const [error, setError] = useState(null)
    const [error1, setError1] = useState(null)
    const [tradeType, setTradeType] = useState('Crypto')
    const token = localStorage.getItem('bearerToken');
    const navigate = useNavigate();
    const [showPinVerification, setShowPinVerification] = useState(false);

    const [selectedCurrency, setSelectedCurrency] = useState('USD');
    const [currencies, setCurrencies] = useState([]);
    const [amount, setAmount] = useState('');
    const [to_currency, setTo] = useState('')
    const [from_currency, setFrom] = useState('')
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [rate, setRate] = useState('');
    const [fees, setFees] = useState('');
    const [total, setTotal] = useState('');
    const [rateFromCurrency, setRateFromCurrency] = useState('');
    const [rateToCurrency, setRateToCurrency] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoading2, setIsLoading2] = useState(false);
    const [currencyBalance, setCurrencyBalance] = useState('')
    const [balance, setBalance] = useState(null);
  
    const handleCurrencyChange = (e) => {
      setSelectedCurrency(e.target.value);
    };
  
    const handleAmountChange = (e) => {
      setAmount(e.target.value);
      handleBlur(e.target.value,from_currency,to_currency);
    };
    const handleFromChange = (e) => {
      setFrom(e.target.value);
      handleBlur(amount,e.target.value,to_currency);
      const currencyObject = balance.data.find(
        (obj) => obj.ticker_name === e.target.value
      );
      if (currencyObject) {
        setCurrencyBalance(currencyObject.balance);
      }
    };
    const handleToChange = (e) => {
      setTo(e.target.value);
      handleBlur(amount,from_currency,e.target.value)
    };
    useEffect(() => {
        const token = localStorage.getItem('bearerToken');
        const fetchCurrencies = async () => {
          try {
            const response = await axios.get(`${BASE_URL}/account/balance`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            setCurrencies(response.data.data);
          } catch (error) {
            setError(error);
          }
        };
        fetchCurrencies();
      }, []);
      const handlePinVerified = (event) => {
        event.preventDefault();
        if (!error) {
        axios.post(`${BASE_URL}/swap/currency/process`, {
            from_currency: from_currency,
            amount: amount,
            to_currrency: to_currency
        }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }).then(result => {
          const swapp = JSON.stringify((result.data.data)).replace(/_|{|}|"/g, ' ').replace(/,/g, '\n');
          toast(swapp)
          navigate('/dashboard')
        })
          .catch(error => {
            const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
            setOpen1(false)
            toast.error(jsonString)
            setShowPinVerification(false)
          })
        }
    }

    function handleBlur(amountExchange,fromCurrency,toCurrency) {
      validateMaxamount(amount);
      setIsLoading(true);
      setRate("loading...")
        setFees("loading...")
        setTotal("loading...")
        setRateFromCurrency("loading...")
        setRateToCurrency("loading...")
      axios.post(`${BASE_URL}/swap/currency`, {
          amount: amountExchange,
          from_currency: fromCurrency,
          to_currrency: toCurrency
      }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then(result => {
        const swap_rate = JSON.stringify((result.data.data)).replace(/_|{|}|"/g, ' ').replace(/,/g, '\n');
        setRate(result.data.data.rate)
        setFees(result.data.data.fees)
        setTotal(result.data.data.to_amount)
        setRateFromCurrency(result.data.data.from_currency)
        setRateToCurrency(result.data.data.to_currrency)
        setIsLoading(false);
      })
        .catch(error => {
          setIsLoading(false);
        })
  }
  const fetchBalance = async () => {
          const response = await axios.get(`${BASE_URL}/account/balance`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          return response.data;
        };
        useEffect(() => {
          const getBal = async () => {
            const data = await  fetchBalance();
            setBalance(data);
          };
      
          getBal();
        }, []);

    const Swap = (e) => {
      e.preventDefault();
      setShowPinVerification(true);
    };
    const handleCancelPinVerification = () => {
      setShowPinVerification(false);
    };

    const handleAlertClose = () => {
      setShowAlert(false);
      navigate('/dashboard')
    };

    const validateMaxamount = amount => {
      if (parseFloat(amount) > parseFloat(currencyBalance)) {
          setIsLoading2(true);
          setError1("Amount cannot exceed your balance for this currency");
      } else {
        setError1("");
        setIsLoading2(false)
      }
    };
  
  return (
    <>
    {showAlert && (
        <AlertModal
          isModalOpen={showAlert}
          alertMessage={alertMessage}
          onClose={handleAlertClose}
        />
      )}
      <Navbar toggleSidebar={toggleSidebar}/>
        <MainContainer isOpen={isOpen}>
            <Sidebar1 isOpen={isOpen} toggleSidebar={toggleSidebar} />
            <MainContent>
        <Container>
        <ToastContainer />
            <FormWrap>
                <CenterContent>
                <PayoutCardWrapper>
                <PayoutCard onClick={() => {setOpen1(true); setDim(true)}} >
                    <img src={bankAlt} style={{margin: '20px', borderRadius: '50%', border:'1px solid #4D0B6C'}}/>
                    <img src={Vector} style={{margin: '20px'}}/>
                    <img src={Wallet} style={{margin: '20px', float: 'right', borderRadius: '50%', border:'1px solid #4D0B6C'}}/>
                    <PayoutH>Swap between your Zeenah Balances</PayoutH>
                    <PayoutP>Swap between Fiat, between Crypto and from Fiat to Cryto and Crypto to Fiat.</PayoutP>
                    <div>
                      <Popup open={open1} onClose={() => { setOpen1(false); setDim(false)}} modal closeOnDocumentClick contentStyle={{width: '100%', height: '100%', backgroundColor: 'white', overflow: 'scroll'}} overlayStyle={{background: dim ? 'rgba(0, 0, 0, 0.5)' : 'none'}}>
                        {close => (
                      <div className="content" >
                      <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><a className="close" onClick={() => {navigate(-1)}} style={{cursor: 'pointer'}} >
                        <b style={{fontSize: '26px', marginLeft: '10px', alignItems: 'center'}}>&times; | </b>
                        </a> <b>Swap your Zeenah balances</b> </div>
                        <Form2 style={{marginTop: '60px'}} onSelect={handleBlur}>
                            <FormH2 style={{color: '#4D0B6C', display: 'flex', lineHeight: 'normal', alignItems : 'center',justifyContent: 'center' }}><img src={bank1} style={{marginRight: '10px'}} /> Swap currencies </FormH2>
                            {/*<div style={{ height: 55, width: '100%', marginBottom: 10, marginTop: '20px' }} >
                                <SwitchSelector
                                    id='tradeType'
                                    name={'tradeType'}
                                    initialSelectedIndex={0}
                                    initial={0}
                                    onChange={value => handleTradeTypeChange(value)}
                                    borderRadius={'3px'}
                                    options={[
                                    {
                                        label: 'Crypto To Fiat',
                                        value: 'Crypto',
                                        selectedBackgroundColor: "#51CB6C"
                                    },
                                    {
                                        label: 'Fiat to Crypto',
                                        value: 'Fiat',
                                        selectedBackgroundColor: "#FF4B4B"
                                    }
                                    ]}
                                />
                            </div> */}
                            <FormLabel style={{marginBottom:'-2px', marginTop: '30px'}}>Swap From</FormLabel>
                            {/*<Container1>
                                <Select value={selectedCurrency} onChange={handleCurrencyChange}>
                                {currencies.map((currency, index) => (
                                <option key={index} value={currency.currency}>
                                    {currency.currency}
                                </option>
                                ))}
                                <option value='USDT'><img src={USDT}  /> USDT</option>
                                    <option value='BTC'><img src={BTC} defaultValue/> BTC</option>
                                    <option value='LTC'><img src={LTC} /> LTC</option>
                                    <option value='BCH'><img src={BCH} /> BCH</option>
                                </Select>
                                <Input type="text" value={amount} onChange={handleAmountChange} placeholder='Amount to withdraw' />
                            </Container1> */}
                            <SelectInput
                              value={from_currency}
                              onChange={handleFromChange}
                              style={{ color: '#4D0B6C', fontWeight: 'bolder' }}
                            >
                              <option value="">Select</option>
                              {[...new Set(currencies.map(currency => currency.ticker_name))]
                                .filter(currency => currency !== to_currency && !["BTC", "BNB", "LTC"].includes(currency))
                                .map((currency, index) => (
                                  <option key={index} value={currency}>
                                    {currency}
                                  </option>
                                ))}
                            </SelectInput>
                            <FormLabel style={{marginBottom: '-2px'}}>Amount</FormLabel>
                            <div><p style={{textAlign:'end', color: '#7BC875', fontWeight: '900', fontSize: '14px', marginRight: '10px', marginTop: '-25px', cursor: 'pointer', float:'right'}} onClick={() => setAmount(currencyBalance)}>max</p></div>
                            <FormInput value={amount} placeholder='Amount to swap' onChange={handleAmountChange} />
                            {error1 && <div style={{fontSize: '10px', color: '#FF0000', marginTop: '-30px', marginBottom: '5px', marginLeft: '10px'}}>{error1}</div>}
                            <FormLabel style={{marginBottom: '-2px'}} >Swap To</FormLabel>
                            {/*<Container1>
                                <Select value={to_currency} onChange={handleCurrencyChange}>
                                    <option value='USDT'><img src={USDT}  /> USDT</option>
                                    <option value='BTC'><img src={BTC} defaultValue/> BTC</option>
                                    <option value='LTC'><img src={LTC} /> LTC</option>
                                    <option value='BCH'><img src={BCH} /> BCH</option>
                                    {currencies.map((currency, index) => (
                                <option key={index} value={currency.currency}>
                                    {currency.currency}
                                </option>
                                ))}
                                </Select>
                                <Input type="text" value={amount} onChange={handleAmountChange} placeholder='Amount to withdraw' />
                            </Container1> */}
                            <SelectInput
                                value={to_currency}
                                onChange={handleToChange}
                                style={{ color: '#4D0B6C', fontWeight: 'bolder' }}
                              >
                                <option>Select</option>
                                {[...new Set(currencies.map(currency => currency.ticker_name))]
                                  .filter(currency => currency !== from_currency && !["BTC", "BNB", "LTC"].includes(currency)) // Exclude selected value from 'from_currency'
                                  .map((currency, index) => (
                                    <option key={index} value={currency}>
                                      {currency}
                                    </option>
                                  ))}
                              </SelectInput>
                            <div style={{display: 'flex', justifyContent: 'space-between' , fontSize: '10px', marginBottom: '10px', marginTop:'-20px', color: '#791FA4', marginLeft: '10px', marginRight: '10px'}}>
                              <div><p>Rate: {rateToCurrency} {rate} per {rateFromCurrency}</p><p>Fees: {rateFromCurrency} {fees}</p></div>
                              <div><p style={{ textAlign: 'end' }}>You get: {rateToCurrency} {total}</p></div>
                            </div>
                            <PrimaryBtn style={{borderRadius: '6px',cursor: isLoading || isLoading2 ? 'not-allowed' : 'pointer'}} disabled={isLoading || isLoading2} onClick={Swap}>Submit</PrimaryBtn>
                        </Form2> 
                        {showPinVerification && (
                          <Pin onPinVerified={handlePinVerified} onCancel={handleCancelPinVerification}/>
                        )}
                      </div>)}
                      </Popup>
                    </div>
                </PayoutCard>
                </PayoutCardWrapper>
                </CenterContent>
            </FormWrap>
        </Container>
        </MainContent>
        </MainContainer>
    </>
  )
}

export default Swap