import React, {useState, useEffect} from 'react'
import { CenterContent, Container, FormWrap,PayoutCard,PayoutH,PayoutP,PayoutP2,PayoutCardWrapper, SelectInput, RoundedDiv2, RoundedDiv3, P2pCard, P2pInfo, P2pInfo2, DivLeft2, DivRight2, TopUpCard, TopupH, TopupP } from '../components/Dashboard/content/dashElements'
import DashboardSidebar from '../components/Dashboard/sidebar'
import Navbar from '../components/Navbar'
import { Form2, Form3, FormArea, FormH1, FormH2, FormInput, FormLabel, Icon, PrimaryBtn, PrimaryBtn2 } from '../components/Form/formElements'
import Wallet from '../assets/wallet.png'
import bankAlt from '../assets/bank-alt.png'
import bank1 from '../assets/bank1.png'
import Popup from 'reactjs-popup'
import styled from 'styled-components';
import axios from 'axios'
import USDT from '../assets/usdt.png'
import BTC from '../assets/btc.png'
import BCH from '../assets/bch.png'
import LTC from '../assets/LTC.png'
import coin from '../assets/coin.png'
import { useNavigate, useLocation } from 'react-router-dom'
import Loader from '../components/loader'
import Warning from '../assets/warning.png'
import Pin from './pin'
import AlertModal from './modal'
import {MdOutlineReportProblem} from 'react-icons/md'
import {GrAdd} from 'react-icons/gr'
import Select from 'react-select';
import Sidebar1, { MainContainer, MainContent } from './nav'
import BASE_URL from '../apiConfig'
import { ToastContainer, toast } from 'react-toastify'

const Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopupCard = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 80%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  border: none;
  background: transparent;
`;
const DivPop = styled.div`
    height: 150px;
    @media screen and (max-width: 580px) {
        height: 195px;
    }
`


const Container1 = styled.div`
  display: flex;
  margin-top: 20px;

`;

const Select1 = styled.select`
padding: 16px 16px;
margin-bottom: 32px;
border: 1px solid #979797;
border-radius: 6px;
background-color: transparent;
color: #000;
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  outline: none;
`;
const Select2 = styled.select`
padding: 16px 16px;
margin-bottom: 32px;
border: 1px solid #979797;
border-radius: 6px;
background-color: #fff;
  border-right: 1px solid #979797;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  outline: none;
`;

const Input = styled.input`
  padding: 16px 16px;
  margin-bottom: 32px;
  border: 1px solid #979797;
  border-radius: 6px;
  background-color: transparent;
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  outline: none;
  width:100%;
  @media screen and (max-width: 580px) {
        width: 80%;
    }
`;


const Payout = () => {
  const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [dim, setDim] = useState(false);
    const [error, setError] = useState(null)
    const [error1, setError1] = useState(null)
    const [showDetails, setShowDetails] = useState(false);
    const [selectedData, setSelectedData] = useState(null);
    const [showPinVerification, setShowPinVerification] = useState(false);
    const [showPinVerification1, setShowPinVerification1] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const [user, setUser] = useState(null);

    const [selectedCurrency, setSelectedCurrency] = useState('USD');
    const [currencies, setCurrencies] = useState([]);
    const [amount, setAmount] = useState('');
    const [wallet_address, setWalletAddress] = useState('');
    const [wallet_type, setWalletType] = useState('');
    const [paymentmethod, setPaymentmethod] = useState('EMAIL');
    const [paymentmethodDetail, setPaymentmethodDetail] = useState('');
    const [totalbalance, setTotalBalance] = useState(null);
    const [totalfiatbalance, setTotalFiatBalance] = useState(null);
    const [selectedValue, setSelectedValue] = useState('');
    const [description, setDescription] = useState('');
    const [dispute_type, setDisputeType] = useState('');
    const [transaction_id, setTransactionID] = useState('');
    const [showNewDispute, setShowNewDispute] = useState(false);
    const [currencyBalance, setCurrencyBalance] = useState('')
    const [balance, setBalance] = useState(null);
    const [currencyFiatBalance, setCurrencyFiatBalance] = useState('')
    const [fiatBalance, setFiatBalance] = useState(null);
    const [user_Data, setUserData] = useState({});
    const [canWithdraw, setCanWithdraw] = useState(false);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const  currenzy  = location.state.currency;

    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        const data = response.data;
        if (data?.data.is_personnal_verification_approved == 1){
          setCanWithdraw(true)
          setLoading(false)
        }
        return response.data;
      } catch (error) {
        console.error(error);
      }
    };
    useEffect(() => {
      const getUserData = async () => {
        const data = await fetchUserData();
        setUserData(data);
        if (data.data.is_personnal_verification_approved == 1){
          setCanWithdraw(true)
          setLoading(false)
        }
      };
  
      getUserData();
    }, []);
    const handleDescription = (e) => {
      setDescription(e.target.value);
    };

    const handleDisputeType = (selectedOption) => {
      const { value } = selectedOption;
      setDisputeType(value);
    };
    const getOptionValue = (options) => options.value;

    const handleDisputeModal = (ID) => {
      setTransactionID(ID)
      setShowNewDispute(!showNewDispute);
    }

    const NewDispute = (event) => {
      event.preventDefault();
        axios.post(`${BASE_URL}/dispute`, {
          transaction_id : transaction_id,
          dispute_type: dispute_type,
          description: description
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(async (response) => {
            setAlertMessage(response.data.message)
            setShowAlert(true)
            await new Promise((resolve) => setTimeout(resolve, 3000));
            navigate('/p2p')
          })
          .catch(error => {
            if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.error) {
              // Extract error message from response and alert it
              const errorMessage = error.response.data.errors.error;
              const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
              setAlertMessage(jsonString);
              setShowAlert(true)
            } else {
              setAlertMessage('Service Error');
              setShowAlert(true);
            }
          });
    };

    const options = [
      {
        label: 'Sell',
        options: [
          { value: 'Sender name doesn’t tally', label: 'Sender name doesn’t tally' },
          { value: 'Payment not received', label: 'Payment not received' },
          { value: 'User filed chargeback', label: 'User filed chargeback' },
          { value: 'User sent stolen funds', label: 'User sent stolen funds' },
          { value: 'Bad terms and conditions', label: 'Bad terms and conditions' }
        ]
      },
      {
        label: 'Top Up',
        options: [
          { value: 'Delay in release of funds', label: 'Delay in release of funds' },
          { value: 'Incorrect account details', label: 'Incorrect account details' },
          { value: 'Fraudulent Account details', label: 'Fraudulent Account details' },
          { value: 'Agent is unreachable', label: 'Agent is unreachable' },
          { value: 'Bad terms and conditions', label: 'Bad terms and conditions' }
        ]
      },
      {
        label: 'Payout',
        options: [
          { value: 'Sender name doesn’t tally', label: 'Sender name doesn’t tally' },
          { value: 'Payment not received', label: 'Payment not received' },
          { value: 'User filed chargeback', label: 'User filed chargeback' },
          { value: 'User sent stolen funds', label: 'User sent stolen funds' },
          { value: 'Bad terms and conditions', label: 'Bad terms and conditions' },
          { value: 'Agent is unreachable', label: 'Agent is unreachable' }
        ]
      },
      {
        label: 'Buy',
        options: [
          { value: 'Delay in release of funds', label: 'Delay in release of funds' },
          { value: 'Incorrect account details', label: 'Incorrect account details' },
          { value: 'Fraudulent Account details', label: 'Fraudulent Account details' },
          { value: 'User is unreachable', label: 'User is unreachable' }
        ]
      }
    ];

    function handleSelectChange(e) {
      setSelectedValue(e.target.value);
    }


  
    const handleCurrencyChange = (e) => {
      setSelectedCurrency(e.target.value);
    };
    const [payment_id, setPaymentID] = useState('')
    const [userPayment, setUserPayment] = useState(null);

    const [showModal, setShowModal] = useState(false);

  const handleModal = () => {
    setShowModal(!showModal);
  }

  const [isChecked, setIsChecked] = useState(false);

  const handleCheckbox = (event) => {
    setIsChecked(event.target.checked);
  }

    const fetchUser = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        return response.data;
      } catch (error) {
        console.error(error);
      }
    };
    useEffect(() => {
      const getUser = async () => {
        const data = await fetchUser();
        setUser(data);
      };
  
      getUser();
    }, []);


    const handleReceived = async (id) => {
      try {
        await axios.post(`${BASE_URL}/trade/received`,{
          trade_id : id
        },{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // Handle successful deletion, e.g. remove the trade from the table data and update the UI
        setAlertMessage('Marked as received');
        setShowAlert(true);
      } catch (error) {
        console.error(error);
        // Handle error, e.g. display an error message to the user
      }
    };

  const fetchUserPayment = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/payment-method`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const getUserPayment = async () => {
      const data = await fetchUserPayment();
      setUserPayment(data);
    };

    getUserPayment();
  }, []);

  const handlePaymentID = (e) => {
    setPaymentID(e.target.value)
}
    const handleAmountChange = (e) => {
      setAmount(e.target.value);
    };
    const handlePaymentmethodChange = (e) => {
      setPaymentmethod(e.target.value);
    };
    const handlePaymentmethodDetailChange = (e) => {
      setPaymentmethodDetail(e.target.value);
    };
    const handleWalletAdrressChange = (e) => {
      setWalletAddress(e.target.value);
    };
    const handleWalletTypeChange = (e) => {
      setWalletType(e.target.value);
      const currencyObject = balance.data.find(
        (obj) => obj.ticker_name === e.target.value
      );
      if (currencyObject) {
        setCurrencyBalance(currencyObject.balance);
      }
    };
    const token = localStorage.getItem('bearerToken');
    useEffect(() => {
        const token = localStorage.getItem('bearerToken');
        const fetchCurrencies = async () => {
          try {
            const response = await axios.get(`${BASE_URL}/payment-method/sys-currencies`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            setCurrencies(response.data.data);
          } catch (error) {
            setError(error);
          }
        };
        fetchCurrencies();
      }, []);
      const navigate = useNavigate();

      const handlePinVerified = (event) => {
          event.preventDefault();
          if (!error || !error1) {
          axios.post(`${BASE_URL}/withdraw`, {
              amount: amount,
              wallet_address: paymentmethodDetail,
              wallet_type: wallet_type,
              type: paymentmethod,
          }, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }).then(result => {
            setAlertMessage(result.data.data.msg);
            setShowAlert(true);
            setShowPinVerification(false)
            setAmount('')
            setPaymentmethodDetail('')
          })
            .catch(error => {
              const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
              setAlertMessage(jsonString);
              setShowAlert(true);
              setShowPinVerification(false)
            })
          }
      }
      const [trade_amount, setTradeAmount] = useState('');
    const [trade_currency, setTradeCurrency] = useState(currenzy);

    const handleTradeCurrency = (e) => {
      setTradeCurrency(e.target.value);
      const currencyObject = fiatBalance.data.find(
        (obj) => obj.ticker_name === e.target.value
      );
      if (currencyObject) {
        setCurrencyFiatBalance(currencyObject.balance);
      }
    };
    const handleTradeAmount = (e) => {
      setTradeAmount(e.target.value);
    };
    
      const payOutFiat = (event) => {
        event.preventDefault();
        
        axios.post(`${BASE_URL}/trade/history/buy-sell-order`, {
            trade_amount: trade_amount,
            payment_id: payment_id,
            trade_type: "sell",
            trade_currency: trade_currency,
        }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }).then(response => {
          setSelectedData(response.data.data.data)
          console.log(response.data.data.data.id)
          navigate("/p2p/transaction/details", { state: { id: response.data.data.data.id } });
          //navigate('/p2p/transactions')
        })
        .catch(error => {
          if (error.response || error.response.data || error.response.data.errors || error.response.data.errors.error || error.response.errors.msg) {
            // Extract error message from response and alert it
            const jsonString = JSON.stringify((error.response.data.errors || error.response.errors.msg)).replace(/[\[\]{}'"]+/g, '');
            toast.error(jsonString, {
              position: toast.POSITION.TOP_CENTER
            });
          } else {
            setAlertMessage(error);
            setShowAlert(true);
          }
        });
        
    }
    const cryptoWithdrawal = (e) => {
      e.preventDefault();
      setShowPinVerification(true);
    };
    const handleCancelPinVerification = () => {
      setShowPinVerification(false);
    };

    const handleReceivedWithVerification = (id) => {
      setShowPinVerification1(true);
      setVerificationCallback(() => handleReceived.bind(null, id));
    };
    
    const [verificationCallback, setVerificationCallback] = useState(null);
    const handleCancelPinVerification1 = () => {
      setShowPinVerification1(false);
    };
    const validateTradeAmount = trade_amount => {
      if (parseFloat(trade_amount) > parseFloat(currencyFiatBalance)) {
          setError1("Payout amount cannot exceed total Balance");
      } else {
        setError1("");
      }
    };

    const validateAmount = amount => {
      if (parseFloat(amount) > parseFloat(currencyBalance)) {
          setError("Payout amount cannot exceed total Balance");
      } else {
        setError("");
      }
    };

    function handleChange(e) {
      handleSelectChange(e)
       handleTradeCurrency(e)
    }

    const handleAlertClose = () => {
      setShowAlert(false);
    };
    const [isOpen, setIsOpen] = useState(true);

    const toggleSidebar = () => {
      setIsOpen(!isOpen);
    };

    const fetchBalance = async () => {
      const response = await axios.get(`${BASE_URL}/account/balance`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    };
    useEffect(() => {
      const getBal = async () => {
        const data = await  fetchBalance();
        setBalance(data);
        setFiatBalance(data)
      };
  
      getBal();
    }, []);

    const handleBetaLinkClick = () => {
      setAlertMessage(<>
        <h5>Coming Soon</h5>
        <p style={{fontSize:'12px'}}>This feature is under development and will be available soon.</p>
      </>);
      setShowAlert(true);
    };
    const dropdownOptions = [
      { text: "USDT", imageSrc: '../assets/usdt.png', backgroundColor: "purple" },
      { text: "BTC", imageSrc: '../assets/btc.png', backgroundColor: "pink" },
    ];
  return (
    ( !loading ? (
    <>
    {showAlert && (
        <AlertModal
          isModalOpen={showAlert}
          alertMessage={alertMessage}
          onClose={handleAlertClose}
        />
      )}
      <Navbar toggleSidebar={toggleSidebar}/>
        <MainContainer isOpen={isOpen}>
            <Sidebar1 isOpen={isOpen} toggleSidebar={toggleSidebar} />
            <MainContent>
        <Container>
        <ToastContainer />
            <FormWrap>
                <CenterContent>
                <PayoutCardWrapper>

                <TopUpCard onClick={() => {
                      if (canWithdraw) {
                        setOpen1(true);
                        setDim(true);
                      } else {
                        setAlertMessage("Please go to compliance and verify your account first!");
                        setShowAlert(true);
                      }
                    }} >
                    <img src={bankAlt} style={{margin: '20px', borderRadius: '50%', border:'1px solid #4D0B6C'}}/>
                    <div>
                      <TopupH>Send money via P2P</TopupH>
                      <TopupP>Receive local currencies like USD, NGN, KES, etc.</TopupP>
                    </div>
                    <div>
                    {userPayment && (
                      <Popup open={open1} onClose={() => { setOpen1(false); setDim(false)}} modal closeOnDocumentClick contentStyle={{width: '100%', height: '100%', backgroundColor: 'white', overflow: 'scroll'}} overlayStyle={{background: dim ? 'rgba(0, 0, 0, 0.5)' : 'none'}}>
                        {close => (
                      <div className="content" >
                      <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><a className="close" onClick={close}>
                        <b style={{fontSize: '26px', marginLeft: '10px', alignItems: 'center',cursor:'pointer'}}>&times; | </b>
                        </a> <b>Send money via P2P</b> </div>
                        <Form2 style={{marginTop: '60px'}} onSubmit={payOutFiat}>
                            <FormH2 style={{color: '#4D0B6C', display: 'flex', lineHeight: 'normal', alignItems : 'center',justifyContent: 'center' }}><img src={bank1} style={{marginRight: '10px'}} /> Send Money </FormH2>
                            <div><p style={{textAlign:'end', color: '#7BC875', fontWeight: '900', fontSize: '14px', marginRight: '10px', marginBottom: '-20px', cursor: 'pointer', float:'right'}} onClick={() => {setTradeAmount(currencyFiatBalance); validateTradeAmount(currencyFiatBalance)}}>max</p></div>
                            <Container1>
                                <Select1 value={currenzy} onChange={handleChange} onBlur={() => validateTradeAmount(trade_amount)}>
                                    <option value={currenzy}>
                                      {currenzy}
                                    </option>
                                </Select1>
                                <Input type="text" value={trade_amount} onChange={handleTradeAmount} placeholder='Amount to withdraw' onBlur={() => validateTradeAmount(trade_amount)} />
                            </Container1>
                            {error1 && <div style={{fontSize: '10px', color: '#FF0000', marginTop: '-26px', marginBottom: '5px', marginLeft: '10px'}}>{error1}</div>}
                            <FormLabel style={{marginBottom: 2}}>Select Recipient</FormLabel>
                            <SelectInput id='paymentMethod' value={payment_id} onChange={handlePaymentID}>
                              <option>Select Beneficiary</option>
                              {userPayment.data.length ? userPayment.data.map((method, index) => (
                                method.currency == currenzy && (
                                  <option key={index} value={method.id}>
                                    {method.payment_info?.bank_name ? (
                                      `${method.payment_info.bank_name} ${method.payment_info.account_number}`
                                    ) : (
                                      `${Object.values(method.payment_info)[0]} ${Object.values(method.payment_info)[2]}`
                                    )}
                                  </option>
                                  )) ) : <option>No payment method (Settings &gt; Payment &gt; Add Payment method)</option> }
                            </SelectInput>
                            <div style={{marginTop:'-25px', marginBottom:'20px', textAlign:'center', alignItems:'centre', display:'flex', justifyContent:'center', alignItems:'center',cursor:'pointer'}}> <a href='/add-payment' style={{textDecoration:'none'}}><GrAdd/> Add Beneficiary</a></div>
                            <PrimaryBtn style={{borderRadius: '6px', cursor: parseFloat(trade_amount) > parseFloat(currencyFiatBalance) ? '' : 'pointer'}}>Submit</PrimaryBtn>
                        </Form2> 
                      </div>)}
                      </Popup> ) }
                    
                      {showDetails && (
                    <Popup open={open2} onClose={() => { setOpen2(false); setDim(false)}} modal closeOnDocumentClick contentStyle={{width: '100%', height: '100%', backgroundColor: 'white', overflow: 'scroll'}} overlayStyle={{background: dim ? 'rgba(0, 0, 0, 0.5)' : 'none'}}>
                        {close => (
                      <div className="content" >
                      <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><a className="close" onClick={close}>
                        <b style={{fontSize: '26px', marginLeft: '10px', alignItems: 'center'}}>&times; | </b>
                        </a> <b>Confirm Payment</b> </div>
                          <Form3 style={{backgroundColor: '#FDF9FF'}}>
                            <FormH2 style={{marginTop: '-45px', fontWeight: 'bolder' }}>Confirm Payment</FormH2>
                            <p style={{margin: '10px'}}>Wait for the customer to transfer the funds into your chosen payment method</p>
                            <FormH1>{selectedData.trade_currency} {selectedData.trade_amount}</FormH1>
                            <RoundedDiv3 style={{background: '#fff', marginTop: '20px', marginLeft: '15px'}}>
                            <br></br>
                            <FormLabel style={{fontSize: '16px'}}>{selectedData.payment_method.payment_info.method_name}</FormLabel>
                            <RoundedDiv2 style={{}}>
                            {Object.entries(selectedData.payment_method.payment_info).map(([key, value]) => (
                                <P2pCard>
                                    <DivLeft2>
                                        <P2pInfo style={{marginBottom: '-15px'}}>{key.replace(/_/g, ' ')}</P2pInfo>
                                    </DivLeft2>
                                    <DivRight2>
                                        <P2pInfo2 style={{marginBottom: '-15px'}}>{value}</P2pInfo2>
                                    </DivRight2>
                                    
                                </P2pCard>
                                ))}
                                <P2pCard>
                                <DivLeft2><P2pInfo style={{marginBottom: '-15px'}}>Transaction ID</P2pInfo></DivLeft2>
                                <DivRight2><P2pInfo2 style={{marginBottom: '-15px'}}>{selectedData.transaction_id}</P2pInfo2></DivRight2>
                                </P2pCard>
                            </RoundedDiv2>
                        </RoundedDiv3>
                        <div style={{display:'flex'}}>
                          <div style={{width: '97%', marginRight: '10px'}}>
                            <PrimaryBtn2 style={{background: 'linear-gradient(180deg, #F3E8F7 0%, #EBDAF1 100%)',color: '#791FA4', borderRadius: '6px', width: '100%'}}>Cancel</PrimaryBtn2>
                          </div>
                          <div style={{width: '100%'}}>
                            <PrimaryBtn2 style={{backgroundColor: '#9316BF', borderRadius: '6px', width: '100%'}} onClick={handleModal}>Received</PrimaryBtn2>
                          </div>
                        </div>
                        <div style={{textAlign: 'center', alignItems: 'center', marginTop:'15px'}}><FormLabel style={{color: '#B51010', fontSize: '16px', alignItems: 'center', cursor: 'pointer'}} onClick={() =>  handleDisputeModal(selectedData.trade_id)} ><MdOutlineReportProblem/> Dispute</FormLabel></div>
                          </Form3>
                          {showModal && (
                            <Background className="modal">
                            <PopupCard className="modal-content">
                              <Icon src={Warning} />
                              <CloseButton onClick={handleModal}>&times;</CloseButton>
                              <DivPop style={{textAlign: 'center', alignItems: 'center'}} >
                              <h4>Confirm you have recieved the <br></br> required amount from the customer</h4>
                              <div style={{marginTop: '20px', marginBottom: '-20px'}} ><FormLabel style={{display: 'inline-flex', color: '#595959', fontWeight: 'initial'}} ><FormInput type='checkbox' id="starter" name="businessType" value="starter" style={{width: '25px', height: '25px', marginRight: '2px'}} checked={isChecked} onChange={handleCheckbox} />I have recieved the agreed amount of payment</FormLabel></div>
                              </DivPop>
                              <PrimaryBtn2 disabled={!isChecked} style={{backgroundColor: '#9316BF', borderRadius: '6px', width: '100%'}} onClick={() => handleReceivedWithVerification(selectedData.trade_id)}>Confirm</PrimaryBtn2>
                              {showPinVerification1 && (
                                <Pin
                                  show={showPinVerification1}
                                  setShow={setShowPinVerification1}
                                  onPinVerified={verificationCallback}
                                  onCancel={handleCancelPinVerification1}
                                />  )}
                            </PopupCard>
                          </Background>
                          )}
                          {showNewDispute && (
                            <Background className="modal">
                            <PopupCard className="modal-content">
                              <CloseButton onClick={handleDisputeModal}>&times;</CloseButton>
                              <h3 style={{margin:'10px', textAlign: 'center'}}>Open New Dispute</h3>
                              <Form2 style={{border: 'none', marginBottom: '-20px', marginTop: '-40px'}} onSubmit={NewDispute}>
                                <FormLabel>Select Dispute Type</FormLabel>
                                <Select style={{ borderColor: '#979797' }} value={{ value: dispute_type, label: dispute_type }} onChange={handleDisputeType} options={options} getOptionValue={(option) => option.value}/>                                
                                <FormLabel style={{marginTop: '15px', marginBottom: '0px'}}>Description</FormLabel>    
                                <FormArea style={{marginBottom: ''}} value={description} onChange={handleDescription} />                            
                              <PrimaryBtn2  style={{backgroundColor: '#9316BF', borderRadius: '6px', width: '100%'}} Link>Open Dispute</PrimaryBtn2>
                              </Form2>
                            </PopupCard>
                          </Background>
                          )}
                      </div>)}
                    </Popup>
                    )}
                    </div>
                </TopUpCard>
                {/*<TopUpCard onClick={() => {
                      if (canWithdraw) {
                        setOpen(true);
                        setDim(true);
                      } else {
                        setAlertMessage("Please go to compliance and verify your account first!");
                        setShowAlert(true);
                      }

                    }} >*/}
                   { currenzy == "USDT" && (<TopUpCard onClick={handleBetaLinkClick} >
                    <img src={Wallet} style={{margin: '20px', borderRadius: '50%', border:'1px solid #4D0B6C'}}/>
                    <div>
                      <TopupH>Send Crypto <sup style={{ fontSize: '10px', borderRadius: '16px', textAlign: 'center', color: '#4D0B6C'}}>BETA</sup></TopupH>
                      <TopupP>Transfer money to your crypto wallet address</TopupP>
                    </div>
                    <div>
                      <Popup open={open} onClose={() => { setOpen(false); setDim(false)}} modal closeOnDocumentClick contentStyle={{width: '100%', height: '100%', backgroundColor: 'white', overflow: 'scroll'}} overlayStyle={{background: dim ? 'rgba(0, 0, 0, 0.5)' : 'none'}}>
                        {close => (
                      <div className="content" >
                      <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><a className="close" onClick={close}>
                        <b style={{fontSize: '26px', marginLeft: '10px', alignItems: 'center',cursor:'pointer'}}>&times; | </b>
                        </a> <b>Crypto withdrawal request</b> </div>
                        {/*<Form2 style={{marginTop: '60px'}}>
                            <FormH2 style={{color: '#4D0B6C', display: 'flex', lineHeight: 'normal', alignItems : 'center',justifyContent: 'center' }}><img src={coin} style={{marginRight: '10px'}} /> Crypto Payout </FormH2>
                            <FormLabel style={{marginBottom: '-20px', marginTop: '20px'}}>Select Crypto</FormLabel>
                            <div><p style={{textAlign:'end', color: '#7BC875', fontWeight: '900', fontSize: '14px', marginRight: '10px', marginBottom: '-20px', cursor: 'pointer', float:'right'}} onClick={() => {setAmount(currencyBalance); validateAmount(currencyBalance)}}>max</p></div>
                            <Container1>
                                <Select1 value={wallet_type} onChange={handleWalletTypeChange} onBlur={() => validateAmount(amount)}>
                                <option>Select</option>
                                <option value='USDT'><img src={USDT}  /> USDT</option>
                                <option value='BTC'><img src={BTC} defaultValue/> BTC</option>
                                <option value='LTC'><img src={LTC} /> LTC</option>
                                <option value='BCH'><img src={BCH} /> BCH</option>
                                </Select1>
                                <Input type="text" value={amount} onChange={handleAmountChange} placeholder='Amount to withdraw' onBlur={() => validateAmount(amount)}/>
                            </Container1>
                            {error && <div style={{fontSize: '10px', color: '#FF0000', marginTop: '-26px', marginBottom: '5px', marginLeft: '10px'}}>{error}</div>}
                            <div style={{display: 'flex', margin: '10px', marginBottom: '30px', float: 'right'}}><img src={USDT} style={{width:'30px', height: '30px', marginRight: '3px'}} /> <img src={BTC} style={{width:'30px', height: '30px', marginRight: '3px'}}  /> <img src={LTC}  style={{width:'30px', height: '30px', marginRight: '3px'}} /> <img src={BCH} style={{width:'30px', height: '30px', marginRight: '3px'}}  /> </div>
                            <FormLabel style={{marginBottom: 2}}>Wallet Address</FormLabel>
                            <FormArea placeholder='Input your Wallet Address' value={wallet_address} onChange={handleWalletAdrressChange} />
                            <div style={{fontSize: '10px', color: '#A300FF', marginTop: '-30px', marginBottom: '25px', marginLeft: '10px'}}>* only BEP20 and BTC Network wallet addresses are accepted </div>
                            <PrimaryBtn onClick={cryptoWithdrawal} disabled={parseFloat(amount) > parseFloat(currencyBalance)} style={{borderRadius: '6px', cursor: parseFloat(amount) > parseFloat(currencyBalance) ? 'not-allowed' : 'pointer'}}>Submit</PrimaryBtn>
                        </Form2> */}
                        <Form2>
                          <FormH2 style={{color: '#4D0B6C', display: 'flex', lineHeight: 'normal', alignItems : 'center',justifyContent: 'center' }}><img src={coin} style={{marginRight: '10px'}} /> Crypto Payout </FormH2>
                          <FormLabel style={{marginBottom: '-20px', marginTop: '20px'}}>Select Crypto</FormLabel>
                            <div><p style={{textAlign:'end', color: '#4D0B6C', fontWeight: '600', fontSize: '14px', marginRight: '10px', marginBottom: '-20px', cursor: 'pointer', float:'right'}} onClick={() => {setAmount(currencyBalance); validateAmount(currencyBalance)}}>Max</p></div>
                            <Container1>
                                <Select2 value={wallet_type} onChange={handleWalletTypeChange} onBlur={() => validateAmount(amount)}>
                                  <option>Select</option>
                                  <option value='USDT'><img src={USDT}  /> USDT</option>
                                </Select2>
                                <Input type="text" value={amount} onChange={handleAmountChange} placeholder='Amount to withdraw' onBlur={() => validateAmount(amount)}/>
                            </Container1>
                            <FormLabel style={{marginBottom: '0px'}}>Payment method</FormLabel>
                            <SelectInput value={paymentmethod} onChange={handlePaymentmethodChange}>
                              <option value='EMAIL'>Binance Email</option>
                              <option value='PAY_ID'>Binance Pay ID</option>
                              <option value='BINANCE_ID'>Binance ID</option>
                            </SelectInput>
                            <FormLabel style={{marginBottom: '0px'}}>Enter your {paymentmethod.replace(/_/g, ' ')} </FormLabel>
                            <FormInput type='text' value={paymentmethodDetail} onChange={handlePaymentmethodDetailChange} placeholder={`Enter your ${paymentmethod.replace(/_/g, ' ')}`}/>
                            <PrimaryBtn onClick={cryptoWithdrawal} disabled={parseFloat(amount) > parseFloat(currencyBalance)} style={{borderRadius: '6px', cursor: parseFloat(amount) > parseFloat(currencyBalance) ? 'not-allowed' : 'pointer'}}>Submit</PrimaryBtn>
                        </Form2>
                        {showPinVerification && (
                          <Pin onPinVerified={handlePinVerified} onCancel={handleCancelPinVerification}/>
                        )}
                      </div>)}
                      </Popup>
                    </div>
                </TopUpCard>)}
                </PayoutCardWrapper>
                </CenterContent>
            </FormWrap>
        </Container>
        </MainContent>
        </MainContainer>
    </>)
    :(<Loader/>))
  )
}

export default Payout