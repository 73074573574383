import React from 'react'
import ZeenahPay from '../assets/ZeenahPay.png'
import { Icon, PrimaryBtn as PrimaryBtn1 } from '../components/Form/formElements'
import styled from "styled-components";


 const Div1 = styled.div`
    margin-bottom: 20px;
    margin-left: 80px;
    width: 100%;

    @media screen and (max-width: 600px) {
        margin-bottom: 20px;
        margin-left: 50px;
        width: 100%;
    }
    `

const Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000
`;

const PopupCard = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  position: relative;
  width: 100%;
  max-width: 70vw;
  margin: 20px;
  padding: 30px;
  overflow-y: auto;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  border: none;
  background: transparent;
`;
const DivPop = styled.div`
    margin: 50px
`
const AlertModal = ({ isModalOpen, alertMessage, onClose }) => {

  return isModalOpen &&  (
    <Background>
         <PopupCard>
         <CloseButton onClick={onClose}>&times;</CloseButton>
                    <Icon src={ZeenahPay}  width='160px'/>
                    <DivPop style={{textAlign: 'center', alignItems: 'center', wordWrap: 'break-word'}}><p>{alertMessage}</p></DivPop>
            <PrimaryBtn1 onClick={onClose} style={{ borderRadius: '12px', backgroundColor: '#791FA4', textAlign: 'center', width: '100%'}}>Ok</PrimaryBtn1>
         </PopupCard>
       </Background>
  )
}

export default AlertModal