import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components';
import {BsPaperclip} from 'react-icons/bs'
import {AiOutlineSend} from 'react-icons/ai'
import {FaWindowMinimize} from 'react-icons/fa'
import { useLocation, useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import { Form4 } from '../components/Form/formElements'
import AlertModal from './modal';
import BASE_URL from '../apiConfig'

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
`;

const ChatHeader = styled.header`
  background-color: #791FA4;
  color: white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ChatMessages = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
    display: flex;
  flex-direction: column;
`;

const ChatMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ isSelf }) => (isSelf ? 'flex-end' : 'flex-start')};
  margin-bottom: 1rem;
`;

const ChatMessageAuthor = styled.span`
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const ChatMessageTime = styled.span`
  font-size: 0.75rem;
  color: #999;
  margin-bottom: 0.25rem;
`;

const ChatMessageContent = styled.p`
  background-color: ${({ isSelf }) => (isSelf ? '#791FA4' : '#eee')};
  color: ${({ isSelf }) => (isSelf ? 'white' : 'black')};
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  margin: 0;
  max-width: 50%;
`;

const ChatInputForm = styled.form`
  display: flex;
  background-color: #f4f4f4;
  padding: 0.5rem;
`;

const ChatInputContainer = styled.div`
  display: flex;
  flex: 1;
`;

const ChatInputField = styled.div`
  flex: 1;
`;

const ChatInput = styled.input`
  border: none;
  outline: none;
  padding: 0.5rem;
  border-radius: 0.25rem;
  width: 100%;
`;

export const ChatFileInputContainer = styled.div`
  position: relative;
  margin-left: 1rem;
  cursor: pointer;
`;

export const ChatOption = styled.div`
  position: relative;
  margin: 0.5rem;
  cursor: pointer;
  font-size: 18px;
  font-weight: bolder;
`;

export const ChatFileInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
`;

export const ChatFileInputLabel = styled.label`
  display: block;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: #791FA4;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
`;

const ChatSubmitButton = styled.button`
  border: none;
  outline: none;
  background-color: #791FA4;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  margin-left: 0.5rem;
  cursor: pointer;
`;
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  background-color: transparent;
  width: 80vw;
`;


const Chat = ({ isChatOpen, onClose, transaction_id, chatName }) => {
  const [inputValue, setInputValue] = useState('');
  const [messages, setMessages] = useState([]);
  const [file, setFile] = useState(null);
  const token = localStorage.getItem('bearerToken');
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const inputRef = useRef(null);
  const chatMessagesRef = useRef(null);



  useEffect(() => {
    const getUser = async () => {
      const data = await fetchUser();
      setUser(data);
    };

    getUser();
  }, []);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
 
  const handleFileInputChange = (e) => {
    setFile(e.target.files[0]);
    handleSubmit(e)
  };

  const [user, setUser] = useState(null);

  const fetchUser = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const getUser = async () => {
      const data = await fetchUser();
      setUser(data);
    };

    getUser();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const messageText = inputValue;

      // Clear the input field immediately
      setInputValue('');
  
      // Update the UI immediately with the new message
      setMessages([...messages, { message: messageText, sender: user?.data?.id }]);
      await axios.post(
        `${BASE_URL}/message`,
        { message: messageText,
          file: file,
          trade_id: transaction_id,
         },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Clear input field and fetch all messages
      setInputValue('');
      inputRef.current.focus();
      fetchMessages();
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMessages = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/message/${transaction_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setMessages([...response.data.data]);
      chatMessagesRef.current.scrollTop = chatMessagesRef.current.scrollHeight;
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    // Fetch all messages on initial render if the chat is open
    if (isChatOpen) {
      fetchMessages();
  
      // Poll server for new messages every 10 seconds
      const intervalId = setInterval(() => {
        fetchMessages();
      }, 10000);
  
      // Cleanup function to clear interval on unmount
      return () => clearInterval(intervalId);
    }
  }, [isChatOpen]);

  // Scroll to the bottom of the chat messages when a new message is added

  const handleAlertClose = () => {
    setShowAlert(false);
  };

  return (
    <>
        {isChatOpen && (
            <>
            {showAlert && (
                <AlertModal
                isModalOpen={showAlert}
                alertMessage={alertMessage}
                onClose={handleAlertClose}
                />
            )}
            <ModalOverlay>
                <ModalContent>
                    <Form4 style={{width: '80%', height: '70vh',marginTop:'-35px', border: '0px', boxShadow: '0px 4px 50px 8px rgba(0, 0, 0, 0.06)'}}>
                        <ChatContainer>
                        <ChatHeader>
                            <div style={{display: "flex", alignItems: "center", cursor:'pointer'}} onClick={() => onClose()}><FaWindowMinimize /></div>
                            {/*<ChatTitle>Dispute ID : {transaction_id}</ChatTitle> */}
                            <div>{chatName}</div>
                        </ChatHeader>
                        <ChatMessages ref={chatMessagesRef}>
                            {messages.map((message, index) => (
                            <ChatMessage key={index} isSelf={user?.data?.id == message.sender}>
                            {message.created_at ? (
                              <ChatMessageTime>
                                {new Date(message.created_at).toLocaleTimeString()}
                              </ChatMessageTime>
                            ) : (
                              <ChatMessageTime>Sending...</ChatMessageTime>
                            )}
                                <ChatMessageContent isSelf={user?.data?.id == message.sender}>
                                {message.message}
                                </ChatMessageContent>
                            </ChatMessage>
                            ))}
                        </ChatMessages>
                        <ChatInputForm onSubmit={handleSubmit} style={{marginBottom: '170px'}}>
                            <ChatInputContainer>
                                {/*<DropupWrapper>
                                    <ChatFileInputContainer>
                                        <ChatFileInput type="file" onChange={handleFileInputChange} />
                                        <ChatFileInputLabel><BsPaperclip/></ChatFileInputLabel>
                                    </ChatFileInputContainer>
                                </DropupWrapper> */}
                            <ChatInputField>
                                <ChatInput
                                type="text"
                                placeholder="Type a message..."
                                value={inputValue}
                                onChange={handleInputChange}
                                />
                            </ChatInputField>
                            </ChatInputContainer>
                            <ChatSubmitButton type="submit"><AiOutlineSend/></ChatSubmitButton>
                        </ChatInputForm>
                        </ChatContainer>
                    </Form4> 
                </ModalContent>
            </ModalOverlay>
            
            </>)}
    </>
  )
}

export default Chat