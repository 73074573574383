import React, {useState, useEffect,useRef} from 'react'
import {GrPrevious, GrNext } from 'react-icons/gr';
import {AiOutlineArrowRight} from 'react-icons/ai';
import {BsDownload, BsSearch} from 'react-icons/bs';
import {IoIosArrowDown} from 'react-icons/io';
import axios from 'axios'
import { Table as Tablle, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { CenterContent, Container, FormWrap, SearchBar, TdRef, Tdreg, ThText,Content,H2, TextH2, ContentDiv, RoundedDiv2, P2pCard, P2pH, P2pT, PrimaryBtn, P2pInfo, P2pInfo2, DivLeft2, DivRight2, RoundedDiv3,SelectInput, InputArea,DivContainer, GridDiv, GridDivL, P2pGrid, TabText, PaymentCard, Tdreg1, TableContainer, Tablee, TableHead, TableRow, TableBody, TableHeader, TableCell, DivLeft, DivRight, RoundedDiv, MobileOnly, DesktopDiv, NonMobile } from './dashElements';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Popup from 'reactjs-popup'
import { CancelBtn, EditBtn, P2pcontainer, P2pH1, P2pTable } from '../p2pElements/p2pElements'
import { NavLink } from 'react-router-dom'
import Nothing from '../../../assets/nothingtoshow.png'
import SentIcon from '../../../assets/sent.png'
import ReceivedIcon from '../../../assets/received.png'
import Empty from '../../../assets/empty.svg'
import Loader from '../../loader'
import {  Form2, Form3, FormContent, FormH1, FormH2, FormInput, FormLabel, Icon, PrimaryBtn2, Text, Text2, FormArea, Form3A } from '../../Form/formElements'
import { useReactToPrint } from 'react-to-print';
import styled from 'styled-components';
import BASE_URL from '../../../apiConfig';
import { DropdownContent, EmailContainer, Title } from '../../MobileTable';

const Div9 = styled.div`
@media (max-width: 600px) {
    font-size: 10px;
  }
`

// Inside your component
export const SearchInput = styled.div`
  position: relative;

  input {
    padding-left: 30px; /* Adjust the value as needed */
  }

  .search-icon {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translateY(-50%);
    pointer-events: none;
  }
`;
const containerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginLeft:'5px',
  marginBottom: '-10px'
};

const elementStyle = {
  padding: '10px', // Add spacing within the elements
};

const Table = () => {
    const [transactions, setTransactions] = useState(null);
    const [selectedData, setSelectedData] = useState({});
    const [showDetails, setShowDetails] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [dim, setDim] = useState(false);
    const token = localStorage.getItem('bearerToken');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);

    const fetchTransactions = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/cmd-get-all`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
    
          return response.data;
        } catch (error) {
          console.error(error);
        }
      };
      useEffect(() => {
        const getTransactions = async () => {
          const data = await fetchTransactions();
          setTransactions(data);
        };
    
        getTransactions();
      }, []);

      const handleRowClick = (trnx) => {
        setSelectedData(trnx);
        setShowDetails(true);
      };
      const [isEmpty, setIsEmpty] = useState(false);
      const [searchQuery, setSearchQuery] = useState('');
      const filteredTransactions = transactions?.data.data.filter((transaction) =>
      transaction.reference.toLowerCase().includes(searchQuery.toLowerCase())||
      transaction.status.toLowerCase().includes(searchQuery.toLowerCase()) ||
      transaction?.customerEmail?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      transaction?.customerName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      transaction.order_type.replace('_', ' ').toLowerCase().includes(searchQuery.toLowerCase()) ||
      transaction.currency.toLowerCase().includes(searchQuery.toLowerCase())
      )

      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const currentItems = filteredTransactions?.slice(indexOfFirstItem, indexOfLastItem);
  
      const pageNumbers = [];
      for (let i = 1; i <= Math.ceil(filteredTransactions?.length / itemsPerPage); i++) {
        pageNumbers.push(i);
      }

     const handlePrevPage = () => {
        setCurrentPage( currentPage - 1);
      };
     const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
      };
      const handleClick = e => {
        setCurrentPage(Number(e.target.id));
      };

      const isLastPage = currentPage === pageNumbers.length;
      const isFirstPage = currentPage === 1;
      const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // reset to first page when search query changes
        setIsEmpty(filteredTransactions.length === 0);
        if (searchQuery === 0) {
          setSearchQuery(null);
        }
      };

      const componentRef = useRef();
      const componentRef1 = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const handlePrint1 = useReactToPrint({
    content: () => componentRef1.current,
  });
  const [isOpenZ, setIsOpenZ] = useState(false);

  const toggleDropdown = (index) => {
    setIsOpenZ((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };
  return (
    <>
        <Container>
        
    <P2pcontainer style={{background: '#fff'}}>
            <SearchInput style={{position:"flex", marginTop: '20px', width: '100%', marginBottom: '20px'}}>
                <SearchBar type="text"  value={searchQuery} onChange={handleSearchInputChange} placeholder=' Search transactions'/>
                <span className="search-icon"><BsSearch /></span>
                {/*<div style={{float: 'right'}}>Filter</div>*/}
            </SearchInput>
     
    <FormWrap>
    {transactions?.data.data.length  ? (
                <CenterContent>
                {isEmpty ? (
                  <div style={{justifyContent:'center', display: 'grid'}}>
                        <div style={{textAlign: 'center'}}>
                          <img src={Nothing} alt="Empty table" />
                        </div>
                        <div style={{textAlign: 'center'}}>
                          <h5 style={{marginBottom: '10px'}}>No search results found</h5>
                        </div>
                        <div>
                        </div>
                      </div>
                ) : (
                  <>
                  <NonMobile>
                    <TableContainer>
                        <Tablee>
                          <TableHead>
                            <TableRow>
                                <TableHeader>Time</TableHeader>
                                <TableHeader>Amount</TableHeader>
                                <TableHeader>Email</TableHeader>
                                <TableHeader>Name</TableHeader>
                                <TableHeader>Transaction Type</TableHeader>
                                <TableHeader>Transaction ID</TableHeader>
                                <TableHeader>Status</TableHeader>
                            </TableRow>
                          </TableHead>
                          <TableBody style={{cursor: 'pointer'}}>
                            {currentItems.map((trnx, index) => (
                              <TableRow key={index}>
                                  <TableCell>{new Date(trnx?.created_at).toLocaleString('en-GB', {
                                    day: 'numeric',
                                    month: 'short',
                                    year: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour12: true
                                })}</TableCell>
                                  <TableCell onClick={() => {setOpen1(true); setDim(true); handleRowClick(trnx)}} style={{fontWeight:'500'}}>{trnx?.currency} {trnx?.fiatAmount}{trnx?.to_amount}</TableCell>
                                  <TableCell onClick={() => {setOpen1(true); setDim(true); handleRowClick(trnx)}}>{trnx?.customerEmail}</TableCell>
                                  <TableCell onClick={() => {setOpen1(true); setDim(true); handleRowClick(trnx)}} style={{fontWeight:'500'}}>{trnx?.customerName}</TableCell>
                                  <TableCell onClick={() => {setOpen1(true); setDim(true); handleRowClick(trnx)}}>{trnx?.order_type && trnx.order_type.replace('_', ' ')}</TableCell>
                                  <TableCell onClick={() => {setOpen1(true); setDim(true); handleRowClick(trnx)}} style={{overflowWrap: 'break-word', margin:'10px'}}>{trnx.reference.slice(0,13)}</TableCell>
                                  <TableCell onClick={() => {setOpen1(true); setDim(true); handleRowClick(trnx)}}><P2pH1 style={{ fontSize: '12px', borderRadius: '16px', textAlign: 'center', padding: '8px', backgroundColor: trnx.status === 'pending' ? '#FFF8D2' : trnx.status === 'failed' ? '#FBD5D5' : trnx.status === 'completed' ? '#DEF7EC' :'#0070FF', color: trnx.status === 'pending' ? '#D79C00' : trnx.status === 'failed' ? '#9B1C1C' : trnx.status === 'completed' ? '#03543F' :'#0070FF'}}>{trnx.status}</P2pH1></TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                      </Tablee>
                    </TableContainer>
                    </NonMobile>
                    <MobileOnly>
                      <RoundedDiv style={{margin:'0px'}}>
                      {currentItems.map((trnx, index) => (
                    <DivContainer style={{cursor:'pointer', paddingRight:'20px'}} onClick={() => {setOpen1(true); setDim(true); handleRowClick(trnx)}} key={index}>
                                  <DivLeft style={{marginLeft:'5px'}}>
                                    <img src={trnx?.order_type ? SentIcon : ReceivedIcon} alt='icon' style={{width: '44px', height: '44px', borderRadius: '10px', objectFit: 'cover'}} />
                                      <div style={{textAlign: 'left', marginLeft: '20px', fontFamily:'Inter'}}>
                                        <h3 style={{fontWeight:'500',fontSize:'14px', lineHeight:'19.36px',color:'#1E1E1E'}}>{trnx?.order_type && trnx.order_type.replace('_', ' ')}</h3>
                                        <p style={{color:'#979797',fontWeight:'500',fontSize:'14px', lineHeight:'16.94px'}}>{new Date(trnx?.created_at).toLocaleString('en-GB', {
                                    day: 'numeric',
                                    month: 'short',
                                    year: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour12: true
                                })}</p>
                                      </div>
                                  </DivLeft> 
                                  <DivRight style={{display: 'grid',marginRight:'5px'}}>
                                    <p style={{marginRight: '10px', textAlign: 'center', fontWeight:'500',fontSize:'16px', lineHeight:'19.36px',color:'#1E1E1E'}}>{trnx?.currency} {trnx?.fiatAmount}{trnx?.to_amount}</p>
                                    <p style={{ textAlign: 'center', fontSize:'14px', fontFamily:'Poppins', color:'#791FA4'}}>{trnx.status}</p>
                                  </DivRight>
                                </DivContainer>
                                ))}
                      </RoundedDiv>
                      </MobileOnly>
                    <div>
                    <ul style={{display: 'flex', listStyle: 'none', margin:'10px'}}>
                    <button onClick={handlePrevPage} disabled={isFirstPage} style={{ cursor: isFirstPage ? 'not-allowed' : 'pointer', backgroundColor: isFirstPage ? '#8080808c' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                          <GrPrevious />
                        </button>
                        
                        {pageNumbers.map(number => (
                          <li key={number}>
                            <button id={number} onClick={handleClick} style={{ backgroundColor: currentPage === number ? '#FCF3FF' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                              {number}
                            </button>
                          </li> 
                        ))}
                        <button onClick={handleNextPage} disabled={isLastPage} style={{ cursor: isLastPage ? 'not-allowed' : 'pointer', backgroundColor: isLastPage ? '#8080808c' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                          <GrNext/>
                        </button> 
                      </ul>
                    </div>
                    </> )}
                    {showDetails && (
                    <Popup open={open1} onClose={() => { setOpen1(false); setDim(false)}} modal closeOnDocumentClick contentStyle={{width: '100%', height: '100%', backgroundColor: 'white', overflow: 'scroll'}} overlayStyle={{background: dim ? 'rgba(0, 0, 0, 0.5)' : 'none'}}>
                        {close => (
                      <div className="content" >
                      <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#fff', color: '#791FA4', alignItems: 'center'}}><a className="close" onClick={close}>
                        <b style={{fontSize: '26px', marginLeft: '10px', alignItems: 'center', cursor:'pointer'}}>&times; | </b>
                        </a> <b>Transaction Details</b> </div>
                        <NonMobile>
                        <div>
                        <DivRight>
                                <PrimaryBtn2 style={{backgroundColor: '#F3E8F7', borderRadius: '6px', width: '200px', textAlign:'center', color:'#4D0B6C', padding:'14px', fontSize:'16px', fontFamily:'Inter', lineHeight:'19.36px'}} onClick={handlePrint}><BsDownload/> Download as PDF</PrimaryBtn2>
                                </DivRight>
                        <Div9 id='form2' style={{margin:'50px'}} ref={componentRef}>
                        <FormH2 style={{marginTop: '-45px', fontWeight: 'bolder', textTransform:'uppercase' }}>{selectedData?.order_type && selectedData.order_type.replace('_',' ')}</FormH2>
                              <>
                              <div style={{display:'flow-root'}}>
                                <DivLeft>
                                <div style={{display:'flex', paddingLeft:'0px', paddingTop:'10px', marginBottom:'10px'}}>
                                  <FormLabel style={{fontSize: '36px', marginBottom:'0px', fontWeight:'600',lineHeight:'44px', fontFamily:'Inter', alignItems:'center',marginLeft:'0px'}}>{selectedData.currency ? selectedData.currency : "Not applicable"} {selectedData.fiatAmount}{selectedData.to_amount} </FormLabel>
                                  <div style={{display:'grid', alignContent:'center',marginLeft:'20px'}}>
                                    <span style={{ alignItems:'center', fontSize: '12px', borderRadius: '16px', textAlign: 'center', padding: '8px', backgroundColor: selectedData.status === 'pending' ? '#FFF8D2' : selectedData.status === 'failed' ? '#FBD5D5' : selectedData.status === 'completed' ? '#DEF7EC' :'#0070FF', color: selectedData.status === 'pending' ? '#D79C00' : selectedData.status === 'failed' ? '#9B1C1C' : selectedData.status === 'completed' ? '#03543F' :'#0070FF'}}>{selectedData.status}</span>
                                  </div>
                                </div>
                                </DivLeft>
                              </div>
                              <div style={{display:'flex', paddingLeft:'15px', paddingTop:'10px', marginBottom:'30px'}}>
                                <div style={{display:'grid', paddingRight:'20px'}}>
                                  <span>Time</span>
                                  <span style={{fontWeight:'600'}}>{new Date(selectedData?.created_at).toLocaleString('en-GB', {
                                      day: 'numeric',
                                      month: 'short',
                                      year: 'numeric',
                                      hour: 'numeric',
                                      minute: 'numeric',
                                      hour12: true
                                  })}</span>
                                </div>
                                <div style={{display:'grid', paddingRight:'20px'}}>
                                  <span>Customer Email</span>
                                  <span style={{fontWeight:'600'}}>{selectedData.customerEmail}</span>
                                </div>
                                <div style={{display:'grid', paddingRight:'20px'}}>
                                  <span>Reference ID</span>
                                  <span style={{fontWeight:'600'}}>{selectedData.reference}</span>
                                </div>
                              </div>
                              <hr style={{color:'#F0F0F0', marginBottom:'30px', marginLeft:'10px', border:'1px solid'}}></hr>
                              <FormLabel style={{fontSize: '20px', marginBottom:'20px',fontWeight:'600', lineHeight:'24px', fontFamily:'Inter' }}>Payment Details</FormLabel>
                                <TableRow>
                                  <TableCell style={{padding:'12px'}}>Rate</TableCell>
                                  <TableCell style={{ paddingLeft:'30px', padding:'12px'}}><b>{selectedData.from_currency} 1 = {selectedData.to_currency} {selectedData.exchange_rate}</b></TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{padding:'12px'}}>Coin</TableCell>
                                  <TableCell style={{ paddingLeft:'30px', padding:'12px'}}><b>{selectedData.coin ? selectedData.coin : "Not applicable"}</b></TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{padding:'12px'}}>Status</TableCell>
                                  <TableCell style={{ paddingLeft:'30px', padding:'12px'}}><b style={{ textAlign: 'center'}}>{selectedData.status}</b></TableCell>
                                </TableRow>
                                {selectedData.cryptoAmount && (
                                <TableRow>
                                  <TableCell style={{padding:'12px'}}>Crypto Amount</TableCell>
                                  <TableCell style={{ paddingLeft:'30px', padding:'12px'}}><b>{selectedData.cryptoAmount ? selectedData.cryptoAmount : "Not applicable"}</b></TableCell>
                                </TableRow> )}
                                {selectedData.currency || selectedData.amount ? (
                                <TableRow>
                                  <TableCell style={{padding:'12px'}}>Amount</TableCell>
                                  <TableCell style={{ paddingLeft:'30px', padding:'12px'}}><b>{selectedData.currency ? selectedData.currency : "Not applicable"} {selectedData.fiatAmount}{selectedData.to_amount}</b></TableCell>
                                </TableRow> ) : null }
                                {selectedData.feeInCrypto &&  (
                                <TableRow>
                                  <TableCell style={{padding:'12px'}}>Fee in Crypto</TableCell>
                                  <TableCell style={{color:'#4D0B6C', paddingLeft:'30px', padding:'12px'}}><b>{selectedData.feeInCrypto? selectedData.feeInCrypto : "Not applicable"}</b></TableCell>
                                </TableRow> )}
                                {selectedData.address &&  (
                                <TableRow>
                                  <TableCell style={{padding:'12px'}}>Crypto Wallet Address</TableCell>
                                  <TableCell style={{color:'#4D0B6C', paddingLeft:'30px', wordBreak:'break-all', padding:'12px'}}><b>{selectedData.address? selectedData.address : "Not applicable"}</b></TableCell>
                                </TableRow> )}
                                {selectedData.to_amount &&(
                                  <>
                                  <hr style={{color:'#F0F0F0', marginBottom:'30px', marginLeft:'10px', border:'1px solid'}}></hr>
                                  <div style={{ borderRadius:'12px', marginTop: '30px', marginBottom:'20px'}}>
                                  <FormLabel style={{fontSize: '20px', marginBottom:'20px',fontWeight:'600', lineHeight:'24px', fontFamily:'Inter' }}>Swap Details</FormLabel>
                                  <TableRow>
                                    <TableCell>From</TableCell>
                                    <TableCell> </TableCell>
                                    <TableCell>To</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell><b>{selectedData.from_currency} {selectedData.from_amount}</b></TableCell>
                                    <TableCell style={{color:'green'}}><b><AiOutlineArrowRight/></b></TableCell>
                                    <TableCell><b>{selectedData.to_currency} {selectedData.to_amount}</b></TableCell>
                                  </TableRow>
                                  </div>
                                  </>
                                )}
                                {selectedData.customerName || selectedData.customerEmail ? (
                                  <>
                                  <hr style={{color:'#F0F0F0', marginBottom:'30px', marginLeft:'10px', border:'1px solid'}}></hr>
                                  <div style={{marginTop: '30px', marginBottom:'20px'}}>
                                    <FormLabel style={{ fontSize: '20px', marginBottom:'20px',fontWeight:'600', lineHeight:'24px', fontFamily:'Inter' }}>
                                      Customer Details
                                    </FormLabel>
                                    <TableRow>
                                      {selectedData.customerName && <TableCell>Customer Name</TableCell>}
                                      {selectedData.customerName && <TableCell><b>{selectedData.customerName}</b></TableCell>}
                                    </TableRow>
                                    <TableRow>
                                      {selectedData.customerEmail && <TableCell>Customer Email</TableCell>}
                                      {selectedData.customerEmail && <TableCell><b>{selectedData.customerEmail}</b></TableCell>}
                                    </TableRow>
                                  </div>
                                  </>
                                ) : null}
                                </>
                          </Div9>
                        </div>
                        </NonMobile>
                        <MobileOnly>
                        <div>
                        <Div9 id='form2' style={{margin:'30px'}} ref={componentRef1}>
                        <div style={containerStyle}>
                          <div style={elementStyle}><FormH2 style={{fontWeight: 'bold', textTransform:'capitalize', fontSize:'14px' }}>{selectedData?.order_type && selectedData.order_type.replace('_',' ')}</FormH2></div>
                          <div style={elementStyle}><span style={{ alignItems:'center', fontSize: '12px', borderRadius: '16px', textAlign: 'center', padding: '8px', backgroundColor: selectedData.status === 'pending' ? '#FFF8D2' : selectedData.status === 'failed' ? '#FBD5D5' : selectedData.status === 'completed' ? '#DEF7EC' :'#0070FF', color: selectedData.status === 'pending' ? '#D79C00' : selectedData.status === 'failed' ? '#9B1C1C' : selectedData.status === 'completed' ? '#03543F' :'#0070FF'}}>{selectedData.status}</span></div>
                        </div>
                        
                              <>
                              
                                  <FormLabel style={{fontSize: '30px', fontWeight:'600',lineHeight:'44px', fontFamily:'Inter', alignItems:'center',marginLeft:'15px'}}>{selectedData.currency ? selectedData.currency : "Not applicable"} {selectedData.fiatAmount}{selectedData.to_amount} </FormLabel>
                                 
                              <div style={{display:'block', paddingLeft:'15px', paddingTop:'10px', marginBottom:'30px', fontSize:'13px'}}>
                                <div style={{display:'grid', paddingBottom:'10px'}}>
                                  <span>Time</span>
                                  <span style={{fontWeight:'600'}}>{new Date(selectedData?.created_at).toLocaleString('en-GB', {
                                      day: 'numeric',
                                      month: 'short',
                                      year: 'numeric',
                                      hour: 'numeric',
                                      minute: 'numeric',
                                      hour12: true
                                  })}</span>
                                </div>
                                <div style={{display:'grid',  paddingBottom:'10px'}}>
                                  <span>Customer Email</span>
                                  <span style={{fontWeight:'600'}}>{selectedData.customerEmail}</span>
                                </div>
                                <div style={{display:'grid', paddingBottom:'10px'}}>
                                  <span>Reference ID</span>
                                  <span style={{fontWeight:'600'}}>{selectedData.reference}</span>
                                </div>
                              </div>
                              <hr style={{color:'#F0F0F0', marginBottom:'30px', marginLeft:'10px', border:'1px solid'}}></hr>
                              <FormLabel style={{fontSize: '20px', marginBottom:'20px',fontWeight:'600', lineHeight:'24px', fontFamily:'Inter' }}>Payment Details</FormLabel>
                              <div style={{fontSize:'13px'}}>
                                <TableRow>
                                  <TableCell style={{padding:'12px'}}>Rate</TableCell>
                                  <TableCell style={{ paddingLeft:'0px', padding:'12px'}}><b>{selectedData.from_currency} 1 = {selectedData.to_currency} {selectedData.exchange_rate}</b></TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{padding:'12px'}}>Coin</TableCell>
                                  <TableCell style={{ paddingLeft:'0px', padding:'12px'}}><b>{selectedData.coin ? selectedData.coin : "Not applicable"}</b></TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell style={{padding:'12px'}}>Status</TableCell>
                                  <TableCell style={{ paddingLeft:'0px', padding:'12px'}}><b style={{ textAlign: 'center'}}>{selectedData.status}</b></TableCell>
                                </TableRow>
                                {selectedData.cryptoAmount && (
                                <TableRow>
                                  <TableCell style={{padding:'12px'}}>Crypto Amount</TableCell>
                                  <TableCell style={{ paddingLeft:'0px', padding:'12px'}}><b>{selectedData.cryptoAmount ? selectedData.cryptoAmount : "Not applicable"}</b></TableCell>
                                </TableRow> )}
                                {selectedData.currency || selectedData.amount ? (
                                <TableRow>
                                  <TableCell style={{padding:'12px'}}>Amount</TableCell>
                                  <TableCell style={{ paddingLeft:'0px', padding:'12px'}}><b>{selectedData.currency ? selectedData.currency : "Not applicable"} {selectedData.fiatAmount}{selectedData.to_amount}</b></TableCell>
                                </TableRow> ) : null }
                                {selectedData.feeInCrypto &&  (
                                <TableRow>
                                  <TableCell style={{padding:'12px'}}>Fee in Crypto</TableCell>
                                  <TableCell style={{color:'#4D0B6C', paddingLeft:'0px', padding:'12px'}}><b>{selectedData.feeInCrypto? selectedData.feeInCrypto : "Not applicable"}</b></TableCell>
                                </TableRow> )}
                                {selectedData.address &&  (
                                <TableRow>
                                  <TableCell style={{padding:'12px'}}>Crypto Wallet Address</TableCell>
                                  <TableCell style={{color:'#4D0B6C', paddingLeft:'0px', wordBreak:'break-all', padding:'12px'}}><b>{selectedData.address? selectedData.address : "Not applicable"}</b></TableCell>
                                </TableRow> )}
                                
                                {selectedData.to_amount &&(
                                  <>
                                  <hr style={{color:'#F0F0F0', marginBottom:'30px', marginLeft:'10px', border:'1px solid'}}></hr>
                                  <div style={{ borderRadius:'12px', marginTop: '30px', marginBottom:'20px'}}>
                                  <FormLabel style={{fontSize: '20px', marginBottom:'20px',fontWeight:'600', lineHeight:'24px', fontFamily:'Inter' }}>Swap Details</FormLabel>
                                  <TableRow>
                                    <TableCell>From</TableCell>
                                    <TableCell> </TableCell>
                                    <TableCell>To</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell><b>{selectedData.from_currency} {selectedData.from_amount}</b></TableCell>
                                    <TableCell style={{color:'green'}}><b><AiOutlineArrowRight/></b></TableCell>
                                    <TableCell><b>{selectedData.to_currency} {selectedData.to_amount}</b></TableCell>
                                  </TableRow>
                                  </div>
                                  </>
                                )}
                                </div>
                                <hr style={{color:'#F0F0F0', marginBottom:'30px', marginLeft:'10px', border:'1px solid'}}></hr>
                                {selectedData.customerName || selectedData.customerEmail ? (
                                  <div style={{marginTop: '30px', marginBottom:'20px', fontSize:'13px'}}>
                                    <FormLabel style={{ fontSize: '20px', marginBottom:'20px',fontWeight:'600', lineHeight:'24px', fontFamily:'Inter' }}>
                                      Customer Details
                                    </FormLabel>
                                    <TableRow>
                                      {selectedData.customerName && <TableCell>Customer Name</TableCell>}
                                      {selectedData.customerName && <TableCell><b>{selectedData.customerName}</b></TableCell>}
                                    </TableRow>
                                    <TableRow>
                                      {selectedData.customerEmail && <TableCell>Customer Email</TableCell>}
                                      {selectedData.customerEmail && <TableCell><b>{selectedData.customerEmail}</b></TableCell>}
                                    </TableRow>
                                  </div>
                                ) : null}
                                </>
                          </Div9>
                        <center style={{marginBottom:'40px', marginTop:'-20px'}}>
                                <PrimaryBtn2 style={{backgroundColor: '#F3E8F7', borderRadius: '6px', width: '200px', textAlign:'center', color:'#4D0B6C', padding:'14px', fontSize:'16px', fontFamily:'Inter', lineHeight:'19.36px'}} onClick={handlePrint1}><BsDownload/> Download as PDF</PrimaryBtn2>
                                </center>
                        </div>
                        </MobileOnly>
                      </div>)}
                    </Popup>
                    )}
                </CenterContent>
                ) : (
                        <div style={{justifyContent:'center', display: 'grid'}}>
                        <div style={{textAlign: 'center'}}>
                          <img src={Empty} alt="Empty table" />
                        </div>
                        <div style={{textAlign: 'center'}}>
                          <h5 style={{marginBottom: '10px'}}>You have no transaction</h5>
                        </div>
                        <div>
                        <div style={{display:'flex'}}>
                          <div style={{width: '97%', marginRight: '10px'}}>
                            <NavLink to='/topup'> 
                                <PrimaryBtn2 style={{background: 'linear-gradient(180deg, #F3E8F7 0%, #EBDAF1 100%)',color: '#791FA4', borderRadius: '6px', width: '100%'}}>Top Up</PrimaryBtn2>
                            </NavLink>
                          </div>
                          <div style={{width: '100%'}}>
                            <NavLink to='/swap'>
                                <PrimaryBtn2 style={{backgroundColor: '#9316BF', borderRadius: '6px', width: '100%'}}>Swap</PrimaryBtn2>
                            </NavLink>
                          </div>
                        </div>
                        </div>
                      </div>
                      )}
            </FormWrap>
  </P2pcontainer>
        </Container>
        

    </>
  )
}

export default Table