import React, {useState} from 'react'
import { CenterContent, Container, FormWrap,PayoutCard,PayoutH,PayoutP,PayoutP2,PayoutCardWrapper } from '../components/Dashboard/content/dashElements'
import Navbar from '../components/Navbar'
import Wallet from '../assets/Ellipse8.png'
import Donation from '../assets/Ellipse9.png'
import  {useNavigate}  from 'react-router-dom'
import Sidebar1, { MainContainer, MainContent } from './nav'

const Paymentlink = () => {
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
        <Navbar toggleSidebar={toggleSidebar}/>
        <MainContainer isOpen={isOpen}>
            <Sidebar1 isOpen={isOpen} toggleSidebar={toggleSidebar} />
            <MainContent>
        <Container>
            <FormWrap>
                <CenterContent>
                <PayoutCardWrapper>
                <PayoutCard onClick={() => navigate("/create-paymentlink")}>
                    <img src={Wallet} style={{margin: '20px', borderRadius: '50%', border:'1px solid #4D0B6C'}}/>
                    <PayoutH>Standard Link</PayoutH>
                    <PayoutP>Transfer money to your crypto wallet address</PayoutP>
                </PayoutCard>
                <PayoutCard onClick={() => navigate("/create-donationlink")}>
                    <img src={Donation} style={{margin: '20px', borderRadius: '50%', border:'1px solid #4D0B6C'}}/>
                    <PayoutH>Donation Link</PayoutH>
                    <PayoutP>Transfer money to a recipient’s bank account details</PayoutP>
                </PayoutCard>
            </PayoutCardWrapper>
                </CenterContent>
            </FormWrap>
        </Container>
        </MainContent>
        </MainContainer>
    </>
  )
}

export default Paymentlink