import React, {useState, useEffect}  from 'react'
import { InputArea, SelectInput } from '../components/Dashboard/content/dashElements'
import { Container, Form2, FormContent, FormH1, FormH2, FormInput, FormLabel, FormWrap, Icon, PrimaryBtn, PrimaryBtn2, Text, Text2 } from '../components/Form/formElements'
import Paylink from '../assets/Ellipse12.png'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import AlertModal from './modal'
import BASE_URL from '../apiConfig'

const CreateProduct = () => {
  const [currency, setCurrency] = useState(null);
  const [product_name, setProduct_name] = useState('')
  const [product_currency, setProduct_currency] = useState('')
  const [product_price, setProduct_price] = useState('')
  const [product_description, setProduct_description] = useState('')
  const [product_image, setProduct_image] = useState('')
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('')

  const token = localStorage.getItem('bearerToken');

  const handleProduct_name = (e) => { setProduct_name(e.target.value)}
  const handleProduct_currency = (e) => { setProduct_currency(e.target.value)}
  const handleProduct_price = (e) => { setProduct_price(e.target.value)}
  const handleProduct_description = (e) => { setProduct_description(e.target.value)}
  const handleProduct_image = (e) => { setProduct_image(e.target.files[0])}


  const fetchCurrency = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/payment-method/sys-currencies`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        return response.data;
      } catch (error) {
        console.error(error);
      }
    };
    useEffect(() => {
      const getCurrency = async () => {
        const data = await fetchCurrency();
        setCurrency(data);
      };
  
      getCurrency();
    }, []);
  let history = useNavigate();

  const CreateProduct = (event) => {
    event.preventDefault();
    axios.post(`${BASE_URL}/products`, {
      product_name: product_name,
      product_currency: product_currency,
      product_price: product_price,
      product_description: product_description,
      product_image: product_image
    }, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data"
        },
      }).then(async (result) => {
      setAlertMessage('Product successfully created');
      setShowAlert(true);
      await new Promise((resolve) => setTimeout(resolve, 3000));
      history("/products")
    })
      .catch(error => {
        const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
        setAlertMessage(jsonString);
        setShowAlert(true);
      })
    }

    const handleAlertClose = () => {
      setShowAlert(false);
    };

  return (
    <>
                    <FormWrap onSubmit={CreateProduct}>
                    <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><a className="close" onClick={() => history(-1)}>
                        <b style={{fontSize: '26px', marginLeft: '10px', alignItems: 'center', cursor: 'pointer'}}>&times; | </b>
                        </a> <b>Create Product</b> </div>
                <FormContent>
                    <Icon src={Paylink}  width='140px' style={{marginBottom:'-20px'}} />
                    <FormH2>Product Information</FormH2>
                    <Text2>Details of your product</Text2>
                    <Form2>
                        <FormLabel for='product_name'>Product Name</FormLabel>
                        <FormInput id='product_name' placeholder='Enter Product name' value={product_name} onChange={handleProduct_name}/>
                        <FormLabel for='product_description'>Product Description</FormLabel>
                        <InputArea id='product_description' type='text' placeholder='Enter Product Description'value={product_description} onChange={handleProduct_description} />

                        <FormLabel for='product_image'>Image (optional)</FormLabel>
                        <FormInput id='product_iamge' placeholder='Drop your document here or browse' type='file' accept="image/*" onChange={handleProduct_image} />

                        <FormLabel for='procuct_currency'>Currency</FormLabel>
                        
                        {currency && 
                        (<SelectInput id="product_currency" value={product_currency} onChange={handleProduct_currency} >
                          <option>Select Product currency</option>
                          
                          {currency.data.map((option, index) => (
                          <option value={option.currency} key={index}>{option.currency}</option>
                          ))}
                        </SelectInput>)}

                        <FormLabel for='product_price'>Set Price for Product</FormLabel>
                        <FormInput id='product_price' placeholder='234,256,000' value={product_price} onChange={handleProduct_price} />


                        <PrimaryBtn2 type='submit'>Create Product</PrimaryBtn2>
                    </Form2>
                </FormContent>
            </FormWrap>
            {showAlert && ( 
        <AlertModal
          isModalOpen={showAlert}
          alertMessage={alertMessage}
          onClose={handleAlertClose}
        />
      )}
    </>
  )
}

export default CreateProduct