import React, { useState, useEffect } from 'react';
import { Container, PrimaryBtn } from '../components/Dashboard/content/dashElements';
import Navbar from '../components/Navbar';
import 'react-tabs/style/react-tabs.css';
import { BusinessForm2, Form2, FormArea, FormH2, FormInput, FormLabel } from '../components/Form/formElements';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { CountryDropdown, RegionDropdown } from 'react-country-region-selector';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Loader from '../components/loader';
import AlertModal from './modal';
import Sidebar1, { MainContainer, MainContent } from './nav';
import BASE_URL from '../apiConfig';

// Component for updating business details
const Business = () => {
  const [isOpen, setIsOpen] = useState(true); // State for sidebar toggle
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  let history = useNavigate(); // Navigation hook

  const [b_details, setBDetails] = useState({}); // State for business details
  const token = localStorage.getItem('bearerToken');

  const [showAlert, setShowAlert] = useState(false); // State for showing alert modal
  const [alertMessage, setAlertMessage] = useState('');

  const [country1, setCountry1] = useState(''); // State for selected country
  const [region1, setRegion1] = useState(''); // State for selected region

  // States for business details
  const [businessName, setBusinessName] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [businessPhone, setBusinessPhone] = useState('');
  const [businessEmail, setBusinessEmail] = useState('');
  const [businessDescription, setBusinessDescription] = useState('');

  const businessCountry = country1; // Assigning country state to a variable
  const businessState = region1; // Assigning region state to a variable

  const [phone, setPhone] = useState(''); // State for phone number

  const handleBusinessName = (e) => {
    setBusinessName(e.target.value);
  };

  const handleBusinessType = (e) => {
    setBusinessType(e.target.value);
  };

  const handleBusinessPhone = (value) => {
    setBusinessPhone(value);
  };

  const handleBusinessEmail = (e) => {
    setBusinessEmail(e.target.value);
  };

  const handleBusinessDescription = (e) => {
    setBusinessDescription(e.target.value);
  };

  // Function to fetch business details
  const fetchBDetails = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/settings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const getBDetails = async () => {
      const data = await fetchBDetails();
      setBDetails(data);
      setBusinessName(data.data.businessName);
      setBusinessType(data.data.businessType);
      setBusinessPhone(data.data.businessPhone);
      setPhone(data.data.businessPhone);
      setBusinessEmail(data.data.businessEmail);
      setBusinessDescription(data.data.businessDescription);
    };

    getBDetails();
  }, []);

  // Function to update business details
  const UpdateBusinessDetails = (event) => {
    event.preventDefault();
    axios
      .post(
        `${BASE_URL}/settings/update`,
        {
          businessName: businessName,
          businessType: businessType,
          businessPhone: businessPhone,
          businessEmail: businessEmail,
          businessDescription: businessDescription,
          businessCountry: businessCountry,
          businessState: businessState,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then(async (result) => {
        setAlertMessage('Business profile successfully updated');
        setShowAlert(true);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        history('/settings');
      })
      .catch((error) => {
        const jsonString = JSON.stringify(error.response.data.errors).replace(/[\[\]{}'"]+/g, '');
        setAlertMessage(jsonString);
        setShowAlert(true);
      });
  };

  const handleAlertClose = () => {
    setShowAlert(false);
  };

  return (
    // Check if business details are available
    b_details ? (
      <>
        {/* Show alert modal if showAlert is true */}
        {showAlert && (
          <AlertModal
            isModalOpen={showAlert}
            alertMessage={alertMessage}
            onClose={handleAlertClose}
          />
        )}
        <Navbar toggleSidebar={toggleSidebar} />
        <MainContainer isOpen={isOpen}>
          <Sidebar1 isOpen={isOpen} toggleSidebar={toggleSidebar} />
          <MainContent>
            <Container>
              <BusinessForm2 onSubmit={UpdateBusinessDetails}>
                <FormH2 style={{ marginTop: '-50px' }}>Update Business Details</FormH2>

                <>
                  <FormLabel for='firstName'>Business Name </FormLabel>
                  <FormInput
                    id='firstName'
                    placeholder='ZeenahPay'
                    value={businessName}
                    style={{ width: '100%' }}
                    onChange={handleBusinessName}
                    readOnly
                  />

                  <FormLabel for='email'> Business Email</FormLabel>
                  <FormInput
                    id='email'
                    placeholder='Johndoe@gmail.com'
                    value={businessEmail}
                    onChange={handleBusinessEmail}
                    readOnly
                  />

                  <FormLabel for='email'>Business Phone Number</FormLabel>
                  <PhoneInput
                    country={'ng'}
                    inputProps={{ name: ' ', required: true, autoFocus: true }}
                    value={businessPhone}
                    onChange={handleBusinessPhone}
                  />

                  <FormLabel style={{ marginTop: '30px' }}>Business Niche / Type </FormLabel>
                  <FormInput placeholder='Fintech' value={businessType} onChange={handleBusinessType} />

                  <FormLabel>About Business</FormLabel>
                  <FormArea placeholder='Business Description' value={businessDescription} onChange={handleBusinessDescription} />

                  <div style={{display:'flex', alignItems:'center',marginBottom:'-20px'}}>
                    <FormLabel>Business Location</FormLabel>
                    <p style={{ marginLeft: '10px', marginTop: '-15px', fontSize: '10px', color:'purple' }}>(Where does your business operate from?)</p>
                  </div>
                  <div style={{ display: 'flex', margin: '10px' }}>
                    <div style={{ width: '50%' }}>
                      <FormLabel for='firstName'>Country</FormLabel>
                      <CountryDropdown
                        value={country1}
                        onChange={(val) => setCountry1(val)}
                        style={{
                          padding: '16px 16px',
                          marginBottom: '32px',
                          border: '1px solid #979797',
                          borderRadius: '6px',
                          backgroundColor: 'transparent',
                          width: '97%',
                        }}
                      />
                    </div>
                    <div>
                      <FormLabel for='lasstName'>State</FormLabel>
                      <RegionDropdown
                        country={country1}
                        value={region1}
                        onChange={(val) => setRegion1(val)}
                        style={{
                          padding: '16px 16px',
                          marginBottom: '32px',
                          border: '1px solid #979797',
                          borderRadius: '6px',
                          backgroundColor: 'transparent',
                          width: '100%',
                        }}
                      />
                    </div>
                  </div>

                  <PrimaryBtn style={{ width: '100%' }}>Update</PrimaryBtn>
                </>

              </BusinessForm2>
            </Container>
          </MainContent>
        </MainContainer>
      </>
    ) : <Loader /> // Show loader if business details are not available
  );
};

export default Business;
