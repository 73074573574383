import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components';
import {BsPaperclip} from 'react-icons/bs'
import {AiOutlineSend} from 'react-icons/ai'
import {RiMore2Fill} from 'react-icons/ri'
import {FcCancel} from 'react-icons/fc'
import {IoReturnDownBack} from 'react-icons/io5'
import { useLocation, useNavigate } from 'react-router-dom';
import axios from '../api/axios';
import { Form2, Form3, Form4, FormArea, FormH1, FormH2, FormInput, FormLabel, FormWrap, Icon, PrimaryBtn, PrimaryBtn2 } from '../components/Form/formElements'
import AlertModal from './modal';
import { Container } from '../components/Dashboard/content/dashElements';
import Sidebar1, { MainContainer, MainContent } from './nav';
import Navbar from '../components/Navbar';
import BASE_URL from '../apiConfig'

const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
`;

const ChatHeader = styled.header`
  background-color: #791FA4;
  color: white;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ChatTitle = styled.h1`
  font-size: .8rem;
  margin: 0;
`;

const ChatMessages = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 1rem;
    display: flex;
  flex-direction: column-reverse;
`;

const ChatMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: ${({ isSelf }) => (isSelf ? 'flex-end' : 'flex-start')};
  margin-bottom: 1rem;
`;

const ChatMessageAuthor = styled.span`
  font-weight: bold;
  margin-bottom: 0.5rem;
`;

const ChatMessageTime = styled.span`
  font-size: 0.75rem;
  color: #999;
  margin-bottom: 0.25rem;
`;

const ChatMessageContent = styled.p`
  background-color: ${({ isSelf }) => (isSelf ? '#791FA4' : '#eee')};
  color: ${({ isSelf }) => (isSelf ? 'white' : 'black')};
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  margin: 0;
  max-width: 50%;
`;

const ChatInputForm = styled.form`
  display: flex;
  background-color: #f4f4f4;
  padding: 0.5rem;
`;

const ChatInputContainer = styled.div`
  display: flex;
  flex: 1;
`;

const ChatInputField = styled.div`
  flex: 1;
`;

const ChatInput = styled.input`
  border: none;
  outline: none;
  padding: 0.5rem;
  border-radius: 0.25rem;
  width: 100%;
`;

export const ChatFileInputContainer = styled.div`
  position: relative;
  margin-left: 1rem;
  cursor: pointer;
`;

export const ChatOption = styled.div`
  position: relative;
  margin: 0.5rem;
  cursor: pointer;
  font-size: 18px;
  font-weight: bolder;
`;

export const ChatFileInput = styled.input`
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
`;

export const ChatFileInputLabel = styled.label`
  display: block;
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: #791FA4;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
`;

const ChatSubmitButton = styled.button`
  border: none;
  outline: none;
  background-color: #791FA4;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  margin-left: 0.5rem;
  cursor: pointer;
`;
const DropupWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const DropupButton = styled.button`
  background-color: #4CAF50;
  color: white;
  padding: 12px;
  font-size: 16px;
  border: none;
  cursor: pointer;
`;

const DropupContent = styled.div`
  position: absolute;
  bottom: 100%;
  left: 0;
  display: ${({ isOpen2 }) => (isOpen2 ? 'block' : 'none')};
  z-index: 1;
  background: #fff;
  border-radius: 5px;
  border: 1px #75757575 solid;
`;

const Option = styled.a`
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block ruby;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
    color: black
  }
`;

const Container1 = styled.div`
  display: flex;
  margin-top: 20px;

`;

const Select1 = styled.select`
padding: 16px 16px;
margin-bottom: 32px;
border: 1px solid #979797;
border-radius: 6px;
background-color: #4D0B6C;
color: #fff;
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  outline: none;
`;

const Input = styled.input`
  padding: 16px 16px;
  margin-bottom: 32px;
  border: 1px solid #979797;
  border-radius: 6px;
  background-color: transparent;
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  outline: none;
  width:100%;
  @media screen and (max-width: 580px) {
        width: 80%;
    }
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000
`;

const PopupCard = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 80%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 1000
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  border: none;
  background: transparent;
`;
const DivPop = styled.div`
    height: 150px;
    @media screen and (max-width: 580px) {
        height: 195px;
    }
`
const B = styled.div`
    font-size: 17px;
    @media screen and (max-width: 580px) {
        font-size: 12px;
    }
`

const Dispute = () => {
  const [inputValue, setInputValue] = useState('');
  const [messages, setMessages] = useState([]);
  const [file, setFile] = useState(null);
  const location = useLocation();
  const token = localStorage.getItem('bearerToken');
  const transaction_id = location.state.id;
  const [closeDispute, setCloseDispute] = useState(false);
  const [description, setDescription] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  let navigate = useNavigate() 

  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleDisputeModal = (ID) => {
    setCloseDispute(!closeDispute);
  }

  useEffect(() => {
    const getUser = async () => {
      const data = await fetchUser();
      setUser(data);
    };

    getUser();
  }, []);

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };
 
  const handleFileInputChange = (e) => {
    setFile(e.target.files[0]);
    handleSubmit(e)
  };
  const [isOpen2, setIsOpen2] = useState(false);

  const handleButtonClick = () => {
    setIsOpen2(!isOpen2);
  };

  const [message, setMessage] = useState('');
  const [user, setUser] = useState(null);

  const fetchUser = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const getUser = async () => {
      const data = await fetchUser();
      setUser(data);
    };

    getUser();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${BASE_URL}/dispute/reply/${transaction_id}`,
        { reply: inputValue,
          file: file
         },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Clear input field and fetch all messages
      setMessage('');
      fetchMessages();
    } catch (error) {
      console.error(error);
    }
  };

  const CloseDispute = (event) => {
    event.preventDefault();
      axios.post(`${BASE_URL}/dispute/close/${transaction_id}`, {
        reply: description
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(async (response) => {
          setCloseDispute(false)
          setAlertMessage(response.data.message)
          setShowAlert(true)
          await new Promise((resolve) => setTimeout(resolve, 3000));
          navigate('/p2p')
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.error) {
            // Extract error message from response and alert it
            const errorMessage = error.response.data.errors.error;
            const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
            setAlertMessage(jsonString);
            setShowAlert(true)
          } else {
            setAlertMessage('Service Error');
            setShowAlert(true);
          }
        });
  };

  const fetchMessages = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/dispute/chats/${transaction_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setMessages([...response.data.data]);
      setInputValue('');
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    // Fetch all messages on initial render
    fetchMessages();

    // Poll server for new messages every 10 seconds
    const intervalId = setInterval(() => {
      fetchMessages();
    }, 10000);

    // Cleanup function to clear interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  // Scroll to the bottom of the chat messages when a new message is added

  const handleAlertClose = () => {
    setShowAlert(false);
  };
  const [isOpen, setIsOpen] = useState(true);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
    <Navbar toggleSidebar={toggleSidebar}/>
        <MainContainer isOpen={isOpen}>
            <Sidebar1 isOpen={isOpen} toggleSidebar={toggleSidebar} />
            <MainContent>
        <Container style={{backgroundColor: '#fff', borderRadius:'6px', padding:'65px', marginTop:'-20px'}}>
            <FormWrap>
    {showAlert && (
        <AlertModal
          isModalOpen={showAlert}
          alertMessage={alertMessage}
          onClose={handleAlertClose}
        />
      )}
      <Form4 style={{width: '80%', height: '85vh', marginTop: '-60px', border: '0px', boxShadow: '0px 4px 50px 8px rgba(0, 0, 0, 0.06)'}}>
      <ChatContainer>
      <ChatHeader>
        <div style={{display: "flex", alignItems: "center", cursor:'pointer'}} onClick={() => navigate(-1)}><IoReturnDownBack /> Go Back</div>
        <ChatTitle>Dispute ID : {transaction_id}</ChatTitle>
        <div>{user?.data?.firstName}</div>
      </ChatHeader>
      <ChatMessages>
        {messages.map((message, index) => (
          <ChatMessage key={index} isSelf={user?.data?.id == message.user_id}>
            <ChatMessageTime>{new Date(message.created_at).toLocaleTimeString()}</ChatMessageTime>
            <ChatMessageContent isSelf={user?.data?.id == message.user_id}>
              {message.message}
            </ChatMessageContent>
          </ChatMessage>
        ))}
      </ChatMessages>
      <ChatInputForm onSubmit={handleSubmit} style={{marginBottom: '45px'}}>
        <ChatInputContainer>
        <DropupWrapper>
          <ChatOption><RiMore2Fill onClick={handleButtonClick}/>
          {(Number(user?.data?.id) == Number(messages[0]?.user_id)) ? (<DropupContent isOpen2={isOpen2}>
            <Option onClick={() => setCloseDispute(true)}><span><FcCancel />Close Dispute</span></Option>
          </DropupContent>): (<Option></Option>)}
          </ChatOption>
          {closeDispute && (
                            <Background className="modal">
                            <PopupCard className="modal-content">
                              <CloseButton onClick={handleDisputeModal}>&times;</CloseButton>
                              <h3 style={{margin:'10px', textAlign: 'center'}}>Close Dispute</h3>
                              <Form2 style={{border: 'none', marginBottom: '-20px', marginTop: '-40px'}}>
                                <FormLabel style={{ marginBottom: '0px'}}>Why are you closing?</FormLabel>    
                                <FormArea style={{marginBottom: ''}} value={description} onChange={handleDescription} />                            
                              <PrimaryBtn2 onClick={CloseDispute} style={{backgroundColor: '#9316BF', borderRadius: '6px', width: '100%'}} Link>Close Dispute</PrimaryBtn2>
                              </Form2>
                            </PopupCard>
                          </Background>
                          )}
          </DropupWrapper>
          <ChatInputField>
            <ChatInput
              type="text"
              placeholder="Type a message..."
              value={inputValue}
              onChange={handleInputChange}
            />
          </ChatInputField>
          <ChatFileInputContainer>
            <ChatFileInput type="file" onChange={handleFileInputChange} />
            <ChatFileInputLabel><BsPaperclip/></ChatFileInputLabel>
          </ChatFileInputContainer>
        </ChatInputContainer>
        <ChatSubmitButton type="submit"><AiOutlineSend/></ChatSubmitButton>
      </ChatInputForm>
    </ChatContainer>
    </Form4>
    </FormWrap>
        </Container>
        </MainContent>
        </MainContainer>
    </>
  )
}

export default Dispute