import React,{useState, useRef, useMemo, useEffect} from 'react'
import { CenterContent, Container, PayoutCard2, PayoutCardWrapper, PayoutH, PrimaryBtn, SelectInput, FormWrap } from '../components/Dashboard/content/dashElements'
import DashboardSidebar from '../components/Dashboard/sidebar'
import Navbar from '../components/Navbar'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { Form2, Form3, FormArea, FormH2, FormInput, FormLabel } from '../components/Form/formElements'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ReactPasswordToggleIcon from 'react-password-toggle-icon';
import {FaEye, FaEyeSlash} from 'react-icons/fa'
import {AiOutlineArrowRight} from 'react-icons/ai'
import {BsExclamationCircle, BsPatchCheckFill} from 'react-icons/bs'
import {IoIosArrowDown, IoIosAddCircle,IoIosArrowUp } from 'react-icons/io'
import {FcApproval} from 'react-icons/fc'
import { ComplianceBtn, ComplianceForm, ComplianceRectangle, ComplianceSquare, ComplianceSubText, ComplianceText, ComplianceTopText } from '../components/Compliance/complianceElements'
import contactbook from '../assets/contactbook.png'
import Identity from '../assets/Identity.png'
import address from '../assets/address.png'
import Store from '../assets/Store.svg'
import Busineessman from '../assets/Businessman.svg'
import Officebuilding from '../assets/Officebuilding.svg'
import Remove from '../assets/Remove.svg'
import briefcase from '../assets/briefcase.png'
import docs from '../assets/docs.png'
import link from '../assets/link.png'
import Select from 'react-select'
import Popup from 'reactjs-popup';
import { Steps, StepsProvider, useSteps } from "react-step-builder";
import Documents from '../assets/Documents.svg'
import agentsuccess from '../assets/agentSuccess.png'
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Loader from '../components/loader'
import MyPaymentMethod from './myPaymentMethod';
import AlertModal from './modal'
import LoadingLine from './loadinglines'
import Sidebar1, { MainContainer, MainContent } from './nav'
import BASE_URL from '../apiConfig'
import { PayoutCard } from '../components/Dashboard/content/dashElements'
import { ToastContainer, toast } from 'react-toastify'
import { PrimaryBtn2 } from '../components/Form/formElements'
import Dojah from 'react-dojah'


const Compliance = () => {
  const [isOpen, setIsOpen] = useState(true);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
    let history = useNavigate();
    const { next, prev, total, current } = useSteps();
    let Next = useSteps()
    const [b_details, setBDetails] = useState({});
    const token = localStorage.getItem('bearerToken');
    const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
    const fetchBDetails = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/settings`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
    
          return response.data;
        } catch (error) {
          console.error(error);
        }
      };
      useEffect(() => {
        const getBDetails = async () => {
          const data = await fetchBDetails();
          setBDetails(data);
          setBusinessName(data.data.businessName)
          setBusinessType(data.data.businessType)
          setBusinessPhone(data.data.businessPhone)
          setPhone(data.data.businessPhone)
          setBusinessEmail(data.data.businessEmail)
          setBusinessDescription(data.data.businessDescription)
        };
    
        getBDetails();
      }, []);

      const [country, setCountry] = useState('');
    const [region, setRegion] = useState('');
    const [country1, setCountry1] = useState('');
    const [region1, setRegion1] = useState('');
    const [errorPassword, setErrorPassword] = useState('');

    const [businessName, setBusinessName] = useState('')
    const [businessType, setBusinessType] = useState('')
    const [businessPhone, setBusinessPhone] = useState('')
    const [businessEmail, setBusinessEmail] = useState('')    
    const [businessDescription, setBusinessDescription] = useState('')    
    const businessCountry = country1 
    const businessState = region1
    
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState("");
    const [image, setImage] = useState('')
    const handleImage = (e) => { 
      setImage(e.target.files[0])}
    const handlePassword = (e) => { setPassword(e.target.value)}
    const handlePhone = (value) => { setPhone(value)}


    const handleBusinessName = (e) => { setBusinessName(e.target.value)}
    const handleBusinessType = (e) => { setBusinessType(e.target.value)}
    const handleBusinessPhone = (value) => { setBusinessPhone(value)}
    const handleBusinessEmail = (e) => { setBusinessEmail(e.target.value)}
    const handleBusinessDescription = (e) => { setBusinessDescription(e.target.value)}

    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [dim, setDim] = useState(false);

    const options = 'abc'
    {/*useMemo(() => countryList().getData(), []) */}
    const showIcon = () => <FaEye aria-hidden="true" />;
    const hideIcon = () => <FaEyeSlash aria-hidden="true" />
    let inputRef = useRef();
    let inputRef1 = useRef();
    const [selected, setSelected] = useState("");
    const onSelect = (code) => setSelected(code);

    const [active, setActive] = React.useState(1)

    const UpdateBusinessDetails = (event) => {
        event.preventDefault();
        axios.post(`${BASE_URL}/settings/update`, {
            businessName: businessName, 
            businessType: businessType, 
            businessPhone: businessPhone, 
            businessEmail: businessEmail, 
            businessDescription: businessDescription, 
            businessCountry: businessCountry,
            businessState: businessState,
        }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }).then(async (result) => {
          setAlertMessage('Business profile successfully updated');
          setShowAlert(true);
          await new Promise((resolve) => setTimeout(resolve, 3000));
          history("/settings")
        })
          .catch(error => {
            const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
            setAlertMessage(jsonString);
            setShowAlert(true);
          })
        }

    const UpdateUserDetails = (event) => {
        event.preventDefault();
        axios.post(`${BASE_URL}/update-profile`, {
            phone: phone, 
            password: password, 
            image: image
        }, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data"
            },
          }).then(async (result) => {
          setAlertMessage("User's profile successfully updated");
          setShowAlert(true);
          await new Promise((resolve) => setTimeout(resolve, 3000));
          history("/settings")
        })
          .catch(error => {
            const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
            setAlertMessage(jsonString);
            setShowAlert(true);
          })
        }
        const [currency, setCurrency] = useState(null);
        const [payInfo, setPayInfo] = useState(null);
        const [payment_currency, setPayment_currency] = useState('')
        const handlePayment_currency = (e) => { setPayment_currency(e.target.value); handleSelectCurrency(e.target.value)}

        const fetchCurrency = async () => {
            try {
              const response = await axios.get(`${BASE_URL}/payment-method/sys-currencies`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
        
              return response.data;
            } catch (error) {
              console.error(error);
              const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
              setAlertMessage(jsonString);
            }
          };
          useEffect(() => {
            const getCurrency = async () => {
              const data = await fetchCurrency();
              setCurrency(data);
            };
        
            getCurrency();
          }, []);
          const handleSelectCurrency = async (id) => {
            try {
              const response = await axios.get(`${BASE_URL}/payment-method/sys-currencies?currency=${id}`,{
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
              return response.data;
              // Handle successful deletion, e.g. remove the trade from the table data and update the UI
            } catch (error) {
              console.error(error);
              const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
              setAlertMessage(jsonString);
              // Handle error, e.g. display an error message to the user
            }
          };
          useEffect(() => {
            const getPayInfo = async () => {
              const data = await handleSelectCurrency();
              setPayInfo(data);
            };
            getPayInfo();
          }, []);

          const [nationality, setNationality] = useState('');
          const [idType, setIdType] = useState('');
          const [id_front, setIdfront] = useState(null);
          const [id_back, setIdback] = useState(null);
          const [selfie_image, setSelfieImage] = useState(null);
          const [doNotShowIdBack, setDonotShowIdBack] = useState(false)


          const handleIdType = (e) => { 
            setIdType(e.target.value)
            if(e.target.value == "Passport"){
              setDonotShowIdBack(true);
            }else{
              setDonotShowIdBack(false)
            }
          }
          const handleIdfront = (e) => { setIdfront(e.target.files[0])}
          const handleIdback= (e) => { setIdback(e.target.files[0])}
          const handleSelfieImage = (e) => { setSelfieImage(e.target.files[0])}

          const ComplianceIdentity = (event) => {
            event.preventDefault();
            axios.post(`${BASE_URL}/compliance/identity`, {
              nationality : nationality,
              idType: idType,
              id_front: id_front,
              id_back: id_back,
              selfie_image: selfie_image
            }, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data"
                },
              }).then(result => {
              next();
            })
              .catch(error => {
                const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
                setAlertMessage(jsonString);
                setShowAlert(true);
              })
            }

            const [zipcode, setZipcode] = useState('');
            const [utilityType, setUtilityType] = useState('');
            const [utility_image, setUtility_image] = useState('');


            const handleZipcode = (e) => { setZipcode(e.target.value)}
            const handleUtilityType = (e) => { setUtilityType(e.target.value)}
            const handleUtility_image = (e) => { setUtility_image(e.target.files[0])}

          const ComplianceAddress = (event) => {
            event.preventDefault();
            axios.post(`${BASE_URL}/compliance/address`, {
              zipcode : zipcode,
              utilityType: utilityType,
              utility_image: utility_image
            }, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data"
                },
              }).then(result => {
              next();
            })
              .catch(error => {
                const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
                setAlertMessage(jsonString);
                setShowAlert(true);              
              })
            }

          const [director_1, setDirector1] = useState('') 
          const [d_image_1, setDimage1] = useState('')
          const [director_2, setDirector2] = useState('') 
          const [d_image_2, setDimage2] = useState('')
          const [director_3, setDirector3] = useState('') 
          const [d_image_3, setDimage3] = useState('')

          const handleDirector1 = (e) => { setDirector1(e.target.value)}
          const handleDirector2 = (e) => { setDirector2(e.target.value)}
          const handleDirector3 = (e) => { setDirector3(e.target.value)}
          const handleDimage1 = (e) => { setDimage1(e.target.files[0])}
          const handleDimage2 = (e) => { setDimage2(e.target.files[0])}
          const handleDimage3 = (e) => { setDimage3(e.target.files[0])}

          const [share_holder_1, setShareholder1] = useState('')
          const [d_share_holder_1, setDshareholder1] = useState('')
          const [share_holder_2, setShareholder2] = useState('')
          const [d_share_holder_2, setDshareholder2] = useState('')
          const [share_holder_3, setShareholder3] = useState('')
          const [d_share_holder_3, setDshareholder3] = useState('')

          const handleShareholder1 = (e) => { setShareholder1(e.target.value)}
          const handleDshareholder1 = (e) => { setDshareholder1(e.target.files[0])}
          const handleShareholder2 = (e) => { setShareholder2(e.target.value)}
          const handleDshareholder2 = (e) => { setDshareholder2(e.target.files[0])}
          const handleShareholder3 = (e) => { setShareholder3(e.target.value)}
          const handleDshareholder3 = (e) => { setDshareholder3(e.target.files[0])}


            const validatePassword = () => {
              if (password !== confirmPassword) {
                setErrorPassword("Passwords do not match!");
              } else {
                setErrorPassword("");
              }
            }

            const [incoporation, setIncoporation] = useState('')
            const [address_proof, setAddressproof] = useState('')
            const [business_license, setBusinesslicense] = useState('')
            const [tax_id, setTaxid] = useState('')
            const [aml_policy, setAmlPolicy] = useState('')

            const [legal_business_name, setLegalName] = useState('')
            const [operating_biss_as, setOperatingName] = useState('')
            const [registration_country, setRegistration_country] = useState('')
            const [state, setOperatingstate] = useState('')
            const [street_address, setStreet_address] = useState('')
            const [website_address, setWebsite_address] = useState('')
            const [business_usecase, setBusiness_usecase] = useState('')
            const [errorMessage, setErrorMessage] = useState('');

            const handleLegalName = (e) => { setLegalName(e.target.value)}
            const handleOperatingName = (e) => { setOperatingName(e.target.value)}
            const handleStreetAdd = (e) => { setStreet_address(e.target.value)}
            const handleWebAddress = (e) => { 
              setWebsite_address(e.target.value);
              const urlPattern = /^https?:\/\/.+$/;
              if (!urlPattern.test(website_address)) {
                setErrorMessage('Please enter a valid URL starting with http:// or https://');
                return;
              } else{
                setErrorMessage('')
              }
            }
            const handleBussinessUsecase = (e) => { setBusiness_usecase(e.target.value)}

            const ComplianceBusinessDetails = (event) => {
              event.preventDefault();
              axios.post(`${BASE_URL}/compliance/update/profile`, {
                  legal_business_name: legal_business_name,
                  operating_biss_as: operating_biss_as,
                  registration_country: registration_country,
                  state: state,
                  street_address : street_address,
                  website_address : website_address,
                  business_usecase : business_usecase
              }, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data"
                  },
                }).then(result => {
                  next();
              })
                .catch(error => {
                  const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
                  setAlertMessage(jsonString);
                  setShowAlert(true);
                })
              }
            const [emailPI, setEmailPI] = useState('')
            const [street_address1, setStreet_address1] = useState('')
            const [fullName, setFullName] = useState('')
            const [city_of_residencePI, setCity_of_residence] = useState('')
            const [state_of_residence, setState_of_residence] = useState('')
            const [country_of_residence, setCountry_of_residence] = useState('')
            const [dob, setDob] = useState('')
            const [phone1, setPhone1] = useState('')

            const handleEmail = (e) => { setEmailPI(e.target.value)}
            const handleStreetAdd1 = (e) => { setStreet_address1(e.target.value)}
            const handleFullName = (e) => { setFullName(e.target.value)}
            const handleCity_of_residence = (e) => { setCity_of_residence(e.target.value)}
            const handleDob = (e) => { setDob(e.target.value)}
            const handlePhone1 = (value) => { setPhone1(value)}

            const ComplianceProfile = (event) => {
              event.preventDefault();
              axios.post(`${BASE_URL}/compliance/personal/profile`, {
                  email: emailPI,
                  street_address: street_address1,
                  fullName: fullName,
                  state: state,
                  city_of_residence : city_of_residencePI,
                  state_of_residence : state_of_residence,
                  country_of_residence : country_of_residence,
                  dob : dob,
                  phone: phone1
              }, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data"
                  },
                }).then(result => {
                next();
              })
                .catch(error => {
                  const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
                  setAlertMessage(jsonString);
                  setShowAlert(true);
                })
              }

            const handleIncoporation = (e) => { setIncoporation(e.target.files[0])}  
            const handleAddressproof = (e) => { setAddressproof(e.target.files[0])}  
            const handleBusinesslicense = (e) => { setBusinesslicense(e.target.files[0])}  
            const handleTaxid = (e) => { setTaxid(e.target.files[0])}  
            const handleAmlPolicy = (e) => { setAmlPolicy(e.target.files[0])}  

          const ComplianceBusinessDocuments = (event) => {
              // Check if any of the directors are still uploading images
            const isAnyUploading = directors.some((director) => director.isUploading);
            const isAnyUploading2 = shareholders.some((shareholder) => shareholder.isUploading);

            if (isAnyUploading || isAnyUploading2) {
              // Show alert message when images are still uploading
              toast.warning('Images are still uploading. Please wait.');
            } else {
              event.preventDefault();
              axios.post(`${BASE_URL}/compliance/business_docs`, {
                  incoporation: incoporation,
                  address_proof: address_proof,
                  business_license: business_license,
                  tax_id: tax_id,
                  aml_policy : aml_policy
              }, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data"
                  },
                }).then(async (result) => {
                toast.success('successfully submited');
                next();
              })
                .catch(error => {
                  const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
                  setAlertMessage(jsonString);
                  setShowAlert(true);
                })
              }
            }
            const [userPayment, setUserPayment] = useState(null);
            const [paymentMethod, setPaymentMethod] = useState('')
            const [payment_channel, setPayment_channel] = useState('')
            const [market_place_1, setMarket_place_1] = useState('')
            const [market_place_2, setMarket_place_2] = useState('')
            const [market_place_3, setMarket_place_3] = useState('')
            const [username_1, setUsername_1] = useState('')
            const [username_2, setUsername_2] = useState('')
            const [username_3, setUsername_3] = useState('')
            const [exchange_statement, setExchange_statement] = useState('')
            const [major_income_sources, setMajor_income_sources] = useState('')
            const [expected_transaction_volume, setExpected_transaction_volume] = useState('')

            const handlePaymentChannel = (e) => { setPayment_channel(e.target.value)}
            const handleMarket_place_1 = (e) => { setMarket_place_1(e.target.value)}
            const handleMarket_place_2 = (e) => { setMarket_place_2(e.target.value)}
            const handleMarket_place_3 = (e) => { setMarket_place_3(e.target.value)}
            const handleUsername_1 = (e) => { setUsername_1(e.target.value)}
            const handleUsername_2 = (e) => { setUsername_2(e.target.value)}
            const handleUsername_3 = (e) => { setUsername_3(e.target.value)}
            const handleMajor = (e) => { setMajor_income_sources(e.target.value)}
            const handleExpected = (e) => { setExpected_transaction_volume(e.target.value)}
            const handleExchangeStatement = (e) => { setExchange_statement(e.target.files[0])}
            const handlePaymentMethod = (e) => {
              setPaymentMethod(e.target.value)
          }
          const ComplianceAgent = (event) => {
            event.preventDefault();
            axios.post(`${BASE_URL}/compliance/agent`, {

              exchange_statement: exchange_statement,
              payment_method : paymentMethod,
              payment_channel: payment_channel,
              market_place_1 : market_place_1,
              username_1: username_1,
              market_place_2: market_place_2,
              username_2: username_2,
              market_place_3: market_place_3,
              username_3: username_3,
              major_income_sources: major_income_sources,
              expected_transaction_volume: expected_transaction_volume
              
            }, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data"
                },
              }).then(async (result) => {
              setAlertMessage('successfully submited');
              setShowAlert(true);      
              await new Promise((resolve) => setTimeout(resolve, 2000));        
              next();
            })
              .catch(error => {
                const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
                setAlertMessage(jsonString);
                setShowAlert(true);
              })
            }
            const fetchUserPayment = async () => {
              try {
                const response = await axios.get(`${BASE_URL}/payment-method`, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                });
          
                return response.data;
              } catch (error) {
                console.error(error);
              }
            };
            useEffect(() => {
              const getUserPayment = async () => {
                const data = await fetchUserPayment();
                setUserPayment(data);
              };
          
              getUserPayment();
            }, []);

            const handleAlertClose = () => {
              setShowAlert(false);
            };
            const [isButtonDisabled, setIsButtonDisabled] = useState(true);
            const [isButtonDisabled1, setIsButtonDisabled1] = useState(true);
            const [user_Data, setUserData] = useState({});
            const fetchUserData = async () => {
              try {
                const response = await axios.get(`${BASE_URL}/user`, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                });
          
                const data = response.data;
                if (data?.data.is_personnal_verification_approved == 1){
                  setIsButtonDisabled(false)
                }
                if (data?.data.is_business_approved == 1){
                  setIsButtonDisabled1(false)
                }
                return response.data;
              } catch (error) {
                console.error(error);
              }
            };
            useEffect(() => {
              const getUserData = async () => {
                const data = await fetchUserData();
                setUserData(data);
                setEmailPI(data.data.email)
                setPhone1(data.data.phoneNumber)
                if (data.data.is_personnal_verification_approved == 1){
                  setIsButtonDisabled(false)
                }
                if (data.data.is_business_approved == 1){
                  setIsButtonDisabled1(false)
                }
              };
          
              getUserData();
            }, []);

            const [profileData, setProfileData] = useState(null);
            const [filledForm1, setHasFilledForm1] = useState(false);
            const [filledForm2, setHasFilledForm2] = useState(false);
            const [filledForm3, setHasFilledForm3] = useState(false);
            useEffect(() => {
                const fetchData = async () => {
                  try {
                    const response = await fetch(`${BASE_URL}/compliance`, {
                      headers: {
                        Authorization: `Bearer ${token}`
                      }
                    });
                    const data = await response.json();
                    console.log(data)
                    setProfileData(data);
                    if ((data.data.legal_business_name ?? "") && (data.data.operating_biss_as ?? "") && (data.data.registration_country ?? "") && (data.data.state ?? "") || (data.data.is_submitted == "1")) {
                      setHasFilledForm2(true);
                    }
                    if ((data.data.exchange_statement ?? "" )&& (data.data.major_income_sources ?? "") && (data.data.expected_transaction_volume ?? "")) {
                      setHasFilledForm3(true);
                    }
                  } catch (error) {
                    console.error(error);
                  }
                };
              
                fetchData();
              }, []);
              const [shareholders, setShareholders] = useState([{ id: 1, position:'shareholder', name: '',email:'', shareholdingPercentage:'', idType:'',proofId:''}]);
              const [shareholdersImages, setShareholdersImages] = useState([]);
              const [directors, setDirectors] = useState([{ id: 1, position:'', name: '',email:'',country:'',phone:'', address:'', idType:'',proofId:'' }]);
              const [directorsImages, setDirectorsImages] = useState([]);

              const handleDirectorImageInputChange = (event, index) => {
                setDirectors((prevFormData) =>
                  prevFormData.map((director, i) =>
                    i === index
                      ? {
                          ...director,
                          isUploading: true, // Set isUploading to true when starting the upload for this director
                        }
                      : director
                  )
                );

                const endpointUrl = `${BASE_URL}/compliance/image-upload`;
              
                axios
                  .post(endpointUrl, {
                    type: 'directors',
                    image: event.target.files[0],
                  }, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'Content-Type': 'multipart/form-data',
                    },
                  })
                  .then((response) => {
                    // Handle the response from the server if needed
                    console.log('Image uploaded successfully!', response.data);
              
                    setDirectors((prevFormData) =>
                      prevFormData.map((formFields, i) =>
                        i === index ? { 
                          ...formFields, 
                          proofId: response.data,
                          isUploading: false, 
                        } : formFields
                      )
                    );
                  })
                  .catch((error) => {
                    // Handle any errors that occurred during the request
                    console.error('Error uploading image:', error);
                    setDirectors((prevFormData) =>
                    prevFormData.map((director, i) =>
                      i === index
                        ? {
                            ...director,
                            isUploading: false, // Set isUploading back to false if there's an error
                          }
                        : director
                    )
                  );
                });
              };

              const handleShareholderImageInputChange = (event, index) => {
                setShareholders((prevFormData) =>
                  prevFormData.map((shareholder, i) =>
                    i === index
                      ? {
                          ...shareholder,
                          isUploading: true, // Set isUploading to true when starting the upload for this director
                        }
                      : shareholder
                  )
                );
                const endpointUrl = `${BASE_URL}/compliance/image-upload`;
              
                axios
                  .post(endpointUrl, {
                    type: 'shareholders',
                    image: event.target.files[0],
                  }, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'Content-Type': 'multipart/form-data',
                    },
                  })
                  .then((response) => {
                    // Handle the response from the server if needed
                    console.log('Image uploaded successfully!', response.data);
              
                    setShareholders((prevFormData) =>
                      prevFormData.map((formFields, i) =>
                        i === index ? { ...formFields, proofId: response.data, isUploading: false } : formFields
                      )
                    );
                  })
                  .catch((error) => {
                    // Handle any errors that occurred during the request
                    console.error('Error uploading image:', error);
                    setDirectors((prevFormData) =>
                    prevFormData.map((director, i) =>
                      i === index
                        ? {
                            ...director,
                            isUploading: false, // Set isUploading back to false if there's an error
                          }
                        : director
                    )
                  );
                });
              };
              

              const handleDirectorInputChange = (event, index) => {
                const { name, value } = event.target;
                setDirectors((prevFormData) =>
                  prevFormData.map((formFields, i) =>
                    i === index ? { ...formFields, [name]: value } : formFields
                  )
                );
              };
            
              // Function to handle changes in form fields for formData2
              const handleShareholderInputChange = (event, index) => {
                const { name, value } = event.target;
                setShareholders((prevFormData) =>
                  prevFormData.map((formFields, i) =>
                    i === index ? { ...formFields, [name]: value } : formFields
                  )
                );
              };

             
              const handleAddDirector = () => {
                const newId = directors.length + 1;
                setDirectors((prevFormData) => [...prevFormData, { id: newId, position:'', name: '',email:'',country:'',phone:'', address:'', idType:'',proofId:'',isUploading: false,  }]);
                setDirectorsImages((prevFormDataImages) => [...prevFormDataImages, null]);
              };
              const handleAddShareholder = () => {
                const newId = shareholders.length + 1;
                setShareholders((prevFormData) => [...prevFormData, { id: newId, position:'shareholder', name: '',email:'', shareholdingPercentage:'', idType:'',proofId:'',isUploading: false,  }]);
                setShareholdersImages((prevFormDataImages) => [...prevFormDataImages, null]);
              };


              const handleRemoveShareholder = (index) => {
                setShareholders((prevFormData) => prevFormData.filter((form, i) => i !== index));
                setShareholdersImages((prevFormDataImages) => prevFormDataImages.filter((image, i) => i !== index));
              };

              const handleRemoveDirector = (index) => {
                setDirectors((prevFormData) => prevFormData.filter((form, i) => i !== index));
                setDirectorsImages((prevFormDataImages) => prevFormDataImages.filter((image, i) => i !== index));
              };

              const toggleDirectorCollapse = (id) => {
                const updatedDirectors = directors.map((director) => {
                  if (director.id === id) {
                    return { ...director, collapsed: !director.collapsed };
                  }
                  return director;
                });
                setDirectors(updatedDirectors);
              };

              const toggleShareholderCollapse = (id) => {
                const updatedShareholders = shareholders.map((shareholder) => {
                  if (shareholder.id === id) {
                    return { ...shareholder, collapsed: !shareholder.collapsed };
                  }
                  return shareholder;
                });
                setShareholders(updatedShareholders);
              };
                const ComplianceDirectorsandShareholders2 = async (event) => {
                  event.preventDefault();
                
                  const formData = new FormData();
                
                  directors.forEach((director) => {
                    formData.append('directors[]name', director.name);
                    formData.append('directors[]email', director.email);
                    formData.append('directors[]country', director.country);
                    formData.append('directors[]phone', director.phone);
                    formData.append('directors[]address', director.address);
                    formData.append('directors[]idType', director.idType);
                    formData.append('directors[]proofId', director.proofId);
                  });
                
                  shareholders.forEach((shareholder) => {
                    formData.append('shareholders[]name', shareholder.name);
                    formData.append('shareholders[]email', shareholder.email);
                    formData.append('shareholders[]shareholdingPercentage', shareholder.shareholdingPercentage);
                    formData.append('shareholders[]idType', shareholder.idType);
                    formData.append('shareholders[]proofId', shareholder.proofId);
                  });
                
                  try {
                    console.log(formData);
                    const result = await axios.post(`${BASE_URL}/compliance/directors`, formData, {
                      headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                      },
                    });
                
                    setAlertMessage('Successfully submitted');
                    setShowAlert(true);
                    await new Promise((resolve) => setTimeout(resolve, 2000));
                    next();
                  } catch (error) {
                    const jsonString = JSON.stringify(error.response.data.errors).replace(/[\[\]{}'"]+/g, '');
                    setAlertMessage(jsonString);
                    setShowAlert(true);
                  }
                };
                const ComplianceDirectorsandShareholders = (event) => {
                  event.preventDefault();
              
                  // Convert the formData, formData1, and formData2 to JSON strings using JSON.stringify()
                  
                  const directorsWithoutUploading = directors.map(({ isUploading, ...rest }) => rest);
                  const shareholdersWithoutUploading = shareholders.map(({ isUploading, ...rest }) => rest);

                  const jsonData = JSON.stringify({ directors: directorsWithoutUploading, shareholders: shareholdersWithoutUploading });
                  // Make the API request using axios
                  axios.post(`${BASE_URL}/compliance/directors`, jsonData, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                      'Content-Type': 'application/json',
                    },
                  })
                    .then((response) => {
                      // Handle the response from the server if needed
                      console.log(response.data);
                      next();
                    })
                    .catch((error) => {
                      // Handle any errors that occurred during the request
                      console.error('Error:', error);
                    });
                  }
                    /**
   *  This is your app ID
   * (go to your dashboard at
   * https://dojah.io/dashboard
   * to create an app and retrieve it)
   */
  const appID = "636f97a5013b8400355125b6";

  /**
   *  This is your account public key
   *  (go to your dashboard at
   *  https://dojah.io/dashboard to
   *  retrieve it. You can also regenerate one)
   */
  const publicKey = "prod_pk_OHRkjdfUdafMfOCMru6fM2YeQ";

  /**
   *  This is the widget type you'd like to load
   *  (go to your dashboard at
   *  https://dojah.io/dashboard to enable different
   *  widget types)
   */
  const type = "verification";

  /**
   *  These are the configuration options
   *  available to you are:
   *  {debug: BOOL, pages: ARRAY[page: STRING, config: OBJECT]}
   *
   *  The config object is as defined below
   *
   *  NOTE: The otp and selfie options are only
   *  available to the `verification` widget
   */
  const config = {
    debug: true,
    pages: [
      {
        page: 'government-data',
        config: {
          bvn: true,
          nin: true,
          dl: true,
          mobile: true,
          otp: true,
          selfie: true,
        },
      },
      { page: 'email', config: { verification: true }},
      {page: 'selfie'},
      {page: 'id', config: {passport: true, dl: true}},
      {page: "address", config: { verification: true }}
    ],
  };

  /**
   *  These are the user's data to verify, options
   *  available to you possible options are:
   *  {first_name: STRING, last_name: STRING, dob: DATE STRING}
   *
   *  NOTE: Passing all the values will automatically skip
   *  the user-data page (thus the commented out `last_name`)
   */
  const userData = {
    first_name: user_Data?.data?.firstName,
    last_name: user_Data?.data?.lastName, // 'Nna'
    dob: '',
  };

  /**
   *  These are the metadata options
   *  You can pass any values within the object
   */
  const metadata = {
    user_id: user_Data?.data?.id,
  };

  /**
   * @param {String} type
   * This method receives the type
   * The type can only be one of:
   * loading, begin, success, error, close
   * @param {String} data
   * This is the data from doja
   */
  const response = (type, data) => {
    console.log(type, data);
    if(type === 'success'){
      toast.success('Verification Submitted!')
      history(0)
    }else if(type === 'error'){
      toast.error('Error! failed to verify');
      history(0)
    }else if(type === 'close'){
      toast.warning('You closed the verification window');
      history(0)
    }else if(type === 'begin'){
    }else if(type === 'loading'){
    }
  }

  // The Doja library accepts 3 props and
  // initiliazes the doja widget and connect process
  return   (
    ( b_details && userPayment && user_Data  ? (
    <>
    {showAlert && (
        <AlertModal
          isModalOpen={showAlert}
          alertMessage={alertMessage}
          onClose={handleAlertClose}
        />
      )}
      <Navbar toggleSidebar={toggleSidebar}/>
        <MainContainer isOpen={isOpen}>
            <Sidebar1 isOpen={isOpen} toggleSidebar={toggleSidebar} />
            <MainContent>
        <Container>
        <ToastContainer />
        <FormWrap>
                <CenterContent>
            <FormH2>Compliance</FormH2>
              <PayoutCardWrapper>
                <PayoutCard2>
                    <img src={Store} style={{padding:'20px', paddingBottom:'0'}}/>
                    <PayoutH style={{marginTop:'0',marginBottom:'0', fontSize:'24px', fontWeight:'600', fontFamily:'poppins'}}>Individual</PayoutH>
                    <div style={{padding: '20px', paddingLeft: '40px', fontSize:'17px', lineHeight:'26px'}}>
                      <li style={{ color:'#1E1E1E', fontWeight:'500'}}>Enter Personal Details</li>
                      <li style={{ color:'#1E1E1E', fontWeight:'500'}}>Upload ID document</li>
                      <li style={{ color:'#1E1E1E', fontWeight:'500'}}>Submit Proof of Address/Utility Bill</li>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', padding:'20px', paddingLeft:'40px', marginTop:'-20px', paddingRight:'40px', cursor: filledForm1 ? 'not-allowed' : 'pointer', backgroundColor:'#fff', color:'#4D0B6C' }} onClick={user_Data?.data?.is_personnal_verification_approved ? () => {setAlertMessage("Personal Account Verification successful Already!"); setShowAlert(true)} : () => {setOpen(true); setDim(true)}}>
                    {user_Data?.data?.is_personnal_verification_approved == 1 ?
                      (<>
                        <div style={{color: "rgb(7, 45, 5)"}}>Verification Approved {console.log(filledForm1)}</div>
                        <div><BsPatchCheckFill color='rgb(7, 45, 5)'/></div>
                      </>)
                      :
                      (
                        <>
                          { filledForm1 ? 
                          (<>
                            <div style={{color: "#0909a8"}}>Pending Verification</div>
                            <div><BsExclamationCircle color='#0909a8'/></div>
                          </>):
                          (
                            <>
                            <div>Get Started</div>
                            <div><AiOutlineArrowRight /></div>
                            </>
                          )}
                        </>
                      )
                    }
                    </div>
                </PayoutCard2>
                <PayoutCard2>
                  <img src={Officebuilding} style={{padding:'20px', paddingBottom:'0'}}/>
                  <PayoutH style={{marginTop:'0',marginBottom:'0', fontSize:'24px', fontWeight:'600', fontFamily:'poppins'}}>Business</PayoutH>
                  <div style={{padding: '20px', paddingLeft: '40px', fontSize:'17px', lineHeight:'26px'}}>
                      <li style={{ color:'#1E1E1E', fontWeight:'500'}}>Enter Business Details</li>
                      <li style={{ color:'#1E1E1E', fontWeight:'500'}}>Upload Business Personnels</li>
                      <li style={{ color:'#1E1E1E', fontWeight:'500'}}>Submit Business proof documents</li>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', padding:'20px', paddingLeft:'40px', paddingRight:'40px',marginTop:'-20px',  cursor: isButtonDisabled ? 'not-allowed' : 'pointer', backgroundColor:'#fff', color:'#4D0B6C' }} title={isButtonDisabled ? 'Please fill the preceding form first.' : ''} 
                    onClick={isButtonDisabled ? () => {toast.warning("Complete Personal Account Verification first",{position: toast.POSITION.TOP_CENTER})} : () => {if (filledForm2) {toast.success("Registered Business Verification submitted already!")} else {setOpen1(true);setDim(true);}}}>
                      {user_Data?.data?.is_business_approved == 1 ?
                      (<>
                        <div style={{color: "rgb(7, 45, 5)"}}>Verification Approved</div>
                        <div><BsPatchCheckFill color='rgb(7, 45, 5)'/></div>
                      </>)
                      :
                      (
                        <>
                          { filledForm2 ? 
                          (<>
                            <div style={{color: "#0909a8"}}>Pending Verification</div>
                            <div><BsExclamationCircle color='#0909a8'/></div>
                          </>):
                          (
                            <>
                            <div style={{color: isButtonDisabled ? 'grey' : ''}}>Get Started</div>
                            <div style={{color: isButtonDisabled ? 'grey' : ''}}><AiOutlineArrowRight /></div>
                            </>
                          )}
                        </>
                      )
                    }
                    </div>
                </PayoutCard2>
                <PayoutCard2>
                  <img src={Busineessman} style={{padding:'20px', paddingBottom:'0'}}/>
                  <PayoutH style={{marginTop:'0',marginBottom:'0', fontSize:'24px', fontWeight:'600', fontFamily:'poppins'}}>Become an Agent</PayoutH>
                    <div style={{padding: '20px', paddingLeft: '40px', fontSize:'17px', lineHeight:'26px'}}>
                      <li style={{ color:'#1E1E1E', fontWeight:'500'}}>Enter Personal Details</li>
                      <li style={{ color:'#1E1E1E', fontWeight:'500'}}>Verify source of Funds</li>
                      <li style={{ color:'#1E1E1E', fontWeight:'500',paddingBottom:'20px'}}>Link your Bank account</li>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', padding:'20px', paddingLeft:'40px', paddingRight:'40px',marginTop:'-20px',  cursor: isButtonDisabled1 ? 'not-allowed' : 'pointer' , backgroundColor:'#fff', color:'#4D0B6C'}} 
                                        onClick={isButtonDisabled1 ? () => {toast.warning("Complete Business Account Verification first",{position: toast.POSITION.TOP_CENTER})} : () => {if (filledForm3) {toast.success("Agent Verification submitted already!")} else {setOpen2(true);setDim(true);}}}>
                      <div style={{color: isButtonDisabled1 ? 'grey' : ''}}>Get Started</div>
                      <div style={{color: isButtonDisabled1 ? 'grey' : ''}}><AiOutlineArrowRight /></div>
                    </div>
                </PayoutCard2>
              </PayoutCardWrapper>
                    {/*<div style={{display: 'block ruby'}}>
                     <ComplianceTopText>Starter business: Personal Account Verification </ComplianceTopText> <ComplianceBtn style={{ cursor: filledForm1 ? 'not-allowed' : 'pointer' }} onClick={filledForm1 ? () => {setAlertMessage("Personal Account Verification Submitted Already!"); setShowAlert(true)} : () => {setOpen(true); setDim(true)}}>Get Started</ComplianceBtn></div>
                    <ComplianceSubText>Provide required personal details here,<br></br> to enable you make withdrawals in crypto and fiat currencies</ComplianceSubText>
                    <ComplianceRectangle> <ComplianceText><img src={contactbook} /> &nbsp; Your Personal Information</ComplianceText> </ComplianceRectangle>
                    <ComplianceRectangle> <ComplianceText><img src={Identity} /> &nbsp; Your Identity Information</ComplianceText> </ComplianceRectangle>
                    <ComplianceRectangle> <ComplianceText><img src={address} /> &nbsp; Your Address and Location Details</ComplianceText> </ComplianceRectangle> */}           
                    <div>
                    <Popup open={open} onClose={() => { setOpen(false); setDim(false)}} modal closeOnDocumentClick contentStyle={{width: '100%', height: '100%', backgroundColor: 'white', overflow: 'scroll'}} overlayStyle={{background: dim ? 'rgba(0, 0, 0, 0.5)' : 'none'}}>
                        {close => (
                            <div>
                            <Steps>
                            <div className='content'>
                            <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><a className="close" onClick={close}>
                            <b style={{fontSize: '26px', marginLeft: '10px', alignItems: 'center', cursor: 'pointer'}}>&times; | </b>
                            </a> <b>Personal Information</b></div>
                            <div style={{ position: 'relative', width: '100%', height: '100vh', padding:'10px' }}>
                              {/*<iframe
                                title="ZeenahPay Compliance"
                                src="https://identity.dojah.io?widget_id=64bea758deada90040c2a5e5"
                                width="100%"
                                style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, width: '100%', height: '100%' }}
                              />*/}
                              <Dojah
                                response={response}
                                appID={appID}
                                publicKey={publicKey}
                                type={type}
                                config={config}
                                userData={userData}
                                metadata={metadata}
                              />
                            </div>
                              
                            </div>
                        {/*<div className="content">
                            <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><a className="close" onClick={close}>
                            <b style={{fontSize: '26px', marginLeft: '10px', alignItems: 'center', cursor: 'pointer'}}>&times; | </b>
                            </a> <b>Personal Information</b> <b style={{float: 'right', marginRight: '10px' }}>Step {current} of {total}</b></div>
                            <Form2 onSubmit={ComplianceProfile}>
                                <FormLabel>Full Name</FormLabel>
                                <FormInput id='fullName' type='text' placeholder='John Doe' value={fullName} onChange={handleFullName} />
                                <FormLabel>Email</FormLabel>
                                <FormInput id='email' style={{backgroundColor: '#f0f0f0', color:'#7f7f7f', cursor: 'not-allowed'}} type='email' placeholder='Johndoe@gmail.com' value={emailPI}  onChange={handleEmail} readOnly={true}/>
                                <FormLabel>Phone Number</FormLabel>
                                <PhoneInput country={'ng'} inputProps={{ name:' ', required: true, autoFocus: true, }} style={{marginBottom:'20px',backgroundColor: '#f0f0f0 !important', color:'#7f7f7f !important'}} value={phone1} onChange={handlePhone1} readOnly={true} disabled={true}/>
                                <FormLabel>Date of Birth</FormLabel>
                                <FormInput type='date' value={dob} onChange={handleDob} id='DOB' />
                                <FormLabel>Country of Residence</FormLabel>
                                <CountryDropdown value={country_of_residence} onChange={(val) => setCountry_of_residence(val)} style={{ padding: "16px 16px", marginBottom: "32px", border: "1px solid #BCBCBC", borderRadius: "6px", backgroundColor: "transparent"}} />
                                <FormLabel>State of Residence</FormLabel>
                                <RegionDropdown country={country_of_residence} value={state_of_residence} onChange={(val) => setState_of_residence(val)} style={{ padding: "16px 16px", marginBottom: "32px", border: "1px solid #BCBCBC", borderRadius: "6px", backgroundColor: "transparent"}} />
                                <FormLabel>City of Residence</FormLabel>
                                <FormInput id='city' type='text' placeholder='Gotham' value={city_of_residencePI} onChange={handleCity_of_residence} />
                                <FormLabel>Street Address</FormLabel>
                                <FormInput id='street' value={street_address1} onChange={handleStreetAdd1} placeholder='47 Old Ikoyi Kilomt'/>
                                <PrimaryBtn  type='submit' style={{ borderRadius: '12px', textAlign: 'center', width: '100%'}}>Submit and Proceed</PrimaryBtn>
                                <PrimaryBtn onClick={() => {next()}}>Next</PrimaryBtn>
                                <PrimaryBtn onClick={() => {prev()}}>back</PrimaryBtn>
                            </Form2>
                        </div> 
                        <div className="content">
                            <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><a className="close" onClick={close}>
                            <b style={{fontSize: '26px', marginLeft: '10px', alignItems: 'center'}}>&times; | </b>
                            </a> <b>Identity Document</b> <b style={{float: 'right', marginRight: '10px' }}>Step {current} of {total}</b></div>
                            <div>
                                <Form2 onSubmit={ComplianceIdentity}>
                                    <FormLabel>Nationality</FormLabel>
                                    <CountryDropdown value={nationality} onChange={(val) => setNationality(val)} style={{ padding: "16px 16px", marginBottom: "32px", border: "1px solid #BCBCBC", borderRadius: "6px", backgroundColor: "transparent"}} />
                                    <FormLabel>Means of Identification</FormLabel>
                                    <SelectInput value={idType} onChange={handleIdType}>
                                        <option>Select ID</option>
                                        <option value='Passport'>Passport</option>
                                        <option value='Natiional Id'>National ID</option>
                                        <option value='Drivers License'>Driver's License</option>
                                    </SelectInput>
                                    <FormLabel>ID Front</FormLabel>
                                    <FormInput id='idFront' type='file'  onChange={(e) => setIdfront(e.target.files[0])} accept="image/*" placeholder='Upload the front page of your identity document'/>
                                    { !doNotShowIdBack &&
                                      <>
                                        <FormLabel>ID Back</FormLabel>
                                        <FormInput type='file' accept="image/*" id='idBack'  onChange={handleIdback} placeholder='Upload the back page of your identity document'/>
                                      </>
                                    }
                                    
                                    <FormLabel>Take a live photo (selfie, potrait)</FormLabel>
                                    <FormInput type='file' accept="image/*"  onChange={handleSelfieImage}/>
                                    {selfie_image &&
                                    <ComplianceSquare  style={{textAlign: 'center', alignItems: 'center'}}>
                                       <img src={URL.createObjectURL(selfie_image)} alt="Selfie" style={{width:'100%', padding:'20%'}} />
                                    </ComplianceSquare>}
                                    <PrimaryBtn type='submit' style={{ borderRadius: '12px', textAlign: 'center', width: '100%'}}>Submit and Proceed</PrimaryBtn>
                                    <PrimaryBtn onClick={() => {next()}}>Next</PrimaryBtn>
                                    <PrimaryBtn onClick={() => {prev()}}>back</PrimaryBtn>
                                </Form2>
                            </div>
                        </div>
                        <div className="content">
                            <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><a className="close" onClick={close}>
                        <b style={{fontSize: '26px', marginLeft: '10px', alignItems: 'center'}}>&times; | </b>
                        </a> <b>Proof of Address</b> <b style={{float: 'right', marginRight: '10px' }}>Step {current} of {total}</b></div>
                        <div>
                        <Form2 onSubmit={ComplianceAddress} >
                            <FormLabel>Address ZIP Code</FormLabel>
                            <FormInput id='zipCode' value={zipcode} placeholder='ZIP Code' onChange={handleZipcode}/>
                            <FormLabel>Utility Bill Type</FormLabel>
                            <SelectInput value={utilityType} onChange={handleUtilityType} >
                                <option value='Electricity bill'>Electricity Bill</option>
                                <option value='Water bill'>Water Bill</option>
                                <option value='Bank statement'>Stamped Bank statement</option>
                                <option value='Notarized rental contract'>Notarized rental contract</option>
                            </SelectInput>
                            <FormLabel>Bill Document</FormLabel>
                            <FormInput id='idFront'  onChange={handleUtility_image} type='file' accept="image/*, application/pdf" placeholder='Upload the Utility bill selected above'/>
                            <PrimaryBtn type='submit' style={{ borderRadius: '12px', textAlign: 'center', width: '100%'}}>Submit and Proceed</PrimaryBtn>
                            <PrimaryBtn onClick={() => {next()}}>Next</PrimaryBtn>
                            <PrimaryBtn onClick={() => {prev()}}>back</PrimaryBtn>
                        </Form2>
                        </div>
                            </div>
                            <div className="content">
                                <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><a className="close" onClick={close}>
                            <b style={{fontSize: '26px', marginLeft: '10px', alignItems: 'center'}}>&times; | </b>
                            </a> <b>Account Status</b> <b style={{float: 'right', marginRight: '10px' }}>Step {current} of {total}</b></div>
                            <Form3 style={{marginTop:'10%'}}>
                              <div style={{justifyContent:'center', display: 'grid'}}>
                                  <div style={{textAlign: 'center'}}>
                                    <img src={Documents} alt="Empty table" />
                                  </div>
                                  <div style={{textAlign: 'center', fontFamily:'poppins'}}>
                                    <h4 style={{marginBottom: '5px'}}>Your submission is under review</h4>
                                    <p style={{lineHeight:'24px',fontSize:'12px', fontWeight:'500', color:'#595959' }}>You will receive an email within 24hours once the review is complete.</p>
                                  </div>
                              </div>
                            </Form3>
                              
                                <PrimaryBtn onClick={() => {prev()}}>back</PrimaryBtn>
                        </div> */}
                        </Steps>
                        </div>
                        )}
                    </Popup>
                    </div>
                
                    {/*<div style={{display: 'block ruby'}}><ComplianceTopText>Registered Business Verification </ComplianceTopText> <ComplianceBtn  style={{ cursor: isButtonDisabled ? 'not-allowed' : 'pointer' }} title={isButtonDisabled ? 'Please fill the preceding form first.' : ''} 
                    onClick={isButtonDisabled ? () => {setAlertMessage("Complete Personal Account Verification first ");setShowAlert(true);} : () => {if (filledForm2) {setAlertMessage("Registered Business Verification submitted already!");setShowAlert(true);} else {setOpen1(true);setDim(true);}}}>Get Started</ComplianceBtn></div>
                    <ComplianceSubText>Provide required personal details here,<br></br> to enable you make withdrawals in crypto and fiat currencies</ComplianceSubText>
                    <ComplianceRectangle> <ComplianceText><img src={briefcase} /> &nbsp; Upload your business details </ComplianceText> </ComplianceRectangle>
                    <ComplianceRectangle> <ComplianceText><img src={docs} /> &nbsp; Upload your business documents</ComplianceText> </ComplianceRectangle>*/}
                    <div>
                    <Popup open={open1} onClose={() => { setOpen1(false); setDim(false)}} modal closeOnDocumentClick contentStyle={{width: '100%', height: '100%', backgroundColor: 'white', overflow: 'scroll'}} overlayStyle={{background: dim ? 'rgba(0, 0, 0, 0.5)' : 'none'}}>
                        {close => (
                            <Steps>
                                <div className="content">
                                    <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><a className="close" onClick={close}>
                                    <b style={{fontSize: '26px', marginLeft: '10px', alignItems: 'center', cursor: 'pointer'}}>&times; | </b>
                                    </a> <b>Business Details</b> <b style={{float: 'right', marginRight: '10px' }}>Step {current} of {total}</b></div>
                                    <Form2 onSubmit={ComplianceBusinessDetails}>
                                        <FormLabel>Legal Business Name</FormLabel>
                                        <FormInput id='legalName' value={legal_business_name} onChange={handleLegalName} placeholder='John Doe LLC'/>
                                        <FormLabel>Doing Business as (Brand Name)</FormLabel>
                                        <FormInput id='brandName' value={operating_biss_as} onChange={handleOperatingName} placeholder='Johndoe'/>
                                        <FormLabel>Country of Registration</FormLabel>
                                        <CountryDropdown value={registration_country} onChange={(val) => setRegistration_country(val)} style={{ padding: "16px 16px", marginBottom: "32px", border: "1px solid #979797", borderRadius: "6px", backgroundColor: "transparent"}} />
                                        <FormLabel>State</FormLabel>
                                        <RegionDropdown country={registration_country} value={state} onChange={(val) => setOperatingstate(val)} style={{ padding: "16px 16px", marginBottom: "32px", border: "1px solid #979797", borderRadius: "6px", backgroundColor: "transparent"}} />
                                        <FormLabel>Street Address</FormLabel>
                                        <FormInput id='street' value={street_address} onChange={handleStreetAdd} placeholder='47 Old Ikoyi Kilomt'/>
                                        <FormLabel>ZIP Code</FormLabel>
                                        <FormInput id='zipCode' placeholder='ZIP Code'/>
                                        <FormLabel>Business Usecase</FormLabel>
                                        <FormArea value={business_usecase} onChange={handleBussinessUsecase} placeholder='What your company does' />
                                        <FormLabel>Website Address (optional) </FormLabel>
                                        <FormInput id='street' value={website_address} onChange={handleWebAddress} type='url' pattern="https?://.+" title="Please enter a valid URL starting with http:// or https://" placeholder='Business URL'/>
                                        {errorMessage && <p style={{color:'red', marginTop:'-25px', marginBottom:'10px', fontSize:'12px'}}>{errorMessage}</p>}
                                        <PrimaryBtn type='Submit' style={{ borderRadius: '12px', textAlign: 'center', width: '100%'}}>Submit and Proceed</PrimaryBtn>
                                    </Form2>
                                </div>
                                <div className="content">
                                    <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><a className="close" onClick={close}>
                                    <b style={{fontSize: '26px', marginLeft: '10px', alignItems: 'center', cursor: 'pointer'}}>&times; | </b>
                                    </a> <b>Business Personnel Details</b> <b style={{float: 'right', marginRight: '10px' }}>Step {current} of {total}</b></div>
                                    <div>
                                      <Form3  style={{boxShadow:'none', maxWidth:'900px'}}>
                                          <h4 style={{marginTop:'-40px'}}>Business Directors Names and IDs</h4>
                                          {directors.map((director, index) => (
                                            <Form3
                                                key={index}
                                                style={{ maxWidth: '900px', padding: '10px 32px' }}
                                              >
                                                <div style={{ display: 'flex', marginTop: '10px' }}>
                                                  <div style={{ width: '50%' }}>
                                                    <SelectInput onChange={(event) => handleDirectorInputChange(event, index)} name='position' value={director.position} style={{ width: '50%', backgroundColor: '#FAF7FB' }}>
                                                      <option>select role</option>
                                                      <option value="CEO">CEO</option>
                                                      <option value="CFO">CFO</option>
                                                      <option value="CTO">CTO</option>
                                                      <option value="CMO">CMO</option>
                                                      <option value="Other C-Level">Other C-Level</option>
                                                    </SelectInput>
                                                  </div>
                                                  <div style={{ width: '50%' }}>
                                                    {directors.length > 1 && (
                                                      <div style={{ width: '50%', float:'right' }}>
                                                        <img src={Remove} style={{ cursor: 'pointer' }} onClick={() => handleRemoveDirector(index)} />
                                                      </div>
                                                    )}
                                                  </div>
                                                  <div style={{  float: 'right' }} onClick={() => toggleDirectorCollapse(director.id)}>
                                                    {director.collapsed ? <IoIosArrowDown /> : <IoIosArrowUp />}
                                                  </div>
                                                </div>
                                                  <div style={{display: director.collapsed ? 'none' : 'block'}}>
                                                    <div style={{ display: 'flex', marginTop: '10px' }}>
                                                      <div style={{ width: '50%' }}>
                                                        <FormLabel htmlFor={`name-${director.id}`}>Name</FormLabel>
                                                        <FormInput onChange={(event) => handleDirectorInputChange(event, index)} id={`name-${director.id}`} name='name' value={director.name} placeholder="John Doe" style={{ width: '97%' }} />
                                                      </div>
                                                      <div style={{ width: '50%' }}>
                                                        <FormLabel htmlFor={`email-${director.id}`}>Email</FormLabel>
                                                        <FormInput onChange={(event) => handleDirectorInputChange(event, index)} id={`email-${director.id}`} name='email' value={director.email} type="text" placeholder="johndoe@example.com" style={{ width: '97%' }} />
                                                      </div>
                                                    </div>
                                                    <div style={{ display: 'flex', marginTop: '10px' }}>
                                                      <div style={{ width: '50%' }}>
                                                        <FormLabel htmlFor={`country-${director.id}`}>Country</FormLabel>
                                                        <FormInput onChange={(event) => handleDirectorInputChange(event, index)} id={`country-${director.id}`} name='country' value={director.country} placeholder="Nigeria" style={{ width: '97%' }} />
                                                      </div>
                                                      <div style={{ width: '50%' }}>
                                                        <FormLabel htmlFor={`phone-${director.id}`}>Phone number</FormLabel>
                                                        <FormInput onChange={(event) => handleDirectorInputChange(event, index)} id={`phone-${director.id}`} name='phone' value={director.phone} type="text" placeholder="Phone Number" style={{ width: '97%' }} />
                                                      </div>
                                                    </div>
                                                    <div style={{ display: 'flex', marginTop: '10px' }}>
                                                      <div style={{ width: '100%' }}>
                                                        <FormLabel htmlFor={`address-${director.id}`}>Address</FormLabel>
                                                        <FormInput onChange={(event) => handleDirectorInputChange(event, index)} id={`address-${director.id}`} name='address' value={director.address} placeholder="14 Adelaide street" style={{ width: '97%' }} />
                                                      </div>
                                                    </div>
                                                    <div style={{ display: 'flex', marginTop: '10px' }}>
                                                      <div style={{ width: '50%' }}>
                                                        <FormLabel htmlFor={`idType-${director.id}`}>ID Type</FormLabel>
                                                        <SelectInput onChange={(event) => handleDirectorInputChange(event, index)} id={`idType-${director.id}`} name='idType' value={director.idType} placeholder="14 Adelaide street" style={{ width: '97%' }} >
                                                            <option>Select ID</option>
                                                            <option value='Passport'>Passport</option>
                                                            <option value='Natiional Id'>National ID</option>
                                                            <option value='Drivers License'>Driver's License</option>
                                                        </SelectInput>
                                                      </div>
                                                      <div style={{ width: '50%' }}>
                                                        <FormLabel htmlFor={`proof-id-${director.id}`}>Proof ID</FormLabel>
                                                        <FormInput
                                                          onChange={(event) => handleDirectorImageInputChange(event, index)}
                                                          id={`proofId-${director.id}`}
                                                          name='proofId'
                                                          type="file"
                                                          accept=".jpg, .jpeg, .png, .gif, .pdf"
                                                          placeholder="National ID, Drivers License, Passport, Etc"
                                                          style={{ width: '97%' }}
                                                        />
                                                        {director.isUploading && <div style={{marginTop:'-32px', marginLeft:'5px', fontSize:'12px', color:'purple'}}>Image Uploading...</div>}
                                                      </div>
                                                    </div>
                                                  </div>
                                              </Form3>
                                          ))}
                                          <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                            <div>
                                              <PrimaryBtn onClick={handleAddDirector} style={{background:'transparent', color:'#4D0B6C', border:'1px solid #4D0B6C', borderRadius:'12px', width:'294px', height:'64px'}}>
                                                <IoIosAddCircle /> Add more
                                              </PrimaryBtn>
                                            </div>
                                            <div>
                                              <PrimaryBtn onClick={() => {next()}} style={{ width:'294px', height:'64px'}}>Proceed</PrimaryBtn>
                                            </div>
                                          </div>
                                      </Form3>
                                    </div>
                                </div>
                                <div className="content">
                                    <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><a className="close" onClick={close}>
                                    <b style={{fontSize: '26px', marginLeft: '10px', alignItems: 'center', cursor: 'pointer'}}>&times; | </b>
                                    </a> <b>Business Personnel Details</b> <b style={{float: 'right', marginRight: '10px' }}>Step {current} of {total}</b></div>
                                    <Form3  style={{boxShadow:'none', maxWidth:'900px'}}>
                                        <h4 style={{marginTop:'-40px'}}>Business Shareholders Names and IDs</h4>
                                        {shareholders.map((shareholder, index) => (
                                          <Form3
                                              key={index}
                                              style={{ maxWidth: '900px', padding: '10px 32px' }}
                                            >
                                              <div style={{ display: 'flex', marginTop: '10px' }}>
                                                <div style={{ width: '50%' }}>
                                                  <SelectInput disabled id={`name-${shareholder.id}`} onChange={(event) => handleShareholderInputChange(event, index)} style={{ width: '50%', backgroundColor: '#FAF7FB' }}>
                                                    <option value="shareholder">Shareholder</option>
                                                  </SelectInput>
                                                </div>
                                                <div style={{ width: '50%' }}>
                                                  {shareholders.length > 1 && (
                                                    <div style={{ width: '50%', float:'right' }}>
                                                      <img src={Remove} style={{ cursor: 'pointer' }} onClick={() => handleRemoveShareholder(index)} />
                                                    </div>
                                                  )}
                                                </div>
                                                <div style={{  float: 'right' }} onClick={() => toggleShareholderCollapse(shareholder.id)}>
                                                  {shareholder.collapsed ? <IoIosArrowDown /> : <IoIosArrowUp />}
                                                </div>
                                              </div>
                                              <div style={{display: shareholder.collapsed ? 'none' : 'block'}}>
                                                  <div style={{ display: 'flex', marginTop: '10px' }}>
                                                    <div style={{ width: '50%' }}>
                                                      <FormLabel htmlFor={`name-${shareholder.id}`}>Name</FormLabel>
                                                      <FormInput onChange={(event) => handleShareholderInputChange(event, index)} id={`name-${shareholder.id}`} name='name' value={shareholder.name} placeholder="John Doe" style={{ width: '97%' }} />
                                                    </div>
                                                    <div style={{ width: '50%' }}>
                                                      <FormLabel htmlFor={`email-${shareholder.id}`}>Email</FormLabel>
                                                      <FormInput onChange={(event) => handleShareholderInputChange(event, index)} id={`email-${shareholder.id}`} type="text" name='email' value={shareholder.email} placeholder="johndoe@example.com" style={{ width: '97%' }} />
                                                    </div>
                                                  </div>
                                                  <div style={{ display: 'flex', marginTop: '10px' }}>
                                                    <div style={{ width: '100%' }}>
                                                      <FormLabel htmlFor={`address-${shareholder.id}`}>Shareholding %</FormLabel>
                                                      <FormInput onChange={(event) => handleShareholderInputChange(event, index)} id={`address-${shareholder.id}`} name='shareholdingPercentage' value={shareholder.shareholdingPercentage} placeholder="20" style={{ width: '97%' }} />
                                                    </div>
                                                  </div>
                                                  <div style={{ display: 'flex', marginTop: '10px' }}>
                                                  <div style={{ width: '50%' }}>
                                                        <FormLabel htmlFor={`idType-${shareholder.id}`}>ID Type</FormLabel>
                                                        <SelectInput onChange={(event) => handleShareholderInputChange(event, index)} id={`idType-${shareholder.id}`} name='idType' value={shareholder.idType} placeholder="14 Adelaide street" style={{ width: '97%' }} >
                                                            <option>Select ID</option>
                                                            <option value='Passport'>Passport</option>
                                                            <option value='Natiional Id'>National ID</option>
                                                            <option value='Drivers License'>Driver's License</option>
                                                        </SelectInput>
                                                      </div>
                                                    <div style={{ width: '50%' }}>
                                                      <FormLabel htmlFor={`proof-id-${shareholder.id}`}>ID</FormLabel>
                                                      <FormInput
                                                        onChange={(event) => handleShareholderImageInputChange(event, index)}
                                                        id={`proof-id-${shareholder.id}`}
                                                        type="file"
                                                        name="proofId"
                                                        accept=".jpg, .jpeg, .png, .gif, .pdf"
                                                        placeholder="National ID, Drivers License, Voter Card, Etc"
                                                        style={{ width: '97%' }}
                                                      />
                                                      {shareholder.isUploading && <div style={{marginTop:'-32px', marginLeft:'5px', fontSize:'12px', color:'purple'}}>Image Uploading...</div>}
                                                    </div>
                                                  </div>
                                                </div>
                                            </Form3>
                                        ))}
                                        <div style={{ display: 'flex', justifyContent: 'space-between'}}>
                                          <div>
                                            <PrimaryBtn onClick={handleAddShareholder} style={{background:'transparent', color:'#4D0B6C', border:'1px solid #4D0B6C', borderRadius:'12px', width:'294px', height:'64px'}}>
                                              <IoIosAddCircle /> Add more
                                            </PrimaryBtn>
                                          </div>
                                          <div>
                                            <PrimaryBtn onClick={ComplianceDirectorsandShareholders} style={{ width:'294px', height:'64px'}}>Proceed</PrimaryBtn>
                                          </div>
                                        </div>
                                    </Form3>
                                </div>
                                <div className="content">
                                    <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><a className="close" onClick={close}>
                                    <b style={{fontSize: '26px', marginLeft: '10px', alignItems: 'center', cursor: 'pointer'}}>&times; | </b>
                                    </a> <b>Upload Business Document</b> <b style={{float: 'right', marginRight: '10px' }}>Step {current} of {total}</b></div>
                                    <h4 style={{textAlign:'center', margin:'40px'}}>Upload Business Document</h4>
                                    <Form2 onSubmit={ComplianceBusinessDocuments}>
                                        <FormLabel>Certificate of incorporation <span style={{color:'red'}}>*</span></FormLabel>
                                        <FormInput  onChange={handleIncoporation} accept=".pdf" type='file' placeholder='Upload Certificate of incorporation, PDF'/>
                                        <FormLabel>Proof of Address <span style={{color:'red'}}>*</span></FormLabel>
                                        <FormInput  accept=".pdf" onChange={handleAddressproof} type='file' placeholder='Upload Recent Business Utility Bill'/>
                                        <FormLabel>Business License</FormLabel>
                                        <FormInput  accept=".pdf" type='file' onChange={handleBusinesslicense} placeholder='Upload Business License'/>
                                        <FormLabel>Tax Document (EIN / VAT) <span style={{color:'red'}}>*</span></FormLabel>
                                        <FormInput  accept=".pdf" onChange={handleTaxid} type='file' placeholder='Upload Tax ID'/>
                                        <FormLabel>AML Policy</FormLabel>
                                        <FormInput  type='file' accept=".pdf" onChange={handleAmlPolicy} placeholder='Upload AML Policy'/>
                                        <PrimaryBtn type='submit' style={{ borderRadius: '12px', textAlign: 'center', width: '100%'}}>Submit and Proceed</PrimaryBtn>
                                    </Form2>
                                </div>
                                <div className="content">
                                  <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><a className="close" onClick={close}>
                                      <b style={{fontSize: '26px', marginLeft: '10px', alignItems: 'center'}}>&times; | </b>
                                      </a> <b>Account Status</b> <b style={{float: 'right', marginRight: '10px' }}>Step {current} of {total}</b></div>
                                      <div>
                                      <Form3 style={{marginTop:'10%'}}>
                                        <div style={{justifyContent:'center', display: 'grid'}}>
                                            <div style={{textAlign: 'center'}}>
                                              <img src={Documents} alt="Empty table" />
                                            </div>
                                            <div style={{textAlign: 'center', fontFamily:'poppins'}}>
                                              <h4 style={{marginBottom: '5px'}}>Your submission is under review</h4>
                                              <p style={{lineHeight:'24px',fontSize:'12px', fontWeight:'500', color:'#595959' }}>You will receive an email within 24hours once the review is complete.</p>
                                              <PrimaryBtn onClick={() => {history(0)}}>Ok</PrimaryBtn>
                                            </div>
                                        </div>
                                      </Form3>
                                  </div>
                                </div>
                            </Steps>
                            
                        )}
                    </Popup>
                    </div>
                    {/*<div style={{display: 'block ruby'}}><ComplianceTopText>Agent Verification </ComplianceTopText> <ComplianceBtn style={{ cursor: isButtonDisabled1 ? 'not-allowed' : 'pointer' }} 
                                        onClick={isButtonDisabled ? () => {setAlertMessage("Complete Busineess Account Verification first ");setShowAlert(true);} : () => {if (filledForm3) {setAlertMessage("Agent Verification submitted already!");setShowAlert(true);} else {setOpen1(true);setDim(true);}}}>Get Started</ComplianceBtn></div>
                    <ComplianceSubText>Provide required personal details here,<br></br> to enable you make withdrawals in crypto and fiat currencies</ComplianceSubText>
                    <ComplianceRectangle> <ComplianceText><img src={briefcase} /> &nbsp; Source of Funds</ComplianceText> </ComplianceRectangle>
                    <ComplianceRectangle> <ComplianceText><img src={link} /> &nbsp; Link Bank Account</ComplianceText> </ComplianceRectangle> */}
                    <div>
                    <Popup open={open2} onClose={() => { setOpen2(false); setDim(false)}} modal closeOnDocumentClick contentStyle={{width: '100%', height: '100%', backgroundColor: 'white', overflow: 'scroll'}} overlayStyle={{background: dim ? 'rgba(0, 0, 0, 0.5)' : 'none'}}>
                        {close => (
                            <Steps>
                                <div className="content">
                                    <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><a className="close" onClick={close}>
                                    <b style={{fontSize: '26px', marginLeft: '10px', alignItems: 'center', cursor: 'pointer'}}>&times; | </b>
                                    </a> <b>Agent Verification</b> <b style={{float: 'right', marginRight: '10px' }}>Step {current} of {total}</b></div>
                                    <Form2 onSubmit={ComplianceAgent} >
                                        <FormLabel>Expected Transaction Volume (In USD)</FormLabel>
                                        <SelectInput value={expected_transaction_volume} onchange={handleExpected} >
                                          <option value='$0 - $9k'>$0 - $9k</option>
                                          <option value='$10k - $99k'>$10k - $99k</option>
                                          <option value='$100k - $1M'>$100k - $1M</option>
                                          <option value='$1M+'>$1M+</option>
                                        </SelectInput>
                                        <FormLabel>Payment Method</FormLabel>
                                        <SelectInput id='paymentMethod' value={paymentMethod} onChange={handlePaymentMethod}>
                                          <option>Select Payment Method</option>
                                          {userPayment.data.length ? userPayment.data.map((method, index) => (
                                          <option key={index} value={method.id}>
                                              {method.method_name}
                                          </option>) ) : <option>No payment method</option> }
                                        </SelectInput>
                                        <FormLabel>Major source of income</FormLabel>
                                        <SelectInput value={major_income_sources} onchange={handleMajor} >
                                            <option value='Salary'>Salary</option>
                                            <option value='Dividends'>Dividends</option>
                                            <option value='Businessy'>Business</option>
                                            <option value='Inheritance'>Inheritance</option>
                                            <option value='Savings'>Savings</option>
                                            <option value='Donations'>Donations</option>
                                            <option value='Others'>Others</option>
                                        </SelectInput>
                                        <FormLabel>Payment Channel</FormLabel>
                                        <SelectInput value={payment_channel} onchange={handlePaymentChannel} >
                                          <option value='Own Accounts'>Own Accounts</option>
                                          <option value='Corporate Account'>Corporate Account</option>
                                          <option value='Third Party Account'>Third Party Account</option>
                                        </SelectInput>
                                        <FormLabel>External Marketplace/Exchange Detail</FormLabel>
                                        <ComplianceSubText>If you have an account as an agent in any other marketplace or exchange do input the name of the exchange and your username in the spaces provided</ComplianceSubText>
                                        <div style={{display:'flex', marginTop: '10px'}}>
                                            <div style={{width: '50%'}}>
                                            <FormLabel for='limit' style={{fontSize: '10px'}}>Marketplace/Exchange</FormLabel>
                                                <SelectInput id='cexName' onchange={handleMarket_place_1} style={{width: '97%', marginRight: '5px'}} value={market_place_1} >
                                                  <option value='Binance P2P'>Binance P2P</option>
                                                  <option value='Remitano'>Remitano</option>
                                                  <option value='Quidax'>Quidax</option>
                                                  <option value='Airtm'>Airtm</option>
                                                </SelectInput>
                                            </div>
                                            <div style={{width: '50%'}}>
                                            <FormLabel for='limit' style={{fontSize: '10px'}}> Username</FormLabel>
                                                <FormInput id='cexUsername' value={username_1} onchange={handleUsername_1} placeholder='Your Username' style={{width: '97%'}} />
                                            </div>
                                        </div>
                                        <div style={{display:'flex', marginTop: '10px'}}>
                                            <div style={{width: '50%'}}>
                                            <FormLabel for='limit' style={{fontSize: '10px'}}>Marketplace/Exchange</FormLabel>
                                                <SelectInput id='cexName' value={market_place_2} onchange={handleMarket_place_2} style={{width: '97%', marginRight: '5px'}}>
                                                  <option value='Remitano'>Remitano</option>
                                                  <option value='Quidax'>Quidax</option>
                                                  <option value='Binance P2P'>Binance P2P</option>
                                                  <option value='Airtm'>Airtm</option>
                                                </SelectInput>
                                            </div>
                                            <div style={{width: '50%'}}>
                                            <FormLabel for='limit' style={{fontSize: '10px'}}>Username</FormLabel>
                                                <FormInput id='cexUsername' value={username_2} onchange={handleUsername_2} placeholder='Your Username' style={{width: '97%'}} />
                                            </div>
                                        </div>
                                        <div style={{display:'flex', marginTop: '10px'}}>
                                            <div style={{width: '50%'}}>
                                            <FormLabel for='limit' style={{fontSize: '10px'}}>Marketplace/Exchange</FormLabel>
                                                <SelectInput id='cexName' value={market_place_3} onchange={handleMarket_place_3} style={{width: '97%', marginRight: '5px'}}>
                                                  <option value='Quidax'>Quidax</option>
                                                  <option value='Remitano'>Remitano</option>
                                                  <option value='Binance P2P'>Binance P2P</option>
                                                  <option value='Airtm'>Airtm</option>
                                                </SelectInput>
                                            </div>
                                            <div style={{width: '50%'}}>
                                            <FormLabel for='limit' style={{fontSize: '10px'}}> Username</FormLabel>
                                                <FormInput id='cexUsername' value={username_3} onchange={handleUsername_3} placeholder='Your Username' style={{width: '97%'}} />
                                            </div>
                                        </div>
                                        <FormLabel style={{fontSize: '10px'}}>Bank/Crypto Exchange  Statement</FormLabel>
                                        <FormInput type='file' id='bankStatement' placeholder='Upload a recent bank/digital asset exchange account statement' onChange={handleExchangeStatement} />
                                        <div style={{display:'flex', marginTop: '10px', marginBottom: '10px'}}><FormInput type="checkbox" id='tc' required style={{width: '30px', height:"30px", marginRight:"10px"}}/><div style={{color:'#000', fontFamily:'Poppins', fontSize:"14px"}}>I affirm that the information provided is true</div></div><br></br>
                                        <PrimaryBtn type='submit' style={{ borderRadius: '12px', textAlign: 'center', width: '100%'}}>Submit and Proceed</PrimaryBtn>
                                    </Form2>
                                </div>
                                <div className="content">
                                  <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><a className="close" onClick={close}>
                                      <b style={{fontSize: '26px', marginLeft: '10px', alignItems: 'center'}}>&times; | </b>
                                      </a> <b>Account Status</b> <b style={{float: 'right', marginRight: '10px' }}>Step {current} of {total}</b></div>
                                      <div>
                                          <Form3 style={{marginTop:'10%'}}>
                                            <div style={{justifyContent:'center', display: 'grid'}}>
                                                <div style={{textAlign: 'center'}}>
                                                  <img src={Documents} alt="Empty table" />
                                                </div>
                                                <div style={{textAlign: 'center', fontFamily:'poppins'}}>
                                                  <h4 style={{marginBottom: '5px'}}>Your submission is under review</h4>
                                                  <p style={{lineHeight:'24px',fontSize:'12px', fontWeight:'500', color:'#595959' }}>You will receive an email within 24hours once the review is complete.</p>
                                                </div>
                                            </div>
                                          </Form3>
                                      </div>
                                </div>
                            </Steps>
                        )}
                    </Popup>
                    </div>
                    </CenterContent>
            </FormWrap>
        </Container>
        </MainContent>
        </MainContainer>
    </>) : <Loader/>)
  ) 
}

export default Compliance