import React, {useState, useEffect} from 'react'
import { Container, Form, FormContent, FormH1, FormWrap, Icon, Icon2, Text, Text1,OtpLabel, PrimaryBtn } from '../components/Form/formElements'
import SideBar from '../components/Sidebar'
import  {useNavigate}  from 'react-router-dom'
import ZeenahPay from '../assets/ZeenahPay.png'
import axios from 'axios'
import AuthCode from 'react-auth-code-input';
import goBack from '../assets/goback.png'
import { useLocation } from 'react-router-dom';
import AlertModal from './modal'
import BASE_URL from '../apiConfig'


const VerifyEmail = () => {
    const [result, setResult] = useState();
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const handleOnChange = (res) => {
    setResult(res);
    }
  const location = useLocation();
  const [email] = useState(location.state.email);
    const navigate = useNavigate();
    const [shouldResend, setShouldResend] = useState(true);
  
    const handleApi = (event) => {
      event.preventDefault();
      axios.post(`${BASE_URL}/verify-email`, {
        email: email,
        code: result
      }).then(async (result) => {
        setAlertMessage(result.data.data.message);
        setShowAlert(true);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        navigate("/login")
      })
        .catch(error => {
          const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
          setAlertMessage(jsonString);
          setShowAlert(true);
        })
    }
    useEffect(() => {
        if (shouldResend) {
      axios.post(`${BASE_URL}/resend-verify-email`,
            {
                email : email
            }).then(response => {
            setAlertMessage('Verification sent!');
          setShowAlert(true);
          handleResetClick()
        })
    }
    setShouldResend(false);
  }, [shouldResend, email]);

  function handleResendClick() {
    setShouldResend(true);
  }

    const [timeLeft, setTimeLeft] = useState(1800);

  useEffect(() => {
    let intervalId;
    if (timeLeft > 0) {
      intervalId = setInterval(() => setTimeLeft(timeLeft - 1), 1000);
    } else {
      clearInterval(intervalId);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [timeLeft]);
  
  const handleResetClick = () => {
    setTimeLeft(1800);
  };

  const handleAlertClose = () => {
    setShowAlert(false);
  };

  return (
    <>
    {showAlert && (
        <AlertModal
          isModalOpen={showAlert}
          alertMessage={alertMessage}
          onClose={handleAlertClose}
        />
      )}
        <SideBar />
        <Container>
            <FormWrap>
                <FormContent>
                    <Icon src={ZeenahPay}  width='160px'/>
                    <Form onSubmit={handleApi}>
                        <FormH1>Verify Email</FormH1>
                        <Text>A 6-digit confirmation code was sent to your Email <Text style={{color: '#791FA4'}}>{email}</Text>, please enter below</Text>
                        <Text style={{color: '#791FA4', fontSize:'10px'}}>* if you do not see the mail in your inbox, check your spam folder</Text>
                        <div style={{marginBottom: '20px'}}>
                            <AuthCode allowedCharacters='numeric' onChange={handleOnChange} containerClassName={'otpContainer'} inputClassName={'otpInput'} isPassword={true} onBlur={() => handleApi}/>          
                        </div>
                        <OtpLabel>O.T.P will expire in <a style={{color: '#791FA4'}}>{Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, '0')}</a> <span style={{color:'#7BC875', cursor: 'pointer', marginLeft: '5px'}} onClick={handleResendClick}>Resend O.T.P</span></OtpLabel>
                        <PrimaryBtn>submit</PrimaryBtn>
                    </Form>
                    <Icon2 style={{display: 'flex', marginTop: '-10px'}}><Icon src={goBack} /><Text1 href='/signup' style={{textDecoration:'none', color:'#4D0B6C', marginTop: '20px', marginLeft: '-10px'}}>Return to Signup</Text1></Icon2>
                </FormContent>
            </FormWrap>
        </Container>
    </>
  )
}

export default VerifyEmail