import React from 'react'
import { Carousel } from 'react-responsive-carousel'
import { Side, Img, Text1, Text2 } from './SidebarElements'
import Multiple1 from '../../assets/multiple1.svg'
import Seamless2 from '../../assets/seamless2.svg'
import Custom3 from '../../assets/custom3.svg'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader


const SideBar = () => {
  return (
    <>
        <Side>
            <Carousel showArrows={false} autoPlay={true} showStatus={false} infiniteLoop={true} swipeable={true} stopOnHover={true}>
                <div>
                    <Img src={Multiple1} style={{width: '300px'}} />
                   <p style={{ fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "900", fontSize: '20px', lineHeight: '29px',textAlign: 'center', marginBottom: '5px', color:'#4D0B6C', fontFamily:'inter'}}>Multiple payment methods</p>
                   <p style={{ fontFamily: 'Inter',fontStyle: 'normal', fontWeight: 400,fontSize: '12px', lineHeight: '19px', textAlign: 'center', marginBottom: '30px'}}>Send and receive payments using your preferred payment method.</p>
                </div>
                <div>
                    <Img src={Seamless2} style={{width: '300px'}} />
                    <p style={{ fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "900", fontSize: '20px', lineHeight: '29px',textAlign: 'center', marginBottom: '5px', color:'#4D0B6C', fontFamily:'inter'}}>Seamless Peer to  Peer</p>
                   <p style={{ fontFamily: 'Inter',fontStyle: 'normal', fontWeight: 400,fontSize: '12px', lineHeight: '19px', textAlign: 'center', marginBottom: '30px'}}>Spend less to get the currency you want with our P2P service</p>
                </div>
                <div>
                    <Img src={Custom3} style={{width: '300px'}} />
                    <p style={{ fontFamily: 'Inter', fontStyle: 'normal', fontWeight: "900", fontSize: '20px', lineHeight: '29px',textAlign: 'center', marginBottom: '5px', color:'#4D0B6C', fontFamily:'inter'}}>Customized Payment Links</p>
                   <p style={{ fontFamily: 'Inter',fontStyle: 'normal', fontWeight: 400,fontSize: '12px', lineHeight: '19px', textAlign: 'center', marginBottom: '30px'}}>Facilitate simpler and secured transactions for your businesses.</p>
                </div>
            </Carousel>
        </Side>
    </>
  )
}

export default SideBar