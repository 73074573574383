import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import Settings from './settings';
import Bizimg from '../assets/bizimg.png'
import DashboardIcon from '../assets/DashboardIcon.svg'
import { BizTitle, BusinessImg, CompanyInfo, SubMenuText, SubMenuTitle, SubMenu, Menu as Men1 } from '../components/Dashboard/sidebar/dashboardSidebarElements';
import {RxDashboard} from 'react-icons/rx'
import {AiOutlineSetting,AiOutlineWallet,AiOutlineInteraction,AiOutlineCloseCircle,AiOutlineLineChart} from 'react-icons/ai'
import {MdProductionQuantityLimits} from 'react-icons/md'
import {HiLink, HiOutlineUserGroup} from 'react-icons/hi'
import {RiExchangeCnyLine} from 'react-icons/ri'
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import {FiLogOut} from 'react-icons/fi'
import {FcAdvertising,FcProcess} from 'react-icons/fc'
import {FaExchangeAlt} from 'react-icons/fa'
import {GoReport} from 'react-icons/go'
import {BsPersonGear} from 'react-icons/bs'
import {IoBusiness} from 'react-icons/io5'
import {GrCompliance} from 'react-icons/gr'
import {MdOutlinePayments} from 'react-icons/md'
import AuthContext from '../context/AuthProvider'
import axios from 'axios'
import { NavLink } from 'react-router-dom';
import Navbar from '../components/Navbar';
import { useLocation } from 'react-router-dom'
import { FaAngleUp, FaAngleDown } from 'react-icons/fa';
import BASE_URL from '../apiConfig'
import AlertModal from './modal';
import GoogleTranslateWidget from '../components/googleTranslate';

const WriteUpText = styled.p`
  text-align: left;
  color: #fff;
`;

const WriteUpContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
`;

const NavbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #252831;
  color: white;
  height: 60px;
  padding: 0 20px;
  position: sticky;
  top: 0;
  z-index: 1;
`;

const NavbarBrand = styled.img`
  height: 30px;
`;

const SidebarContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #fff;
  width: ${(props) => (props.isOpen ? '300px' : '0px')};
  transition: all 0.3s ease-in-out;
  position: sticky;
  top: 60px;
  height: calc(100vh - 60px);
  overflow-y: auto;
  box-shadow: 10px 0 10px -5px rgba(179, 179, 179, 0.16);
  @media screen and (max-width: 960px){
        display: none;
       
    }
`;
const SubMen = styled.ul`
  color: ${({ isActive5, isActive1, isActive2, isActive3, isActive4, isActive0 }) =>
    isActive5 || isActive1 || isActive2 || isActive3 || isActive4 || isActive0
      ? '#fff'
      : '#4D0B6c'};
  background: ${({ isActive5, isActive1, isActive2, isActive3, isActive4, isActive0 }) =>
    isActive5 || isActive1 || isActive2 || isActive3 || isActive4 || isActive0
      ? '#4D0B6c'
      : '#fff'};
  border-radius: 8px;
  margin: 10px;
`;

export const MainContainer = styled.div`
  display: flex;
  flex: 1;
  margin-left: ${(props) => (props.isOpen ? '0px' : '0px')};
`;

export const MainContent = styled.div`
  flex: 1;
  padding-top: 20px;
`;

const SidebarToggleButton = styled.button`

    display: block;
    position: absolute;
    top: 10px;
    left: 10px;
`;
const Menu = styled.ul`
  list-style: none;
  align-items: center;
  margin-left: 1rem;
`
const DropdownMenu = styled.ul`
background: #FCF3FF;
margin-bottom: 5px;
border-radius: 8px;
margin: -20px;
margin-bottom: 5px;
margin-top: 0;
color: '#4D0B6c';
  list-style: none;
  li {
    padding: 8px 16px;
    display: flex;
    align-items: center;
    color: '#4D0B6c';
    &:hover {
      background-color: #f2f2f2;
    }
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const ArrowIcon = styled.span`
  margin-left: 30px;
`;

const MenuItem = styled.li`
  display: flex;
  align-items: center;
&:hover {
    background-color: #4D0B6C !important;
    color: white !important;
    border-radius: 8px !important;
    font-weight: bold !important;
  }
`

const Sidebar1 = ({ isOpen, toggleSidebar }) => {
    const { setAuth } = useContext(AuthContext);
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const token = localStorage.getItem('bearerToken');
    const location = useLocation();
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [isActive5, setIsActive5] = useState(false);
    const [isActive1, setIsActive1] = useState(false);
    const [isActive2, setIsActive2] = useState(false);
    const [isActive3, setIsActive3] = useState(false);
    const [isActive4, setIsActive4] = useState(false);
    const [isActive0, setIsActive0] = useState(false);

    const [isDropDownOpen, setIsDropDownOpen] = useState(false);
    const [isDropDownOpen1, setIsDropDownOpen1] = useState(false);

    const toggleDropdown = () => setIsDropDownOpen(!isDropDownOpen);
    const toggleDropdown1 = () => setIsDropDownOpen1(!isDropDownOpen1);
    const sidebarRef = useRef(null);
    const [transactions, setTransactions] = useState(null);

     useEffect(() => {
      if (location.pathname === '/p2p/dispute') {
        setIsActive5(true);
        setIsDropDownOpen(true)
        const sidebarElement = sidebarRef.current;
        const sidebarHeight = sidebarElement.offsetHeight;
        const middlePosition = sidebarHeight / 2;
        sidebarElement.scrollTop = middlePosition;
      } else {
        setIsActive5(false);
      }
    }, [location]);
     useEffect(() => {
      if (location.pathname === '/p2p/closed') {
        setIsActive1(true);
        setIsDropDownOpen(true)
        const sidebarElement = sidebarRef.current;
        const sidebarHeight = sidebarElement.offsetHeight;
        const middlePosition = sidebarHeight / 2;
        sidebarElement.scrollTop = middlePosition;
      } else {
        setIsActive1(false);
      }
    }, [location]);
     useEffect(() => {
      if (location.pathname === '/p2p/transactions') {
        setIsActive2(true);
        setIsDropDownOpen(true)
        const sidebarElement = sidebarRef.current;
        const sidebarHeight = sidebarElement.offsetHeight;
        const middlePosition = sidebarHeight / 2;
        sidebarElement.scrollTop = middlePosition;
      } else {
        setIsActive2(false);
      }
    }, [location]);
     useEffect(() => {
      if (location.pathname === '/p2p/success') {
        setIsActive3(true);
        setIsDropDownOpen(true)
        const sidebarElement = sidebarRef.current;
        const sidebarHeight = sidebarElement.offsetHeight;
        const middlePosition = sidebarHeight / 2;
        sidebarElement.scrollTop = middlePosition;
      } else {
        setIsActive3(false);
      }
    }, [location]);
     useEffect(() => {
      if (location.pathname === '/p2p/failed') {
        setIsActive4(true);
        setIsDropDownOpen(true)
        const sidebarElement = sidebarRef.current;
        const sidebarHeight = sidebarElement.offsetHeight;
        const middlePosition = sidebarHeight / 2;
        sidebarElement.scrollTop = middlePosition;
      } else {
        setIsActive4(false);
      }
    }, [location]);
     useEffect(() => {
      if (location.pathname === '/p2p') {
        setIsActive0(true);
        setIsDropDownOpen(true)
        const sidebarElement = sidebarRef.current;
        const sidebarHeight = sidebarElement.offsetHeight;
        const middlePosition = sidebarHeight / 2;
        sidebarElement.scrollTop = middlePosition;
      } else {
        setIsActive0(false);
      }
    }, [location]);
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        return response.data;
      } catch (error) {
        console.error(error);
      }
    };
  
    useEffect(() => {
      const getUser = async () => {
        const data = await fetchUser();
        setUser(data);
      };
  
      getUser();
    }, []);
  
    const logout = async () => {
        // if used in more components, this should be in context 
        // axios to /logout endpoint 
        setAuth({});
        localStorage.removeItem('email');
        localStorage.removeItem('bearerToken');
        localStorage.removeItem('password');
        navigate('/login');
    }
    const fetchTransactions = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/trade/history/buy-sell-history?status=pending`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        return response.data;
      } catch (error) {
        console.error(error);
      }
    };
    useEffect(() => {
      const getTransactions = async () => {
        const data = await fetchTransactions();
        setTransactions(data);
      };
    
      const interval = setInterval(getTransactions, 300000);
    
      // Cleanup function to clear the interval when the component unmounts
      return () => clearInterval(interval);
    }, []);
    const handleAlertClose = () => {
      setShowAlert(false);
    };
  
    const handleBetaLinkClick = () => {
      setAlertMessage(<>
        <h5>Coming Soon</h5>
        <p style={{fontSize:'12px'}}>This feature is under development and will be available soon.</p>
      </>);
      setShowAlert(true);
    };
  return (
    <>
    <SidebarContainer style={{overflowY: 'auto'}} isOpen={isOpen} ref={sidebarRef}>
      <CompanyInfo>
                <BusinessImg>
                    <img src={Bizimg} style={{marginLeft:'16px'}} alt='business logo'/>
                </BusinessImg>{user && (
                <BizTitle style={{width: 'auto', marginBottom: '40px'}}>
                <WriteUpContainer style={{width: 'auto', marginBottom: '40px'}}>
                    <WriteUpText>{user.data.businessName}</WriteUpText>
                </WriteUpContainer>
                </BizTitle>)} 
                {/*<WalletAddress>0xB837917.....F3B70</WalletAddress>
                <PolygonUp />
                <PolygonDown />*/}
            </CompanyInfo>
                <Men1>
                <Menu
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              if (level === 0) {
                return {
                  "&:hover": {
                     backgroundColor: "#4D0B6C !important",
                     color: "white !important",
                     borderRadius: "8px !important",
                     fontWeight: "bold !important"
                   },
                };
              }
            },
          }}>
                    <SubMenu>
                        <SubMenuTitle>HOME</SubMenuTitle>
                        <NavLink to='/dashboard' style={({ isActive }) => isActive ? { color: '#fff', background: '#4D0B6C',  textDecoration: 'none', borderRadius: '8px',  fontWeight: 'bold' }: { color: '#4D0B6c', background: 'none', textDecoration: 'none'}}>
                        <MenuItem style={{padding: '20px'}}><RxDashboard/> <SubMenuText> Dashboard</SubMenuText></MenuItem>
                        </NavLink>
                    </SubMenu>
                    <SubMenu>
                        <SubMenuTitle>Payments</SubMenuTitle>
                        <DropdownContainer>
                        <div onClick={toggleDropdown} style={{color: isActive5 || isActive1 || isActive2 || isActive3 || isActive4 || isActive0 ? '#fff' : '#4D0B6c', background: isActive5 || isActive1 || isActive2 || isActive3 || isActive4 || isActive0 ? '#4D0B6c' : '#fff', borderRadius: '8px', marginBottom:'5px', cursor:'pointer'}}>
                          <MenuItem style={{padding: '20px'}}><AiOutlineInteraction/> <SubMenuText> P2P {transactions?.data.data.length > 0 && (
                                  <sup style={{ fontSize: '14px', marginLeft: '2px', fontWeight:'600', color:'white', backgroundColor:'#f009', padding:'5px', borderRadius:'20%' }}>{transactions?.data.data.length}</sup>)}</SubMenuText> <ArrowIcon>{isDropDownOpen ? <FaAngleUp /> : <FaAngleDown />}</ArrowIcon></MenuItem>
                        </div>
                          {isDropDownOpen && (
                            <DropdownMenu>
                              <li style={{ color: '#4D0B6c', background:isActive0 ? '#07f3ff24' : 'none', textDecoration: 'none', padding: '10px' }}>
                              <NavLink to='/p2p' style={{textDecoration: 'none'}}>
                                  <MenuItem><FcAdvertising style={{color: 'green'}}/>  <SubMenuText style={{fontSize: '13px', marginLeft: '5px'}}> Active Ads <SubMenuText style={{display: isActive0 ? 'contents' : 'none', marginLeft: '5px', color: '#167e16' }}>•</SubMenuText></SubMenuText></MenuItem>
                              </NavLink>
                              </li>
                              <li style={{ color: '#4D0B6c', background:isActive1 ? '#07f3ff24' : 'none', textDecoration: 'none', padding: '10px' }}>
                              <NavLink to='/p2p/closed' style={{textDecoration: 'none'}}>
                                  <MenuItem><AiOutlineCloseCircle style={{color: 'red'}}/>  <SubMenuText style={{fontSize: '13px', marginLeft: '5px'}}> Closed Ads <SubMenuText style={{display: isActive1? 'contents' : 'none', marginLeft: '5px', color: '#167e16' }}>•</SubMenuText></SubMenuText></MenuItem>
                              </NavLink>
                              </li>
                              <li style={{ color: '#4D0B6c', background:isActive2 ? '#07f3ff24' : 'none', textDecoration: 'none', padding: '10px' }}>
                              <NavLink to='/p2p/transactions' style={{textDecoration: 'none'}}>
                                  <MenuItem><FcProcess color='blue' /> <SubMenuText style={{fontSize: '13px', marginLeft: '5px'}}> Ongoing Transactions {transactions?.data.data.length > 0 && (
                                  <sup style={{ fontSize: '14px', marginLeft: '2px', fontWeight:'600', color:'white', backgroundColor:'#f009', padding:'5px', borderRadius:'20%' }}>{transactions?.data.data.length}</sup>)}<SubMenuText style={{display: isActive2 ? 'contents' : 'none', marginLeft: '5px', color: '#167e16' }}>•</SubMenuText></SubMenuText></MenuItem>
                              </NavLink>
                              </li>
                              <li style={{ color: '#4D0B6c', background:isActive3 ? '#07f3ff24' : 'none', textDecoration: 'none', padding: '10px' }}>
                              <NavLink to='/p2p/success' style={{textDecoration: 'none'}}>
                                  <MenuItem><FaExchangeAlt style={{color: 'green'}} />  <SubMenuText style={{fontSize: '12px', marginLeft: '5px'}}> Successful Transactions <SubMenuText style={{display: isActive3 ? 'contents' : 'none', marginLeft: '5px', color: '#167e16' }}>•</SubMenuText></SubMenuText></MenuItem>
                              </NavLink>
                              </li>
                              <li style={{ color: '#4D0B6c', background:isActive4 ? '#07f3ff24' : 'none', textDecoration: 'none', padding: '10px' }}>
                              <NavLink to='/p2p/failed' style={{textDecoration: 'none'}}>
                                  <MenuItem><FaExchangeAlt color='red'/>  <SubMenuText style={{fontSize: '13px', marginLeft: '5px'}}> Failed Transactions <SubMenuText style={{display: isActive4 ? 'contents' : 'none', marginLeft: '5px', color: '#167e16' }}>•</SubMenuText></SubMenuText></MenuItem>
                              </NavLink>
                              </li>
                              <li style={{ color: '#4D0B6c', background:isActive5 ? '#07f3ff24' : 'none', textDecoration: 'none', padding: '10px' }}>
                              <NavLink to='/p2p/dispute' style={{textDecoration: 'none'}}>
                                  <MenuItem><GoReport color='#ea9208'/>  <SubMenuText style={{fontSize: '13px', marginLeft: '5px'}}> Dispute <SubMenuText style={{display: isActive5 ? 'contents' : 'none', marginLeft: '5px', color: '#167e16' }}>•</SubMenuText></SubMenuText></MenuItem>
                              </NavLink>
                              </li>
                            </DropdownMenu>
                          )}
                        </DropdownContainer>
                        {/*<NavLink to='/topup' style={({ isActive }) => isActive ? { color: '#fff', background: '#4D0B6C',  textDecoration: 'none', borderRadius: '8px',  fontWeight: 'bold' }: { color: '#4D0B6c', background: 'none', textDecoration: 'none' }}>
                            <MenuItem style={{padding: '20px'}}><AiOutlineWallet/> <SubMenuText> Deposit</SubMenuText></MenuItem>
                        </NavLink>
                        
                        <NavLink to='/payout' style={({ isActive }) => isActive ? { color: '#fff', background: '#4D0B6C',  textDecoration: 'none', borderRadius: '8px',  fontWeight: 'bold' }: { color: '#4D0B6c', background: 'none', textDecoration: 'none' }}>
                            <MenuItem style={{padding: '20px'}}><AiOutlineWallet/> <SubMenuText> Send Money</SubMenuText></MenuItem>
                        </NavLink>*/}
                        <NavLink to='/swap' style={({ isActive }) => isActive ? { color: '#fff', background: '#4D0B6C',  textDecoration: 'none', borderRadius: '8px',  fontWeight: 'bold' }: { color: '#4D0B6c', background: 'none', textDecoration: 'none' }}>
                            <MenuItem style={{padding: '20px'}}><RiExchangeCnyLine/> <SubMenuText> Swap</SubMenuText></MenuItem>
                        </NavLink>
                        <NavLink to='/transactions' style={({ isActive }) => isActive ? { color: '#fff', background: '#4D0B6C',  textDecoration: 'none', borderRadius: '8px',  fontWeight: 'bold' }: { color: '#4D0B6c', background: 'none', textDecoration: 'none' }}>
                            <MenuItem style={{padding: '20px'}}><AiOutlineLineChart/> <SubMenuText>Transactions</SubMenuText></MenuItem>
                        </NavLink>
                        {/*<NavLink to='/customers' style={({ isActive }) => isActive ? { color: '#fff', background: '#4D0B6C',  textDecoration: 'none', borderRadius: '8px',  fontWeight: 'bold' }: { color: '#4D0B6c', background: 'none', textDecoration: 'none' }}>*/}
                            <MenuItem onClick={handleBetaLinkClick}  style={{padding: '20px',color: '#4D0B6C' }} ><HiOutlineUserGroup/> <SubMenuText>Beneficiaries <sup style={{ fontSize: '10px', borderRadius: '16px', textAlign: 'center', color: '#4D0B6C'}}>BETA</sup></SubMenuText></MenuItem>
                        {/*</NavLink>*/}
                    </SubMenu>
                    <SubMenu>
                        <SubMenuTitle>Cards</SubMenuTitle>
                        {/*<NavLink to='/products' style={({ isActive }) => isActive ? { color: '#fff', background: '#4D0B6C',  textDecoration: 'none', borderRadius: '8px',  fontWeight: 'bold' }: { color: '#4D0B6c', background: 'none', textDecoration: 'none' }}>*/}
                            <MenuItem onClick={handleBetaLinkClick}  style={{padding: '20px',color: '#4D0B6C' }}><MdProductionQuantityLimits/> <SubMenuText> Gift Cards <sup style={{ fontSize: '10px', borderRadius: '16px', textAlign: 'center', color: '#4D0B6C'}}>BETA</sup></SubMenuText></MenuItem>
                        {/*</NavLink>*/}
                        {/*<NavLink to='/paymentlink-history' style={({ isActive }) => isActive ? { color: '#fff', background: '#4D0B6C',  textDecoration: 'none', borderRadius: '8px',  fontWeight: 'bold' }: { color: '#4D0B6c', background: 'none', textDecoration: 'none' }}>*/}
                            <MenuItem onClick={handleBetaLinkClick}  style={{padding: '20px',color: '#4D0B6C' }}><HiLink/> <SubMenuText> Virtual Card <sup style={{ fontSize: '10px', borderRadius: '16px', textAlign: 'center', color: '#4D0B6C'}}>BETA</sup></SubMenuText></MenuItem>
                        {/*</NavLink>*/}
                        {/*<NavLink to='/create-paymentlink' style={({ isActive }) => isActive ? { color: '#4D0B6C', background: 'none',  textDecoration: 'none' }: { color: '#6C6363', background: 'none', textDecoration: 'none' }}>
                            <MenuItem icon={<TfiViewListAlt></TfiViewListAlt>}> <SubMenuText> Payment Button</SubMenuText></MenuItem>
                        </NavLink> */}
                    </SubMenu>
                    {/*<SubMenu>
                      <SubMenuTitle>Language</SubMenuTitle>
                        <GoogleTranslateWidget />
                    </SubMenu> */}
                    <SubMenu>
                        <SubMenuTitle>Account</SubMenuTitle>
                        <DropdownContainer>
                        <div onClick={toggleDropdown1} style={{color: isActive5 || isActive1 || isActive2 || isActive3 || isActive4 || isActive0 ? '#fff' : '#4D0B6c', background: isActive5 || isActive1 || isActive2 || isActive3 || isActive4 || isActive0 ? '#4D0B6c' : '#fff', borderRadius: '8px', marginBottom:'5px', cursor:'pointer'}}>
                          <MenuItem style={{padding: '20px'}}><AiOutlineSetting /> <SubMenuText> Settings</SubMenuText> <ArrowIcon>{isDropDownOpen1 ? <FaAngleUp /> : <FaAngleDown />}</ArrowIcon></MenuItem>
                        </div>
                          {isDropDownOpen1 && (
                            <DropdownMenu>
                              <li style={{ color: '#4D0B6c', background:isActive0 ? '#07f3ff24' : 'none', textDecoration: 'none', padding: '10px' }}>
                              <NavLink to='/settings/personal-details' style={{textDecoration: 'none'}}>
                                  <MenuItem><BsPersonGear /><SubMenuText style={{fontSize: '13px', marginLeft: '5px'}}> Personal Details <SubMenuText style={{display: isActive0 ? 'contents' : 'none', marginLeft: '5px', color: '#167e16' }}>•</SubMenuText></SubMenuText></MenuItem>
                              </NavLink>
                              </li>
                              <li style={{ color: '#4D0B6c', background:isActive1 ? '#07f3ff24' : 'none', textDecoration: 'none', padding: '10px' }}>
                              <NavLink to='/settings/business-details' style={{textDecoration: 'none'}}>
                                  <MenuItem><IoBusiness /><SubMenuText style={{fontSize: '13px', marginLeft: '5px'}}> Business Details <SubMenuText style={{display: isActive1? 'contents' : 'none', marginLeft: '5px', color: '#167e16' }}>•</SubMenuText></SubMenuText></MenuItem>
                              </NavLink>
                              </li>
                              <li style={{ color: '#4D0B6c', background:isActive2 ? '#07f3ff24' : 'none', textDecoration: 'none', padding: '10px' }}>
                              <NavLink to='/settings/compliance' style={{textDecoration: 'none'}}>
                                  <MenuItem><GrCompliance/><SubMenuText style={{fontSize: '13px', marginLeft: '5px'}}> Compliance <SubMenuText style={{display: isActive2 ? 'contents' : 'none', marginLeft: '5px', color: '#167e16' }}>•</SubMenuText></SubMenuText></MenuItem>
                              </NavLink>
                              </li>
                              <li style={{ color: '#4D0B6c', background:isActive3 ? '#07f3ff24' : 'none', textDecoration: 'none', padding: '10px' }}>
                              <NavLink to='/settings/paymentmethod' style={{textDecoration: 'none'}}>
                                  <MenuItem><MdOutlinePayments/><SubMenuText style={{fontSize: '12px', marginLeft: '5px'}}> Beneficiaries <SubMenuText style={{display: isActive3 ? 'contents' : 'none', marginLeft: '5px', color: '#167e16' }}>•</SubMenuText></SubMenuText></MenuItem>
                              </NavLink>
                              </li>
                            </DropdownMenu>
                          )}
                        </DropdownContainer>
                        <MenuItem style={{color: 'red', padding: '20px'}} onClick={logout}><FiLogOut/><SubMenuText> Logout</SubMenuText></MenuItem>
                    </SubMenu>
                    </Menu>        
                </Men1>
    </SidebarContainer>
    {showAlert && (
      <AlertModal
        isModalOpen={showAlert}
        alertMessage={alertMessage}
        onClose={handleAlertClose}
      />
    )} 
    </>
  );
};
export default Sidebar1

const Xyz = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
    <Navbar toggleSidebar={toggleSidebar} />
    <MainContainer isOpen={isOpen}>
      <Sidebar1 isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <MainContent>
        <Settings />
      </MainContent>
    </MainContainer>
  </>
  );
}
