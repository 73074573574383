import React, {useState} from 'react'
import Table from '../components/Dashboard/content/table'
import Navbar from '../components/Navbar'
import Sidebar1, { MainContainer, MainContent } from './nav'

const Transactions = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
        <Navbar toggleSidebar={toggleSidebar}/>
        <MainContainer isOpen={isOpen}>
            <Sidebar1 isOpen={isOpen} toggleSidebar={toggleSidebar} />
            <MainContent>
        <Table/>
        </MainContent>
        </MainContainer>
    </>
  )
}

export default Transactions