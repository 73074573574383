import React, { useState, useEffect } from 'react';
import { PDFDownloadLink,PDFRenderer,PDFViewer, Document, Page, View, Text, Image, StyleSheet, Font} from '@react-pdf/renderer';
import zeenahlogo from '../assets/zeenahLogo.png'
import copyright from '../assets/copyright.png'
import icon from '../assets/icon_16.png'
import holidayFont from '../assets/fonts/Holiday.ttf'


Font.register({ family: 'Holiday', src: holidayFont });

const styles = StyleSheet.create({
  container: {
    color: '#500772',
    padding: 30,
    fontFamily: 'Helvetica',
    maxWidth: '100vw',
    maxHeight: '100vh',
  },
  logo: {
    width:'50px',
    cursor: 'pointer',
    marginLeft: 'auto',
  },
  header: {
    display: 'flex',
    flexDirection: 'row', // Arrange items horizontally
    alignItems: 'center',
  },
  hText: {
    borderBottomWidth: 5,
    borderBottomColor: '#500772',
    borderRightWidth: 1,
    borderRightColor: '#500772',
    fontWeight: '900',
    fontSize: 22,
    width: '60%',
  },
  h1: {
    marginTop: 20,
  },
  senderName: {
    textTransform: 'uppercase',
    fontSize: 14,
    fontWeight: 'bold',
    padding: 2,
    marginTop: 5,
    display: 'block',
  },
  amount: {
    fontWeight: 'bold',
    fontSize: 14,
    padding: 2,
    display: 'block',
  },
  div: {
    display: 'flex',
    alignItems: 'left',
    float: 'left',
    textAlign: 'left',
    marginBottom: 20,
  },
  status: {
    fontSize: 12,
    display: 'block',
    padding: 2,
  },
  date: {
    fontSize: 12,
    display: 'block',
    padding: 2,
  },
  trxnTable: {
    width: '100%',
    borderSpacing: 20,
    borderTop: '2px dotted #500772',
    borderBottom: '2px dotted #500772',
    marginBottom: 20,
  },
  tableRow: {
    flexDirection: 'row',
    borderBottomColor: '#000',
    borderBottomWidth: 1,
  },
  th: {
    textAlign: 'left',
    fontSize:'16px'
  },
  td: {
    textAlign: 'right',
    fontSize:'16px'
  },
  tableHeader: {
    color: 'black',
    fontSize: 14,
    textAlign: 'left',
    marginLeft: 10,
    padding: 8,
    fontWeight: '900',
  },
  hr: {
    borderTop: '2px dotted #500772',
  },
  reference: {
    maxWidth: '100%',
    marginTop: 20,
  },
  referenceHeader: {
    fontSize: 16,
    textAlign: 'left',
    padding: 10,
    marginLeft: -8,
  },
  th2: {
    fontSize: 16,
  },
  td2: {
    fontSize: 16,
    paddingRight: 10,
  },
  icon: {
    width: '50%',
    cursor: 'pointer',
    paddingRight: 10,
  },
  fText: {
    paddingRight: '30%',
    float: 'left',
    fontWeight: 'bold',
    fontSize: 20,
    width: 700,
    fontFamily:'Holiday'
  },
  footer: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'row', // Arrange items horizontally
    alignItems: 'center',
  },
  f1: {
    marginLeft: '-40vw',
    marginTop: 20,
    fontFamily: 'Holiday',
  },
  inlineRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '10px'
  },
  inlineRow2: {
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
  },
  column: {
    flex: 1,
    flexDirection: 'column',
    marginLeft: 25,
  },
});
const userId = localStorage.getItem('userId');
const PdfDocument = ({ selectedData }) => (
  <Document>
      <Page size="A4">
        <View style={styles.container}>
          <View style={styles.header}>
            <Text style={styles.hText}>RECEIPT</Text>
            <Image style={styles.logo} src={zeenahlogo} />
          </View>
          <View style={styles.div}>
            <Text style={styles.senderName}>{ userId === selectedData.agent_id ? selectedData?.agent.businessName : selectedData?.user.businessName}</Text>
            <Text style={styles.amount}>Amount: {selectedData.trade_currency} {Number(selectedData.trade_amount)}</Text>
            <Text style={styles.status}>
              Status: <Text style={{ color: 'green' }}>Success</Text>
            </Text>
            <Text style={styles.date}>
              Date: {selectedData?.updated_at
                ? new Date(selectedData.updated_at).toLocaleString('en-GB', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                  })
                : new Date().toLocaleString('en-GB', {
                    day: 'numeric',
                    month: 'short',
                    year: 'numeric',
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                  })}
            </Text>
          </View>
          <View>
            <View style={styles.hr} />
            <Text style={styles.tableHeader}>P2P Transaction Details</Text>
          </View>
          <View style={styles.trxnTable}>
            <View style={styles.inlineRow}>
              <Text style={styles.th}>Payment Method</Text>
              <Text style={styles.td}>{selectedData?.payment_method?.payment_info.method_name}</Text>
            </View>
            <View style={styles.inlineRow}>
              <Text style={styles.th}>Agent's Name</Text>
              <Text style={styles.td}>{selectedData?.agent.firstName} {selectedData?.agent.lastName}</Text>
            </View>
            <View style={{ ...styles.inlineRow, marginBottom:'10px'}}>
              <Text style={styles.th}>Customer's Name</Text>
              <Text style={styles.td}>{selectedData?.user.firstName} {selectedData?.user.lastName}</Text>
            </View>
          </View>
          <View>
            <View style={styles.inlineRow}>
              <Text style={styles.th}>Reference</Text>
              <Text style={styles.td}>{selectedData.transaction_id}</Text>
            </View>
          </View>
          <View style={{ marginTop: '4vh' }}>
            <Text style={styles.referenceHeader}>Remark: ZeenahPay™</Text>
            <View>
            <View style={styles.footer}>
              <View>
                <Text style={styles.th2}>Account No</Text>
                <Text style={styles.td2}>
                                    {selectedData?.payment_method?.payment_info?.bank_name ? (
                                      `${selectedData?.payment_method?.payment_info.account_number}`
                                    ) : (
                                      `${Object.values(selectedData?.payment_method?.payment_info)[2]}`
                                    )}
                </Text>
              </View>
              <View>
                <Text style={styles.th2}>Bank Name</Text>
                  <Text style={styles.td2}>{selectedData?.payment_method?.payment_info.bank_name}
                    {selectedData?.payment_method?.payment_info?.bank_name ? (
                                      `${selectedData?.payment_method?.payment_info.bank_name}`
                                    ) : (
                                      `${Object.values(selectedData?.payment_method?.payment_info)[0]}`
                                    )}
                  </Text>
              </View>
            </View>
            </View>
          </View>
          <View style={styles.footer}>
            <Text style={styles.fText}>Congrats</Text>
            <Image style={styles.icon} src={icon} />
          </View>
          <Text style={{ color: '#848484', marginTop: '5vh', fontSize: 12, textAlign: 'center' }}>
            <Image src={copyright} style={{width:'15px'}} /> {new Date().getFullYear()} ZeenahPay. All Rights Reserved.
          </Text>
        </View>
      </Page>
    </Document>
);


export default PdfDocument;
