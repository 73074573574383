import styled from 'styled-components';

export const Side = styled.div`
    background: #E6DDE8;
    width: 542px;
    height: 101vh;
    display: absolute;
    float: left;
    margin-left: -10px;
    margin-top: -10px;
    position: fixed;
`
export const CardContainer = styled.div`
    position: absolute;
    width: 395px;
    height: 501px;
    left: 70px;
    top: 209px;
`
export const Card = styled.div`
    position: absolute;
    width: 391px;
    height: 263px;
    left: 70px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
`
export const Img = styled.img`
    padding-top: 10px;
`
export const Text1 = styled.span`
    position: absolute;
    width: 453px;
    height: 32px;
    left: 54px;
    top: 500px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 29px;
    text-align: center;
    margin-left: 55px;
    color: #000000;
`
export const Text2 = styled.span`
    position: absolute;
    width: 408px;
    height: 42px;
    left: 70px;
    top: 530px;
    margin-left: 55px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #000000;
`
export const Rect = styled.div`
    box-sizing: border-box;
    width: 74px;
    height: 10px;
    background: #4D0B6C;
    border-radius: 12px;
`
export const RectContainer = styled.div`
    left: 215px;
    top: 612px;
    position: absolute;
    display: flex;
`
export const RectGrey = styled.div`
    box-sizing: border-box;
    width: 74px;
    height: 10px;
    background: #979797;
    border-radius: 12px;
`
export const DashSide = styled.div`
    background: #fff;
    width: 300px;
    height: 101vh;
    display: absolute;
    float: left;
    margin-top: -50px;
    position: relative;
    z-index: 1;

    @media screen and (max-width: 960px){
        display: none;
    }
`
export const CompanyInfo = styled.div`
    position: absolute;
    width: 208px;
    height: 100px;
    left: 59px;
    top: 57px;

    background: #4D0B6C;
    border-radius: 12px;
`
export const BusinessImg = styled.div`
    position: absolute;
    width: 51px;
    height: 51px;
    left: 10px;
    top: 25px;
    background: #D9D9D9;
    border-radius: 50%;
    align-items: center;
    display: grid;
`
export const BizTitle = styled.div`
    position: absolute;
    width: 100%;
    height: 33px;
    left: 70px;
    top: 30px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 33px;
    color: #6C6363;
`
export const WalletAddress = styled.div`
    position: absolute;
    width: 151px;
    height: 24px;
    left: 65px;
    top: 50px;

    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #979797;
`
export const PolygonDown = styled.div`
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 15px solid #6C6363;
    font-size: 0;
    line-height: 0;
    left: 252px;
    top: 45px;
    position: absolute;
`
export const PolygonUp = styled.div`
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid #6C6363;
    font-size: 0;
    line-height: 0;
    left: 252px;
    top: 25px;
    position: absolute;
`
export const SubMenuTitle = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 27px;
    color: #000;
    text-transform: uppercase; 
`
export const Menu = styled.div`
    height: auto;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    position: absolute;
    top: 160px;
    left: 20px;
    text-align: left;
`
export const SubMenuItem = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 27px;
    color: #4D0B6C;
    display: ruby;
    margin-top: 5px;
`
export const SubMenuText = styled.div`
    margin-left: 20px;
    font-weight: 400;
`
export const SubMenu = styled.div`
    margin-bottom: 30px;
    display: grid;
    color: #4D0B6C;
`