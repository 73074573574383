import styled from "styled-components";

export const Container = styled.div`
    height: auto;
    position: fixed;
    bottom: 0;
    right: 0;
    top: 0;
    overflow: scroll;
    left: 370px;

    @media screen and (max-width: 960px) {
        left: 0;
    }
`

export const FormWrap = styled.div`
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
`

export const Icon = styled.img`
    padding: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
`
export const Icon2 = styled.div`
    padding: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    color: #4D0B6C;
`

export const FormContent = styled.div`
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 480px) {
        padding: 10px;
    }
`
export const Form = styled.form`
    background: #fff;
    max-width: 500px;
    height: auto;
    width: 100%;
    z-index: 1;
    display: grid;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 80px 32px;
    border-radius: 6px;
    box-shadow: 0px 4px 50px 8px rgba(0, 0, 0, 0.06);
`
export const Form2 = styled.form`
    background: #fff;
    max-width: 500px;
    height: auto;
    width: 100%;
    z-index: 1;
    display: grid;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 80px 32px;
    border-radius: 6px;
    box-shadow: 0px 4px 50px 8px rgba(0, 0, 0, 0.06);
`
export const BusinessForm2 = styled.form`
    background: #fff;
    max-width: 500px;
    height: auto;
    width: 100%;
    z-index: 1;
    display: grid;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 80px 32px;
    border-radius: 6px;
    box-shadow: 0px 4px 50px 8px rgba(0, 0, 0, 0.06);
    @media screen and (max-width: 926px) {
        background: transparent;
        max-width: 500px;
        height: auto;
        width: 100%;
        z-index: 1;
        display: grid;
        margin: 0 auto;
        margin-bottom: 20px;
        margin-top: -30px;
        padding: 80px 32px;
        border-radius: 6px;
    }
`
export const Form3 = styled.div`
    background: #fff;
    max-width: 500px;
    height: auto;
    width: 100%;
    z-index: 1;
    display: grid;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 80px 32px;
    border-radius: 6px;
    box-shadow: 0px 4px 50px 8px rgba(0, 0, 0, 0.06);
    `
export const Form3A = styled.div`
    display: none;
    @media screen and (max-width: 680px) {
        background: #fff;
        max-width: 500px;
        height: auto;
        width: 100%;
        z-index: 1;
        display: grid;
        margin: 0 auto;
        margin-top: 20px;
        padding: 80px 32px;
        border-radius: 6px;
        box-shadow: 0px 4px 50px 8px rgba(0, 0, 0, 0.06);
    }
    `
export const MobileOnly = styled.div`
    display: none;
    @media screen and (max-width: 926px) {
        display: block;
    }
    `
export const Form3B = styled.div`
    background: #fff;
    max-width: 500px;
    height: auto;
    width: 100%;
    z-index: 1;
    display: grid;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 80px 32px;
    border-radius: 6px;
    box-shadow: 0px 4px 50px 8px rgba(0, 0, 0, 0.06);
    @media screen and (max-width: 680px) {
        display: none;
    }
    `

export const FormW = styled.div`
    background: #fff;
    height: auto;
    width: 100%;
    z-index: 1;
    display: grid;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 32px 32px;
    border-radius: 6px;
    grid-template-columns: 1fr 1fr;
    box-shadow: 0px 4px 50px 8px rgba(0, 0, 0, 0.06);
    @media screen and (max-width: 680px) {
        display: none;
    }
`
export const Span = styled.span`
    float: left; 
    font-weight: 500; 
    font-size: 24px; 
    line-height: 36px; 
    margin-top: 20px; 
    @media screen and (max-width: 680px) {
        display: none;
    }
`
export const WalletMobile = styled.div`
    display: none;
@media screen and (max-width: 680px) {
    display: grid;
    grid-template-columns: 1fr;
    height: auto;
    align-items: center;
    border: 0px solid #979797;
    padding: 32px 32px;
    border-radius: 10px;
    height: auto;
    box-shadow: 0px 4px 50px 8px rgba(0, 0, 0, 0.06);
}
`
export const Form4 = styled.div`
    background: #fff;
    width: 100%;
    z-index: 1000;
    display: grid;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 0px 0px;
    border-radius: 6px;
    border: 1px solid #979797;
`
export const FormH1 = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 60px;
    color: #000000;
    text-align: center;
`
export const FormH2 = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 60px;
    color: #000000;
    text-align: center;
`
export const FormLabel = styled.label`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 15px;
    color: #1E1E1E;
    margin-left: 10px;
`
export const OtpLabel = styled.label`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 24px;
    margin-bottom: 15px;
    color: #6C6363;
    text-align: center;
`
export const FormInput = styled.input`
    padding: 16px 16px;
    margin-bottom: 32px;
    border: 1px solid #BCBCBC;
    border-radius: 12px;
    background-color: transparent;
`
export const FormArea = styled.textarea`
    padding: 16px 16px;
    margin-bottom: 32px;
    border: 1px solid #979797;
    border-radius: 6px;
    background-color: transparent;
    

`
export const PrimaryBtn = styled.button`
    background: #4D0B6C;
    border-radius: 60px;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 16px 0;
    font-size: 20px;
`
export const PrimaryBtn2 = styled.button`
    background: #4D0B6C;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 16px 0;
    font-size: 20px;
    @media screen and (max-width: 680px) {
        font-size: 16px
    }
`

export const Text = styled.span`
    text-align: center;
    margin: 10px;
    color: #000;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
`
export const Text2 = styled.span`
    text-align: center;
    margin: 10px;
    color: #000;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
`
export const Text1 = styled.a`
    text-align: center;
    margin: 10px;
    color: #000;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
`