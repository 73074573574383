import styled from "styled-components";

export const ComplianceForm = styled.div`
    background: #fff;
    max-width: 800px;
    height: auto;
    width: 90%;
    z-index: 1;
    display: grid;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 80px 32px;
    border-radius: 6px;
    box-shadow: 0px 4px 50px 8px rgba(0, 0, 0, 0.06);
`
export const ComplianceRectangle = styled.div`
    padding: 16px 16px;
    margin-bottom: 32px;
    border: 1px solid #979797;
    border-radius: 6px;
    background-color: transparent;
`
export const ComplianceSquare = styled.div`
    padding: 10px 10px;
    margin-bottom: 32px;
    background-color: transparent;
`
export const ComplianceTopText = styled.div`
    font-size: 18px;
    font-weight: 500;
    line-height: 33px;
    color: #575555;

    @media screen and (max-width: 768px) {
        font-size: 12px;
        font-weight: 700
    }
`
export const ComplianceText = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 30px;
    color: #575555;
    display: flex;
    align-items: center;
`
export const ComplianceSubText = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 21px;
    color: #575555;
    margin-bottom: 15px;

    @media screen and (max-width: 768px) {
        font-size: 10px;
    }
`
export const ComplianceBtn = styled.button`
    background: #4D0B6C;
    border-radius: 12px;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 10px 0;
    font-size: 12px;
    margin-bottom: 10px;
    margin-right: 5px;
    width: 182px;
    height: 48px;
    float: right;

    @media screen and (max-width: 768px) {
        width: 100px;
        height: auto;
    }
`
    export const Modal = styled.div`
        position: fixed;
        z-index: 1;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.25);
` 
    
  export const Close = styled.div`
        color: white;
        float: right;
        cursor: pointer;
        &:hover{
            color: red;
        }
  ` 