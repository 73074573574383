import React , { useState} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BsPersonBadge,BsClipboardCheck } from 'react-icons/bs';
import { IoIosBusiness } from 'react-icons/io';
import { MdOutlinePayments } from 'react-icons/md';
import Navbar from '../components/Navbar';
import Sidebar1, { MainContainer, MainContent } from './nav';

const Settings = () => {
  const [isOpen, setIsOpen] = useState(true);
  const navigate = useNavigate();
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
    <Navbar toggleSidebar={toggleSidebar}/>
    <MainContainer isOpen={isOpen}>
        <Sidebar1 isOpen={isOpen} toggleSidebar={toggleSidebar} />
        <MainContent>
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '70vh', color:'#4D0B6C' }}>
      <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: '1rem', textAlign: 'center' }}>
        <div style={{margin: '20px', borderRadius: '12px', padding: '10px', boxShadow: '0px 4px 50px 8px rgba(149, 52, 193, 0.06)', cursor:'pointer'}} onClick={() => navigate('/settings/personal-details')}>
            <BsPersonBadge style={{ fontSize: '6rem' }} />
            <div style={{ marginTop: '0.5rem' }}>Personal Details</div>
        </div>
        <div style={{margin: '20px', borderRadius: '12px', padding: '10px', boxShadow: '0px 4px 50px 8px rgba(149, 52, 193, 0.06)', cursor:'pointer'}} onClick={() => navigate('/settings/business-details')}>
            <IoIosBusiness style={{ fontSize: '6rem' }} />
            <div style={{ marginTop: '0.5rem' }}>Business Details</div>
        </div>
        <div style={{margin: '20px', borderRadius: '12px', padding: '10px', boxShadow: '0px 4px 50px 8px rgba(149, 52, 193, 0.06)', cursor:'pointer'}} onClick={() => navigate('/settings/compliance')}>
            <BsClipboardCheck style={{ fontSize: '6rem'}} />
            <div style={{ marginTop: '0.5rem' }}>Compliance</div>
        </div>
        <div style={{margin: '20px', borderRadius: '12px', padding: '10px', boxShadow: '0px 4px 50px 8px rgba(149, 52, 193, 0.06)', cursor:'pointer'}} onClick={() => navigate('/settings/paymentmethod')}>
            <MdOutlinePayments style={{ fontSize: '6rem' }} />
            <div style={{ marginTop: '0.5rem' }}>Payment Method</div>
        </div>
      </div>
    </div>
    </MainContent>
        </MainContainer>
        </>
  );
};

export default Settings;
