import React,{useState, useRef, useMemo, useEffect} from 'react'
import { Container, PrimaryBtn, SelectInput } from '../components/Dashboard/content/dashElements'
import DashboardSidebar from '../components/Dashboard/sidebar'
import Navbar from '../components/Navbar'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { Form2, Form3, FormArea, FormH2, FormInput, FormLabel } from '../components/Form/formElements'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ReactPasswordToggleIcon from 'react-password-toggle-icon';
import {FaEye, FaEyeSlash} from 'react-icons/fa'
import { ComplianceBtn, ComplianceForm, ComplianceRectangle, ComplianceSquare, ComplianceSubText, ComplianceText, ComplianceTopText } from '../components/Compliance/complianceElements'
import contactbook from '../assets/contactbook.png'
import Identity from '../assets/Identity.png'
import address from '../assets/address.png'
import briefcase from '../assets/briefcase.png'
import docs from '../assets/docs.png'
import link from '../assets/link.png'
import Select from 'react-select'
import Popup from 'reactjs-popup';
import { Steps, StepsProvider, useSteps } from "react-step-builder";
import success from '../assets/complete.png'
import agentsuccess from '../assets/agentSuccess.png'
import { CountryDropdown, RegionDropdown, CountryRegionData } from 'react-country-region-selector';
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Loader from '../components/loader'
import MyPaymentMethod from './myPaymentMethod';
import AlertModal from './modal'
import LoadingLine from './loadinglines'
import Sidebar1, { MainContainer, MainContent } from './nav'
import BASE_URL from '../apiConfig'

const Personal = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
    let history = useNavigate();
    const { next, prev, total, current } = useSteps();
    let Next = useSteps()
    const [b_details, setBDetails] = useState({});
    const token = localStorage.getItem('bearerToken');
    const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
    const fetchBDetails = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/settings`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
    
          return response.data;
        } catch (error) {
          console.error(error);
        }
      };
      useEffect(() => {
        const getBDetails = async () => {
          const data = await fetchBDetails();
          setBDetails(data);
          setBusinessName(data.data.businessName)
          setBusinessType(data.data.businessType)
          setBusinessPhone(data.data.businessPhone)
          setPhone(data.data.businessPhone)
          setBusinessEmail(data.data.businessEmail)
          setBusinessDescription(data.data.businessDescription)
        };
    
        getBDetails();
      }, []);

      const [country, setCountry] = useState('');
    const [region, setRegion] = useState('');
    const [country1, setCountry1] = useState('');
    const [region1, setRegion1] = useState('');
    const [errorPassword, setErrorPassword] = useState('');

    const [businessName, setBusinessName] = useState('')
    const [businessType, setBusinessType] = useState('')
    const [businessPhone, setBusinessPhone] = useState('')
    const [businessEmail, setBusinessEmail] = useState('')    
    const [businessDescription, setBusinessDescription] = useState('')    
    const businessCountry = country1 
    const businessState = region1
    
    const [phone, setPhone] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState("");
    const [image, setImage] = useState('')
    const handleImage = (e) => { 
      setImage(e.target.files[0])}
    const handlePassword = (e) => { setPassword(e.target.value)}
    const handlePhone = (value) => { setPhone(value)}


    const handleBusinessName = (e) => { setBusinessName(e.target.value)}
    const handleBusinessType = (e) => { setBusinessType(e.target.value)}
    const handleBusinessPhone = (value) => { setBusinessPhone(value)}
    const handleBusinessEmail = (e) => { setBusinessEmail(e.target.value)}
    const handleBusinessDescription = (e) => { setBusinessDescription(e.target.value)}

    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [dim, setDim] = useState(false);

    const options = 'abc'
    {/*useMemo(() => countryList().getData(), []) */}
    const showIcon = () => <FaEye aria-hidden="true" />;
    const hideIcon = () => <FaEyeSlash aria-hidden="true" />
    let inputRef = useRef();
    let inputRef1 = useRef();
    const [selected, setSelected] = useState("");
    const onSelect = (code) => setSelected(code);

    const [active, setActive] = React.useState(1)

    const UpdateBusinessDetails = (event) => {
        event.preventDefault();
        axios.post(`${BASE_URL}/settings/update`, {
            businessName: businessName, 
            businessType: businessType, 
            businessPhone: businessPhone, 
            businessEmail: businessEmail, 
            businessDescription: businessDescription, 
            businessCountry: businessCountry,
            businessState: businessState,
        }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }).then(async (result) => {
          setAlertMessage('Business profile successfully updated');
          setShowAlert(true);
          await new Promise((resolve) => setTimeout(resolve, 3000));
          history("/settings")
        })
          .catch(error => {
            const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
            setAlertMessage(jsonString);
            setShowAlert(true);
          })
        }

    const UpdateUserDetails = (event) => {
        event.preventDefault();
        axios.post(`${BASE_URL}/update-profile`, {
            phone: phone, 
            password: password, 
            image: image
        }, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data"
            },
          }).then(async (result) => {
          setAlertMessage("User's profile successfully updated");
          setShowAlert(true);
          await new Promise((resolve) => setTimeout(resolve, 3000));
          history("/settings")
        })
          .catch(error => {
            const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
            setAlertMessage(jsonString);
            setShowAlert(true);
          })
        }
        const [currency, setCurrency] = useState(null);
        const [payInfo, setPayInfo] = useState(null);
        const [payment_currency, setPayment_currency] = useState('')
        const handlePayment_currency = (e) => { setPayment_currency(e.target.value); handleSelectCurrency(e.target.value)}

        const fetchCurrency = async () => {
            try {
              const response = await axios.get(`${BASE_URL}/payment-method/sys-currencies`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
        
              return response.data;
            } catch (error) {
              console.error(error);
              const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
              setAlertMessage(jsonString);
            }
          };
          useEffect(() => {
            const getCurrency = async () => {
              const data = await fetchCurrency();
              setCurrency(data);
            };
        
            getCurrency();
          }, []);
          const handleSelectCurrency = async (id) => {
            try {
              const response = await axios.get(`${BASE_URL}/payment-method/sys-currencies?currency=${id}`,{
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
              return response.data;
              // Handle successful deletion, e.g. remove the trade from the table data and update the UI
            } catch (error) {
              console.error(error);
              const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
              setAlertMessage(jsonString);
              // Handle error, e.g. display an error message to the user
            }
          };
          useEffect(() => {
            const getPayInfo = async () => {
              const data = await handleSelectCurrency();
              setPayInfo(data);
            };
            getPayInfo();
          }, []);

          const [nationality, setNationality] = useState('');
          const [idType, setIdType] = useState('');
          const [id_front, setIdfront] = useState('');
          const [id_back, setIdback] = useState('');
          const [selfie_image, setSelfieImage] = useState('');


          const handleIdType = (e) => { setIdType(e.target.value)}
          const handleIdfront = (e) => { setIdfront(e.target.files[0])}
          const handleIdback= (e) => { setIdback(e.target.files[0])}
          const handleSelfieImage = (e) => { setSelfieImage(e.target.files[0])}

          const ComplianceIdentity = (event) => {
            event.preventDefault();
            axios.post(`${BASE_URL}/compliance/identity`, {
              nationality : nationality,
              idType: idType,
              id_front: id_front,
              id_back: id_back,
              selfie_image: selfie_image
            }, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data"
                },
              }).then(async (result) => {
              setAlertMessage('successfully submited');
              setShowAlert(true);
              await new Promise((resolve) => setTimeout(resolve, 2000));
              next();
            })
              .catch(error => {
                const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
                setAlertMessage(jsonString);
                setShowAlert(true);
              })
            }

            const [zipcode, setZipcode] = useState('');
            const [utilityType, setUtilityType] = useState('');
            const [utility_image, setUtility_image] = useState('');


            const handleZipcode = (e) => { setZipcode(e.target.value)}
            const handleUtilityType = (e) => { setUtilityType(e.target.value)}
            const handleUtility_image = (e) => { setUtility_image(e.target.files[0])}

          const ComplianceAddress = (event) => {
            event.preventDefault();
            axios.post(`${BASE_URL}/compliance/address`, {
              zipcode : zipcode,
              utilityType: utilityType,
              utility_image: utility_image
            }, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data"
                },
              }).then(async (result) => {
              setAlertMessage('successfully submited');
              setShowAlert(true);
              await new Promise((resolve) => setTimeout(resolve, 3000));
              next();
            })
              .catch(error => {
                const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
                setAlertMessage(jsonString);
                setShowAlert(true); 
              })
            }

          const [director_1, setDirector1] = useState('') 
          const [d_image_1, setDimage1] = useState('')
          const [director_2, setDirector2] = useState('') 
          const [d_image_2, setDimage2] = useState('')
          const [director_3, setDirector3] = useState('') 
          const [d_image_3, setDimage3] = useState('')

          const handleDirector1 = (e) => { setDirector1(e.target.value)}
          const handleDirector2 = (e) => { setDirector2(e.target.value)}
          const handleDirector3 = (e) => { setDirector3(e.target.value)}
          const handleDimage1 = (e) => { setDimage1(e.target.files[0])}
          const handleDimage2 = (e) => { setDimage2(e.target.files[0])}
          const handleDimage3 = (e) => { setDimage3(e.target.files[0])}

          const [share_holder_1, setShareholder1] = useState('')
          const [d_share_holder_1, setDshareholder1] = useState('')
          const [share_holder_2, setShareholder2] = useState('')
          const [d_share_holder_2, setDshareholder2] = useState('')
          const [share_holder_3, setShareholder3] = useState('')
          const [d_share_holder_3, setDshareholder3] = useState('')

          const handleShareholder1 = (e) => { setShareholder1(e.target.value)}
          const handleDshareholder1 = (e) => { setDshareholder1(e.target.files[0])}
          const handleShareholder2 = (e) => { setShareholder2(e.target.value)}
          const handleDshareholder2 = (e) => { setDshareholder2(e.target.files[0])}
          const handleShareholder3 = (e) => { setShareholder3(e.target.value)}
          const handleDshareholder3 = (e) => { setDshareholder3(e.target.files[0])}

          const ComplianceDirectors = (event) => {
            event.preventDefault();
            axios.post(`${BASE_URL}/compliance/directors`, {
              director_1 : director_1,
              d_image_1: d_image_1,
              director_2 : director_2,
              d_image_2: d_image_2,
              director_3 : director_3,
              d_image_3: d_image_3,
              share_holder_1: share_holder_1,
              d_share_holder_1: d_share_holder_1,
              share_holder_2: share_holder_2,
              d_share_holder_2: d_share_holder_2,
              share_holder_3 : share_holder_3,
              d_share_holder_3: d_share_holder_3
              
            }, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data"
                },
              }).then(async (result) => {
              setAlertMessage('successfully submited');
              setShowAlert(true);
              await new Promise((resolve) => setTimeout(resolve, 2000));
              next();
            })
              .catch(error => {
                const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
                setAlertMessage(jsonString);
                setShowAlert(true);
              })
            }

            const validatePassword = () => {
              if (password !== confirmPassword) {
                setErrorPassword("Passwords do not match!");
              } else {
                setErrorPassword("");
              }
            }

            const [incoporation, setIncoporation] = useState('')
            const [address_proof, setAddressproof] = useState('')
            const [business_license, setBusinesslicense] = useState('')
            const [tax_id, setTaxid] = useState('')
            const [aml_policy, setAmlPolicy] = useState('')

            const [legal_business_name, setLegalName] = useState('')
            const [operating_biss_as, setOperatingName] = useState('')
            const [registration_country, setRegistration_country] = useState('')
            const [state, setOperatingstate] = useState('')
            const [street_address, setStreet_address] = useState('')
            const [website_address, setWebsite_address] = useState('')
            const [business_usecase, setBusiness_usecase] = useState('')

            const handleLegalName = (e) => { setLegalName(e.target.value)}
            const handleOperatingName = (e) => { setOperatingName(e.target.value)}
            const handleStreetAdd = (e) => { setStreet_address(e.target.value)}
            const handleWebAddress = (e) => { setWebsite_address(e.target.value)}
            const handleBussinessUsecase = (e) => { setBusiness_usecase(e.target.value)}

            const ComplianceBusinessDetails = (event) => {
              event.preventDefault();
              axios.post(`${BASE_URL}/compliance/update/profile`, {
                  legal_business_name: legal_business_name,
                  operating_biss_as: operating_biss_as,
                  registration_country: registration_country,
                  state: state,
                  street_address : street_address,
                  website_address : website_address,
                  business_usecase : business_usecase
              }, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data"
                  },
                }).then(async (result) => {
                setAlertMessage('successfully submited');
                setShowAlert(true);
                await new Promise((resolve) => setTimeout(resolve, 2000));
                next();
              })
                .catch(error => {
                  const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
                  setAlertMessage(jsonString);
                  setShowAlert(true); 
                })
              }
            const [emailPI, setEmailPI] = useState('')
            const [street_address1, setStreet_address1] = useState('')
            const [fullName, setFullName] = useState('')
            const [city_of_residencePI, setCity_of_residence] = useState('')
            const [state_of_residence, setState_of_residence] = useState('')
            const [country_of_residence, setCountry_of_residence] = useState('')
            const [dob, setDob] = useState('')
            const [phone1, setPhone1] = useState('')

            const handleEmail = (e) => { setEmailPI(e.target.value)}
            const handleStreetAdd1 = (e) => { setStreet_address1(e.target.value)}
            const handleFullName = (e) => { setFullName(e.target.value)}
            const handleCity_of_residence = (e) => { setCity_of_residence(e.target.value)}
            const handleDob = (e) => { setDob(e.target.value)}
            const handlePhone1 = (value) => { setPhone1(value)}

            const ComplianceProfile = (event) => {
              event.preventDefault();
              axios.post(`${BASE_URL}/compliance/personal/profile`, {
                  email: emailPI,
                  street_address: street_address1,
                  fullName: fullName,
                  state: state,
                  city_of_residence : city_of_residencePI,
                  state_of_residence : state_of_residence,
                  country_of_residence : country_of_residence,
                  dob : dob,
                  phone: phone1
              }, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data"
                  },
                }).then(async (result) => {
                setAlertMessage('successfully submited');
                setShowAlert(true);
                await new Promise((resolve) => setTimeout(resolve, 2000));
                next();
              })
                .catch(error => {
                  const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
                  setAlertMessage(jsonString);
                  setShowAlert(true);
                })
              }

            const handleIncoporation = (e) => { setIncoporation(e.target.files[0])}  
            const handleAddressproof = (e) => { setAddressproof(e.target.files[0])}  
            const handleBusinesslicense = (e) => { setBusinesslicense(e.target.files[0])}  
            const handleTaxid = (e) => { setTaxid(e.target.files[0])}  
            const handleAmlPolicy = (e) => { setAmlPolicy(e.target.files[0])}  

          const ComplianceBusinessDocuments = (event) => {
            event.preventDefault();
            axios.post(`${BASE_URL}/compliance/business_docs`, {
                incoporation: incoporation,
                address_proof: address_proof,
                business_license: business_license,
                tax_id: tax_id,
                aml_policy : aml_policy
            }, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data"
                },
              }).then(async (result) => {
              setAlertMessage('successfully submited');
              setShowAlert(true); 
              await new Promise((resolve) => setTimeout(resolve, 3000));             
              next();
            })
              .catch(error => {
                const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
                setAlertMessage(jsonString);
                setShowAlert(true);
              })
            }
            const [userPayment, setUserPayment] = useState(null);
            const [paymentMethod, setPaymentMethod] = useState('')
            const [payment_channel, setPayment_channel] = useState('')
            const [market_place_1, setMarket_place_1] = useState('')
            const [market_place_2, setMarket_place_2] = useState('')
            const [market_place_3, setMarket_place_3] = useState('')
            const [username_1, setUsername_1] = useState('')
            const [username_2, setUsername_2] = useState('')
            const [username_3, setUsername_3] = useState('')
            const [exchange_statement, setExchange_statement] = useState('')
            const [major_income_sources, setMajor_income_sources] = useState('')
            const [expected_transaction_volume, setExpected_transaction_volume] = useState('')

            const handlePaymentChannel = (e) => { setPayment_channel(e.target.value)}
            const handleMarket_place_1 = (e) => { setMarket_place_1(e.target.value)}
            const handleMarket_place_2 = (e) => { setMarket_place_2(e.target.value)}
            const handleMarket_place_3 = (e) => { setMarket_place_3(e.target.value)}
            const handleUsername_1 = (e) => { setUsername_1(e.target.value)}
            const handleUsername_2 = (e) => { setUsername_2(e.target.value)}
            const handleUsername_3 = (e) => { setUsername_3(e.target.value)}
            const handleMajor = (e) => { setMajor_income_sources(e.target.value)}
            const handleExpected = (e) => { setExpected_transaction_volume(e.target.value)}
            const handleExchangeStatement = (e) => { setExchange_statement(e.target.files[0])}
            const handlePaymentMethod = (e) => {
              setPaymentMethod(e.target.value)
          }
          const ComplianceAgent = (event) => {
            event.preventDefault();
            axios.post(`${BASE_URL}/compliance/agent`, {

              exchange_statement: exchange_statement,
              payment_method : paymentMethod,
              payment_channel: payment_channel,
              market_place_1 : market_place_1,
              username_1: username_1,
              market_place_2: market_place_2,
              username_2: username_2,
              market_place_3: market_place_3,
              username_3: username_3,
              major_income_sources: major_income_sources,
              expected_transaction_volume: expected_transaction_volume
              
            }, {
                headers: {
                  Authorization: `Bearer ${token}`,
                  "Content-Type": "multipart/form-data"
                },
              }).then(async (result) => {
              setAlertMessage('successfully submited');
              setShowAlert(true);       
              await new Promise((resolve) => setTimeout(resolve, 3000));       
              next();
            })
              .catch(error => {
                const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
                setAlertMessage(jsonString);
                setShowAlert(true);
              })
            }
            const fetchUserPayment = async () => {
              try {
                const response = await axios.get(`${BASE_URL}/payment-method`, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                });
          
                return response.data;
              } catch (error) {
                console.error(error);
              }
            };
            useEffect(() => {
              const getUserPayment = async () => {
                const data = await fetchUserPayment();
                setUserPayment(data);
              };
          
              getUserPayment();
            }, []);

            const handleAlertClose = () => {
              setShowAlert(false);
            };
            const [isButtonDisabled, setIsButtonDisabled] = useState(true);
            const [isButtonDisabled1, setIsButtonDisabled1] = useState(true);
            const [userData, setUserData] = useState({});
            const fetchUserData = async () => {
              try {
                const response = await axios.get(`${BASE_URL}/user`, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                });
          
                return response.data;
              } catch (error) {
                console.error(error);
              }
            };
            useEffect(() => {
              const getUserData = async () => {
                const data = await fetchUserData();
                setUserData(data);
                setEmailPI(data.data.email)
                setPhone1(data.data.phoneNumber)
                if (data.is_personnal_verification_approved == 1){
                  setIsButtonDisabled(false)
                }
                if (data.is_business_approved == 1){
                  setIsButtonDisabled1(false)
                }
              };
          
              getUserData();
            }, []);
            

  return   (
    ( b_details && userPayment && userData  ? (
    <>
    {showAlert && (
        <AlertModal
          isModalOpen={showAlert}
          alertMessage={alertMessage}
          onClose={handleAlertClose}
        />
      )}
      <Navbar toggleSidebar={toggleSidebar}/>
        <MainContainer isOpen={isOpen}>
            <Sidebar1 isOpen={isOpen} toggleSidebar={toggleSidebar} />
            <MainContent>
        <Container>
                <Form2 style={{marginTop: '40px'}} onSubmit={UpdateUserDetails} >
                <FormH2 style={{marginTop: '-50px'}}>Update Personal Details</FormH2>
                    <FormLabel>Phone Number</FormLabel>
                    <PhoneInput country={'ng'} inputProps={{ name:' ', required: true, autoFocus: true, }} value={phone} onChange={handlePhone}/>
                    <FormLabel style={{marginTop: '20px', marginBottom: '1px'}}>Update Profile Image</FormLabel>
                    <FormInput type='file' accept="image/*" onChange={handleImage} />
                    <div style={{position:"relative", marginTop: '0px'}}>
                        <FormLabel for='password' type='password'>Password</FormLabel>
                        <FormInput ref={inputRef} type="password" placeholder="*******************" style={{width: '100%'}} value={password} onChange={handlePassword} />
                        <ReactPasswordToggleIcon inputRef={inputRef} showIcon={showIcon} hideIcon={hideIcon} style={{height: '100px'}}/>
                    </div>
                    <div style={{position:"relative", marginTop: '0px'}}>
                    <FormLabel for='confirmPassword' type='password'>Confirm Password</FormLabel>
                    <FormInput
                      ref={inputRef1}
                      type="password"
                      placeholder="*******************"
                      style={{width: '100%'}}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      onBlur={() => validatePassword(confirmPassword)}
                    />
                        <ReactPasswordToggleIcon inputRef={inputRef1} showIcon={showIcon} hideIcon={hideIcon} style={{height: '100px'}}/>
                    </div>
                    {errorPassword && <div style={{fontSize: '10px', color: '#FF0000', marginTop: '-10px', marginBottom: '5px', marginLeft: '10px'}}>{errorPassword}</div>}
                    <PrimaryBtn style={{width: '100%'}}>Update</PrimaryBtn>
                </Form2>
        </Container>
        </MainContent>
        </MainContainer>
    </>) : <Loader/>)
  ) 
}

export default Personal