import React, {useState, useEffect, useRef} from 'react'
import AuthCode from 'react-auth-code-input';
import ZeenahPay from '../assets/ZeenahPay.png'
import { Form, FormContent, FormH1, Icon, Text, PrimaryBtn as PrimaryBtn1 } from '../components/Form/formElements'
import styled from "styled-components";
import axios from 'axios'
import AlertModal from './modal';
import BASE_URL from '../apiConfig'

 const Div1 = styled.div`
    margin-bottom: 20px;
    margin-left: 80px;
    width: 100%;

    @media screen and (max-width: 600px) {
        margin-bottom: 20px;
        margin-left: 50px;
        width: 100%;
    }
    `

const Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000
`;

const PopupCard = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  border: none;
  background: transparent;
`;
const DivPop = styled.div`
    height: 150px;
    @media screen and (max-width: 580px) {
        height: 195px;
    }
`
const Pin = ({ onPinVerified, onCancel }) => {

    const token = localStorage.getItem('bearerToken');
    const [showPin, setShowPin] = useState(true);
    const [result, setResult] = useState();
    const [errMsg, setErrMsg] = useState('')
    const errRef = useRef();
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const handleOnChange = (res) => {
    setResult(res);
    }
    const handleApi = (event) => {
        event.preventDefault();
        axios.post(`${BASE_URL}/pin/verify`, {
          pin: result
        },
        {
            headers: {
            Authorization: `Bearer ${token}`,
            }
        }).then(result => {
          setAlertMessage(result?.data.data.msg);
          setShowAlert(true);
          onPinVerified(event);
          setShowPin(false)
        }).catch(error => {
          setErrMsg(error?.response.data.errors)
        })
        errRef.current.focus();
      }

      const handleCancel = () => {
        onCancel();
      };

      const handleAlertClose = () => {
        setShowAlert(false);
      };

  return showPin &&  (
    <>
    {showAlert && (
        <AlertModal
          isModalOpen={showAlert}
          alertMessage={alertMessage}
          onClose={handleAlertClose}
        />
      )}
    <Background>
         <PopupCard>
         <CloseButton onClick={handleCancel}>&times;</CloseButton>
           <FormContent>
                    <Icon src={ZeenahPay}  width='160px'/>
                    <Form onSubmit={handleApi}>
                        <FormH1 style={{marginTop:'-70px'}}>Transaction Pin</FormH1>
                        <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive" style={{color: '#FF0000'}} >{errMsg}</p>
                        <Text style={{marginBottom: '-20px'}}>Enter your 4 digit Transaction Pin to Proceed</Text>
                        <Div1 style={{marginBottom: '20px'}}>
                            <AuthCode allowedCharacters='numeric' isPassword={true} onChange={handleOnChange} length={4} containerClassName={'otpContainer'} inputClassName={'otpInput'} onBlur={() => handleApi}/>          
                        </Div1>
                        <PrimaryBtn1>submit</PrimaryBtn1>
                    </Form>
                </FormContent>
         </PopupCard>
       </Background>
       </>
  )
}

export default Pin