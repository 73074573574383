import React , {useState, useEffect} from 'react'
import Navbar from '../components/Navbar'
import 'react-tabs/style/react-tabs.css';
import axios from 'axios';
import  {useNavigate}  from 'react-router-dom'
import { SelectInput,P2pCard,DivLeft2,P2pInfo2 } from '../components/Dashboard/content/dashElements'
import { Form2, FormArea, FormContent, FormH2, FormInput, FormLabel, FormWrap, PrimaryBtn} from '../components/Form/formElements'
import SwitchSelector from "react-switch-selector";
import styled from "styled-components";
import Loader from '../components/loader';
import AlertModal from './modal';
import Sidebar1, { MainContainer, MainContent } from './nav';
import { useLocation } from 'react-router-dom';
import BASE_URL from '../apiConfig'

const Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopupCard = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 80%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  border: none;
  background: transparent;
`;
const DivPop = styled.div`
    height: 150px;
    @media screen and (max-width: 580px) {
        height: 195px;
    }
`
const EditAd = () => {

  const location = useLocation();
  const [tradeId] = useState(location.state.tradeId);
  const [trades] = useState(location.state.trades);
  const selectedTrade = trades.find(trade => trade.id === tradeId);

    const [userData, setUserData] = useState({});
    const [userPayment, setUserPayment] = useState(null);
    const [showPopup, setShowPopup] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [currencies, setCurrencies] = useState([]);
    const [isOpen, setIsOpen] = useState(true);
    const toggleSidebar = () => {
      setIsOpen(!isOpen);
    };

    const handleAlertClose = () => {
      setShowAlert(false);
    };

    useEffect(() => {
      const token = localStorage.getItem('bearerToken');
      const fetchCurrencies = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/payment-method/sys-currencies`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setCurrencies(response.data.data);
        } catch (error) {
          setError(error);
        }
      };
      fetchCurrencies();
    }, []);
    
const fetchUserData = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const getUserData = async () => {
      const data = await fetchUserData();
      setUserData(data);
    };

    getUserData();
  }, []);
const fetchUserPayment = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/payment-method`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const getUserPayment = async () => {
      const data = await fetchUserPayment();
      setUserPayment(data);
    };

    getUserPayment();
  }, []);

    const token = localStorage.getItem('bearerToken');

    const [totalAmount, setTotalAmount] = useState(selectedTrade?.totalAmount)
    const [min_amount, setMinAmount] = useState(selectedTrade?.min_amount)
    const [max_amount, setMaxAmount] = useState(selectedTrade?.max_amount)
    const [marginPrice, setMarginPrice] = useState(selectedTrade?.marginPrice)
    const [trade_currency, setTradeCurrency] = useState(selectedTrade?.trade_currency)
    const [priceType, setPriceType] = useState(selectedTrade?.priceType)
    const [paymentMethod, setPaymentMethod] = useState(selectedTrade?.paymentMethod)
    const [assetName, setAssetName] = useState(selectedTrade?.assetName)
    const [fiatName, setFiatName] = useState(selectedTrade?.fiatName)
    const [tradeType, setTradeType] = useState(selectedTrade?.tradeType)
    const [time_limit, setTimeLimit] = useState(selectedTrade?.time_limit)
    const [terms, setTerms] = useState(selectedTrade?.terms)
    const [error, setError] = useState("");
    const [error1, setError1] = useState("");
    const [error2, setError2] = useState("");
    const [errorBuy, setBuyError] = useState("");
    const [currencyBalance, setCurrencyBalance] = useState('')
    const [balance, setBalance] = useState(null);
    const [flat_fee, setFlatFee] = useState('')
    const [percentage_fee, setPercentageFee] = useState('')
    

    const handleTotalAmount = (e) => {
        const value = parseFloat(e.target.value.replace(/,/g, ''));
        setTotalAmount(value)
    }
    const handleTimeLimit = (e) => {
        setTimeLimit(e.target.value)
    }
    const handlePercentageFee = (e) => {
      setPercentageFee(e.target.value)
    }
    const handleFlatFee = (e) => {
      setFlatFee(e.target.value)
    }
    const handleTerms = (e) => {
        setTerms(e.target.value)
    }
    const handleMinAmount = (e) => {
        const value = parseFloat(e.target.value.replace(/,/g, ''));
        setMinAmount(value)
    }
    const handleMaxAmount = (e) => {
      const value = parseFloat(e.target.value.replace(/,/g, '')); // Remove commas and parse as float
      setMaxAmount(value);
    }
    const handleMarginPrice = (e) => {
        setMarginPrice(e.target.value)
    }
    useEffect(() => {
      const currencyObject = balance?.data.find(
        (obj) => obj.ticker_name === selectedTrade?.trade_currency
      );
      if (currencyObject) {
        setCurrencyBalance(currencyObject.balance);
      }
    }, [balance?.data, selectedTrade?.trade_currency]);
    const handlePaymentMethod = (e) => {
        setPaymentMethod(e.target.value)
    }
    const handleAssetName = (e) => {
        setAssetName(e.target.value)
    }
      // Handle input change for tradeType
  const handleTradeTypeChange = (value) => {
    setTradeType(value);
  }

  // Handle input change for priceType
  const handlePriceTypeChange = (value) => {
    setPriceType(value);
  }
  const validateMaxamount = max_amount => {
    if (parseFloat(max_amount) > parseFloat(totalAmount)) {
        setError("Max amount cannot exceed total amount");
    } else {
      setError("");
    }
  };
  const validateMaxBuyamount = max_amount => {
    if (parseFloat(max_amount) > 10000000) {
        setBuyError("Max amount cannot exceed 10,000,000");
    } else {
      setBuyError("");
    }
  };
  const validateMinamount = min_amount => {
    if (parseFloat(min_amount) > parseFloat(totalAmount)) {
        setError2("Minimum amount cannot exceed total amount");
    } else {
      setError2("");
    }
  };

    const navigate = useNavigate();
    const createAd = (event) => {
        event.preventDefault();
        if (!error || !error1 || !error2 || !errorBuy) {
        axios.post(`${BASE_URL}/trade/${tradeId}/update`, {
          totalAmount: totalAmount,
          min_amount: min_amount,
          max_amount: max_amount,
          flat_fee: flat_fee,
          tradeType: tradeType,
          percentage_fee: percentage_fee,
          paymentMethod: paymentMethod,
          trade_currency: trade_currency,
          assetName: trade_currency,
          fiatName: trade_currency,
          time_limit: time_limit,
          terms: terms
        }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }).then(async (result) => {
          setAlertMessage('Edited Successfully');
          setShowAlert(true);
          await new Promise((resolve) => setTimeout(resolve, 3000)); // Delay the navigation for 1 second
          navigate("/p2p")
        })
          .catch(error => {
            const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
            setAlertMessage(jsonString);
            setShowAlert(true);
          })
        }
    }
    let history = useNavigate();

    const fetchBalance = async () => {
      const response = await axios.get(`${BASE_URL}/account/balance`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    };
    useEffect(() => {
      const getBal = async () => {
        const data = await  fetchBalance();
        setBalance(data);
      };
  
      getBal();
    }, []);

    const validateAmount = totalAmount => {
      if (parseFloat(totalAmount) > parseFloat(currencyBalance)) {
          setError1("total amount cannot exceed Balance");
      } else {
        setError1("");
      }
    };

    function getCurrencySymbol(currencyCode) {
      switch (currencyCode) {
        case 'CLP':
          return 'CL$';
        case 'NGN':
          return '₦';
        case 'USD':
          return '$';
        case 'EUR':
          return '€';
        case 'GBP':
          return '£';
        case 'INR':
          return '₹';
        default:
          return '';
      }
    }

  return  (
    <>
        <Navbar toggleSidebar={toggleSidebar}/>
        <MainContainer isOpen={isOpen}>
            <Sidebar1 isOpen={isOpen} toggleSidebar={toggleSidebar} />
            <MainContent>
    <FormWrap>
                <FormContent>
                
                    <Form2 style={{backgroundColor: '#FDF9FF'}} onSubmit={createAd} >
                    <FormH2>Edit AD</FormH2> 
                        {tradeType === 'buy' ?
                        (<><FormLabel for='totalAmount'>Amount</FormLabel>
                        <div style={{ position: 'relative' }}>
                          <span style={{ position: 'absolute', left: '10px', top: '30%', transform: 'translateY(-50%)', color:'purple', fontFamily:'monospace' }}> {getCurrencySymbol(trade_currency)}</span>
                          <FormInput id='totalAmount' type='' placeholder='0' value={totalAmount ? totalAmount.toLocaleString() : ''} onChange={handleTotalAmount} style={{ paddingLeft: trade_currency === 'CLP' ? '35px' : '30px', width:'100%' }}/>
                        </div>
                         </>) : 
                        (<><FormLabel for='totalAmount'>Amount <div><p style={{textAlign:'end', color: '#7BC875', fontWeight: '900', fontSize: '14px', marginRight: '10px', marginBottom: '-20px', cursor: 'pointer', float:'right'}} onClick={() => {setTotalAmount(currencyBalance); validateAmount(currencyBalance)}}>max</p></div></FormLabel>
                        <div style={{ position: 'relative' }}>
                          <span style={{ position: 'absolute', left: '10px', top: '30%', transform: 'translateY(-50%)', color:'purple', fontFamily:'monospace' }}> {getCurrencySymbol(trade_currency)}</span>
                          <FormInput id='totalAmount' type='' placeholder='0' value={totalAmount ? totalAmount.toLocaleString() : ''} onChange={handleTotalAmount} onBlur={() => validateAmount(totalAmount)} style={{ paddingLeft: trade_currency === 'CLP' ? '35px' : '30px', width:'100%' }} />
                        </div>
                        {error1 && <div style={{fontSize: '10px', color: '#FF0000', marginTop: '-26px', marginBottom: '5px', marginLeft: '10px'}}>{error1}</div>} </>)}

                        
                        <FormLabel for='limit' style={{marginBottom: '-5px', textAlign:'center', fontWeight:'initial'}}>Order Limit</FormLabel>
                        {tradeType === 'buy' ?
                        (<div style={{display:'flex', marginTop: '10px'}}>
                                <div>
                                <FormLabel for='limit'>Min Limit</FormLabel>
                                <div style={{ position: 'relative' }}>
                                  <span style={{ position: 'absolute', left: '10px', top: '30%', transform: 'translateY(-50%)', color:'purple', fontFamily:'monospace' }}> {getCurrencySymbol(trade_currency)}</span>
                                  <FormInput id='min_amount' type='' placeholder='Min' style={{ width: '97%', paddingLeft: trade_currency === 'CLP' ? '35px' : '30px' }} value={min_amount ? min_amount.toLocaleString() : ''} onChange={handleMinAmount}/>
                                </div>
                                </div>
                                <div>
                                <FormLabel for='limit'>Max Limit</FormLabel>
                                <div style={{ position: 'relative' }}>
                                  <span style={{ position: 'absolute', left: '10px', top: '30%', transform: 'translateY(-50%)', color:'purple', fontFamily:'monospace' }}> {getCurrencySymbol(trade_currency)}</span>
                                  <FormInput id='max_amount' type='' placeholder='Max' style={{ width: '97%', paddingLeft: trade_currency === 'CLP' ? '35px' : '30px' }} value={max_amount ? max_amount.toLocaleString() : ''} onChange={handleMaxAmount} onBlur={() => validateMaxBuyamount(max_amount)}/>
                                  {errorBuy && <div style={{fontSize: '10px', color: '#FF0000', marginTop: '-10px', marginBottom: '5px', marginLeft: '10px'}}>{errorBuy}</div>}
                                </div>
                                </div>
                        </div>)
                        : (<div style={{display:'flex', marginTop: '10px'}}>
                                <div>
                                <FormLabel for='limit'>Min Limit</FormLabel>
                                <div style={{ position: 'relative' }}>
                                  <span style={{ position: 'absolute', left: '10px', top: '30%', transform: 'translateY(-50%)', color:'purple', fontFamily:'monospace' }}> {getCurrencySymbol(trade_currency)}</span>
                                    <FormInput id='min_amount' type='' placeholder='Min' style={{width: '97%', paddingLeft: trade_currency === 'CLP' ? '35px' : '30px'}} value={min_amount ? min_amount.toLocaleString() : ''} onChange={handleMinAmount} onBlur={() => validateMinamount(min_amount)}/>
                                  </div>
                                    {error2 && <div style={{fontSize: '10px', color: '#FF0000', marginTop: '-10px', marginBottom: '5px', marginLeft: '10px'}}>{error2}</div>}
                                </div>
                                <div>
                                <FormLabel for='limit'>Max Limit</FormLabel>
                                <div style={{ position: 'relative' }}>
                                  <span style={{ position: 'absolute', left: '10px', top: '30%', transform: 'translateY(-50%)', color:'purple', fontFamily:'monospace' }}> {getCurrencySymbol(trade_currency)}</span>
                                    <FormInput id='max_amount' type='number' placeholder='Max' style={{width: '97%', paddingLeft: trade_currency === 'CLP' ? '35px' : '30px' }} value={max_amount ? max_amount.toLocaleString() : ''} onChange={handleMaxAmount} onBlur={() => validateMaxamount(max_amount)} />
                                </div>
                                    {error && <div style={{fontSize: '10px', color: '#FF0000', marginTop: '-10px', marginBottom: '5px', marginLeft: '10px'}}>{error}</div>}
                                </div>
                        </div>) }
                            
                        <FormLabel for='marginPrice'>Set fee Amount</FormLabel>
                        <div style={{ position: 'relative' }}>
                          <span style={{ position: 'absolute', left: '10px', top: '30%', transform: 'translateY(-50%)', color:'purple', fontFamily:'monospace' }}>{getCurrencySymbol(trade_currency)}</span>
                          <FormInput id='marginPrice' placeholder='10' style={{ paddingLeft: trade_currency === 'CLP' ? '35px' : '30px', width:'100%' }} value={flat_fee} onChange={handleFlatFee} />
                        </div>
                        <FormLabel for='marginPrice'>Set fee Percentage</FormLabel>
                        <div style={{ position: 'relative' }}>
                          <span style={{ position: 'absolute', left: '10px', top: '30%', transform: 'translateY(-50%)', color:'purple', fontFamily:'monospace' }}>%</span>
                          <FormInput id='marginPrice' placeholder='10' style={{ paddingLeft: '30px', width:'100%' }} value={percentage_fee} onChange={handlePercentageFee} />
                        </div>
                        <DivLeft2>
                                <P2pInfo2>Payment time limit:</P2pInfo2>
                                <P2pInfo2 style={{color: '#EBC84D'}}>The Zeenah amount will be escrowed during this period</P2pInfo2>
                        </DivLeft2>
                            
                        <SelectInput id='time_limit' value={time_limit} onChange={handleTimeLimit} >
                            <option>set time limit</option>
                            <option value="15">15 minutes</option>
                            <option value="30">30 minutes</option>
                            <option value="60">1 hour</option>
                            <option value="1440">1 day</option>
                            <option value="10080">1 week</option>
                        </SelectInput>

                        <FormLabel for='terms'>Terms (optional)</FormLabel>
                        <FormArea id='terms' type='text' placeholder='Terms will be displayed to the counterparty' value={terms} onChange={handleTerms} />
                        <PrimaryBtn type='submit' style={{ borderRadius: '12px', backgroundColor: '#791FA4', textAlign: 'center', width: '100%',opacity: error || error1 || error2 || errorBuy ? 0.5 : 1,pointerEvents: error || error1 || error2 || errorBuy ? 'none' : 'auto'}}>Post</PrimaryBtn>
                    </Form2>
                </FormContent>
            </FormWrap>
            </MainContent>
            </MainContainer>
    {showAlert && (
        <AlertModal
          isModalOpen={showAlert}
          alertMessage={alertMessage}
          onClose={handleAlertClose}
        />
      )}
</>
  );
}

export default EditAd