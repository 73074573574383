import './App.css';
import { Route, Routes} from 'react-router-dom'
import Signup from './pages/signup';
import Login from './pages/login';
import Otp from './pages/otp';
import Dashboard from './pages/dashboard';
import Transactions from './pages/transactions';
import Customers from './pages/customers';
import PaymentLink from './pages/paymentlink';
import PaymentLinkH from './pages/paymentlinkh';
import Topup from './pages/topup';
import Products from './pages/products';
import CreateProduct from './pages/create-product';
import CreatePaymentLink from './pages/create-paymentlink';
import CreateDonationLink from './pages/create-donationlink';
import P2p from './pages/p2p';
import PayMethod from './pages/paymentmethod';
import Settings from './pages/settings';
import RequireAuth from './components/RequireAuth';
import CreateAd from './pages/create-ad';
import { StepsProvider } from "react-step-builder";
import Payout from './pages/payout';
import Swap from './pages/swap';
import Checkout from './pages/checkout';
import Test1 from './pages/realtest';
import Test2 from './pages/test2';
import PaymentForm from './pages/paymentForm';
import ForgotPassword from './pages/forgotpassword';
import ResetPassword from './pages/resetpassword';
import Dispute from './pages/dispute';
import NotFound from './pages/404';
import VerifyEmail from './pages/verifyemail';
import UpdatePin from './pages/updatepin';
import Xyz from './pages/nav';
import P2pDisute from './pages/p2p-dispute';
import P2pClosed from './pages/p2p-closed';
import P2pSuccess from './pages/p2p-success';
import P2pFailed from './pages/p2p-failed';
import P2pTransactions from './pages/p2p-transactions';
import Personal from './pages/personal';
import Business from './pages/business';
import Compliance from './pages/compliance';
import MyPaymentMethod from './pages/myPaymentMethod';
import EditAd from './pages/edit-ad';
import P2pTransactionDetails from './pages/p2p-transactiondetails';
import Wallet from './pages/wallet';
import MyForm from './pages/tab';
import AwaitingConfirmation from './pages/awaiting_confirmation';
import Testpm from './pages/testpm';
import Depay from './pages/Depay';
import BinancePay from './pages/BinancePay';
import Chat from './pages/chat';
import TransakPayComponent from './pages/transak';



function App() {
  return (
    <div className="App">
        <Routes>
          <Route path='*' element={<NotFound/>} />
          <Route path='/' element={<Login/>} />
          <Route path='/signup' element={<Signup/>} />
          <Route path='/login' element={<Login/>} />
          <Route path='/otp' element={<Otp/>} />
          <Route path='/verify-email' element={<VerifyEmail/>} />
          <Route path='/forgotpassword' element={<ForgotPassword />} />
          <Route path='/resetpassword' element={<ResetPassword/> } />
          <Route path='/checkout/*' element={<StepsProvider><Checkout/></StepsProvider>} />
          <Route path='/tab' element={<MyForm/>} />
          <Route path='/awaiting' element={<AwaitingConfirmation />} />
          <Route path='/testpm' element={<Testpm/>} />
          <Route element={<RequireAuth />}>
          <Route path='/chat' element={<Chat /> } />
          <Route path='/dispute' element={<Dispute/>} />
          <Route path='/dashboard' element={<Dashboard/>} />
          <Route path='/dashboard/wallet' element={<Wallet/>} />
          <Route path='/updatepin' element={<StepsProvider><UpdatePin/></StepsProvider>} />
          <Route path='/transactions' element={<Transactions/>} />
          <Route path='/customers' element={<Customers/>}/>
          <Route path='/paymentlink' element={<PaymentLink/>}/>
          <Route path='/paymentlink-history' element={<PaymentLinkH/>}/>
          <Route path='/topup' element={<Topup/>} />
          <Route path='/products' element={<Products/>}/>
          <Route path='/create-product' element={<CreateProduct/>}/>
          <Route path='/create-paymentlink' element={<CreatePaymentLink/>}/>
          <Route path='/create-donationlink' element={<CreateDonationLink/>}/>
          <Route path='/p2p' element={<P2p/>} />
          <Route path='/p2p/dispute' element={<P2pDisute/>} />
          <Route path='/p2p/closed' element={<P2pClosed/>} />
          <Route path='/p2p/success' element={<P2pSuccess/>} />
          <Route path='/p2p/failed' element={<P2pFailed/>} />
          <Route path='/p2p/transactions' element={<P2pTransactions/>} />
          <Route path='/p2p/transaction/details' element={<P2pTransactionDetails/>} />
          <Route path='/paymentmethod' element={<PayMethod/>} />
          <Route path='/settings/paymentmethod' element={<MyPaymentMethod/>} />
          <Route path='/payout' element={<Payout/>} />
          <Route path='/swap' element={<Swap/>} />
          <Route path='/test1' element={<Test1 />} />
          <Route path='/test2' element={<Test2 />} />
          <Route path='/test' element={<Xyz/>} />
          <Route path='/transak' element={<TransakPayComponent />} />
          <Route path='/create-ad' element={<CreateAd/> } />
          <Route path='/edit-ad' element={<EditAd/> } />
          <Route path='/settings' element={<StepsProvider><Settings/></StepsProvider>} />
          <Route path='/settings/personal-details' element={<Personal/>} />
          <Route path='/settings/business-details' element={<Business/>} />
          <Route path='/settings/compliance' element={<StepsProvider><Compliance/></StepsProvider>} />
          <Route path='/add-payment' element={<PaymentForm/>} />
          <Route path='/topup/Depay' element={<Depay />} />
          <Route path='/topup/Binancepay' element={<BinancePay />} />
          </Route>
        </Routes>
    </div>
  );
}

export default App;
