import React, {useState, useEffect} from 'react'
import { FaSearch } from 'react-icons/fa';
import { Table as Tablle, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import Navbar from '../components/Navbar';
import { CenterContent, Container, DesktopDiv, DivContainer, DivLeft, DivRight, FormWrap, SearchBar, TableCell, Tdreg, ThText } from '../components/Dashboard/content/dashElements';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import axios from 'axios'
import Loader from '../components/loader';
import Nothing from '../assets/nothingtoshow.png'
import Empty from '../assets/empty.svg'
import Sidebar1, { MainContainer, MainContent } from './nav'
import { P2pcontainer } from '../components/Dashboard/p2pElements/p2pElements';
import { Form3A, PrimaryBtn2 } from '../components/Form/formElements';
import { NavLink } from 'react-router-dom'
import {GrPrevious, GrNext } from 'react-icons/gr';
import {IoIosArrowDown} from 'react-icons/io';
import BASE_URL from '../apiConfig'
import { DropdownContent, EmailContainer, Title } from '../components/MobileTable';


const Customers = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
    const [customers, setCustomers] = useState(null);
    const token = localStorage.getItem('bearerToken');

    const fetchCustomers = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/customers`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
    
          return response.data;
        } catch (error) {
          console.error(error);
        }
      };
      useEffect(() => {
        const getCustomers = async () => {
          const data = await fetchCustomers();
          setCustomers(data);
        };
    
        getCustomers();
      }, []);
      const [isEmpty, setIsEmpty] = useState(false);
      const [searchQuery, setSearchQuery] = useState('');
      const filteredData = customers?.data.data.filter((customer) =>
      customer.customer_name.toLowerCase().includes(searchQuery.toLowerCase())||
      customer.customer_email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      new Date(customer.created_at).toLocaleString().toLowerCase().includes(searchQuery.toLowerCase())
      )

      const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // reset to first page when search query changes
        setIsEmpty(filteredData.length === 0);
        if (searchQuery === 0) {
          setSearchQuery(null);
        }
      };

      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);
  
      const pageNumbers = [];
      for (let i = 1; i <= Math.ceil(filteredData?.length / itemsPerPage); i++) {
        pageNumbers.push(i);
      }

     const handlePrevPage = () => {
        setCurrentPage( currentPage - 1);
      };
     const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
      };
      const handleClick = e => {
        setCurrentPage(Number(e.target.id));
      };

      const isLastPage = currentPage === pageNumbers.length;
      const isFirstPage = currentPage === 1;

      const [isOpenZ, setIsOpenZ] = useState(false);

      const toggleDropdown = (index) => {
        setIsOpenZ((prevState) => ({
          ...prevState,
          [index]: !prevState[index],
        }));
      };
  return (
    <>
    <Navbar toggleSidebar={toggleSidebar}/>
        <MainContainer isOpen={isOpen}>
            <Sidebar1 isOpen={isOpen} toggleSidebar={toggleSidebar} />
            <MainContent>
            <div style={{marginLeft: '30px'}}>
              <h2>Beneficiaries</h2>
              <div style={{position:"flex", marginTop: '20px', width: '100%', marginBottom: '20px'}}>
                  <SearchBar type="text" value={searchQuery} onChange={handleSearchInputChange} placeholder='search customers'  />
              </div>
            </div>
        {customers ? ( <P2pcontainer>     
    <FormWrap style={{background: '#fff'}}>
    {customers.data.data.length  ? (
                <CenterContent>
                {isEmpty ? (
                  <div style={{justifyContent:'center', display: 'grid'}}>
                        <div style={{textAlign: 'center'}}>
                          <img src={Nothing} alt="Empty table" />
                        </div>
                        <div style={{textAlign: 'center'}}>
                          <h5 style={{marginBottom: '10px'}}>No search results found</h5>
                        </div>
                        <div>
                        </div>
                      </div>
                ) : (
                  <>
                  <DesktopDiv style={{overflow: 'auto'}}>
                      <Tablle style={{ textAlign: 'left', borderCollapse: 'collapse'}}>
                        <Thead style={{ backgroundColor: "#F3E8F7",color: "#1E1E1E", fontWeight: "600", lineHeight:"21px"}}>
                            <Tr>
                            <Th><ThText>Name</ThText></Th>
                            <Th><ThText>Email address</ThText></Th>
                            <Th><ThText>Phone Number</ThText></Th>
                            <Th><ThText>Shipping address</ThText></Th>
                            <Th><ThText>Product</ThText></Th>
                            <Th><ThText>Amount</ThText></Th>
                            <Th><ThText>Quantity</ThText></Th>
                            <Th><ThText>Transaction Type</ThText></Th>
                            <Th><ThText>Date added</ThText></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {currentItems.map((user, index) => (
                          <Tr key={index} style={{borderBottom: '1px solid #ddd'}}>
                            <Td><Tdreg>{user.customer_name}</Tdreg></Td>
                            <Td><Tdreg>{user.customer_email}</Tdreg></Td>
                            <Td><Tdreg>N/A</Tdreg></Td>
                            <Td><Tdreg>N/A</Tdreg></Td>
                            <Td><Tdreg>N/A</Tdreg></Td>
                            <Td><Tdreg>N/A</Tdreg></Td>
                            <Td><Tdreg>N/A</Tdreg></Td>
                            <Td><Tdreg>N/A</Tdreg></Td>
                            <Td><Tdreg>{new Date(user.created_at).toLocaleString()}</Tdreg></Td>
                            </Tr>
                            ))}
                        </Tbody>
                      </Tablle>
                  </DesktopDiv>
                  {currentItems.map((user, index) => (
                    <Form3A style={{ padding: '20px 20px', borderRadius:'10px' }} key={index}>
                      <Title> 
                        <DivLeft style={{ marginBottom: '0' }}>{user.customer_name}</DivLeft>
                        <DivRight style={{ marginBottom: '0',cursor: 'pointer',transform: isOpenZ[index] ? 'rotate(180deg)' : 'rotate(0)', transition: '0.3s', }} onClick={() => toggleDropdown(index)}> 
                          <IoIosArrowDown />
                        </DivRight>
                      </Title>
                      {isOpenZ[index] && <DropdownContent>
                        <EmailContainer>
                        <DivContainer key={index} style={{cursor:'pointer', margin:'0', display:'block'}}>
                            <DivLeft>
                                <p>Name</p>
                              </DivLeft> 
                              <DivRight>
                                <p style={{wordBreak:'break-all'}}>{user?.customer_name}</p>
                              </DivRight>
                          </DivContainer>
                          <DivContainer key={index} style={{cursor:'pointer', display:'block', margin:'0'}}>
                            <DivLeft>
                                <p>Email</p>
                              </DivLeft> 
                              <DivRight style={{ textAlign:'end'}}>
                                <p style={{wordBreak:'break-all', fontSize:'13px', fontWeight:'bold'}}>{user.customer_email}</p>
                              </DivRight>
                          </DivContainer>
                          <DivContainer key={index} style={{cursor:'pointer', margin:'0', display:'block'}}>
                            <DivLeft>
                                <p>Phone Number</p>
                              </DivLeft> 
                              <DivRight>
                                <p style={{wordBreak:'break-all'}}>N/A</p>
                              </DivRight>
                          </DivContainer>
                          <DivContainer key={index} style={{cursor:'pointer', margin:'0', display:'block'}}>
                            <DivLeft>
                                <p>Shipping address</p>
                              </DivLeft> 
                              <DivRight>
                                <p style={{wordBreak:'break-all'}}>N/A</p>
                              </DivRight>
                          </DivContainer>
                          <DivContainer key={index} style={{cursor:'pointer', margin:'0', display:'block'}}>
                            <DivLeft>
                                <p>Product</p>
                              </DivLeft> 
                              <DivRight>
                                <p style={{wordBreak:'break-all'}}>N/A</p>
                              </DivRight>
                          </DivContainer>
                          <DivContainer key={index} style={{cursor:'pointer', margin:'0', display:'block'}}>
                            <DivLeft>
                                <p>Amount</p>
                              </DivLeft> 
                              <DivRight>
                                <p style={{wordBreak:'break-all'}}>N/A</p>
                              </DivRight>
                          </DivContainer>
                          <DivContainer key={index} style={{cursor:'pointer', margin:'0', display:'block'}}>
                            <DivLeft>
                                <p>Quantity</p>
                              </DivLeft> 
                              <DivRight>
                                <p style={{wordBreak:'break-all'}}>N/A</p>
                              </DivRight>
                          </DivContainer>
                          <DivContainer key={index} style={{cursor:'pointer', margin:'0', display:'block'}}>
                            <DivLeft>
                                <p>Transaction Type</p>
                              </DivLeft> 
                              <DivRight>
                                <p style={{wordBreak:'break-all'}}>N/A</p>
                              </DivRight>
                          </DivContainer>
                          <DivContainer key={index} style={{cursor:'pointer', margin:'0', display:'block'}}>
                            <DivLeft>
                                <p>Date added</p>
                              </DivLeft> 
                              <DivRight>
                                <p>{new Date(user?.created_at).toLocaleString('en-GB', {
                                    day: 'numeric',
                                    month: 'short',
                                    year: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour12: true
                                })}</p>
                              </DivRight>
                          </DivContainer>
                        </EmailContainer>
                      </DropdownContent>}
                    </Form3A>))}
                    <div>
                    <ul style={{display: 'flex', listStyle: 'none', margin:'10px'}}>
                    <button onClick={handlePrevPage} disabled={isFirstPage} style={{ cursor: isFirstPage ? 'not-allowed' : 'pointer', backgroundColor: isFirstPage ? '#8080808c' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                          <GrPrevious />
                        </button>
                        
                        {pageNumbers.map(number => (
                          <li key={number}>
                            <button id={number} onClick={handleClick} style={{ backgroundColor: currentPage === number ? '#FCF3FF' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                              {number}
                            </button>
                          </li> 
                        ))}
                        <button onClick={handleNextPage} disabled={isLastPage} style={{ cursor: isLastPage ? 'not-allowed' : 'pointer', backgroundColor: isLastPage ? '#8080808c' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                          <GrNext/>
                        </button> 
                      </ul>
                        
                    </div>
                    </> )}
                </CenterContent>
                ) : (
                        <div style={{justifyContent:'center', display: 'grid'}}>
                        <div style={{textAlign: 'center'}}>
                          <img src={Empty} alt="Empty table" />
                        </div>
                        <div style={{textAlign: 'center'}}>
                          <h5 style={{marginBottom: '10px'}}>You have no Customers</h5>
                        </div>
                        <div>
                        <div style={{display:'flex'}}>
                          <div style={{width: '100%', marginRight: '10px'}}>
                            <NavLink to='/create-paymentlink'> 
                                <PrimaryBtn2 style={{background: 'linear-gradient(180deg, #F3E8F7 0%, #EBDAF1 100%)',color: '#791FA4', borderRadius: '6px', width: '100%', fontSize: '14px'}}>Create Payment <br/> Link</PrimaryBtn2>
                            </NavLink>
                          </div>
                          <div style={{width: '100%'}}>
                            <NavLink to='/create-donationlink'>
                                <PrimaryBtn2 style={{backgroundColor: '#9316BF', borderRadius: '6px', width: '100%', fontSize: '14px'}}>Create Donation Link</PrimaryBtn2>
                            </NavLink>
                          </div>
                        </div>
                        </div>
                      </div>
                      )}
            </FormWrap>
  </P2pcontainer> ) : <Loader/>}
        </MainContent>
        </MainContainer>
    </>
  )
}

export default Customers