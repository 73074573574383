import React, {useState, useEffect} from 'react'
import { FaAngleDoubleRight, FaArrowRight } from 'react-icons/fa'
import { BiArrowBack } from 'react-icons/bi'
import {  Form2, Form3, FormContent, FormH1, FormH2, FormInput, FormLabel, Icon, PrimaryBtn2, Text, Text2, FormArea } from '../components/Form/formElements'
import Navbar from '../components/Navbar'
import { Content,H2, TextH2, ContentDiv, RoundedDiv2, P2pCard, P2pH, P2pT, PrimaryBtn, P2pInfo, P2pInfo2, DivLeft2, DivRight2, RoundedDiv3,SelectInput, InputArea,DivContainer, GridDiv, GridDivL, P2pGrid, TabText, PaymentCard, Tdreg1, SearchBar, TableRow, TableCell } from '../components/Dashboard/content/dashElements'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import axios from 'axios'
import { CancelBtn, EditBtn, P2pcontainer, P2pH1, P2pTable } from '../components/Dashboard/p2pElements/p2pElements'
import { NavLink } from 'react-router-dom'
import { Table as Tablle, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { CenterContent, Container, FormWrap, Tdreg, ThText } from '../components/Dashboard/content/dashElements';
import { useNavigate, useLocation } from 'react-router-dom'
import Loader from '../components/loader'
import Popup from 'reactjs-popup'
import Nothing from '../assets/nothingtoshow.png'
import Empty from '../assets/empty.svg'
import userEvent from '@testing-library/user-event'
import Pin from './pin'
import AlertModal from './modal'
import {MdOutlineReportProblem} from 'react-icons/md'
import styled from 'styled-components';
import Select from 'react-select';
import Sidebar1, { MainContainer, MainContent } from './nav'
import {GrPrevious, GrNext } from 'react-icons/gr';
import BASE_URL from '../apiConfig'
import Countdown from '../components/countdown'
import { ToastContainer, toast } from 'react-toastify'
import { DivPop, Icon9, PopupCard } from './topup'
import Hourglass from '../assets/Hourglass.png'
const AwaitingConfirmation = () => {
    const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
        <div style={{padding:'30px'}}> 
          <h3 style={{margin:'10px', textAlign: 'center'}}>Awaiting agent confirmation</h3>
            <div style={{margin: '20px', background: 'rgba(227, 180, 244, 0.46)', color:'#791FA4', fontSize: '12px', borderRadius: '6px', padding:'20px'}}>Some transactions may take longer time than  others, you can keep waiting in the background if this may be the case. Your Zeenah account will be topped up automatically once confirmed</div>
              <DivPop style={{textAlign: 'center', alignItems: 'center', marginBottom:'90px'}} >
              <p>Estimated Time: <b style={{color: '#791FA4'}}>15:00</b></p>
                <div style={{margin: '20px'}}>
                  <Icon9 src={Hourglass} />
                </div>
              </DivPop>
                <center>
                  <PrimaryBtn2  style={{backgroundColor: '#F3E8F7', borderRadius: '6px', width: '200px', textAlign:'center', color:'#4D0B6C'}} onClick={() => alert("Hi")} >Open Dispute</PrimaryBtn2>
                </center>
        </div>
    </>
  )
}

export default AwaitingConfirmation
