import React,{useState, useEffect, useRef} from 'react'
import Navbar from '../components/Navbar'
import axios from 'axios'
import USDT from '../assets/usdt.png'
import BTC from '../assets/btc.png'
import BCH from '../assets/bch.png'
import LTC from '../assets/LTC.png'
import bank1 from '../assets/bank1.png'
import { NavLink, useNavigate } from 'react-router-dom'
import AlertModal from './modal'
import Sidebar1, { MainContainer, MainContent } from './nav'
import BASE_URL from '../apiConfig'
import { ToastContainer, toast } from 'react-toastify'
import DePayWidgets from '@depay/widgets';
import { useLocation } from 'react-router-dom';
import { Container } from '../components/Dashboard/content/dashElements'

const Depay = () => {

    const location = useLocation();

    const [amount, setAmount] = useState('20');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [isOpen, setIsOpen] = useState(true);


    const handleAlertClose = () => {
        setShowAlert(false);
      };

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };
  
      DePayWidgets.Payment({
        accept: [
          { // 20 USDT on ethereum
            blockchain: 'ethereum',
            amount: amount,
            token: '0xdac17f958d2ee523a2206206994597c13d831ec7',
            receiver: '0x4e260bB2b25EC6F3A59B478fCDe5eD5B8D783B02'
          },{ // 20 BUSD on bsc
            blockchain: 'bsc',
            amount: amount,
            token: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
            receiver: '0x552C2a5a774CcaEeC036d41c983808E3c76477e6'
          },{ // 20 USDC on polygon
            blockchain: 'polygon',
            amount: amount,
            token: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
            receiver: '0x552C2a5a774CcaEeC036d41c983808E3c76477e6'
          }
        ],
          fee: {
            amount: '3%',
            receiver: '0x4e260bB2b25EC6F3A59B478fCDe5eD5B8D783B02'
          },
          style: {
              colors: {
                primary: '#4D0B6C',
                text: '#000',
                buttonText: '#fff',
                icons: '#4D0B6C'
              },
              fontFamily: '"Inter", sans-serif',
              fontFamily: '"Roboto", sans-serif',
              css: `
                @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
          
                .ReactDialogBackground {
                  background: rgba(0,0,0,0.8);
                }
              `
            },
            title: <div style={{color:'#4D0B6C', fontFamily:'Inter', fontSize:'34.21px', fontWeight:'700', lineHeight:'41.4px'}}>ZeenahPay</div>
      });
      
    return (
      <>
      {showAlert && (
          <AlertModal
            isModalOpen={showAlert}
            alertMessage={alertMessage}
            onClose={handleAlertClose}
          />
        )}
        <Navbar toggleSidebar={toggleSidebar}/>
          <MainContainer isOpen={isOpen}>
              <Sidebar1 isOpen={isOpen} toggleSidebar={toggleSidebar} />
              <MainContent>
          <Container>
          <ToastContainer />
              <DePayWidgets />
          </Container>
          </MainContent>
          </MainContainer>
      </>
    )
  }
  
  export default Depay