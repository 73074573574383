import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import BASE_URL from '../apiConfig'

const Test1 = () => {
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const transaction_id = 16;
  const token = localStorage.getItem('bearerToken');
  const [user, setUser] = useState(null);

  const fetchUser = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const getUser = async () => {
      const data = await fetchUser();
      setUser(data);
    };

    getUser();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(
        `${BASE_URL}/dispute/reply/${transaction_id}`,
        { reply: message },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Clear input field and fetch all messages
      setMessage('');
      fetchMessages();
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMessages = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/dispute/chats/${transaction_id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setMessages(response.data.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // Fetch all messages on initial render
    fetchMessages();

    // Poll server for new messages every 10 seconds
    const intervalId = setInterval(() => {
      fetchMessages();
    }, 10000);

    // Cleanup function to clear interval on unmount
    return () => clearInterval(intervalId);
  }, []);
  return (
    <Wrapper>
      <h2>Dispute</h2>

      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="message">Message:</label>
          <input
            type="text"
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <button type="submit">Send Message</button>
      </form>

      <MessageList>
        {messages.map((msg) => (
          <Message
            key={msg.id}
            isUserMessage={user?.data?.id == msg.user_id}
          >
            {msg.message}
          </Message>
        ))}
      </MessageList>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
`;

const MessageList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
`;

const Message = styled.li `
display: flex; align-items: center; margin-bottom: 10px; padding: 10px; border-radius: 10px; max-width: 70%; word-break: break-word; 
${({ isUserMessage }) => (isUserMessage ? 
`background-color: #9b59b6; color: white; justify-content: flex-end; margin-left: auto;` :
`background-color: #dcdcdc; color: black; justify-content: flex-start; margin-right: auto;`
)}`;

export default Test1