import React, {useState, useRef, useEffect} from 'react'
import { Container, Form, FormContent, FormH1, FormInput, FormLabel, FormWrap, Icon, PrimaryBtn, Text } from '../components/Form/formElements'
import SideBar from '../components/Sidebar'
import ZeenahPay from '../assets/ZeenahPay.png'
import ReactPasswordToggleIcon from 'react-password-toggle-icon';
import {FaEye, FaEyeSlash} from 'react-icons/fa'
import useAuth from '../hooks/useAuth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from '../api/axios'
import AlertModal from './modal';
import BASE_URL from '../apiConfig'


const ForgotPassword = () => {
    let inputRef = useRef();

    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('')
    const navigate = useNavigate();

    const userRef = useRef();
    const errRef = useRef();

    const [email, setEmail] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [buttonText, setButtonText] = useState('Submit');

    useEffect(() => {
      userRef.current.focus();
    }, [])
    
    const handleSubmit = async (e) => {
      setButtonDisabled(true);
        setButtonText('Submitting...')
      e.preventDefault();

      try {
        const response = await axios.post(`${BASE_URL}/forgot-password`,
            JSON.stringify({ email }),
            {
                headers: { 'Content-Type': 'application/json' }
            }
        );
        setAlertMessage(response.data.message);
        setShowAlert(true);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        navigate('/resetpassword')
    } catch (err) {
        setButtonDisabled(false)
        setButtonText('Submit')
        if (err.response) {
            setErrMsg(err.response.data.message)
        } else if (!err?.response) {
            setErrMsg('No server response');
        }
        errRef.current.focus();
    }
}
const handleAlertClose = () => {
    setShowAlert(false);
  };
  
  return (
    <>
        <SideBar />
        <Container>
            <FormWrap>
                <FormContent>
                    <Icon src={ZeenahPay}  width='160px'/>
                    <Form onSubmit={handleSubmit}>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive" style={{color: '#FF0000'}} >{errMsg}</p>
                        <FormH1 style={{marginTop: '-30px', marginBottom: '10px'}}>Forgot Password</FormH1>
                        <FormLabel htmlFor='email'>Email</FormLabel>
                        <FormInput id='email' placeholder='Johndoe@ZeenahPay.com' ref={userRef} type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                        <PrimaryBtn type='submit' disabled={buttonDisabled}>{buttonText}</PrimaryBtn>
                        <Text>No account yet? <a href='/signup' style={{color: '#4D0B6C', textDecoration:"none", textAlign:'center'}}>Register Here</a></Text>
                        <Text>Remeber password? <a href='/login' style={{color: '#4D0B6C', textDecoration:"none", textAlign:'center'}}>Login Here</a></Text>
                    </Form>
                </FormContent>
            </FormWrap>
        </Container>
        {showAlert && (
        <AlertModal
          isModalOpen={showAlert}
          alertMessage={alertMessage}
          onClose={handleAlertClose}
        />
      )}
    </>
  )
}

export default ForgotPassword