import React, { useState, useEffect } from 'react';
import { PrimaryBtn, SelectInput } from '../components/Dashboard/content/dashElements';
import { Form2, Form3, FormH1, FormH2, FormInput, FormLabel } from '../components/Form/formElements';
import axios from 'axios';
import Loader from '../components/loader';
import { useNavigate } from 'react-router-dom';
import Popup from 'reactjs-popup'
import AlertModal from './modal';
import BASE_URL from '../apiConfig'
import { ToastContainer, toast } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";

const PaymentForm = ({ paymentData }) => {
  const [currencies, setCurrencies] = useState([]);
  const [formData, setFormData] = useState([]);
  const [selectedCurrency, setSelectedCurrency] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const [gateway_id, setGatewayId] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(true);
    const [open2, setOpen2] = useState(false);
    const [dim, setDim] = useState(true);
    const [tradeType, setTradeType] = useState('Crypto')
    const token = localStorage.getItem('bearerToken');
    const [reference_id, setReferenceId] = useState('');
    const email = localStorage.getItem('email');
    const [shouldResend, setShouldResend] = useState(false);
    const [buttonText, setButtonText] = useState('Send code');
    const [buttonText2, setButtonText2] = useState('Save');
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [showText, setShowText] = useState(true);
    const [showCountDown, setShowCountDown] = useState(false);
    const [buttonDisabled2, setButtonDisabled2] = useState(false);
    const [otpCode, setOtpCode] = useState('')
    const [countDown, setCountdown] = useState(30);
    const [formIndex, setFormIndex] = useState('');
    
  

  let navigate = useNavigate()
  useEffect(() => {
    const token = localStorage.getItem('bearerToken');
    const fetchCurrencies = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/payment-method/sys-currencies`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setCurrencies(response.data.data);
      } catch (error) {
        setError(error);
      }
    };
    fetchCurrencies();
  }, []);

  useEffect(() => {
    let intervalId;
    if (countDown > 0) {
      setButtonDisabled2(true);
      intervalId = setInterval(() => setCountdown(countDown - 1), 1000);
    } else {
      setButtonDisabled2(false);
      clearInterval(intervalId);
      setShowText(true)
      setShowCountDown(false)
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [countDown]);

  useEffect(() => {
    const token = localStorage.getItem('bearerToken');
    const fetchPaymentMethods = async () => {
      try {
        if (!selectedCurrency) {
          setFormData([]);
          return;
        }
        setIsLoading(true);
        const response = await axios.get(`${BASE_URL}/payment-method/sys-currencies?currency=${selectedCurrency}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setFormData(response.data.data);
        setIsLoading(false);
      } catch (error) {
        setError(error);
        setIsLoading(false);
      }
    };
    fetchPaymentMethods();
  }, [selectedCurrency]);

  const handleInputChange = (index) => (e) => {
    const updatedFormData = [...formData];
    updatedFormData[index].payment_info[e.target.name] = e.target.value;
    setFormData(updatedFormData);
  };
  const handleCurrencyChange = (e) => {
    setSelectedCurrency(e.target.value);
  };
  const handleOtpCode = (e) => {
    setOtpCode(e.target.value)
}
  const postPaymentMethod = (event) => {
    event.preventDefault();
      formData[formIndex].payment_info.gateway_id = gateway_id;
    axios.post(
      `${BASE_URL}/payment-method`,
      formData[formIndex].payment_info,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .then(result => {
      toast.success('Payment method successfully added!', {
        position: toast.POSITION.TOP_CENTER
      });
      navigate('/settings/paymentmethod')
    })
      .catch(error => {
        const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
        setAlertMessage(jsonString);
        setShowAlert(true);
      })
  }

  if (isLoading) {
    return <Loader/>;
  }

  if (error) {
    return <p>An error occurred: {error.message}</p>;
  }
  const handleAlertClose = () => {
    setShowAlert(false);
    navigate("/dashboard")
  };
      const sendOtp = () => {
      axios.post(`${BASE_URL}/send-otp`,
            {
                email : email
            },
        {
            headers: {
            Authorization: `Bearer ${token}`,
            }
        }).then(response => {
          setReferenceId(response?.data?.data?.entity[0]?.reference_id);
          setShowCountDown(true)
          setShowText(false)
          setCountdown(30)
          // Start the countdown
          setShouldResend(true)
        })
        .catch(error => {
          showAlert(true);
          alertMessage(error)
          // Handle error
        });
    };

        const handleApi = (event) => {
          event.preventDefault();
          setButtonText2('Validating OTP...')
          setButtonDisabled(true)
          axios.post(`${BASE_URL}/validate-otp`, {
            reference_id: reference_id,
            code: otpCode
          },
          {
              headers: {
              Authorization: `Bearer ${token}`,
              }
          }).then(async (response) => {
            if (response.status >= 200 && response.status < 300) {
              toast.success(response.data.message, {
                position: toast.POSITION.TOP_CENTER
              });
              await new Promise((resolve) => setTimeout(resolve, 3000));
              postPaymentMethod(event)
            } else {
              toast.error('OTP not valid', {
                position: toast.POSITION.TOP_CENTER
              });
              setShowCountDown(false)
              setShowText(true)
              setButtonText2('Save');
              setButtonDisabled(false);
              setButtonDisabled2(false);
              setOtpCode('')
            }
          }).catch(error => {
              const jsonString = JSON.stringify((error)).replace(/[\[\]{}'"]+/g, '');
              toast.error(jsonString, {
                position: toast.POSITION.TOP_CENTER
              });
              setButtonDisabled(false)
              setButtonDisabled2(false)
              setShowCountDown(false)
              setShowText(true)
              setButtonText2('Save')
              setOtpCode('')
            })
        }

        {/*useEffect(() => {
          let intervalId;
          if (countDown > 0) {
            setButtonDisabled2(true);
            intervalId = setInterval(() => setCountdown(countDown - 1), 1000);
          } else {
            setButtonDisabled2(false);
            clearInterval(intervalId);
          }
          return () => {
            clearInterval(intervalId);
          };
        }, [countDown]); */}

        // const handleInputChange2 = (e) => {
        //   const parsedPaymentInfo = JSON.parse(e.target.value);
        //   // Update selectedPaymentMethod state
        //   setSelectedPaymentMethod(parsedPaymentInfo.method_name);
        //   // Update gatewayId state based on some condition (here, using paymentInfo.id)
        //   setGatewayId(parsedPaymentInfo.id);

        //   const existingPaymentMethodIndex = formData.findIndex(item => item.payment_info.gateway_id === parsedPaymentInfo.method_name);

        //   if (existingPaymentMethodIndex !== -1) {
        //     // If the selected payment method already exists, update its info
        //     const updatedFormDataCopy = [...formData];
        //     updatedFormDataCopy[existingPaymentMethodIndex].payment_info.gateway_id = {
        //       gateway_id: parsedPaymentInfo.id
        //     };
        //     setFormData(updatedFormDataCopy);
        //     console.log(formData[formIndex])
        //   } else {
        //     // If the selected payment method doesn't exist, add it to formData
        //     setFormData([...formData.payment_info.gateway_id , { gateway_id: parsedPaymentInfo.id }]);
        //     console.log(formData[formIndex])
        //   }
        // };

        const handleInputChange2 = (e) => {
          const parsedPaymentInfo = JSON.parse(e.target.value);
          setSelectedPaymentMethod(parsedPaymentInfo.method_name);
          setGatewayId(parsedPaymentInfo.id);
        };

  return (
    <>
    <Popup open={open1} onClose={() => { setOpen1(false); setDim(false); navigate(-1)}} modal closeOnDocumentClick contentStyle={{width: '100%', height: '100%', backgroundColor: 'white', overflow: 'scroll'}} overlayStyle={{background: dim ? 'rgba(0, 0, 0, 0.5)' : 'none'}}>
                        {close => (
                          <div className="content" >
                          <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><a className="close" onClick={close} style={{cursor: 'pointer'}} >
                        <b style={{fontSize: '26px', marginLeft: '10px', alignItems: 'center'}}>&times; | </b>
                        </a> <b>Add Beneficiary</b> </div>
      <ToastContainer />
      <Form3 style={{marginTop: '10%'}}>
        <FormH2 style={{marginTop:'-40px'}}>Add Payment Method</FormH2>
        <div style={{display: formData.length > 0 ? 'none' : 'block'}}>
          <FormLabel>Currency</FormLabel>
          <SelectInput onChange={handleCurrencyChange} style={{ width: '100%', marginRight:'5px', borderRadius:'12px' }}>
          <option>Select currency</option>
          {[...new Set(currencies.map(currency => currency.currency))].map((currency, index) => (
            <option key={index} value={currency}>
              {currency}
            </option>
          ))}
          </SelectInput>
        </div>
        {formData.length > 0 &&
          <>
        <div style={{display: 'flex'}}>
        <div>
          <FormLabel>Currency</FormLabel>
          <SelectInput id="currency" value={selectedCurrency} onChange={handleCurrencyChange} style={{ width: '100%', marginRight:'5px', borderRadius:'12px' }}>
          <option>Select currency</option>
          {[...new Set(currencies.map(currency => currency.currency))].map((currency, index) => (
            <option key={index} value={currency}>
              {currency}
            </option>
          ))}
          </SelectInput>
        </div>
          <div style={{paddingLeft:'20px'}}>
          
          <FormLabel>Beneficiaries</FormLabel>
          <SelectInput id="paymentMethod" value={selectedPaymentMethod} onChange={(e) => handleInputChange2(e)} style={{width: '100%', marginLeft:'5px', borderRadius:'12px' }}>
            <option>{selectedPaymentMethod ? selectedPaymentMethod : "Select method"}</option>
            {[...new Set(formData.map(paymentInfo => JSON.stringify(paymentInfo)))].map((paymentInfo, index) => {
              const parsedPaymentInfo = JSON.parse(paymentInfo);
              return (
                <option key={index} value={paymentInfo}>
                  {parsedPaymentInfo.method_name}
                </option>
              );
            })}
          </SelectInput>
          </div> 
        </div>
        </>}
        {formData.length > 0 && selectedPaymentMethod && (
  <div>
    {/*<FormH2 style={{ marginTop: '' }}>{selectedPaymentMethod}</FormH2>*/}
    {formData.map((paymentInfo, index) => {
      if (paymentInfo.payment_info.method_name === selectedPaymentMethod) {
        return (
          <div key={paymentInfo.payment_info.method_name}>
            {Object.keys(paymentInfo.payment_info).map((key) => (
              setFormIndex(index),
              key !== 'country' && key !== 'method_name' && key !== 'currency' && (
                <div key={key}>
                  <FormLabel style={{ textTransform: 'capitalize' }} htmlFor={key}>
                    {key.replace(/_/g, ' ')}:
                  </FormLabel>
                  <FormInput
                    style={{ width: '100%' }}
                    type="text"
                    name={key}
                    id={key}
                    onChange={handleInputChange(index)}
                  />
                </div>
              )
            ))}
            <FormLabel>OTP</FormLabel>
            <div style={{ display: 'flex' }}>
            <div style={{ position: 'relative', flex: 1 }}>
              <FormInput
                style={{ width: '100%' }}
                type='text'
                value={otpCode}
                onChange={handleOtpCode}
              />
              {showText && <span style={{ position: 'absolute', right: 0, padding: '15px 10px',color:'#4D0B6C', fontFamily:'poppins', fontWeight:'600', fontSize:'16px', lineHeight:'24px', cursor: 'pointer' }} onClick={sendOtp}>
                {buttonText}
              </span>}
              {showCountDown && countDown > 0 && <span style={{ position: 'absolute', right: 0, padding: '15px 10px',color:'#4D0B6C', fontFamily:'poppins', fontWeight:'600', fontSize:'16px', lineHeight:'24px', cursor:'not-allowed'}}>
                { (<span>Resend O.T.P in <a style={{color: '#791FA4'}}>{Math.floor(countDown / 60)}:{(countDown % 60).toString().padStart(2, '0')}</a></span>)} 
              </span>}
            </div>
          </div>
            <PrimaryBtn style={{ width: '100%' }} onClick={handleApi} disabled={buttonDisabled}>
              {buttonText2}
            </PrimaryBtn>
          </div>
        );
      }
      return null;
    })}
  </div>
)}

      </Form3>
      </div>)}
                      </Popup>
                      {showAlert && (
        <AlertModal
          isModalOpen={showAlert}
          alertMessage={alertMessage}
          onClose={handleAlertClose}
        />
      )}
    </>
  );
};

export default PaymentForm
