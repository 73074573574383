import { useState, useEffect } from 'react';
import axios from 'axios';
import BASE_URL from '../apiConfig'

const Countdown = ({ endDate, trnx }) => {
  const [timeRemaining, setTimeRemaining] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date().getTime();
      const distance = endDate - now;

      if (distance <= 0) {
        // Countdown has ended
        clearInterval(interval);
        setTimeRemaining(<div style={{color:'red', fontWeight: '700', lineHeight:'19.36px', fontSize:'16px'}}>Expired</div>);
        // Send POST request when expired
        axios
          .post(`${BASE_URL}/trade/trade-status`, {
            trade: trnx.id,
            status: 'failed',
          })
          .then(response => {
            // Handle success if needed
          })
          .catch(error => {
            // Handle error if needed
          });
      } else {
        // Calculate remaining time
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);
        setTimeRemaining(<div style={{color:'#791FA4', fontWeight: '700', lineHeight:'19.36px', fontSize:'16px'}}>{minutes}:{seconds.toString().padStart(2, '0')}</div>);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [endDate, trnx]);

  return <span>{timeRemaining}</span>;
};

export default Countdown;