import React, {useState, useEffect} from 'react'
import { FaAngleDoubleRight, FaArrowRight } from 'react-icons/fa'
import {  Form2, Form3, FormContent, FormH1, FormH2, FormInput, FormLabel, Icon, PrimaryBtn2, Text, Text2, FormArea } from '../components/Form/formElements'
import Navbar from '../components/Navbar'
import { Content,H2, TextH2, ContentDiv, RoundedDiv2, P2pCard, P2pH, P2pT, PrimaryBtn, P2pInfo, P2pInfo2, DivLeft2, DivRight2, RoundedDiv3,SelectInput, InputArea,DivContainer, GridDiv, GridDivL, P2pGrid, TabText, PaymentCard, Tdreg1, SearchBar } from '../components/Dashboard/content/dashElements'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import axios from 'axios'
import { CancelBtn, EditBtn, P2pcontainer, P2pH1, P2pTable } from '../components/Dashboard/p2pElements/p2pElements'
import { NavLink } from 'react-router-dom'
import { Table as Tablle, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { CenterContent, Container, FormWrap, Tdreg, ThText } from '../components/Dashboard/content/dashElements';
import { useNavigate } from 'react-router-dom'
import Loader from '../components/loader'
import Popup from 'reactjs-popup'
import Nothing from '../assets/nothingtoshow.png'
import Empty from '../assets/empty.svg'
import userEvent from '@testing-library/user-event'
import Pin from './pin'
import AlertModal from './modal'
import {MdOutlineReportProblem} from 'react-icons/md'
import styled from 'styled-components';
import Select from 'react-select';
import Sidebar1, { MainContainer, MainContent } from './nav'
import {GrPrevious, GrNext } from 'react-icons/gr';
import BASE_URL from '../apiConfig'

const Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopupCard = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 80%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  border: none;
  background: transparent;
`;
const DivPop = styled.div`
    height: 150px;
    @media screen and (max-width: 580px) {
        height: 195px;
    }
`


const Container1 = styled.div`
  display: flex;
  margin-top: 20px;

`;

const Select1 = styled.select`
padding: 16px 16px;
margin-bottom: 32px;
border: 1px solid #979797;
border-radius: 6px;
background-color: #4D0B6C;
color: #fff;
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  outline: none;
`;

const Input = styled.input`
  padding: 16px 16px;
  margin-bottom: 32px;
  border: 1px solid #979797;
  border-radius: 6px;
  background-color: transparent;
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  outline: none;
  width:100%;
  @media screen and (max-width: 580px) {
        width: 80%;
    }
`;

const P2pClosed = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
    const [deleted, setDeleted] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);



  const handleAlertClose = () => {
    setShowAlert(false);
  };

    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [dim, setDim] = useState(false);

    const token = localStorage.getItem('bearerToken');
    const [user, setUser] = useState(null);

    const fetchUser = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        return response.data;
      } catch (error) {
        console.error(error);
      }
    };
    useEffect(() => {
      const getUser = async () => {
        const data = await fetchUser();
        setUser(data);
      };
  
      getUser();
    }, []);

    const fetchDeleted = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/trade/deleted`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
    
          return response.data;
        } catch (error) {
          console.error(error);
        }
      };
      useEffect(() => {
        const getDeleted = async () => {
          const data = await fetchDeleted();
          setDeleted(data);
        };
    
        getDeleted();
      }, []);


    const [isEmpty, setIsEmpty] = useState(false);
      const [searchQuery, setSearchQuery] = useState('');
      const filteredData = deleted?.data.data.filter((del) =>
      del.tradeType.toLowerCase().includes(searchQuery.toLowerCase())||
      del.assetName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      del.trade_currency.toLowerCase().includes(searchQuery.toLowerCase()) ||
      del.totalAmount.toLowerCase().includes(searchQuery.toLowerCase()) ||
      del.min_amount.toLowerCase().includes(searchQuery.toLowerCase()) ||
      del.max_amount.toLowerCase().includes(searchQuery.toLowerCase()) ||
      del.trade_currency.toLowerCase().includes(searchQuery.toLowerCase()) ||
      del.marginPrice.toLowerCase().includes(searchQuery.toLowerCase()) ||
      del.paymentMethod.toLowerCase().includes(searchQuery.toLowerCase()) ||
      new Date(del.deleted_at).toLocaleString().toLowerCase().includes(searchQuery.toLowerCase())
      )

      const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // reset to first page when search query changes
        setIsEmpty(filteredData.length === 0);
        if (searchQuery === 0) {
          setSearchQuery(null);
        }
      };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = deleted?.data.data.slice(indexOfFirstItem, indexOfLastItem);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(deleted?.data.data.length / itemsPerPage); i++) {
      pageNumbers.push(i);
    }

   const handlePrevPage = () => {
      setCurrentPage( currentPage - 1);
    };
   const handleNextPage = () => {
      setCurrentPage(currentPage + 1);
    };
    const handleClick = e => {
      setCurrentPage(Number(e.target.id));
    };

    const isLastPage = currentPage === pageNumbers.length;
    const isFirstPage = currentPage === 1;
  return (
    <>
        <Navbar toggleSidebar={toggleSidebar}/>
        <MainContainer isOpen={isOpen}>
            <Sidebar1 isOpen={isOpen} toggleSidebar={toggleSidebar} />
            <MainContent>
        <Container style={{backgroundColor: '#fff', borderRadius:'6px'}}>
            <FormWrap>
            <div style={{marginLeft: '20px', marginTop:'10px'}}>
              <h2>Closed Ads</h2>
              <div style={{position:"flex", marginTop: '10px', width: '100%'}}>
                  <SearchBar type="text" value={searchQuery} onChange={handleSearchInputChange} placeholder='search closed ads'  />
              </div>
            </div>
    {deleted ? (
    <P2pcontainer>
    <PrimaryBtn Link style={{float: 'right'}}><NavLink to='/create-ad' style={{textDecoration: 'none', color: 'white'}}>Create AD</NavLink></PrimaryBtn>
    <FormWrap>
    {deleted.data.data.length  ? (
                <CenterContent>
                {isEmpty ? (
                  <div style={{justifyContent:'center', display: 'grid'}}>
                        <div style={{textAlign: 'center'}}>
                          <img src={Nothing} alt="Empty table" />
                        </div>
                        <div style={{textAlign: 'center'}}>
                          <h5 style={{marginBottom: '10px'}}>No search results found</h5>
                        </div>
                        <div>
                        </div>
                      </div>
                ) : (
                  <>
                  <Tablle style={{ textAlign: 'left', borderCollapse: 'collapse'}}>
                        <Thead style={{ backgroundColor: "#F3E8F7",
  color: "#1E1E1E",
  fontWeight: "600",
  lineHeight:"21px"}}>
                            <Tr>
                            <Th><ThText>Order</ThText></Th>
                            <Th><ThText>Amount</ThText></Th>
                            <Th><ThText>Limit</ThText></Th>
                            <Th><ThText>Profit</ThText></Th>
                            <Th><ThText>Payment Method</ThText></Th>
                            <Th><ThText>Deleted At</ThText></Th>
                            </Tr>
                        </Thead>
                        
                        <Tbody>
                            {currentItems.map((del, index) => (
                            <Tr key={index} style={{borderBottom: '1px solid #ddd'}}>
                            <Td><Tdreg><P2pH1>{del.tradeType} {del.assetName}</P2pH1></Tdreg></Td>
                            <Td><Tdreg>{del.trade_currency} {del.totalAmount}</Tdreg></Td>
                            <Td><Tdreg>{del.min_amount} ~ {del.max_amount}</Tdreg></Td>
                            <Td><Tdreg>{del.trade_currency} {del.marginPrice}</Tdreg></Td>
                            <Td><Tdreg>{del.paymentMethod}</Tdreg></Td>
                            <Td><Tdreg>{new Date(del.deleted_at).toLocaleString()}</Tdreg></Td>
                            </Tr> ))}
                        </Tbody>
                    </Tablle>
                    <div>
                    <ul style={{display: 'flex', listStyle: 'none', margin:'10px'}}>
                    <button onClick={handlePrevPage} disabled={isFirstPage} style={{ cursor: isFirstPage ? 'not-allowed' : 'pointer', backgroundColor: isFirstPage ? '#8080808c' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                          <GrPrevious />
                        </button>
                        
                        {pageNumbers.map(number => (
                          <li key={number}>
                            <button id={number} onClick={handleClick} style={{ backgroundColor: currentPage === number ? '#FCF3FF' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                              {number}
                            </button>
                          </li> 
                        ))}
                        <button onClick={handleNextPage} disabled={isLastPage} style={{ cursor: isLastPage ? 'not-allowed' : 'pointer', backgroundColor: isLastPage ? '#8080808c' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                          <GrNext/>
                        </button> 
                      </ul>
                    </div>
                    </> )}
                </CenterContent>
) : (
                        <div style={{justifyContent:'center', display: 'grid'}}>
                        <div>
                          <img src={Empty} alt="Empty table" />
                        </div>
                        <div style={{textAlign: 'center'}}>
                          <h4 style={{marginBottom: '10px'}}>Nothing to display</h4>
                        </div>
                        <div>
                            <NavLink to='/create-ad'> 
                                <PrimaryBtn style={{width: '100%'}} Link>Create AD</PrimaryBtn>
                            </NavLink>
                        </div>
                      </div>
                      )}
            </FormWrap>
  </P2pcontainer> ) : <Loader/>}
                
            </FormWrap>
        </Container>
        </MainContent>
        </MainContainer>
        {showAlert && (
        <AlertModal
          isModalOpen={showAlert}
          alertMessage={alertMessage}
          onClose={handleAlertClose}
        />
      )}
    </>
  )
}

export default P2pClosed