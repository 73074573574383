import React, {useState, useEffect, useRef} from 'react'
import { Container , Content, FormWrap,H2, TextH2, ContentDiv, RoundedDiv, DivContainer, DivLeft, DivRight, PrimaryBtn, Image, NonMobile, MobileOnly, DivContainer2 } from '../components/Dashboard/content/dashElements'
import DashboardSidebar from '../components/Dashboard/sidebar'
import Navbar from '../components/Navbar'
import Usdt from '../assets/usdt.png'
import Busd from '../assets/busd.png'
import Zeenah from '../assets/Zeenah.png'
import Btc from '../assets/btc.png'
import Nothing from '../assets/nothingtoshow.png'
import axios from 'axios'
import Loader from '../components/loader'
import { NavLink } from 'react-router-dom'
import styled from "styled-components";
import AuthCode from 'react-auth-code-input';
import ZeenahPay from '../assets/ZeenahPay.png'
import  {useNavigate}  from 'react-router-dom'
import { Form, FormContent, FormH1, Icon, Text, PrimaryBtn as PrimaryBtn1 } from '../components/Form/formElements'
import AlertModal from './modal'
import Sidebar1, { MainContainer, MainContent } from './nav'
import BASE_URL from '../apiConfig'
import ReactPasswordToggleIcon from 'react-password-toggle-icon';
import {FaEye, FaEyeSlash} from 'react-icons/fa'
import MobileDeposit from '../assets/mobileDeposit.svg'
import MobileSend from '../assets/mobileSend.svg'
import MobileSwap from '../assets/mobileSwap.svg'

 const Div1 = styled.div`
    margin-bottom: 20px;
    margin-left: 80px;
    width: 100%;

    @media screen and (max-width: 600px) {
        margin-bottom: 20px;
        margin-left: 50px;
        width: 100%;
    }
    `

const Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopupCard = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  border: none;
  background: transparent;
`;
const DivPop = styled.div`
    height: 150px;
    @media screen and (max-width: 580px) {
        height: 195px;
    }
`

const Dashboard = () => {
  const [user, setUser] = useState(null);
  const [balance, setBalance] = useState(null);
  const [totalbalance, setTotalBalance] = useState(null);
  const [totalcryptobalance, setTotalCryptoBalance] = useState(null);
  const [totalfiatbalance, setTotalFiatBalance] = useState(null);
  const token = localStorage.getItem('bearerToken');
  const [showPopup, setShowPopup] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const showIcon = () => <FaEye aria-hidden="true" />;
  const hideIcon = () => <FaEyeSlash aria-hidden="true" />
  let inputRef = useRef();


  const fetchUser = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  const fetchBalance = async () => {
    const response = await axios.get(`${BASE_URL}/account/balance`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  useEffect(() => {
    const getUser = async () => {
      const [data, balance]= await Promise.all([fetchUser(), fetchBalance()]);
      setUser(data);
      setBalance(balance);
    };

    getUser();
  }, []);

  const fetchTotalBalance = async () => {
    const response = await axios.get(`${BASE_URL}/account/total-balance`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  useEffect(() => {
    const getTotalBal = async () => {
      const data = await  fetchTotalBalance();
      setTotalBalance(data);
    };

    getTotalBal();
  }, []);
  const fetchTotalCryptoBalance = async () => {
    const response = await axios.get(`${BASE_URL}/account/total-balance?type=crypto`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  useEffect(() => {
    const getTotalCryptoBal = async () => {
      const data = await  fetchTotalCryptoBalance();
      setTotalCryptoBalance(data);
    };

    getTotalCryptoBal();
  }, []);

  const fetchTotalFiatBalance = async () => {
    const response = await axios.get(`${BASE_URL}/account/total-balance?type=fiat`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  };

  useEffect(() => {
    const getTotalFiatBal = async () => {
      const data = await  fetchTotalFiatBalance();
      setTotalFiatBalance(data);
    };

    getTotalFiatBal();
  }, []);

  const [result, setResult] = useState();
  const [result2, setResult2] = useState();
    const handleOnChange = (res) => {
    setResult(res);
    }
    const handleOnChange2 = (res) => {
      if (result !== res) {
        setErrorPassword(true);
      } else {
        setErrorPassword(false);
      }
    setResult2(res);
    }
    const navigate = useNavigate();

  
    const handleApi = (event) => {
      event.preventDefault();
      if (!errorPassword){
        axios.post(`${BASE_URL}/pin/update`, {
          pin: result
        },
        {
            headers: {
            Authorization: `Bearer ${token}`,
            }
        }).then(async (result) => {
          setAlertMessage(result.data.message);
          setShowAlert(true);
          await new Promise((resolve) => setTimeout(resolve, 3000));
          navigate(0)
        })
          .catch(error => {
            setAlertMessage('Pin Not Set!');
            setShowAlert(true);
          })
      }else{
        setAlertMessage('both pins must match');
        setAlertMessage(true)
      }
      
    }
    const handleAlertClose = () => {
      setShowAlert(false);
    };
    const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleRowClick = (currency, bal, type, ledger_balance) => {
    navigate("/dashboard/wallet", { state: { currency: currency, bal: bal, type: type, ledger_balance: ledger_balance} });
  };

  const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => { setPasswordShown(!passwordShown);};
    const [showBalance, setShowBalance] = useState(true);
    const [totoBal, setTotoBal] = useState('$176,000.40');
    const validateCPassword = () => {
      if (result !== result2) {
        setErrorPassword(true);
      } else {
        setErrorPassword(false);
      }
    }
  return user && user?.data?.is_pin_set == 0 ? (
    <>
     {showPopup && (
       <Background>
         <PopupCard>
           <FormContent>
                    <Icon src={ZeenahPay}  width='160px'/>
                    <Form onSubmit={handleApi}>
                        <FormH1 style={{marginTop:'-70px'}}>Set Pin</FormH1>
                        <Text style={{marginBottom: '-20px'}}>Set your 4 digit Transaction Pin below</Text>
                        <Div1 style={{marginBottom: '20px'}}>
                            <AuthCode allowedCharacters='numeric' onChange={handleOnChange} isPassword={true} length={4} containerClassName={'otpContainer'} inputClassName={'otpInput'}/>          
                        </Div1>
                        <Text style={{marginBottom: '-20px'}}>Confirm</Text>
                        <Div1 style={{marginBottom: '20px'}}>
                            <AuthCode allowedCharacters='numeric' onChange={handleOnChange2} isPassword={true} length={4} containerClassName={'otpContainer'} inputClassName={'otpInput'} onBlur={validateCPassword}/>          
                        </Div1>
                        {errorPassword && <div style={{fontSize: '10px', color: '#FF0000', marginBottom: '20px', marginLeft: '10px', textAlign:'center'}}>Pin must match!</div>}
                        <PrimaryBtn1 style={{ backgroundColor: errorPassword ? "grey" : "#4D0B6C", cursor: errorPassword ? "not-allowed" : "pointer" }} disabled={errorPassword}>submit</PrimaryBtn1>

                    </Form>
                </FormContent>
         </PopupCard>
       </Background>
     )}
     {/* Add a button to show the popup */}
   </>
 ) : (
    <>
    <Navbar toggleSidebar={toggleSidebar}/>
        <MainContainer isOpen={isOpen}>
            <Sidebar1 isOpen={isOpen} toggleSidebar={toggleSidebar} />
            <MainContent>
        <Container>
        {user ? (
            <FormWrap> 
                <NonMobile>
                  <div style={{marginBottom: '20px', marginLeft: '30px'}}>
                  <H2>Welcome, {user?.data?.firstName} {user?.data?.lastName}</H2>
                  <p>{user?.data?.businessName}</p>
                </div>
                <Content style={{marginLeft: '0px', marginTop:'-20px', gridTemplateColumns:'1fr'}}>
                        <ContentDiv>
                            <RoundedDiv>
                                <DivContainer><DivLeft style={{marginBottom:'0'}}><TextH2>Balance</TextH2></DivLeft></DivContainer>
                                {totalbalance ? (<DivLeft style={{height: 'auto', marginTop:'10px', marginLeft:'20px'}}>
                                <div style={{display:'block'}}>
                                  <TextH2 style={{fontSize: '32px', color: '#7416a0', fontWeight: 'bold'}}> $ {totalbalance}</TextH2>
                                  <p style={{fontSize: '14px', color: '#6C6363', textAlign: 'start'}}>Available Total balance</p>
                                </div>
                                </DivLeft>) : ('')}
                                <DivContainer style={{border: 'none'}}><DivLeft><span>Assets</span></DivLeft><DivRight><PrimaryBtn onClick={() => {navigate("/topup", { state: { currency: 'USD' } })}}>Topup</PrimaryBtn></DivRight></DivContainer>
                            </RoundedDiv>
                        </ContentDiv>
                        <ContentDiv>
                        <RoundedDiv>
                                { balance?.data?.filter(bal => bal.balance_type === 'fiat').sort((a, b) => {
                                  // Define the desired order of fiat currencies
                                  const order = ['CLP', 'USD','EUR','GBP', 'NGN'];
                                  
                                  // Get the index of the currency in the order list
                                  const indexA = order.indexOf(a.ticker_name);
                                  const indexB = order.indexOf(b.ticker_name);
                                  
                                  // Compare the indices to sort the currencies accordingly
                                  return indexA - indexB;
                                }).map((bal, index) => (
                                <DivContainer key={index} onClick={() => {handleRowClick(bal.ticker_name, bal.balance, bal.balance_type, bal.ledger_balance)}} style={{cursor:'pointer', paddingRight:'20px'}}>
                                  <DivLeft>
                                    <img src={bal.ticker_icon} alt={bal.ticker_name} style={{width: '50px', height: '50px', borderRadius: '50%', border:'1px solid #4D0B6C', objectFit: 'cover'}} />
                                      <div style={{textAlign: 'left', marginLeft: '20px'}}>
                                        <h3>{bal.ticker_name}</h3>
                                        <p>{bal.ticker_full_name}</p>
                                        <p style={{ fontSize:'12px'}}>Ledger Bal: </p>
                                      </div>
                                  </DivLeft> 
                                  <DivRight style={{display: 'grid'}}>
                                    <p style={{marginRight: '10px', textAlign: 'center', color:'#4D0B6C'}}>{bal?.ticker_name} {Number(bal?.balance).toFixed(2)}</p>
                                    <p style={{marginTop:'-20px', textAlign: 'center', fontSize:'12px'}}>{bal?.ticker_name} {Number(bal?.ledger_balance).toFixed(2)}</p>
                                  </DivRight>
                                </DivContainer>
                                ) ) }
                                {totalfiatbalance ? (<DivContainer><DivLeft><div style={{textAlign: 'left', marginLeft: '20px'}}><h4>Total Fiat Balance</h4></div></DivLeft> <DivRight><h3 style={{marginRight: '10px', textAlign: 'center', marginBottom: '20px', color: '#7416a0', fontWeight: 'bolder'}}>{totalfiatbalance ? (`$${Number(totalfiatbalance).toFixed(2)}`) : (<p style={{fontSize: '14px'}}>not available at the moment</p>)}</h3></DivRight></DivContainer>) : ('')}
                        </RoundedDiv>
                        <RoundedDiv>
                        { balance?.data?.filter(bal => bal?.balance_type === 'crypto').map((bal, index) => (
                                <DivContainer key={index} onClick={() => {handleRowClick(bal.ticker_name, bal.balance, bal.balance_type, bal.ledger_balance)}} style={{cursor:'pointer'}}>
                                  <DivLeft>
                                    <Image src={bal.ticker_icon} alt={bal.ticker_name} />
                                    <div style={{textAlign: 'left', marginLeft: '20px'}}>
                                      <span><h3>{bal?.ticker_name}</h3><p>{bal?.ticker_full_name}</p></span>
                                      
                                      <p style={{ fontSize:'12px'}}>Ledger Bal: </p>
                                    </div>
                                  </DivLeft> 
                                  <DivRight style={{display: 'grid'}}>
                                    <p style={{marginRight: '10px', textAlign: 'center', color:'#4D0B6C'}}>{bal?.ticker_name} {bal?.balance}</p>
                                    <p style={{marginTop:'-40px', textAlign: 'center', fontSize:'12px'}}>{bal?.ticker_name} {bal?.ledger_balance}</p>
                                  </DivRight>
                                </DivContainer>
                                ) ) }
                                {totalcryptobalance ? (<DivContainer><DivLeft><div style={{textAlign: 'left', marginLeft: '20px'}}><h4>Total Crypto Balance</h4></div></DivLeft> <DivRight><h3 style={{marginRight: '10px', textAlign: 'center', marginBottom: '20px', color: '#7416a0', fontWeight: 'bolder'}}>{totalcryptobalance ? (`$${Number(totalcryptobalance).toFixed(8)}`) : (<p style={{fontSize: '14px'}}>not available at the moment</p>)}</h3></DivRight></DivContainer>) : ('')}
                        </RoundedDiv>
                        </ContentDiv>
                    </Content>
                </NonMobile>
                <MobileOnly>
                <Content style={{marginLeft: '0px', marginTop:'-20px', gridTemplateColumns:'1fr'}}>
                        <ContentDiv>
                            <RoundedDiv>
                                <DivContainer style={{display:'block'}}>
                                  <div style={{display:'flex', marginLeft:'10px'}}>
                                    <span style={{marginBottom:'10p', color:'#595959',fontSize:'16px', fontWeight:'400', lineHeight:'24px', marginRight:'15px'}}>Total Balance (USD)</span>
                                    <span onClick={() => setShowBalance(!showBalance)} style={{display:'flex'}}><ReactPasswordToggleIcon inputRef={inputRef} showIcon={showIcon} hideIcon={hideIcon} style={{height: 'auto', position:'inherit'}}/></span>
                                  </div>
                                  <DivLeft style={{height: 'auto'}}>
                                <TextH2 style={{fontSize: '32px', color: '#7416a0', fontWeight: 'bold'}} ref={inputRef}>{showBalance ? `${Number(totalbalance)}` : '******'}</TextH2>
                                </DivLeft>
                                </DivContainer>
                                  <div style={{display: 'flex', justifyContent: 'center', marginBottom: '20px', alignItems: 'center'}}>
                                      <img src={MobileDeposit} style={{ marginRight: '8px', cursor: 'pointer' }} onClick={() => {navigate("/topup", { state: { currency: 'USD' } })}} />
                                      <img src={MobileSwap} style={{ margin: '0 8px', cursor: 'pointer' }} onClick={() => {navigate("/swap", { state: { currency: 'USD' } })}} />
                                      <img src={MobileSend} style={{ marginLeft: '8px', cursor: 'pointer' }} onClick={() => {navigate("/payout", { state: { currency: 'USD' } })}}/>
                                  </div>
                            </RoundedDiv>
                        </ContentDiv>
                        <ContentDiv>
                        <RoundedDiv>
                                { balance?.data?.filter(bal => bal.balance_type === 'fiat').sort((a, b) => {
                                  // Define the desired order of fiat currencies
                                  const order = ['CLP', 'USD','EUR','GBP', 'NGN'];
                                  
                                  // Get the index of the currency in the order list
                                  const indexA = order.indexOf(a.ticker_name);
                                  const indexB = order.indexOf(b.ticker_name);
                                  
                                  // Compare the indices to sort the currencies accordingly
                                  return indexA - indexB;
                                }).map((bal, index) => (
                                <DivContainer2 key={index} onClick={() => {handleRowClick(bal.ticker_name, bal.balance, bal.balance_type, bal.ledger_balance)}} style={{cursor:'pointer', paddingRight:'10px'}}>
                                  <DivLeft>
                                    <img src={bal.ticker_icon} alt={bal.ticker_name} style={{width: '50px', height: '50px', borderRadius: '50%', border:'1px solid #4D0B6C', objectFit: 'cover'}} />
                                    <div style={{textAlign: 'left', marginLeft: '20px'}}>
                                      <h3 style={{color:'#1E1E1E', lineHeight:'24px', fontWeight:'500', fontSize:'16px'}}>{bal?.ticker_name}</h3>
                                      <p style={{color:'#898989', lineHeight:'21px', fontWeight:'400', fontSize:'14px'}}>{bal?.ticker_full_name}</p>
                                      <p style={{color:'#595959', lineHeight:'21px', fontWeight:'400', fontSize:'14px'}}>Ledger Bal: </p>
                                    </div>
                                  </DivLeft> 
                                  <DivRight style={{display: 'grid'}}>
                                    <p style={{ textAlign: 'right', color:'#1E1E1E', lineHeight:'24px', fontWeight:'600', fontSize:'16px'}}>{Number(bal?.balance).toFixed(2)}</p>
                                    <p style={{color:'transparent'}}>0 </p>
                                    <p style={{ textAlign: 'right', fontSize:'14px', color:'#595959', fontWeight:'600', lineHeight:'21px'}}>{Number(bal?.ledger_balance).toFixed(2)}</p>
                                  </DivRight>
                                </DivContainer2>
                                ) ) }
                                { balance?.data?.filter(bal => bal?.balance_type === 'crypto').map((bal, index) => (
                                <DivContainer2 key={index} onClick={() => {handleRowClick(bal.ticker_name, bal.balance, bal.balance_type, bal.ledger_balance)}} style={{cursor:'pointer'}}>
                                  <DivLeft>
                                    <Image src={bal.ticker_icon} alt={bal.ticker_name} />
                                    <div style={{textAlign: 'left', marginLeft: '20px'}}>
                                      <h3 style={{color:'#1E1E1E', lineHeight:'24px', fontWeight:'500', fontSize:'16px'}}>{bal?.ticker_name}</h3>
                                      <p style={{color:'#898989', lineHeight:'21px', fontWeight:'400', fontSize:'14px'}}>{bal?.ticker_full_name}</p>
                                      <p style={{color:'#595959', lineHeight:'21px', fontWeight:'400', fontSize:'14px'}}>Ledger Bal: </p>
                                    </div>
                                  </DivLeft> 
                                  <DivRight style={{display: 'grid'}}>
                                    <p style={{marginRight: '10px', textAlign: 'right', color:'#1E1E1E', lineHeight:'24px', fontWeight:'600', fontSize:'16px'}}>{bal?.balance}</p>
                                    <p style={{color:'transparent'}}>0 </p>
                                    <p style={{marginRight: '10px', textAlign: 'right', fontSize:'14px', color:'#595959', fontWeight:'600', lineHeight:'21px'}}>{bal?.ledger_balance}</p>
                                  </DivRight>
                                </DivContainer2>
                                ) ) }
                                {totalfiatbalance?.data?.balance ? (<DivContainer><DivLeft><div style={{textAlign: 'left', marginLeft: '20px'}}><h4>Total Fiat Balance</h4></div></DivLeft> <DivRight><h3 style={{marginRight: '10px', textAlign: 'center', marginBottom: '20px', color: '#7416a0', fontWeight: 'bolder'}}>{totalfiatbalance?.data?.currency} {totalfiatbalance?.data?.balance ? (`${totalfiatbalance.data.balance}`) : (<p style={{fontSize: '14px'}}>not available at the moment</p>)}</h3></DivRight></DivContainer>) : ('')}
                        </RoundedDiv>
                        </ContentDiv>
                </Content>
                </MobileOnly>
            </FormWrap>
            ) : <Loader/> }
        </Container>
        </MainContent>
        </MainContainer>
        {showAlert && (
        <AlertModal
          isModalOpen={showAlert}
          alertMessage={alertMessage}
          onClose={handleAlertClose}
        />
      )}
    </>
  )
}

export default Dashboard