import React, {useState, useRef, useMemo} from 'react'
import  {useLocation, useNavigate}  from 'react-router-dom'
import { Container, Form, FormContent, FormH1, FormInput, FormLabel, FormWrap, Icon, PrimaryBtn, Text } from '../components/Form/formElements'
import SideBar from '../components/Sidebar'
import ZeenahPay from '../assets/ZeenahPay.png'
import axios from 'axios';
import CountryDropdown from 'country-dropdown-with-flags-for-react';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ReactPasswordToggleIcon from 'react-password-toggle-icon';
import {FaEye, FaEyeSlash} from 'react-icons/fa'
import Select from 'react-select'
import countryList from 'react-select-country-list'
import AlertModal from './modal'
import BASE_URL from '../apiConfig'
import CookieConsent from "react-cookie-consent";
import GoogleTranslateWidget from '../components/googleTranslate'


const Signup = () => {
    
    const [value, setValue] = useState('')
    const options = useMemo(() => countryList().getData(), [])
    const [errorPassword, setErrorPassword] = useState("");
    const [errorPassword1, setErrorPassword1] = useState("");
    const [errorEmail, setErrorEmail] = useState("");
    const [autoComplete, setAutoComplete] = useState("off");
    const [buttonText, setButtonText] = useState('Sign up');
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [showAlert, setShowAlert] = useState(true);
    const [alertMessage, setAlertMessage] = useState(<div>This service is currently unavailable. Please use the following link to proceed to Yativo: <a href='https://www.yativo.com'>https://www.yativo.com</a> our newer service!</div>);
  
    const changeHandler = value => {
      setValue(value)
    }

    const showIcon = () => <FaEye aria-hidden="true" />;
    const hideIcon = () => <FaEyeSlash aria-hidden="true" />
    let inputRef = useRef();
    let inputRef1 = useRef();
    const [selected, setSelected] = useState("");
    const onSelect = (code) => setSelected(code);
    const location = useLocation();

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [cPassword, setCpassword] = useState('')
    const [businessName, setBusinessName] = useState('')
    const [country, setCountry] = useState('Nigeria')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [businessType, setBusinessType] = useState('')
    const handleEmail = (e) => {
      setEmail(e.target.value)
    }
    const handleBusinessName = (e) => {
        setBusinessName(e.target.value)
    }
    const handleCountry = (value) => {
        setCountry(value.label)
    }
    const handleFirstName = (e) => {
        setFirstName(e.target.value)
    }
    const handleLastName = (e) => {
        setLastName(e.target.value)
    }
    const handlePhoneNumber = (value) => {
        setPhoneNumber(value)
    }
    const handleBusinessType = (e) => {
        setBusinessType(e.target.value)
    }
  
    const handlePassword = (e) => {
      setPassword(e.target.value)
    }
    const handleCPassword = (e) => {
      setCpassword(e.target.value)
    }
    const navigate = useNavigate();
  
    const handleApi = (event) => {
        setButtonDisabled(true);
        setButtonText('Registering ...')
      event.preventDefault();
      if (!errorPassword || !errorEmail || !errorPassword1) {
      axios.post(`${BASE_URL}/register`, {
        businessName: businessName,
        country: country,
        firstName: firstName,
        lastName: lastName,
        email: email,
        phoneNumber: phoneNumber,
        businessType: businessType,
        password: password
      }).then(async (result) => {
        setAlertMessage('Success, please verify email!');
        setShowAlert(true);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        navigate("/verify-email", { state: {email} })
      })
        .catch(error => {
          const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
          setAlertMessage(jsonString);
          setShowAlert(true);
          setButtonDisabled(false);
          setButtonText('Sign Up')
        })
      }
      else{
        setAlertMessage('You have filled the form incorrectly')
        setShowAlert(true)
        setButtonText('Sign Up')
      }
    }
    const validatePassword = password => {
      const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      if (!passwordRegExp.test(password)) {
        setErrorPassword("Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character");
        setButtonDisabled(true)
      } else {
        setErrorPassword("");
        setButtonDisabled(false)
      }
    };
    const validateCPassword = () => {
      if (password !== cPassword) {
        setErrorPassword1("Passwords do not match!");
      } else {
        setErrorPassword1("");
      }
    }
    const validateEmail = email => {
      const emailRegExp = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!emailRegExp.test(email)) {
        setErrorEmail("Invalid email address");
      } else {
        setErrorEmail("");
      }
    };
    const handleAlertClose = () => {
      setShowAlert(false);
    };
    const [isChecked, setIsChecked] = useState(false);
  
    const handleCheckboxChange = () => {
      setIsChecked(!isChecked);
    };

    const RedirectToYativo = () => {
      setShowAlert(true);
      setAlertMessage(<div>This service is currently unavailable. Please use the following link to proceed to Yativo: <a href='https://www.yativo.com'>https://www.yativo.com</a> our newer service!</div>);
    };

  return (
    <>
        <SideBar />
        <CookieConsent
          location="top"
          buttonText="I consent"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#2B373B" }}
          buttonStyle={{ backgroundColor: "#4D0B6C",color:'#fff', fontSize: "13px" }}
          expires={150}
          overlay
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
        <Container>
            <FormWrap>
                <FormContent>
                    <Icon src={ZeenahPay}  width='160px'/>
                    <div style={{display:'flex', display: 'flex', textAlign: 'center', alignContent: 'center', alignSelf: 'center'}}>
                      <FormH1 style={{borderRadius:'10px'}}>
                      <GoogleTranslateWidget/>
                    </FormH1>
                    </div>
                    <Form onSubmit={RedirectToYativo}>
                        <FormH1>Get Started</FormH1>
                        <Text>Create an account to start accepting crypto payments from customers globally</Text>
                        <FormLabel for='businessName'>Business Name</FormLabel>
                        <FormInput id='businessName' placeholder='Enter business name' disabled value={businessName} onChange={handleBusinessName} />
                        <FormLabel for='businessCountry'>Business Country</FormLabel>
                        <CountryDropdown  id="country" className='countryInput' preferredCountries={['ng', 'us']}  handleChange={e => setCountry(e.target.value)} value={country} onChange={handleCountry}></CountryDropdown> 
                        <div style={{display:'flex', marginTop: '30px', gap:'5px'}}>
                            <div>
                                <FormLabel for='firstName'>First Name</FormLabel>
                                <FormInput id='firstName' placeholder='John' style={{width: '90%'}} disabled value={firstName} onChange={handleFirstName} />
                            </div>
                            <div>
                                <FormLabel for='lasstName'>Last Name</FormLabel>
                                <FormInput id='lastName' placeholder='Doe' style={{width: '90%'}} disabled value={lastName} onChange={handleLastName} />
                            </div>
                        </div>
                        <FormLabel for='email'>Email</FormLabel>
                        {errorEmail && <div style={{fontSize: '10px', color: '#FF0000', marginTop: '-10px', marginBottom: '5px', marginLeft: '10px'}}>{errorEmail}</div>}
                        <FormInput id='email' placeholder='Johndoe@ZeenahPay.com' type='email' disabled value={email} onChange={handleEmail} onBlur={() => validateEmail(email)} autoComplete={autoComplete} />
                        <FormLabel for='PhoneInput' style={{marginBottom:'-5px'}}>Phone Number</FormLabel>
                        {/*<span style={{color: '#791FA4',fontSize:'10px'}}>* The number must be enabled to receive messages on WhatsApp</span> */}
                        <PhoneInput country={'ng'} value={phoneNumber} onChange={handlePhoneNumber} />
                        
                        <div style={{position:"relative", marginTop: '20px'}}>
                            <FormLabel for='password' type='password'>Password</FormLabel>
                            <FormInput ref={inputRef} type="password" placeholder="*******************" style={{width: '100%'}} disabled value={password} onChange={handlePassword} onBlur={() => validatePassword(password)} autoComplete={autoComplete} />
                            <ReactPasswordToggleIcon inputRef={inputRef} showIcon={showIcon} hideIcon={hideIcon} style={{height: '100px'}}/>
                            {errorPassword && <div style={{fontSize: '10px', color: '#FF0000', marginTop: '-27px', marginBottom: '20px', marginLeft: '10px'}}>{errorPassword}</div>}
                        </div>
                        <div style={{position:"relative"}}>
                            <FormLabel for='password' type='password'>Confirm Password</FormLabel>
                            <FormInput ref={inputRef1} type="password" placeholder="*******************" style={{width: '100%'}} disabled value={cPassword} onChange={handleCPassword} onBlur={() => validateCPassword(cPassword)} autoComplete={autoComplete} />
                            <ReactPasswordToggleIcon inputRef={inputRef1} showIcon={showIcon} hideIcon={hideIcon} style={{height: '100px'}}/>
                            {errorPassword1 && <div style={{fontSize: '10px', color: '#FF0000', marginTop: '-27px', marginBottom: '20px', marginLeft: '10px'}}>{errorPassword1}</div>}
                        </div>
                        <div style={{ height: "200px", background: "#fafafa", overflow: "scroll", borderRadius:'8px', marginBottom: '20px'}}>
                          <div style={{padding:'15px'}}>
                         <h3 style={{textAlign:'center'}}>Beta Testing Agreement</h3>
                        <p style={{padding:'15px', fontSize:'14px'}}>The Zeenah Beta Testing Agreement</p>


<p style={{padding:'15px', fontSize:'14px'}}>This Beta Test Agreement (“Agreement”) governs the disclosure of information by ZeenahPay Finance, Inc. (“Company”) to you (the “Recipient”) and Recipient’s use of Company’s beta service offering.</p>


<div style={{padding:'15px', fontSize:'14px'}}><b>1.</b> Subject to the terms and conditions of this Agreement, Company grants Recipient a nonexclusive, nontransferable license to use the Company service (“Service”) for a period designated by the Company for the purpose of testing and evaluating the Service.</div>


<p style={{padding:'15px', fontSize:'14px'}}><b>2.</b> The Recipient agrees that it will at all times will hold in strict confidence and not disclose Confidential Information (as defined below) to any third party except as approved in writing by the Company and will use the Confidential Information for no purpose other than evaluating the Service. The Recipient shall only permit access to Confidential Information to those of its employees having a need to know and who have signed confidentiality agreements or are otherwise bound by confidentiality obligations at least as restrictive as those contained herein. “Confidential Information” means all non-public materials and information provided or made available by Company to Recipient, including products and services, information regarding technology, know-how, processes, software programs, research, development, financial information and information the Company provides regarding third parties.</p>


<p style={{padding:'15px', fontSize:'14px'}}><b>3.</b> The Recipient’s obligations under this Agreement with respect to any portion of the Confidential Information shall terminate when the Recipient can document that: (a) it was in the public domain at the time it was communicated to the Recipient; (b) it entered the public domain subsequent to the time it was communicated to the Recipient through no fault of the Recipient; (c) it was in the Recipient’s possession free of any obligation of confidence at the time it was communicated to the Recipient; (d) it was rightfully communicated to the Recipient free of any obligation of confidence subsequent to the time it was communicated to the Recipient; or (e) it was developed by employees or agents of the Recipient who had no access to any information communicated to the Recipient. After Recipient’s evaluation of the Service is complete, or upon request of the Company, the Recipient shall promptly return to the Company all documents, notes and other tangible materials and return or certify the destruction of all electronic documents, notes, software, data, and other materials in electronic form representing the Confidential Information and all copies thereof.
</p>

<p style={{padding:'15px', fontSize:'14px'}}><b>4.</b> The Recipient agrees that nothing contained in this Agreement shall be construed as granting any ownership rights to any Confidential Information disclosed pursuant to this Agreement, or to any invention or any patent, copyright, trademark, or other intellectual property right. The Recipient shall not make, have made, use or sell for any purpose any product or other item using, incorporating or derived from any Confidential Information or the Service. The Recipient will not modify, reverse engineer, decompile, create other works from, or disassemble any software programs contained in the Confidential Information or the Service.
</p>

<p style={{padding:'15px', fontSize:'14px'}}><b>5.</b> This Service is a beta release offering and is not at the level of performance of a commercially available product offering. The Service may not operate correctly and may be substantially modified prior to first commercial release, or at Company’s option may not be released commercially in the future. THE SERVICE AND DOCUMENTATION ARE PROVIDED “AS IS” WITHOUT WARRANTY OF ANY KIND, AND COMPANY AND ITS LICENSORS DISCLAIM ALL WARRANTIES, EXPRESS, IMPLIED, OR STATUTORY, INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT OF THIRD PARTY RIGHTS, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE. NO ORAL OR WRITTEN ADVICE OR CONSULTATION GIVEN BY COMPANY, ITS AGENTS OR EMPLOYEES WILL IN ANY WAY GIVE RISE TO A WARRANTY. THE ENTIRE RISK ARISING OUT OF THE USE OR PERFORMANCE OF THE SERVICE REMAINS WITH RECIPIENT.
</p>

<p style={{padding:'15px', fontSize:'14px'}}><b>6.</b> COMPANY AND ITS LICENSORS SHALL NOT BE LIABLE FOR LOSS OF USE, LOST PROFIT, COST OF COVER, LOSS OF DATA, BUSINESS INTERRUPTION, OR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, SPECIAL, OR EXEMPLARY DAMAGES ARISING OUT OF OR RELATED TO THE SERVICE OR THIS AGREEMENT, HOWEVER CAUSED AND REGARDLESS OF THE FORM OF ACTION, WHETHER IN CONTRACT, TORT (INCLUDING NEGLIGENCE) STRICT LIABILITY, OR OTHERWISE, EVEN IF SUCH PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN NO EVENT WILL COMPANY’S AGGREGATE CUMULATIVE LIABILITY FOR ANY CLAIMS ARISING OUT OF OR RELATED TO THIS AGREEMENT EXCEED $50.00 OR THE AMOUNT RECIPIENT ACTUALLY PAID COMPANY UNDER THIS AGREEMENT (IF ANY).
</p>

<p style={{padding:'15px', fontSize:'14px'}}><b>7.</b> The Recipient’s obligations under this Agreement shall survive any termination of this agreement. This Agreement shall be governed by and construed in accordance with the laws of Wyoming. The Recipient hereby agrees that breach of this Agreement will cause Company irreparable damage for which recovery of damages would be inadequate, and that the Company shall therefore be entitled to obtain timely injunctive relief under this Agreement, as well as such further relief as may be granted by a court of competent jurisdiction. The Recipient will not assign or transfer any rights or obligations under this Agreement without the prior written consent of the Company.
</p>

<p style={{padding:'15px', fontSize:'14px'}}>In Witness Whereof, the Recipient has caused this Agreement to be executed by signing up for the beta version of the Zeenah app.</p>
                          </div>
                          <div style={{display:'flex', padding:'20px'}}><FormInput type="checkbox" id='tc' checked={isChecked}  onChange={handleCheckboxChange} required style={{width: '30px', height:"30px", marginRight:"10px"}}/><div style={{color:'#000', fontFamily:'Poppins', fontSize:"14px"}}>I have read, understood and fully agree with ZeenahPay’s privacy policies. terms and conditions.</div></div><br></br>
                        </div>
                        <PrimaryBtn type='submit' style={{ backgroundColor: isChecked ? '#4D0B6C' : 'grey' }}  disabled={buttonDisabled}>{buttonText}</PrimaryBtn>
                        <Text>Have an account? <a href='/login' style={{color: '#4D0B6C', textDecoration:"none", textAlign:'center'}}>Sign in</a></Text>
                    </Form>
                </FormContent>
            </FormWrap>
        </Container>
        {showAlert && (
        <AlertModal
          isModalOpen={showAlert}
          alertMessage={alertMessage}
          onClose={handleAlertClose}
        />)}
    </>
  )
}

export default Signup