import styled from "styled-components";

export const P2pcontainer = styled.div`
    height: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fff;
    margin: 20px;
`
export const P2pTable = styled.table`
    text-align: left;
    border-collapse: collapse;
`
export const P2pTr = styled.tr`
    border: 1px #000 solid;
`
export const P2pH1 = styled.p`
    font-size: 16px;
    font-weight: 700;
    color: #51CB6C;
    font-family: 'Inter';
    text-transform: uppercase;
`
export const EditBtn = styled.button`
    background: transparent;
    border-radius: 6px;
    color: #4D0B6C;
    border: 1px #4D0B6C solid;
    cursor: pointer;
    padding: 10px 0;
    font-size: 12px;
    margin-bottom: 10px;
    margin-right: 5px;
    width: 80px;
`
export const CancelBtn = styled.button`
    background: transparent;
    border-radius: 6px;
    color: #0070FF;
    border: 1px #0070FF solid;
    cursor: pointer;
    padding: 10px 0;
    font-size: 12px;
    margin-bottom: 10px;
    margin-left: 5px;
    width: 80px;
`