import React, {useState, useRef, useMemo, useEffect} from 'react'
import { InputArea, SelectInput } from '../components/Dashboard/content/dashElements'
import { Container, Form2, FormContent, FormH1, FormH2, FormInput, FormLabel, FormWrap, Icon, PrimaryBtn, PrimaryBtn2, Text, Text2 } from '../components/Form/formElements'
import Paylink from '../assets/Ellipse12.png'
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import BASE_URL from '../apiConfig'

const CreatePaymentLink = () => {

  const token = localStorage.getItem('bearerToken');
  const [link_title, setLink_title] = useState('')
  const [link_description, setLink_description] = useState('')
  const [link_type] = useState('standard')
  const [phone_number, setPhone_number] = useState('0')  
  const [shipping_address] = useState('no')  
  const [redirect_website, setRedirect_website] = useState('')
  const [amount, setAmount] = useState('')
  const [payment_success_msg, setPayment_success_msg] = useState('')
  const [product_id, setProductId]  = useState('')  
  const slug = (link_title)
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [product, setProduct] = useState(null);
  
  const fetchProduct = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/products`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const getProduct = async () => {
      const data = await fetchProduct();
      setProduct(data);
    };

    getProduct();
  }, []);

  const handleLink_title = (e) => { setLink_title(e.target.value)}
  const handleLink_description = (e) => { setLink_description(e.target.value)}
  const handleAmount = (e) => { setAmount(e.target.value)}
  const handleProduct = (e) => { setProductId(e.target.value)}
  const handlePhone_number = (e) => { setPhone_number(e.target.checked ? '1' : '0' )}
  const handleRedirect_website = (e) => { setRedirect_website(e.target.value)}
  const handlePayment_success_msg = (e) => { setPayment_success_msg(e.target.value)}

  let history = useNavigate();

  const CreatePaymentLink = (event) => {
    event.preventDefault();
    axios.post(`${BASE_URL}/payment-link`, {
      link_description : link_description,
      link_title: link_title,
      link_type: link_type,
      phone_number: phone_number,
      shipping_address: shipping_address,
      redirect_website: redirect_website,
      payment_success_msg: payment_success_msg, 
      product_id: product_id, 
      slug: slug,
      donate_amount: amount,
      product_id: product_id
    }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(async (result) => {
      setAlertMessage('Successful!');
      setShowAlert(true);
      await new Promise((resolve) => setTimeout(resolve, 3000));
      history("/paymentlink-history")
    })
      .catch(error => {
        const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
        setAlertMessage(jsonString);
        setShowAlert(true);
      })
    }

    const handleAlertClose = () => {
      setShowAlert(false);
    };

  return (
    <>
                    <FormWrap>
                    <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><a className="close" onClick={() => history(-1)}>
                        <b style={{fontSize: '26px', marginLeft: '10px', alignItems: 'center', cursor: 'pointer'}}>&times; | </b>
                        </a> <b>Create Standard Link</b> </div>
                <FormContent>
                    <Icon src={Paylink}  width='140px' style={{marginBottom:'-20px'}} />
                    <FormH2>Payment Link Information</FormH2>
                    <Text2>Details of your payment link</Text2>
                    <Form2 onSubmit={CreatePaymentLink}>
                        <FormLabel for='paymentTitle'>Payment link title</FormLabel>
                        <FormInput id='link_title' placeholder='Enter Payment link title' value={link_title} onChange={handleLink_title}/>
                        <FormLabel for='link_description'>Payment link Description</FormLabel>
                        <InputArea id='link_description' type='text' placeholder='Enter Payment link Description' value={link_description} onChange={handleLink_description} />


                        <FormLabel for='link_type'>Select Product (optional)</FormLabel>
                        {product && 
                        (<SelectInput id="product" value={product_id} onChange={handleProduct} >
                          <option>Select Product currency</option>
                          
                          {product.data.map((option, index) => (
                          <option value={option.id} key={index}>{option.product_name}</option>
                          ))}
                        </SelectInput>)}

                        <FormLabel for='productPrice'>Amount to collect (if no product added)</FormLabel>
                        <FormInput id='productPrice' placeholder='leave empty for user input' value={amount} onChange={handleAmount} />
                        
                        <FormLabel>Extra options</FormLabel>
                        <div style={{display:'flex', marginTop: '10px', marginBottom: '10px'}}><FormInput type="checkbox" id='tc' required style={{width: '30px', height:"30px", marginRight:"10px"}}/><div style={{color:'#000', fontFamily:'Poppins', fontSize:"14px"}}>Collect customer’s phone number</div></div><br></br>
                        <div style={{display:'flex', marginTop: '10px', marginBottom: '10px'}}><FormInput type="checkbox" id='tc' required style={{width: '30px', height:"30px", marginRight:"10px"}}/><div style={{color:'#000', fontFamily:'Poppins', fontSize:"14px"}}>Collect customer’s Shipping address</div></div><br></br>

                        <FormLabel>Confirmation</FormLabel>
                        <div style={{marginBottom: '20px'}} ><FormLabel style={{display: 'inline-flex'}} ><FormInput type="radio" id="confirm" name="confirm" value="true" style={{width: '25px', height: '25px', marginRight: '10px'}} /> Display confirmation page after payment</FormLabel></div>

                        <FormLabel for='customMessage'>Add Custom Message</FormLabel>
                        <InputArea id='customMessage' type='text' value={payment_success_msg} onChange={handlePayment_success_msg} />
                        <div style={{marginBottom: '20px'}} ><FormLabel style={{display: 'inline-flex'}} ><FormInput type="radio" id="redirect" name="redirect" value="true" style={{width: '25px', height: '25px', marginRight: '10px'}} /> Redirect to website after payment</FormLabel></div>

                        <PrimaryBtn2 type='submit'>Create Payment Link</PrimaryBtn2>
                    </Form2>
                </FormContent>
            </FormWrap>
    </>
  )
}

export default CreatePaymentLink