import React from 'react'
import { FaAngleDoubleRight, FaArrowRight } from 'react-icons/fa'
import { Container, Content,H2, TextH2, ContentDiv, RoundedDiv2, P2pCard, P2pH, P2pT, PrimaryBtn, P2pInfo, P2pInfo2, DivLeft2, DivRight2, RoundedDiv3, InputArea, SelectInput,DivContainer, GridDiv, GridDivL, P2pGrid, TabText, PaymentCard } from '../components/Dashboard/content/dashElements'
import {  Form2, FormContent, FormH1, FormH2, FormInput, FormLabel, FormWrap, Icon, PrimaryBtn2, Text, Text2 } from '../components/Form/formElements'
import Navbar from '../components/Navbar'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Bank from '../assets/bank.png'


const PayMethod = () => {
  return (
    <>
        <Navbar/>
        <Container>
            <FormWrap>
                    <P2pGrid>
                        <GridDiv>
                        <FormH2 style={{textAlign: 'left', marginBottom: '20px'}}>Add Payment Method</FormH2>
                        <Tabs>
    <TabList>
      <Tab><TabText>Bank Transfer</TabText></Tab>
      <Tab><TabText>Paypal</TabText></Tab>
      <Tab><TabText>Payoneer</TabText></Tab>
      <Tab><TabText>Cashapp</TabText></Tab>
      <Tab><TabText>NETELLER</TabText></Tab>
      <Tab><TabText>Stripe</TabText></Tab>
      <Tab><TabText>Chipper Cash</TabText></Tab>
    </TabList>

    <TabPanel>
      <Form2 style={{border: 'none', textAlign: 'left'}}>
      <h4>Bank Transfer</h4>
        <Text2 style={{ textAlign: 'left', marginBottom: '40px'}}>The added payment method will be shown to the buyer during the transaction, please ensure that the information is real and matches your KYC information on Zeenah.</Text2>
        <FormLabel for='accountName'>Name</FormLabel>
        <FormInput id='accountName' placeholder='Enter Name on The Account'  style={{backgroundColor: '#FDF9FF'}}/>
        <FormLabel for='accountNunber'>Bank account number</FormLabel>
        <FormInput id='accountNumber' placeholder='Enter your bank account number' style={{backgroundColor: '#FDF9FF'}} />
        <FormLabel for='bankName'>Bank name</FormLabel>
        <FormInput id='bankName' placeholder='Enter name of your bank' style={{backgroundColor: '#FDF9FF'}} />
        <PrimaryBtn type='submit' style={{ borderRadius: '12px', backgroundColor: '#791FA4', textAlign: 'center', width: '100%'}}>Confirm</PrimaryBtn>
      </Form2>
    </TabPanel>
    <TabPanel>
    <Form2 style={{border: 'none', textAlign: 'left'}}>
    <h4>Paypal</h4>
        <Text2 style={{ textAlign: 'left', marginBottom: '40px'}}>The added payment method will be shown to the buyer during the transaction, please ensure that the information is real and matches your KYC information on Zeenah.</Text2>
        <FormLabel for='accountName'>Name</FormLabel>
        <FormInput id='accountName' placeholder='Enter Name on The Account'  style={{backgroundColor: '#FDF9FF'}}/>
        <FormLabel for='accountNunber'>Bank account number</FormLabel>
        <FormInput id='accountNumber' placeholder='Enter your bank account number' style={{backgroundColor: '#FDF9FF'}} />
        <FormLabel for='bankName'>Bank name</FormLabel>
        <FormInput id='bankName' placeholder='Enter name of your bank' style={{backgroundColor: '#FDF9FF'}} />
        <PrimaryBtn type='submit' style={{ borderRadius: '12px', backgroundColor: '#791FA4', textAlign: 'center', width: '100%'}}>Confirm</PrimaryBtn>
      </Form2>
    </TabPanel>
    <TabPanel>
    <Form2 style={{border: 'none', textAlign: 'left'}}>
    <h4>Payoneer</h4>
        <Text2 style={{ textAlign: 'left', marginBottom: '40px'}}>The added payment method will be shown to the buyer during the transaction, please ensure that the information is real and matches your KYC information on Zeenah.</Text2>
        <FormLabel for='accountName'>Name</FormLabel>
        <FormInput id='accountName' placeholder='Enter Name on The Account'  style={{backgroundColor: '#FDF9FF'}}/>
        <FormLabel for='accountNunber'>Bank account number</FormLabel>
        <FormInput id='accountNumber' placeholder='Enter your bank account number' style={{backgroundColor: '#FDF9FF'}} />
        <FormLabel for='bankName'>Bank name</FormLabel>
        <FormInput id='bankName' placeholder='Enter name of your bank' style={{backgroundColor: '#FDF9FF'}} />
        <PrimaryBtn type='submit' style={{ borderRadius: '12px', backgroundColor: '#791FA4', textAlign: 'center', width: '100%'}}>Confirm</PrimaryBtn>
      </Form2>
    </TabPanel>
    <TabPanel>
    <Form2 style={{border: 'none', textAlign: 'left'}}>
    <h4>Cashapp</h4>
        <Text2 style={{ textAlign: 'left', marginBottom: '40px'}}>The added payment method will be shown to the buyer during the transaction, please ensure that the information is real and matches your KYC information on Zeenah.</Text2>
        <FormLabel for='accountName'>Name</FormLabel>
        <FormInput id='accountName' placeholder='Enter Name on The Account'  style={{backgroundColor: '#FDF9FF'}}/>
        <FormLabel for='accountNunber'>Bank account number</FormLabel>
        <FormInput id='accountNumber' placeholder='Enter your bank account number' style={{backgroundColor: '#FDF9FF'}} />
        <FormLabel for='bankName'>Bank name</FormLabel>
        <FormInput id='bankName' placeholder='Enter name of your bank' style={{backgroundColor: '#FDF9FF'}} />
        <PrimaryBtn type='submit' style={{ borderRadius: '12px', backgroundColor: '#791FA4', textAlign: 'center', width: '100%'}}>Confirm</PrimaryBtn>
      </Form2>
    </TabPanel>
    <TabPanel>
    <Form2 style={{border: 'none', textAlign: 'left'}}>
    <h4>NETELLER</h4>
        <Text2 style={{ textAlign: 'left', marginBottom: '40px'}}>The added payment method will be shown to the buyer during the transaction, please ensure that the information is real and matches your KYC information on Zeenah.</Text2>
        <FormLabel for='accountName'>Name</FormLabel>
        <FormInput id='accountName' placeholder='Enter Name on The Account'  style={{backgroundColor: '#FDF9FF'}}/>
        <FormLabel for='accountNunber'>Bank account number</FormLabel>
        <FormInput id='accountNumber' placeholder='Enter your bank account number' style={{backgroundColor: '#FDF9FF'}} />
        <FormLabel for='bankName'>Bank name</FormLabel>
        <FormInput id='bankName' placeholder='Enter name of your bank' style={{backgroundColor: '#FDF9FF'}} />
        <PrimaryBtn type='submit' style={{ borderRadius: '12px', backgroundColor: '#791FA4', textAlign: 'center', width: '100%'}}>Confirm</PrimaryBtn>
      </Form2>
    </TabPanel>
    <TabPanel>
    <Form2 style={{border: 'none', textAlign: 'left'}}>
    <h4>Stripe</h4>
        <Text2 style={{ textAlign: 'left', marginBottom: '40px'}}>The added payment method will be shown to the buyer during the transaction, please ensure that the information is real and matches your KYC information on Zeenah.</Text2>
        <FormLabel for='accountName'>Name</FormLabel>
        <FormInput id='accountName' placeholder='Enter Name on The Account'  style={{backgroundColor: '#FDF9FF'}}/>
        <FormLabel for='accountNunber'>Bank account number</FormLabel>
        <FormInput id='accountNumber' placeholder='Enter your bank account number' style={{backgroundColor: '#FDF9FF'}} />
        <FormLabel for='bankName'>Bank name</FormLabel>
        <FormInput id='bankName' placeholder='Enter name of your bank' style={{backgroundColor: '#FDF9FF'}} />
        <PrimaryBtn type='submit' style={{ borderRadius: '12px', backgroundColor: '#791FA4', textAlign: 'center', width: '100%'}}>Confirm</PrimaryBtn>
      </Form2>
    </TabPanel>
    <TabPanel>
    <Form2 style={{border: 'none', textAlign: 'left'}}>
    <h4>Chipper cash</h4>
        <Text2 style={{ textAlign: 'left', marginBottom: '40px'}}>The added payment method will be shown to the buyer during the transaction, please ensure that the information is real and matches your KYC information on Zeenah.</Text2>
        <FormLabel for='accountName'>Name</FormLabel>
        <FormInput id='accountName' placeholder='Enter Name on The Account'  style={{backgroundColor: '#FDF9FF'}}/>
        <FormLabel for='accountNunber'>Bank account number</FormLabel>
        <FormInput id='accountNumber' placeholder='Enter your bank account number' style={{backgroundColor: '#FDF9FF'}} />
        <FormLabel for='bankName'>Bank name</FormLabel>
        <FormInput id='bankName' placeholder='Enter name of your bank' style={{backgroundColor: '#FDF9FF'}} />
        <PrimaryBtn type='submit' style={{ borderRadius: '12px', backgroundColor: '#791FA4', textAlign: 'center', width: '100%'}}>Confirm</PrimaryBtn>
      </Form2>
    </TabPanel>
  </Tabs>
                        </GridDiv>
                        <GridDivL style={{backgroundColor: '#fff'}}>
                            <div style={{textAlign: 'center'}}>
                            <TabText style={{fontSize: '17px'}}>Saved payment method</TabText>
                                <PaymentCard style={{display: 'grid'}}>
                                    <div style={{margin: '10px'}}>
                                        <div style={{float: 'left'}}><img src={Bank} alt='logo' />
                                    </div>
                                        <div style={{color: 'red', fontSize: '16px', float: 'right'}}>Remove</div>
                                    </div>
                                    <div>
                                        <DivLeft2>
                                            <P2pInfo>Account name</P2pInfo>
                                            <P2pInfo>Bank</P2pInfo>
                                        </DivLeft2>
                                        <DivRight2>
                                            <P2pInfo2>Reece James</P2pInfo2>
                                            <P2pInfo2>Zenith Bank</P2pInfo2>
                                        </DivRight2>
                                    </div>
                                    <hr></hr>
                                    <div>
                                        <DivLeft2><P2pInfo>Account number</P2pInfo></DivLeft2>
                                        <DivRight2><P2pInfo2 style={{fontSize: '16px'}}>102-176-1971</P2pInfo2></DivRight2>
                                    </div>
                                    
                                </PaymentCard>
                            </div>
                        </GridDivL>
                    </P2pGrid>
            </FormWrap>
        </Container>
    </>
  )
}

export default PayMethod