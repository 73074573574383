import React, {useState, useRef, useEffect} from 'react'
import { Container, Form, FormContent, FormH1, FormInput, FormLabel, FormWrap, Icon, PrimaryBtn, Text } from '../components/Form/formElements'
import SideBar from '../components/Sidebar'
import ZeenahPay from '../assets/ZeenahPay.png'
import ReactPasswordToggleIcon from 'react-password-toggle-icon';
import {FaEye, FaEyeSlash} from 'react-icons/fa'
import useAuth from '../hooks/useAuth';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from '../api/axios'
import AddToHomeScreenPrompt from '../components/addToHome';
import CookieConsent from "react-cookie-consent";
import GoogleTranslateWidget from '../components/googleTranslate';
import AlertModal from './modal';


const LOGIN_URL = '/login';

const Login = () => {
    const showIcon = () => <FaEye aria-hidden="true" />;
    const hideIcon = () => <FaEyeSlash aria-hidden="true" />
    let inputRef = useRef();
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => { setPasswordShown(!passwordShown);};
    const [buttonText, setButtonText] = useState('Sign in');
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [showAlert, setShowAlert] = useState(true);
    const [alertMessage, setAlertMessage] = useState(<div>This service is currently unavailable. Please use the following link to proceed to Yativo: <a href='https://www.yativo.com'>https://www.yativo.com</a> our newer service!</div>);


    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const userRef = useRef();
    const errRef = useRef();

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [errMsg, setErrMsg] = useState('')

    useEffect(() => {
      userRef.current.focus();
    }, [])

    useEffect(() => {
      setErrMsg('');
    }, [email, password])

    
    
    const handleSubmit = async (e) => {
        setButtonDisabled(true);
        setButtonText('Logging in...')
      e.preventDefault();

      try {
        const response = await axios.post(LOGIN_URL,
            JSON.stringify({ email, password }),
            {
                headers: { 'Content-Type': 'application/json' }
            }
        );
        const token = response?.data?.token;
        localStorage.setItem('bearerToken', token);
        localStorage.setItem('email',email);
        localStorage.setItem('password',password);
        setEmail('');
        setPassword('');
        navigate('/otp')
    } catch (err) {
        setButtonDisabled(false)
        setButtonText('Sign in')
        if (!err?.response) {
            setErrMsg('No server response');
        } else if (err.response?.status === 400) {
            setErrMsg('Missing Username or Password');
        } else if (err.response?.status === 401) {
            setErrMsg(err.response.data.errors.message);
        } else {
            setErrMsg('Login Failed');
        }
        errRef.current.focus();
    }
}

const handleAlertClose = () => {
  setShowAlert(false);
};
const RedirectToYativo = () => {
  setShowAlert(true);
  setAlertMessage(<div>This service is currently unavailable. Please use the following link to proceed to Yativo: <a href='https://www.yativo.com'>https://www.yativo.com</a> our newer service!</div>);
};

  return (
    <>
        <SideBar />
        <CookieConsent
          location="top"
          buttonText="I consent"
          cookieName="myAwesomeCookieName2"
          style={{ background: "#2B373B" }}
          buttonStyle={{ backgroundColor: "#4D0B6C",color:'#fff', fontSize: "13px" }}
          expires={150}
          overlay
        >
          This website uses cookies to enhance the user experience.
        </CookieConsent>
        <Container>
            <FormWrap>
                <FormContent>
                    <Icon src={ZeenahPay}  width='160px'/>
                    <div style={{display:'flex', display: 'flex', textAlign: 'center', alignContent: 'center', alignSelf: 'center'}}>
                      <FormH1>
                      <GoogleTranslateWidget/>
                    </FormH1>
                    </div>
                    <Form onSubmit={RedirectToYativo}>
                    {errMsg && <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive" style={{backgroundColor: '#FF0000', color:'#fff', padding:'10px', textAlign:'center'}} >{errMsg}</p>}
                        <FormH1>Welcome back</FormH1>
                        <Text>Login to start making global payouts using your preferred payment method</Text>
                        <FormLabel htmlFor='email'>Email</FormLabel>
                        <FormInput id='email' placeholder='Johndoe@ZeenahPay.com' disabled ref={userRef} type='email' value={email} onChange={(e) => setEmail(e.target.value)} />
                        <div style={{position:"relative"}}>
                            <FormLabel htmlFor='password' type='password'>Password</FormLabel>
                            <FormInput ref={inputRef} type="password" disabled placeholder="*******************" style={{width: '100%'}} value={password} onChange={(e) => setPassword(e.target.value)} />
                            <ReactPasswordToggleIcon inputRef={inputRef} showIcon={showIcon} hideIcon={hideIcon} style={{height: '100px'}}/>
                        </div>
                        <FormLabel > <a href='/forgotpassword' style={{color: '#4D0B6C', textDecoration:"none", float: 'right'}}>Forgot Password</a></FormLabel>
                        <PrimaryBtn type='submit' disabled={buttonDisabled}>{buttonText}</PrimaryBtn>
                        <Text>No account yet? <a href='/signup' style={{color: '#4D0B6C', textDecoration:"none", textAlign:'center'}}>Register Here</a></Text>
                    </Form>
                </FormContent>
            </FormWrap>
            <AddToHomeScreenPrompt />
        </Container>
        {showAlert && (
        <AlertModal
          isModalOpen={showAlert}
          alertMessage={alertMessage}
          onClose={handleAlertClose}
        />
      )}
    </>
  )
}

export default Login