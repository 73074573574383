import React, {useState, useEffect} from 'react'
import { BizTitle, BusinessImg, CompanyInfo, DashSide, PolygonDown, PolygonUp, SubMenuText,SubMenuItem, SubMenuTitle, WalletAddress, SubMenu } from './dashboardSidebarElements'
import {Menu as Men1} from './dashboardSidebarElements'
import Bizimg from '../../../assets/bizimg.png'
import {TfiViewListAlt} from 'react-icons/tfi'
import {AiOutlineSetting,AiOutlineWallet} from 'react-icons/ai'
import {MdProductionQuantityLimits} from 'react-icons/md'
import {HiLink, HiOutlineUserGroup} from 'react-icons/hi'
import {RiExchangeCnyLine} from 'react-icons/ri'
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import {FiLogOut} from 'react-icons/fi'
import {FaExchangeAlt} from 'react-icons/fa'
import AuthContext from '../../../context/AuthProvider'
import axios from 'axios'
import { Menu, MenuItem, ProSidebarProvider } from 'react-pro-sidebar';
import { NavLink } from 'react-router-dom';
import '../sidebar/side.css'
import styled from 'styled-components';
import BASE_URL from '../../../apiConfig'

const WriteUpContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0 auto;
`;

const WriteUpText = styled.p`
  text-align: left;
  color: #fff;
`;

const DashboardSidebar = () => {

    const { setAuth } = useContext(AuthContext);
    const navigate = useNavigate();
    const [user, setUser] = useState(null);

    const token = localStorage.getItem('bearerToken');
  
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        return response.data;
      } catch (error) {
        console.error(error);
      }
    };
  
    useEffect(() => {
      const getUser = async () => {
        const data = await fetchUser();
        setUser(data);
      };
  
      getUser();
    }, []);

    const logout = async () => {
        // if used in more components, this should be in context 
        // axios to /logout endpoint 
        setAuth({});
        localStorage.removeItem('email');
        localStorage.removeItem('bearerToken');
        localStorage.removeItem('password');
        navigate('/login');
    }

  return (
    <>
        <DashSide style={{overflow: 'auto'}}>
            <ProSidebarProvider rootStyles={{
          background:
            'linear-gradient(180deg, rgba(166,240,255,1) 0%, rgba(220,250,255,1) 49%, rgba(230,252,255,1) 100%)',
        }}>
                    <CompanyInfo>
                <BusinessImg>
                    <img src={Bizimg} style={{marginLeft:'16px'}} alt='business logo'/>
                </BusinessImg>{user && (
                <BizTitle style={{width: 'auto', marginBottom: '40px'}}>
                <WriteUpContainer style={{width: 'auto', marginBottom: '40px'}}>
                    <WriteUpText>{user.data.businessName}</WriteUpText>
                </WriteUpContainer>
                </BizTitle>)} 
                {/*<WalletAddress>0xB837917.....F3B70</WalletAddress>
                <PolygonUp />
                <PolygonDown />*/}
            </CompanyInfo>
                <Men1>
                <Menu
          menuItemStyles={{
            button: ({ level, active, disabled }) => {
              if (level === 0) {
                return {
                  "&:hover": {
                     backgroundColor: "#4D0B6C !important",
                     color: "white !important",
                     borderRadius: "8px !important",
                     fontWeight: "bold !important"
                   },
                };
              }
            },
          }}>
                    <SubMenu>
                        <SubMenuTitle>HOME</SubMenuTitle>
                        <NavLink to='/dashboard' style={({ isActive }) => isActive ? { color: '#fff', background: '#4D0B6C',  textDecoration: 'none', borderRadius: '8px',  fontWeight: 'bold' }: { color: '#4D0B6c', background: 'none', textDecoration: 'none' }}>
                        <MenuItem
          rootStyles={{
            ['.']: {
              backgroundColor: '#eaabff',
              color: '#9f0099',
              '&:hover': {
                backgroundColor: '#4D0B6C',
              },
            },
          }}
         icon={<TfiViewListAlt/>}> <SubMenuText> Overview</SubMenuText></MenuItem>
                        </NavLink>
                    </SubMenu>
                    <SubMenu>
                        <SubMenuTitle>Payments</SubMenuTitle>
                        <NavLink to='/transactions' style={({ isActive }) => isActive ? { color: '#fff', background: '#4D0B6C',  textDecoration: 'none', borderRadius: '8px',  fontWeight: 'bold' }: { color: '#4D0B6c', background: 'none', textDecoration: 'none' }}>
                            <MenuItem icon={<RiExchangeCnyLine/>}> <SubMenuText> Transactions</SubMenuText></MenuItem>
                        </NavLink>
                        <NavLink to='/customers' style={({ isActive }) => isActive ? { color: '#fff', background: '#4D0B6C',  textDecoration: 'none', borderRadius: '8px',  fontWeight: 'bold' }: { color: '#4D0B6c', background: 'none', textDecoration: 'none' }}>
                            <MenuItem icon={<HiOutlineUserGroup/>}> <SubMenuText>Beneficiaries</SubMenuText></MenuItem>
                        </NavLink>
                        <NavLink to='/payout' style={({ isActive }) => isActive ? { color: '#fff', background: '#4D0B6C',  textDecoration: 'none', borderRadius: '8px',  fontWeight: 'bold' }: { color: '#4D0B6c', background: 'none', textDecoration: 'none' }}>
                            <MenuItem icon={<AiOutlineWallet/>}> <SubMenuText> Send Money</SubMenuText></MenuItem>
                        </NavLink>
                        <NavLink to='/swap' style={({ isActive }) => isActive ? { color: '#fff', background: '#4D0B6C',  textDecoration: 'none', borderRadius: '8px',  fontWeight: 'bold' }: { color: '#4D0B6c', background: 'none', textDecoration: 'none' }}>
                            <MenuItem icon={<RiExchangeCnyLine/>}> <SubMenuText> Swap</SubMenuText></MenuItem>
                        </NavLink>
                        <NavLink to='/p2p' style={({ isActive }) => isActive ? { color: '#fff', background: '#4D0B6C',  textDecoration: 'none', borderRadius: '8px',  fontWeight: 'bold' }: { color: '#4D0B6c', background: 'none', textDecoration: 'none' }}>
                            <MenuItem routerLink={<NavLink to="/p2p" activeClassName="active"/>} icon={<FaExchangeAlt/>}> <SubMenuText> P2P</SubMenuText></MenuItem>
                        </NavLink>
                        <NavLink to='/topup' style={({ isActive }) => isActive ? { color: '#fff', background: '#4D0B6C',  textDecoration: 'none', borderRadius: '8px',  fontWeight: 'bold' }: { color: '#4D0B6c', background: 'none', textDecoration: 'none' }}>
                            <MenuItem icon={<AiOutlineWallet/>}> <SubMenuText> Deposit</SubMenuText></MenuItem>
                        </NavLink>
                    </SubMenu>
                    <SubMenu>
                        <SubMenuTitle>Commerce</SubMenuTitle>
                        <NavLink to='/products' style={({ isActive }) => isActive ? { color: '#fff', background: '#4D0B6C',  textDecoration: 'none', borderRadius: '8px',  fontWeight: 'bold' }: { color: '#4D0B6c', background: 'none', textDecoration: 'none' }}>
                            <MenuItem icon={<MdProductionQuantityLimits/>}> <SubMenuText> Products</SubMenuText></MenuItem>
                        </NavLink>
                        <NavLink to='/paymentlink-history' style={({ isActive }) => isActive ? { color: '#fff', background: '#4D0B6C',  textDecoration: 'none', borderRadius: '8px',  fontWeight: 'bold' }: { color: '#4D0B6c', background: 'none', textDecoration: 'none' }}>
                            <MenuItem icon={<HiLink/>}> <SubMenuText> Payment Link</SubMenuText></MenuItem>
                        </NavLink>
                        {/*<NavLink to='/create-paymentlink' style={({ isActive }) => isActive ? { color: '#4D0B6C', background: 'none',  textDecoration: 'none' }: { color: '#6C6363', background: 'none', textDecoration: 'none' }}>
                            <MenuItem icon={<TfiViewListAlt></TfiViewListAlt>}> <SubMenuText> Payment Button</SubMenuText></MenuItem>
                        </NavLink> */}
                    </SubMenu>
                    <SubMenu>
                        <SubMenuTitle>Account</SubMenuTitle>
                        <NavLink to='/settings' style={({ isActive }) => isActive ? { color: '#fff', background: '#4D0B6C',  textDecoration: 'none', borderRadius: '8px',  fontWeight: 'bold' }: { color: '#4D0B6c', background: 'none', textDecoration: 'none' }}>
                            <MenuItem icon={<AiOutlineSetting />}> <SubMenuText> Settings</SubMenuText></MenuItem>
                        </NavLink>
                        <MenuItem style={{color: 'red'}} onClick={logout} icon={<FiLogOut/>}><SubMenuText> Logout</SubMenuText></MenuItem>
                    </SubMenu>
                    </Menu>        
                </Men1>
                
            </ProSidebarProvider>
        </DashSide>
    </>
  )
}

export default DashboardSidebar