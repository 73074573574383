import React, { useState, useEffect }  from 'react'
import { Form3, PrimaryBtn2 } from '../components/Form/formElements'
import { Container, Content,H2, TextH2, ContentDiv, RoundedDiv2, P2pCard, P2pH, P2pT, PrimaryBtn, P2pInfo, P2pInfo2, DivLeft2, DivRight2, RoundedDiv3, InputArea, SelectInput,DivContainer, GridDiv, GridDivL, P2pGrid, TabText, PaymentCard } from '../components/Dashboard/content/dashElements'
import BASE_URL from '../apiConfig'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Bank from '../assets/bank.png'

const Testpm = () => {
    const [isOpen, setIsOpen] = useState(true);

    const toggleSidebar = () => {
      setIsOpen(!isOpen);
    };
    const [data, setData] = useState([]);
    const token = localStorage.getItem('bearerToken');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const itemsToDisplay = data.slice(startIndex, endIndex);
    const [isDeleting, setIsDeleting] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const navigate = useNavigate()
  
    useEffect(() => {
      fetch(`${BASE_URL}/payment-method`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(res => res.json())
        .then(response => setData(response.data))
        .catch(err => console.error(err));
    }, []);
    const totalPages = Math.ceil(data.length / itemsPerPage);
  
  const handlePageChange = page => {
    setCurrentPage(page);
  };
  
  const paginationButtons = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationButtons.push(
      <PrimaryBtn2 style={{width: '20px', borderRadius: '20px'}} key={i} onClick={() => handlePageChange(i)}>
        {i}
      </PrimaryBtn2>
    );
  }
  const handleDelete = async (id) => {
    try {
      setIsDeleting(true);
      await axios.get(`${BASE_URL}/payment-method/${id}/delete`,{
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      // Handle successful deletion, e.g. remove the trade from the table data and update the UI
      setAlertMessage('successfully deleted');
      setShowAlert(true);
    } catch (error) {
      console.error(error);
      const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
      setAlertMessage(jsonString);
      setShowAlert(true);
      // Handle error, e.g. display an error message to the user
    } finally {
      setIsDeleting(false);
    }
  };
  
  const handleAlertClose = () => {
    setShowAlert(false);
    navigate(0)
  };
  
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);
  
  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(data?.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }
  
  const handlePrevPage = () => {
    setCurrentPage( currentPage - 1);
  };
  const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const handleClick = e => {
    setCurrentPage(Number(e.target.id));
  };
  
  const isLastPage = currentPage === pageNumbers.length;
  const isFirstPage = currentPage === 1;
  return data && (
    <>
    {currentItems.map(paymentMethod => (
    <Form3 style={{maxWidth:'800px', padding:'32px 32px'}}>
            <div style={{margin: '10px'}}>
                <div style={{float: 'left'}}><img src={Bank} alt='logo' /></div>
                    <div style={{color: 'red', fontSize: '16px', float: 'right', cursor:'pointer'}} onClick={() =>  handleDelete(paymentMethod.id)} disabled={isDeleting}>{isDeleting ? 'Deleting...' : 'Delete'}</div>
            </div>
            {Object.entries(paymentMethod.payment_info).map(([key, value]) => (
            <div>
                <DivLeft2 style={{marginBottom:''}}>
                    <P2pInfo key={key}>{key.replace(/_/g, ' ')}</P2pInfo>
                </DivLeft2>
                <DivRight2 style={{marginBottom:''}}>
                    <P2pInfo2 key={key}>{value}</P2pInfo2>
                </DivRight2>
            </div>
            ))}
    </Form3>
    ))}
    </>
  )
}

export default Testpm