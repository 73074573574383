import React from 'react';
import styled, { keyframes } from 'styled-components';

const LoadingLine = () => {
  return (
    <Container>
      <Progress />
    </Container>
  );
};

const loadingLineAnimation = keyframes`
  0% {
    width: 0%;
  }
  50% {
    width: 50%;
  }
  100% {
    width: 100%;
  }
`;

const Container = styled.div`
  width: 100%;
  height: 5px;
  background-color: #ddd;
`;

const Progress = styled.div`
  height: 100%;
  background-color: #7416a0;
  animation-name: ${loadingLineAnimation};
  animation-duration: 2s;
  animation-iteration-count: infinite;
`;

export default LoadingLine