import React, { useEffect } from 'react';

const GoogleTranslateWidget = () => {
  useEffect(() => {
    // Embed the Google Translate widget script
    const script = document.createElement('script');
    script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    script.async = true;
    document.head.appendChild(script);

    // Function to initialize the widget
    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement(
        {
          pageLanguage: 'en',
          layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
          autoDisplay: false,
        },
        'google_translate'
      );

      // Hide the "Powered by Google Translate" attribution text
      const googleTranslateAttribution = document.querySelector('.goog-logo-link');
      if (googleTranslateAttribution) {
        googleTranslateAttribution.style.display = 'none';
      }
       // Hide the "translated into" banner
       const translateBanner = document.querySelector('.skiptranslate');
       if (translateBanner) {
         translateBanner.style.display = 'none';
       }
       // Hide the Google Translate banner
      const googleTranslateBanner = document.querySelector('.goog-te-banner-frame');
      if (googleTranslateBanner) {
        googleTranslateBanner.style.display = 'none';
      }
      const iframeToHide = document.querySelector('#\\:2\\.container');
      if (iframeToHide) {
        iframeToHide.style.visibility = 'hidden';
      }
    };
  }, []);

  return (
    <div id='Anutrickz_translateBTN' style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
      {/* Place the Google Translate widget container where you want it to appear */}
      <svg preserveAspectRatio='xMidYMid meet' role='img' viewBox='0 0 36 36'>
        <path className='clr-i-outline clr-i-outline-path-1' d='M30 3H14v5h2V5h14c.6 0 1 .4 1 1v11c0 .6-.4 1-1 1H17v7h-5.3L8 27.9V25H5c-.6 0-1-.4-1-1V13c0-.6.4-1 1-1h13v-2H5c-1.7 0-3 1.3-3 3v11c0 1.7 1.3 3 3 3h1v5.1l6.3-5.1H19v-7h11c1.7 0 3-1.3 3-3V6c0-1.7-1.3-3-3-3z' fill='currentColor'></path>
        <path className='clr-i-outline clr-i-outline-path-2' d='M6.2 22.9h2.4l.6-1.6h3.1l.6 1.6h2.4L11.9 14H9.5l-3.3 8.9zm4.5-6.4l1 3.1h-2l1-3.1z' fill='currentColor'></path>
        <path className='clr-i-outline clr-i-outline-path-3' d='M20 17c1.1 0 2.6-.3 4-1c1.4.7 3 1 4 1v-2s-1 0-2.1-.4c1.2-1.2 2.1-3 2.1-5.6V8h-3V6h-2v2h-3v2h5.9c-.2 1.8-1 2.9-1.9 3.6c-.6-.5-1.2-1.2-1.6-2.1h-2.1c.4 1.3 1 2.3 1.8 3.1c-1 .4-1.9.4-2.1.4v2z' fill='currentColor'></path>
      </svg>
      <div id="google_translate"></div>
    </div>
  );
};

export default GoogleTranslateWidget;
