import React, {useState, useEffect} from 'react'
import { Container, Form, FormContent, FormH1, FormWrap, Icon, Icon2, Text, Text1,OtpLabel, PrimaryBtn } from '../components/Form/formElements'
import SideBar from '../components/Sidebar'
import  {useNavigate}  from 'react-router-dom'
import ZeenahPay from '../assets/ZeenahPay.png'
import axios from 'axios'
import AuthCode from 'react-auth-code-input';
import goBack from '../assets/goback.png'
import { useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import AlertModal from './modal'
import BASE_URL from '../apiConfig'
import { ToastContainer, toast } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";


const Otp = () => {
    const [result, setResult] = useState();
    const handleOnChange = (res) => {
    setResult(res);
    }
    const { setAuth } = useAuth();
  const location = useLocation();
  const [reference_id, setReferenceId] = useState('');
  const token = localStorage.getItem('bearerToken');
  const email = localStorage.getItem('email');
  const password = localStorage.getItem('password');
    const navigate = useNavigate();
    const [phone, setPhone] = useState('');
    const [shouldResend, setShouldResend] = useState(true);
    const [buttonText, setButtonText] = useState('Submit');
    const [buttonText2, setButtonText2] = useState('Resend O.T.P');
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [formattedEmail, setFormatted] = useState('');
  
    const handleApi = (event) => {
      event.preventDefault();
      setButtonText('Validating...')
      setButtonDisabled(true)
      axios.post(`${BASE_URL}/validate-otp`, {
        reference_id: reference_id,
        code: result
      },
      {
          headers: {
          Authorization: `Bearer ${token}`,
          }
      }).then(async (response) => {
        if (response.status >= 200 && response.status < 300) {
          toast.success(response.data.message, {
            position: toast.POSITION.TOP_CENTER
          });
          setAuth({ email, password, token });
          await new Promise((resolve) => setTimeout(resolve, 3000));
          navigate('/dashboard');
        } else {
          toast.error('OTP not valid', {
            position: toast.POSITION.TOP_CENTER
          });
          setButtonText('Submit');
          setButtonDisabled(false);
        }
      }).catch(error => {
          const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
          toast.error(jsonString, {
            position: toast.POSITION.TOP_CENTER
          });
          setButtonDisabled(false)
          setButtonText('Submit')
        })
    }


    useEffect(() => {
      setFormatted(email.substring(0, 5) + "*****" + email.substring(10))
      if (shouldResend) {
      axios.post(`${BASE_URL}/send-otp`,
            {
                email : email
            },
        {
            headers: {
            Authorization: `Bearer ${token}`,
            }
        }).then(response => {
          setReferenceId(response?.data?.data?.entity[0]?.reference_id);
          handleResetClick()
          setShouldResend(false);
          setButtonText2('Resend O.T.P')
        })
      }
      
    }, [shouldResend]);
    function handleResendClick() {
      setButtonText2('Resending O.T.P ...')
      setResult(' ')
      setShouldResend(true);
    }
    const [timeLeft, setTimeLeft] = useState(60);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
      let intervalId;
      if (timeLeft > 0) {
        setDisabled(true);
        intervalId = setInterval(() => setTimeLeft(timeLeft - 1), 1000);
      } else {
        setDisabled(false);
        clearInterval(intervalId);
      }
      return () => {
        clearInterval(intervalId);
      };
    }, [timeLeft]);
  
  const handleResetClick = () => {
    if (!disabled) {
    setTimeLeft(60);
    }
  };

  const handleAlertClose = () => {
    setShowAlert(false);
  };
  const [user, setUser] = useState(null);
  useEffect(() => {
    const getUser = async () => {
      const data = await fetchUser();
      setUser(data.id);
    };

    getUser();
  }, []);
  const fetchUser = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/user`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  localStorage.setItem('userId',user);
  return  (
    <>
        <SideBar />
        <Container>
            <FormWrap>
                <FormContent>
                <ToastContainer />
                    <Icon src={ZeenahPay}  width='160px'/>
                    <Form onSubmit={handleApi}>
                        <FormH1>Confirm O.T.P</FormH1>
                        <Text>A 6-digit confirmation code was sent to your Email<Text style={{color: '#791FA4'}}>{formattedEmail}</Text>, please enter below</Text>
                        <Text style={{color: '#791FA4', fontSize:'10px'}}>* if you do not see the mail in your inbox, check your spam folder</Text>
                        <div style={{marginBottom: '20px'}}>
                            <AuthCode allowedCharacters='numeric' onChange={handleOnChange} containerClassName={'otpContainer'} isPassword={true} inputClassName={'otpInput'} onBlur={() => handleApi}/>          
                        </div>
                        <OtpLabel>{timeLeft > 0 && (<span>Resend O.T.P in <a style={{color: '#791FA4'}}>{Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, '0')}</a></span>)}  <span style={{  color: disabled ? "#aaa" : "#7BC875", cursor: disabled ? "not-allowed" : "pointer", marginLeft: "5px" }} onClick={handleResendClick}>{buttonText2}</span></OtpLabel>
                        <PrimaryBtn disabled={buttonDisabled}>{buttonText}</PrimaryBtn>
                    </Form>
                    <Icon2 style={{display: 'flex', marginTop: '-10px'}}><Icon src={goBack} /><Text1 href='/login' style={{textDecoration:'none', color:'#4D0B6C', marginTop: '20px', marginLeft: '-10px'}}>Return to Log in</Text1></Icon2>
                </FormContent>
            </FormWrap>
        </Container>
        {showAlert && (
        <AlertModal
          isModalOpen={showAlert}
          alertMessage={alertMessage}
          onClose={handleAlertClose}
        />
      )}
    </>
  )
}

export default Otp