import React, {useState, useRef, useEffect} from 'react'
import { Steps, StepsProvider, useSteps } from "react-step-builder";
import { Container, Form, FormContent, FormH1, FormWrap, Icon, Icon2, Text, Text1,OtpLabel, PrimaryBtn, FormInput, FormLabel } from '../components/Form/formElements'
import SideBar from '../components/Sidebar'
import  {useNavigate}  from 'react-router-dom'
import ZeenahPay from '../assets/ZeenahPay.png'
import axios from 'axios'
import AuthCode from 'react-auth-code-input';
import styled from "styled-components";
import DashboardSidebar from '../components/Dashboard/sidebar'
import Navbar from '../components/Navbar'
import AlertModal from './modal'
import ReactPasswordToggleIcon from 'react-password-toggle-icon';
import {FaEye, FaEyeSlash} from 'react-icons/fa'
import goBack from '../assets/goback.png'
import BASE_URL from '../apiConfig'

 const Div1 = styled.div`
    margin-bottom: 20px;
    margin-left: 80px;
    width: 100%;

    @media screen and (max-width: 600px) {
        margin-bottom: 20px;
        margin-left: 50px;
        width: 100%;
    }
    `

const UpdatePin = () => {
  const showIcon = () => <FaEye aria-hidden="true" />;
    const hideIcon = () => <FaEyeSlash aria-hidden="true" />
    let inputRef = useRef();
    const [passwordShown, setPasswordShown] = useState(false);
    const togglePassword = () => { setPasswordShown(!passwordShown);};
    const [buttonText, setButtonText] = useState('Verify');
    const [buttonText1, setButtonText1] = useState('Submit');
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [buttonDisabled1, setButtonDisabled1] = useState(false);
    const [errMsg, setErrMsg] = useState('')
    const [phone, setPhone] = useState('');
    const [shouldResend, setShouldResend] = useState(true);
    const [buttonText2, setButtonText2] = useState('Resend O.T.P');
    const [formattedPhone, setFormatted] = useState('');
    const [reference_id, setReferenceId] = useState('');

    const userRef = useRef();
    const errRef = useRef();

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [result, setResult] = useState();
    const handleOnChange = (res) => {
    setResult(res);
    }
    const navigate = useNavigate();
    const token = localStorage.getItem('bearerToken');
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const { next, prev, total, current } = useSteps();
    const userPassword = localStorage.getItem('password');
    const [nav, setNav] = useState(false)
  
    const handleSubmit = async (e) => {
      setButtonDisabled(true);
      setButtonText('Verifying...')
    e.preventDefault();

    if (password == userPassword){
      setButtonText('Verified!')
      next()
    }
    else{
      setShowAlert(true)
      setAlertMessage("Password is incorrect!")
      setButtonText('Verify')
      setButtonDisabled(false)
    }
}

    const handleApi = (event) => {
      event.preventDefault();
      axios.post(`${BASE_URL}/pin/update`, {
        pin: result
      },
      {
          headers: {
          Authorization: `Bearer ${token}`,
          }
      }).then(async (result) => {
        setAlertMessage(result.data.message);
        setShowAlert(true);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        setNav(true);
      })
        .catch(error => {
          const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
          setAlertMessage(jsonString);
          setShowAlert(true);
        })
    }
    const handleAlertClose = () => {
      setShowAlert(false);
      if (nav){
        navigate('/dashboard')
      }
    };

    const handleApi2 = (event) => {
      event.preventDefault();
      setButtonText1('Validating...')
      setButtonDisabled1(true)
      axios.post(`${BASE_URL}/validate-otp`, {
        reference_id: reference_id,
        code: result
      },
      {
          headers: {
          Authorization: `Bearer ${token}`,
          }
      }).then(async (result) => {
        setAlertMessage(result.data.message);
        setShowAlert(true);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        next()
      })
        .catch(error => {
          const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
          setAlertMessage(jsonString);
          setShowAlert(true);
          setButtonDisabled1(false)
          setButtonText1('Submit')
        })
    }

    const fetchUser = async () => {
      axios.get(`${BASE_URL}/user`,{
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(result => { setPhone(result?.data?.data?.phoneNumber)
          setFormatted(result?.data?.data?.phoneNumber.substring(0, 7) + "***" + result?.data?.data?.phoneNumber.substring(10))
        })
        return result?.data;
      }
      useEffect(() => {
        const getPhoneNum = async () => {
          const result = await  fetchUser();
          setPhone(result?.data?.data?.phoneNumber);
        };
    
        getPhoneNum();
      }, []);

    useEffect(() => {
      if (shouldResend && phone) {
      axios.post(`${BASE_URL}/send-otp`,
            {
                phone : phone
            },
        {
            headers: {
            Authorization: `Bearer ${token}`,
            }
        }).then(response => {
          setReferenceId(response?.data?.data?.entity[0]?.reference_id);
          handleResetClick()
          setShouldResend(false);
          setButtonText2('Resend O.T.P')
        })
      }
      
    }, [shouldResend, phone]);
    function handleResendClick() {
      setButtonText2('Resending O.T.P ...')
      setShouldResend(true);
    }
    const [timeLeft, setTimeLeft] = useState(60);
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
      let intervalId;
      if (timeLeft > 0) {
        setDisabled(true);
        intervalId = setInterval(() => setTimeLeft(timeLeft - 1), 1000);
      } else {
        setDisabled(false);
        clearInterval(intervalId);
      }
      return () => {
        clearInterval(intervalId);
      };
    }, [timeLeft]);
  
  const handleResetClick = () => {
    if (!disabled) {
    setTimeLeft(60);
    }
  };

  return (
    <>
    {showAlert && (
        <AlertModal
          isModalOpen={showAlert}
          alertMessage={alertMessage}
          onClose={handleAlertClose}
        />
      )}
        <Steps>
          <div className='content'>
                <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><a className="close">
                <b style={{ marginTop: '10px', marginLeft: '10px', alignItems: 'center', cursor: 'pointer'}} onClick={() => navigate('/dashboard')}>Zeenahpay | </b>
                </a> <b>Verify Password</b> <b style={{float: 'right', marginRight: '10px' }}>Step {current} of {total}</b></div>
                <FormContent>
                    <Icon src={ZeenahPay}  width='160px'/>
                    <Form onSubmit={handleSubmit}>
                    <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive" style={{color: '#FF0000'}} >{errMsg}</p>
                        <Text>Verify your password to update your pin</Text>
                        <div style={{position:"relative"}}>
                            <FormLabel htmlFor='password' type='password'>Password</FormLabel>
                            <FormInput ref={inputRef} type="password" placeholder="*******************" style={{width: '100%'}} value={password} onChange={(e) => setPassword(e.target.value)} />
                            <ReactPasswordToggleIcon inputRef={inputRef} showIcon={showIcon} hideIcon={hideIcon} style={{height: '100px'}}/>
                        </div>
                        <PrimaryBtn type='submit' disabled={buttonDisabled}>{buttonText}</PrimaryBtn>
                    </Form>
                </FormContent>
          </div>
          <div className='content'>
                <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><a className="close">
                <b style={{ marginTop: '10px', marginLeft: '10px', alignItems: 'center', cursor: 'pointer'}} onClick={() => navigate('/dashboard')}>Zeenahpay | </b>
                </a> <b>OTP</b> <b style={{float: 'right', marginRight: '10px' }}>Step {current} of {total}</b></div>
                <FormWrap>
                <FormContent>
                    <Icon src={ZeenahPay}  width='160px'/>
                    <Form onSubmit={handleApi2}>
                        <FormH1>Confirm O.T.P</FormH1>
                        <Text>A 6-digit confirmation code was sent to your WhatsApp Number<Text style={{color: '#791FA4'}}>{formattedPhone}</Text>, please enter below</Text>
                        <div style={{marginBottom: '20px'}}>
                            <AuthCode allowedCharacters='numeric' onChange={handleOnChange} containerClassName={'otpContainer'} isPassword={true} inputClassName={'otpInput'} onBlur={() => handleApi}/>          
                        </div>
                        <OtpLabel>{timeLeft > 0 && (<span>Resend O.T.P in <a style={{color: '#791FA4'}}>{Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, '0')}</a></span>)}  <span style={{  color: disabled ? "#aaa" : "#7BC875", cursor: disabled ? "not-allowed" : "pointer", marginLeft: "5px" }} onClick={handleResendClick}>{buttonText2}</span></OtpLabel>
                        <PrimaryBtn disabled={buttonDisabled1}>{buttonText1}</PrimaryBtn>
                    </Form>
                    <Icon2 style={{display: 'flex', marginTop: '-10px'}}><Icon src={goBack} /><Text1 href='/login' style={{textDecoration:'none', color:'#4D0B6C', marginTop: '20px', marginLeft: '-10px'}}>Return to Log in</Text1></Icon2>
                </FormContent>
            </FormWrap>
          </div>
          <div className='content'>
                <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><a className="close">
                <b style={{ marginTop: '10px', marginLeft: '10px', alignItems: 'center', cursor: 'pointer'}} onClick={() => navigate('/dashboard')}>Zeenahpay | </b>
                </a> <b>Payment Link</b> <b style={{float: 'right', marginRight: '10px' }}>Step {current} of {total}</b></div>
            <FormWrap>
                <FormContent style={{marginTop: '30px'}}>
                    <Icon src={ZeenahPay}  width='160px'/>
                    <Form onSubmit={handleApi}>
                        <FormH1>Update Pin</FormH1>
                        <Text>Update your 4 digit Transaction Pin below</Text>
                        <Div1 style={{marginBottom: '20px'}}>
                            <AuthCode allowedCharacters='numeric' onChange={handleOnChange} isPassword={true} length={4} containerClassName={'otpContainer'} inputClassName={'otpInput'} onBlur={() => handleApi}/>          
                        </Div1>
                        <PrimaryBtn>submit</PrimaryBtn>
                        <b onClick={() => navigate('/dashboard')} style={{textAlign: 'center', color: '#7BC875', cursor: 'pointer'}}>Go Home</b>
                    </Form>
                </FormContent>
            </FormWrap>
          </div>
        </Steps>
            
    </>
  )
}

export default UpdatePin