import React from 'react'
import Popup from 'reactjs-popup'

const BinancePay = () => {
  return (
    <div className='content'>
        <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><a className="close">
            <b style={{fontSize: '26px', marginLeft: '10px', alignItems: 'center', cursor: 'pointer'}}> | </b>
            </a> <b>BinancePay</b>
        </div>
        <div style={{ position: 'relative', width: '100%', height: '100vh', padding:'10px' }}>
            <iframe
                title="ZeenahPay Compliance"
                src="https://pay.binance.com/en/checkout/13f06cb9fe704f14b8439fc27df1fb07"
                width="100%"
                style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, width: '100%', height: '100%' }}
            />
        </div>                        
    </div>
  )
}

export default BinancePay