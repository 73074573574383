import React, {useState, useEffect} from 'react'
import { FaAngleDoubleRight, FaArrowRight } from 'react-icons/fa'
import {  Form2, Form3, FormContent, FormH1, FormH2, FormInput, FormLabel, Icon, PrimaryBtn2, Text, Text2, FormArea, Form3A } from '../components/Form/formElements'
import Navbar from '../components/Navbar'
import { Content,H2, TextH2, ContentDiv, RoundedDiv2, P2pCard, P2pH, P2pT, PrimaryBtn, P2pInfo, P2pInfo2, DivLeft2, DivRight2, RoundedDiv3,SelectInput, InputArea,DivContainer, GridDiv, GridDivL, P2pGrid, TabText, PaymentCard, Tdreg1, SearchBar, DivLeft, DivRight, TableContainer, Tablee, TableHead, TableRow, TableBody, TableHeader, TableCell, RoundedDiv, MobileOnly } from '../components/Dashboard/content/dashElements'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import axios from 'axios'
import { CancelBtn, EditBtn, P2pcontainer, P2pH1, P2pTable } from '../components/Dashboard/p2pElements/p2pElements'
import { NavLink } from 'react-router-dom'
import { Table as Tablle, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { CenterContent, Container, FormWrap, Tdreg, ThText } from '../components/Dashboard/content/dashElements';
import { useNavigate } from 'react-router-dom'
import Loader from '../components/loader'
import Popup from 'reactjs-popup'
import Nothing from '../assets/nothingtoshow.png'
import Empty from '../assets/empty.svg'
import userEvent from '@testing-library/user-event'
import Pin from './pin'
import AlertModal from './modal'
import {BsDownload} from 'react-icons/bs'
import styled from 'styled-components';
import Select from 'react-select';
import Sidebar1, { MainContainer, MainContent } from './nav'
import {GrPrevious, GrNext } from 'react-icons/gr';
import BASE_URL from '../apiConfig'
import { DropdownContent, EmailContainer, Title } from '../components/MobileTable'
import {IoIosArrowDown} from 'react-icons/io';
import PdfDocument from './pdfdocument'
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useReactToPrint } from 'react-to-print';
import { Spinner } from './p2p-transactiondetails'

const Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopupCard = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 80%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  border: none;
  background: transparent;
`;
const DivPop = styled.div`
    height: 150px;
    @media screen and (max-width: 580px) {
        height: 195px;
    }
`


const Container1 = styled.div`
  display: flex;
  margin-top: 20px;

`;

const Select1 = styled.select`
padding: 16px 16px;
margin-bottom: 32px;
border: 1px solid #979797;
border-radius: 6px;
background-color: #4D0B6C;
color: #fff;
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  outline: none;
`;

const Input = styled.input`
  padding: 16px 16px;
  margin-bottom: 32px;
  border: 1px solid #979797;
  border-radius: 6px;
  background-color: transparent;
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  outline: none;
  width:100%;
  @media screen and (max-width: 580px) {
        width: 80%;
    }
`;

const P2pSuccess = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const navigate = useNavigate()
    const [buy, setBuy] = useState(null);
    const [sell, setSell] = useState(null);
    const [successful, setSuccessful] = useState(null);
    const [failed, setFailed] = useState(null);
    const [disputes, setDisputes] = useState(null);
    const [transactions, setTransactions] = useState(null);
    const [deleted, setDeleted] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const [showDetails, setShowDetails] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [description, setDescription] = useState('');
    const [dispute_type, setDisputeType] = useState('');
    const [transaction_id, setTransactionID] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
  const [showNewDispute, setShowNewDispute] = useState(false);

  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleDisputeType = (selectedOption) => {
    const { value } = selectedOption;
    setDisputeType(value);
  };
  const handleDisputeModal = (ID) => {
    setTransactionID(ID)
    setShowNewDispute(!showNewDispute);
  }

  const handleAlertClose = () => {
    setShowAlert(false);
  };

    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [dim, setDim] = useState(false);

    const token = localStorage.getItem('bearerToken');
    const [user, setUser] = useState(null);

    const fetchUser = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        return response.data;
      } catch (error) {
        console.error(error);
      }
    };
    useEffect(() => {
      const getUser = async () => {
        const data = await fetchUser();
        setUser(data);
      };
  
      getUser();
    }, []);

    const fetchSuccessful = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/trade/history/buy-sell-history?status=success`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
    
          return response.data;
        } catch (error) {
          console.error(error);
        }
      };
      useEffect(() => {
        const getSuccessful = async () => {
          const data = await fetchSuccessful();
          setSuccessful(data);
        };
    
        getSuccessful();
      }, []);


    const handleRowClick = (trnx) => {
      setSelectedData(trnx);
      setShowDetails(true);
    };


    const [isEmpty, setIsEmpty] = useState(false);
      const [searchQuery, setSearchQuery] = useState('');
      const filteredData = successful?.data.data.filter((trnx) =>
      trnx.trade_status.toLowerCase().includes(searchQuery.toLowerCase())||
      trnx.transaction_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
      trnx.trade_currency.toLowerCase().includes(searchQuery.toLowerCase()) ||
      trnx.trade_amount.toLowerCase().includes(searchQuery.toLowerCase()) ||
      trnx.trade_type.toLowerCase().includes(searchQuery.toLowerCase()) ||
      trnx.payment_method?.payment_info?.method_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      new Date(trnx.updated_at).toLocaleString().toLowerCase().includes(searchQuery.toLowerCase())
      )

      const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // reset to first page when search query changes
        setIsEmpty(filteredData.length === 0);
        if (searchQuery === 0) {
          setSearchQuery(null);
        }
      };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = successful?.data.data.slice(indexOfFirstItem, indexOfLastItem);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(successful?.data.data.length / itemsPerPage); i++) {
      pageNumbers.push(i);
    }

   const handlePrevPage = () => {
      setCurrentPage( currentPage - 1);
    };
   const handleNextPage = () => {
      setCurrentPage(currentPage + 1);
    };
    const handleClick = e => {
      setCurrentPage(Number(e.target.id));
    };

    const isLastPage = currentPage === pageNumbers.length;
    const isFirstPage = currentPage === 1;
    const [isOpenZ, setIsOpenZ] = useState(false);
    const toggleDropdown = (index) => {
      setIsOpenZ((prevState) => ({
        ...prevState,
        [index]: !prevState[index],
      }));
    };
  return (
    <>
        <Navbar toggleSidebar={toggleSidebar}/>
        <MainContainer isOpen={isOpen}>
            <Sidebar1 isOpen={isOpen} toggleSidebar={toggleSidebar} />
            <MainContent>
        <Container style={{backgroundColor: '#fff', borderRadius:'6px'}}>
            <FormWrap>
            <div style={{marginLeft: '20px', marginTop:'10px'}}>
              <h2>Completed Transactions</h2>
              <div style={{position:"flex", marginTop: '5px', width: '100%', marginBottom: ''}}>
                  <SearchBar type="text" value={searchQuery} onChange={handleSearchInputChange} placeholder='search transactions'  />
              </div>
            </div>
    {successful ? (
    <P2pcontainer>
    <PrimaryBtn Link style={{float: 'right'}}><NavLink to='/create-ad' style={{textDecoration: 'none', color: 'white'}}>Create AD</NavLink></PrimaryBtn>
    <FormWrap>  
        {successful.data.data.length  ? (
                <CenterContent>
                {isEmpty ? (
                  <div style={{justifyContent:'center', display: 'grid'}}>
                        <div style={{textAlign: 'center'}}>
                          <img src={Nothing} alt="Empty table" />
                        </div>
                        <div style={{textAlign: 'center'}}>
                          <h5 style={{marginBottom: '10px'}}>No search results found</h5>
                        </div>
                        <div>
                        </div>
                      </div>
                ) : (
                  <>
                  <TableContainer>
                        <Tablee>
                          <TableHead>
                            <TableRow>
                                <TableHeader>Status</TableHeader>
                                <TableHeader>Transaction ID</TableHeader>
                                <TableHeader>Amount</TableHeader>
                                <TableHeader>Trade Type</TableHeader>
                                <TableHeader>Time</TableHeader>
                                {/*<TableHeader>Download Receipt</TableHeader> */}
                            </TableRow>
                          </TableHead>
                          <TableBody style={{cursor: 'pointer'}}>
                            {currentItems.map((trnx, index) => (
                              <TableRow key={index}>
                                  <TableCell ><P2pH1 style={{ fontSize: '12px', borderRadius: '16px', textAlign: 'center', padding: '8px', backgroundColor: '#51CB6C', color: '#fff'}}>{trnx.trade_status}</P2pH1></TableCell>
                                  <TableCell style={{overflowWrap: 'break-word', margin:'10px'}}>{trnx.transaction_id.slice(0,13)}</TableCell>
                                  <TableCell  style={{fontWeight:'500'}}>{trnx?.trade_currency} {trnx?.trade_amount}</TableCell>
                                  <TableCell >{trnx?.trade_type}</TableCell>
                                  <TableCell>
                                    {new Date(trnx?.updated_at).toLocaleString('en-GB', {
                                      day: 'numeric',
                                      month: 'short',
                                      year: 'numeric',
                                      hour: 'numeric',
                                      minute: 'numeric',
                                      hour12: true
                                    })}
                                  </TableCell>
                                 {/*<TableCell>
                                    <PrimaryBtn2 style={{ backgroundColor: '#F3E8F7', borderRadius: '6px', width: '100%', cursor: 'pointer', color: '#4D0B6C', marginRight: '10px', fontSize: '15px' }}>
                                      <PDFDownloadLink style={{ textDecoration: 'none', color: '#4D0B6C' }} document={<PdfDocument selectedData={trnx} />} fileName={`Zeenahpay-${trnx.transaction_id}.pdf`}>
                                        {({ blob, url, loading, error }) => (
                                          <div>
                                            {loading ? (
                                              <div style={{ textAlign: 'center', display: 'flex', fontSize: '12px', margin: '5px' }}>
                                                Generating PDF <Spinner style={{ marginLeft: '1px' }} />
                                              </div>
                                            ) : (
                                              'Download Receipt'
                                            )}
                                          </div>
                                        )}
                                      </PDFDownloadLink>
                                    </PrimaryBtn2>
                                  </TableCell> */}
                              </TableRow>
                            ))}
                          </TableBody>
                      </Tablee>
                    </TableContainer>
                    <MobileOnly>
                      <RoundedDiv style={{margin:'0px'}}>
                      {currentItems.map((trnx, index) => (
                    <DivContainer style={{ paddingRight:'20px'}} onClick={() => {setOpen1(true); setDim(true); handleRowClick(trnx)}} key={index}>
                                  <DivLeft style={{marginLeft:'5px'}}>
                                   {/*<div style={{cursor:'pointer', background:'#F3E8F7', borderRadius:'10px', padding:'15px'}}>
                                    <PDFDownloadLink style={{ textDecoration: 'none', color: '#4D0B6C' }} document={<PdfDocument selectedData={trnx} />} fileName={`Zeenahpay-${trnx.transaction_id}.pdf`}>
                                        {({ blob, url, loading, error }) => (
                                          <div>
                                            {loading ? (
                                              <div style={{ textAlign: 'center', display: 'flex', fontSize: '12px', margin: '5px' }}>
                                                <Spinner/>
                                              </div>
                                            ) : (
                                              <BsDownload />
                                            )}
                                          </div>
                                        )}
                                      </PDFDownloadLink>
                                    </div> */}
                                      <div style={{textAlign: 'left', marginLeft: '20px', fontFamily:'Inter'}}>
                                        <h3 style={{fontWeight:'500',fontSize:'14px', lineHeight:'19.36px',color:'#1E1E1E'}}>{trnx?.trade_type}</h3>
                                        <p style={{color:'#979797',fontWeight:'500',fontSize:'14px', lineHeight:'16.94px'}}>{new Date(trnx?.created_at).toLocaleString('en-GB', {
                                    day: 'numeric',
                                    month: 'short',
                                    year: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                    hour12: true
                                })}</p>
                                      </div>
                                  </DivLeft> 
                                  <DivRight style={{display: 'grid',marginRight:'5px'}}>
                                    <p style={{marginRight: '10px', textAlign: 'center', fontWeight:'500',fontSize:'16px', lineHeight:'19.36px',color:'#1E1E1E'}}>{trnx?.trade_currency} {trnx?.trade_amount}</p>
                                    <p style={{ textAlign: 'center', fontSize:'14px', fontFamily:'Poppins', color:'#791FA4'}}>{trnx.trade_status}</p>
                                  </DivRight>
                                </DivContainer>
                                ))}
                      </RoundedDiv>
                      </MobileOnly> 
                    <div>
                    <ul style={{display: 'flex', listStyle: 'none', margin:'10px'}}>
                    <button onClick={handlePrevPage} disabled={isFirstPage} style={{ cursor: isFirstPage ? 'not-allowed' : 'pointer', backgroundColor: isFirstPage ? '#8080808c' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                          <GrPrevious />
                        </button>
                        
                        {pageNumbers.map(number => (
                          <li key={number}>
                            <button id={number} onClick={handleClick} style={{ backgroundColor: currentPage === number ? '#FCF3FF' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                              {number}
                            </button>
                          </li> 
                        ))}
                        <button onClick={handleNextPage} disabled={isLastPage} style={{ cursor: isLastPage ? 'not-allowed' : 'pointer', backgroundColor: isLastPage ? '#8080808c' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                          <GrNext/>
                        </button> 
                      </ul>
                    </div>
                    </>)}
                </CenterContent>
                ) : (
                        <div style={{justifyContent:'center', display: 'grid'}}>
                        <div style={{textAlign: 'center'}}>
                          <img src={Empty} alt="Empty table" />
                        </div>
                        <div style={{textAlign: 'center'}}>
                          <h5 >You have not completed a transaction</h5>
                          <p style={{marginBottom: '10px', fontSize: '10px'}}>If you need any assistance contact support</p>
                        </div>
                        <div>
                        <div style={{display:'flex'}}>
                          <div style={{width: '97%', marginRight: '10px'}}>
                            <NavLink to='/topup'> 
                                <PrimaryBtn2 style={{background: 'linear-gradient(180deg, #F3E8F7 0%, #EBDAF1 100%)',color: '#791FA4', borderRadius: '6px', width: '100%'}}>Top Up</PrimaryBtn2>
                            </NavLink>
                          </div>
                          <div style={{width: '100%'}}>
                            <NavLink to='/payout'>
                                <PrimaryBtn2 style={{backgroundColor: '#9316BF', borderRadius: '6px', width: '100%'}}>Pay Out</PrimaryBtn2>
                            </NavLink>
                          </div>
                        </div>
                        </div>
                      </div>
                      )}
            </FormWrap>
  </P2pcontainer>  ) : <Loader/>}
    
                        
            </FormWrap>
        </Container>
        </MainContent>
        </MainContainer>
        {showAlert && (
        <AlertModal
          isModalOpen={showAlert}
          alertMessage={alertMessage}
          onClose={handleAlertClose}
        />
      )}
    </>
  )
}

export default P2pSuccess