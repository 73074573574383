import React, {useState, useEffect} from 'react'
import { FaAngleDoubleRight, FaArrowRight } from 'react-icons/fa'
import {  Form2, Form3, FormContent, FormH1, FormH2, FormInput, FormLabel, Icon, PrimaryBtn2, Text, Text2, FormArea } from '../components/Form/formElements'
import Navbar from '../components/Navbar'
import { Content,H2, TextH2, ContentDiv, RoundedDiv2, P2pCard, P2pH, P2pT, PrimaryBtn, P2pInfo, P2pInfo2, DivLeft2, DivRight2, RoundedDiv3,SelectInput, InputArea,DivContainer, GridDiv, GridDivL, P2pGrid, TabText, PaymentCard, Tdreg1, SearchBar } from '../components/Dashboard/content/dashElements'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import axios from 'axios'
import { CancelBtn, EditBtn, P2pcontainer, P2pH1, P2pTable } from '../components/Dashboard/p2pElements/p2pElements'
import { NavLink } from 'react-router-dom'
import { Table as Tablle, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { CenterContent, Container, FormWrap, Tdreg, ThText } from '../components/Dashboard/content/dashElements';
import { useNavigate } from 'react-router-dom'
import Loader from '../components/loader'
import Popup from 'reactjs-popup'
import Nothing from '../assets/nothingtoshow.png'
import Empty from '../assets/empty.svg'
import userEvent from '@testing-library/user-event'
import Pin from './pin'
import AlertModal from './modal'
import {MdOutlineReportProblem} from 'react-icons/md'
import styled from 'styled-components';
import Select from 'react-select';
import Sidebar1, { MainContainer, MainContent } from './nav'
import {GrPrevious, GrNext } from 'react-icons/gr';
import BASE_URL from '../apiConfig'

const Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopupCard = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 80%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  border: none;
  background: transparent;
`;
const DivPop = styled.div`
    height: 150px;
    @media screen and (max-width: 580px) {
        height: 195px;
    }
`


const Container1 = styled.div`
  display: flex;
  margin-top: 20px;

`;

const Select1 = styled.select`
padding: 16px 16px;
margin-bottom: 32px;
border: 1px solid #979797;
border-radius: 6px;
background-color: #4D0B6C;
color: #fff;
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  outline: none;
`;

const Input = styled.input`
  padding: 16px 16px;
  margin-bottom: 32px;
  border: 1px solid #979797;
  border-radius: 6px;
  background-color: transparent;
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  outline: none;
  width:100%;
  @media screen and (max-width: 580px) {
        width: 80%;
    }
`;

const P2pDisute = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const navigate = useNavigate()
    const [buy, setBuy] = useState(null);
    const [sell, setSell] = useState(null);
    const [successful, setSuccessful] = useState(null);
    const [failed, setFailed] = useState(null);
    const [disputes, setDisputes] = useState(null);
    const [transactions, setTransactions] = useState(null);
    const [deleted, setDeleted] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const [showDetails, setShowDetails] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [description, setDescription] = useState('');
    const [dispute_type, setDisputeType] = useState('');
    const [transaction_id, setTransactionID] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
  const [showNewDispute, setShowNewDispute] = useState(false);

  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleDisputeType = (selectedOption) => {
    const { value } = selectedOption;
    setDisputeType(value);
  };
  const handleDisputeModal = (ID) => {
    setTransactionID(ID)
    setShowNewDispute(!showNewDispute);
  }

  const NewDispute = (event) => {
    event.preventDefault();
      axios.post(`${BASE_URL}/dispute`, {
        transaction_id : transaction_id,
        dispute_type: dispute_type,
        description: description
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(async (response) => {
          setAlertMessage(response.data.message)
          setShowAlert(true)
          await new Promise((resolve) => setTimeout(resolve, 3000));
          navigate('/p2p')
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.error) {
            // Extract error message from response and alert it
            const errorMessage = error.response.data.errors.error;
            const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
            setAlertMessage(jsonString);;
            setShowAlert(true)
          } else {
            setAlertMessage('Service Error');
            setShowAlert(true);
          }
        });
  };

  const handleAlertClose = () => {
    setShowAlert(false);
  };

    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [dim, setDim] = useState(false);

    const token = localStorage.getItem('bearerToken');
    const [user, setUser] = useState(null);

    const fetchUser = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        return response.data;
      } catch (error) {
        console.error(error);
      }
    };
    useEffect(() => {
      const getUser = async () => {
        const data = await fetchUser();
        setUser(data);
      };
  
      getUser();
    }, []);

    const fetchDispute = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/dispute`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
    
          return response.data;
        } catch (error) {
          console.error(error);
        }
      };
      useEffect(() => {
        const getDisputes = async () => {
          const data = await fetchDispute();
          setDisputes(data);
        };
    
        getDisputes();
      }, []);

    const handleDelete = async (id) => {
      try {
        setIsDeleting(true);
        await axios.delete(`${BASE_URL}/trade/delete/${id}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // Handle successful deletion, e.g. remove the trade from the table data and update the UI
        setAlertMessage('successfully deleted');
        setShowAlert(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        navigate(0);
      } catch (error) {
        console.error(error);
        const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
        setAlertMessage(jsonString);
        setShowAlert(true);
        // Handle error, e.g. display an error message to the user
      } finally {
        setIsDeleting(false);
      }
    };
    const CheckStatus = async (id) => {
      try {
        const response = await axios.get(`${BASE_URL}/trade/trade-status?trade=${id}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // Handle successful deletion, e.g. remove the trade from the table data and update the UI
        setAlertMessage(response.data.data.status);
        setShowAlert(true);
      } catch (error) {
        console.error(error);
        const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
        setAlertMessage(jsonString);
        setShowAlert(true);
        // Handle error, e.g. display an error message to the user
      }
    };
    const handleReceived = async (id) => {
      try {
        await axios.post(`${BASE_URL}/trade/received`,{
          trade_id : id
        },{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // Handle successful deletion, e.g. remove the trade from the table data and update the UI
        setAlertMessage('Marked as received');
        setShowAlert(true);
      } catch (error) {
        console.error(error);
        const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
        setAlertMessage(jsonString);
        setShowAlert(true);
        // Handle error, e.g. display an error message to the user
      }
    };
    const handlePaid = async (id) => {
      try {
        await axios.post(`${BASE_URL}/trade/paid`,{
          trade_id : id
        },{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // Handle successful deletion, e.g. remove the trade from the table data and update the UI
        setAlertMessage('Marked as paid');
        setShowAlert(true);
      } catch (error) {
        console.error(error);
        const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
        setAlertMessage(jsonString);
        setShowAlert(true);
        // Handle error, e.g. display an error message to the user
      }
    };

    const handleRowClick = (trnx) => {
      setSelectedData(trnx);
      setShowDetails(true);
    };
  
    const handleCloseDetails = () => {
      setShowDetails(false);
    };
    const [showPinVerification, setShowPinVerification] = useState(false);

    const handlePaidWithVerification = (id) => {
      setShowPinVerification(true);
      setVerificationCallback(() => handlePaid.bind(null, id));
    };
    
    const handleReceivedWithVerification = (id) => {
      setShowPinVerification(true);
      setVerificationCallback(() => handleReceived.bind(null, id));
    };
    
    const [verificationCallback, setVerificationCallback] = useState(null);
    const handleCancelPinVerification = () => {
      setShowPinVerification(false);
    };

    function handleDisputeClick(transactionId) {
      navigate('/dispute', { state: { id: transactionId } });
    }
    const options = [
      {
        label: 'Sell',
        options: [
          { value: 'Sender name doesn’t tally', label: 'Sender name doesn’t tally' },
          { value: 'Payment not received', label: 'Payment not received' },
          { value: 'User filed chargeback', label: 'User filed chargeback' },
          { value: 'User sent stolen funds', label: 'User sent stolen funds' },
          { value: 'Bad terms and conditions', label: 'Bad terms and conditions' }
        ]
      },
      {
        label: 'Top Up',
        options: [
          { value: 'Delay in release of funds', label: 'Delay in release of funds' },
          { value: 'Incorrect account details', label: 'Incorrect account details' },
          { value: 'Fraudulent Account details', label: 'Fraudulent Account details' },
          { value: 'Agent is unreachable', label: 'Agent is unreachable' },
          { value: 'Bad terms and conditions', label: 'Bad terms and conditions' }
        ]
      },
      {
        label: 'Payout',
        options: [
          { value: 'Sender name doesn’t tally', label: 'Sender name doesn’t tally' },
          { value: 'Payment not received', label: 'Payment not received' },
          { value: 'User filed chargeback', label: 'User filed chargeback' },
          { value: 'User sent stolen funds', label: 'User sent stolen funds' },
          { value: 'Bad terms and conditions', label: 'Bad terms and conditions' },
          { value: 'Agent is unreachable', label: 'Agent is unreachable' }
        ]
      },
      {
        label: 'Buy',
        options: [
          { value: 'Delay in release of funds', label: 'Delay in release of funds' },
          { value: 'Incorrect account details', label: 'Incorrect account details' },
          { value: 'Fraudulent Account details', label: 'Fraudulent Account details' },
          { value: 'User is unreachable', label: 'User is unreachable' }
        ]
      }
    ];
    const [isEmpty, setIsEmpty] = useState(false);
      const [searchQuery, setSearchQuery] = useState('');
      const filteredData = disputes?.data.data.filter((dispute) =>
      dispute.status.toLowerCase().includes(searchQuery.toLowerCase())||
      dispute.description.toLowerCase().includes(searchQuery.toLowerCase())||
      dispute.status.toLowerCase().includes(searchQuery.toLowerCase())||
      dispute.id.toString().includes(searchQuery.toLowerCase())||
      dispute.dispute_type.toLowerCase().includes(searchQuery.toLowerCase()) ||
      new Date(dispute.created_at).toLocaleString().toLowerCase().includes(searchQuery.toLowerCase())
      )

      const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // reset to first page when search query changes
        setIsEmpty(filteredData.length === 0);
        if (searchQuery === 0) {
          setSearchQuery(null);
        }
      };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredData?.length / itemsPerPage); i++) {
      pageNumbers.push(i);
    }

   const handlePrevPage = () => {
      setCurrentPage( currentPage - 1);
    };
   const handleNextPage = () => {
      setCurrentPage(currentPage + 1);
    };
    const handleClick = e => {
      setCurrentPage(Number(e.target.id));
    };

    const isLastPage = currentPage === pageNumbers.length;
    const isFirstPage = currentPage === 1;

  return (
    <>
        <Navbar toggleSidebar={toggleSidebar}/>
        <MainContainer isOpen={isOpen}>
            <Sidebar1 isOpen={isOpen} toggleSidebar={toggleSidebar} />
            <MainContent>
        <Container style={{backgroundColor: '#fff', borderRadius:'6px'}}>
            <FormWrap>
            <div style={{marginLeft: '20px', marginTop:'10px'}}>
              <h2>P2P Dispute</h2>
              <div style={{position:"flex", marginTop: '20px', width: '100%'}}>
                  <SearchBar type="text" value={searchQuery} onChange={handleSearchInputChange} placeholder='search disputes'  />
              </div>
            </div>
      {disputes ? (
    <P2pcontainer>
    <PrimaryBtn Link style={{float: 'right'}}><NavLink to='/create-ad' style={{textDecoration: 'none', color: 'white'}}>Create AD</NavLink></PrimaryBtn>
    <FormWrap>
    {disputes.data.data.length  ? (
                <CenterContent>
                {isEmpty ? (
                  <div style={{justifyContent:'center', display: 'grid'}}>
                        <div style={{textAlign: 'center'}}>
                          <img src={Nothing} alt="Empty table" />
                        </div>
                        <div style={{textAlign: 'center'}}>
                          <h5 style={{marginBottom: '10px'}}>No search results found</h5>
                        </div>
                        <div>
                        </div>
                      </div>
                ) : (
                  <>
                  <Tablle style={{ textAlign: 'left', borderCollapse: 'collapse'}}>
                        <Thead style={{ backgroundColor: "#F3E8F7",
  color: "#1E1E1E",
  fontWeight: "600",
  lineHeight:"21px"}}>
                            <Tr>
                            <Th><ThText>Status</ThText></Th>
                            <Th><ThText>Dispute Description</ThText></Th>
                            <Th><ThText>Dispute Type</ThText></Th>
                            <Th><ThText>Dispute ID</ThText></Th>
                            <Th><ThText>Time</ThText></Th>
                            <Th><ThText>Open</ThText></Th>
                            </Tr>
                        </Thead>
                        
                        <Tbody>
                            {currentItems.map((dispute, index) => (
                            <Tr key={index} style={{borderBottom: '1px solid #DDD', cursor: 'pointer'}}>
                            <Td><Tdreg><P2pH1 style={{fontSize: '12px', color: dispute.status === 'closed' ? '#24ce24' : '#0070FF'}}>{dispute.status}</P2pH1></Tdreg></Td>
                            <Td><Tdreg1 style={{overflowWrap: 'break-word'}}>{dispute.description}</Tdreg1></Td>
                            <Td><Tdreg>{dispute.dispute_type}</Tdreg></Td>
                            <Td><Tdreg>{dispute.id}</Tdreg></Td>
                            <Td><Tdreg>{new Date(dispute.created_at).toLocaleString()}</Tdreg></Td>
                            <Td style={{display: 'flex', marginTop: '10px'}}>
                                <CancelBtn onClick={() =>  handleDisputeClick(dispute.id)}>Dispute</CancelBtn> 
                            </Td>
                            </Tr> ))}
                        </Tbody>
                    </Tablle>
                    <div>
                    <ul style={{display: 'flex', listStyle: 'none', margin:'10px'}}>
                    <button onClick={handlePrevPage} disabled={isFirstPage} style={{ cursor: isFirstPage ? 'not-allowed' : 'pointer', backgroundColor: isFirstPage ? '#8080808c' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                          <GrPrevious />
                        </button>
                        
                        {pageNumbers.map(number => (
                          <li key={number}>
                            <button id={number} onClick={handleClick} style={{ backgroundColor: currentPage === number ? '#FCF3FF' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                              {number}
                            </button>
                          </li> 
                        ))}
                        <button onClick={handleNextPage} disabled={isLastPage} style={{ cursor: isLastPage ? 'not-allowed' : 'pointer', backgroundColor: isLastPage ? '#8080808c' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                          <GrNext/>
                        </button> 
                      </ul>
                    </div> </>)}
                    {showDetails && (
                    <Popup open={open1} onClose={() => { setOpen1(false); setDim(false)}} modal closeOnDocumentClick contentStyle={{width: '100%', height: '100%', backgroundColor: 'white', overflow: 'scroll'}} overlayStyle={{background: dim ? 'rgba(0, 0, 0, 0.5)' : 'none'}}>
                        {close => (
                      <div className="content" >
                      <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><a className="close" onClick={close}>
                        <b style={{fontSize: '26px', marginLeft: '10px', alignItems: 'center'}}>&times; | </b>
                        </a> <b>Confirm Payment</b> </div>
                          <Form3 style={{backgroundColor: '#FDF9FF'}} >
                            <FormH2 style={{marginTop: '-45px', fontWeight: 'bolder' }}>Confirm Payment</FormH2>
                            <p style={{margin: '10px'}}>Wait for the customer to transfer the funds into your chosen payment method</p>
                            <FormH1>{selectedData.trade_currency} {selectedData.trade_amount}</FormH1>
                            <RoundedDiv3 style={{background: '#fff', marginTop: '20px', marginLeft: '15px'}}>
                            <br></br>
                            <FormLabel style={{fontSize: '16px'}}>{selectedData.payment_method.payment_info.method_name}</FormLabel>
                            <RoundedDiv2 style={{}}>
                            {Object.entries(selectedData.payment_method.payment_info).map(([key, value]) => (
                                <P2pCard>
                                    <DivLeft2>
                                        <P2pInfo style={{marginBottom: '-15px'}}>{key.replace(/_/g, ' ')}</P2pInfo>
                                    </DivLeft2>
                                    <DivRight2>
                                        <P2pInfo2 style={{marginBottom: '-15px'}}>{value}</P2pInfo2>
                                    </DivRight2>
                                    
                                </P2pCard>
                                ))}
                                <P2pCard>
                                <DivLeft2><P2pInfo style={{marginBottom: '-15px'}}>Transaction ID</P2pInfo></DivLeft2>
                                <DivRight2><P2pInfo2 style={{marginBottom: '-15px'}}>{selectedData.transaction_id}</P2pInfo2></DivRight2>
                                </P2pCard>
                            </RoundedDiv2>
                        </RoundedDiv3>
                        <div style={{display:'flex'}}>
                          <div style={{width: '97%', marginRight: '10px'}}>
                            <PrimaryBtn2 style={{background: 'linear-gradient(180deg, #F3E8F7 0%, #EBDAF1 100%)',color: '#791FA4', borderRadius: '6px', width: '100%'}}>Cancel</PrimaryBtn2>
                          </div>
                          <div style={{width: '100%'}}>
                          {selectedData.agent_id === user.data.id && selectedData.trade_type === 'sell' ? (
                            <PrimaryBtn2 style={{backgroundColor: '#9316BF', borderRadius: '6px', width: '100%'}} onClick={() => handleReceivedWithVerification(selectedData.trade_id)}>Received</PrimaryBtn2>
          ) : <PrimaryBtn2 style={{backgroundColor: '#9316BF', borderRadius: '6px', width: '100%'}} onClick={() => handlePaidWithVerification(selectedData.trade_id)}>I have paid</PrimaryBtn2>}
                          </div>
                        </div>
                        <div style={{textAlign: 'center', alignItems: 'center', marginTop:'15px'}}><FormLabel style={{color: '#B51010', fontSize: '16px', alignItems: 'center', cursor: 'pointer'}} onClick={() =>  handleDisputeModal(selectedData.trade_id)} ><MdOutlineReportProblem/> Dispute</FormLabel></div>
                        {showNewDispute && (
                            <Background className="modal">
                            <PopupCard className="modal-content">
                              <CloseButton onClick={handleDisputeModal}>&times;</CloseButton>
                              <h3 style={{margin:'10px', textAlign: 'center'}}>Open New Dispute</h3>
                              <Form2 style={{border: 'none', marginBottom: '-20px', marginTop: '-40px'}} onSubmit={NewDispute}>
                                <FormLabel>Select Dispute Type</FormLabel>
                                <Select style={{ borderColor: '#979797' }} value={{ value: dispute_type, label: dispute_type }} onChange={handleDisputeType} options={options} getOptionValue={(option) => option.value}/>                                
                                <FormLabel style={{marginTop: '15px', marginBottom: '0px'}}>Description</FormLabel>    
                                <FormArea style={{marginBottom: ''}} value={description} onChange={handleDescription} />                            
                              <PrimaryBtn2  style={{backgroundColor: '#9316BF', borderRadius: '6px', width: '100%'}} Link>Open Dispute</PrimaryBtn2>
                              </Form2>
                            </PopupCard>
                          </Background>
                          )}
                          </Form3>
                          {showPinVerification && (
                          <Pin
                              show={showPinVerification}
                              setShow={setShowPinVerification}
                              onPinVerified={verificationCallback}
                              onCancel={handleCancelPinVerification}
                            />  )}
                      </div>)}
                    </Popup>
                    )}
                </CenterContent>
                ) : (
                        <div style={{justifyContent:'center', display: 'grid'}}>
                        <div style={{textAlign: 'center'}}>
                          <img src={Empty} alt="Empty table" />
                        </div>
                        <div style={{textAlign: 'center'}}>
                          <h5 >You have no disputes </h5>
                          <p style={{marginBottom: '10px', fontSize: '10px'}}>If you need any assistance contact support</p>
                        </div>
                        <div>
                        {/*<div style={{display:'flex'}}>
                          <div style={{width: '97%', marginRight: '10px'}}>
                            <NavLink to='/topup'> 
                                <PrimaryBtn2 style={{background: 'linear-gradient(180deg, #F3E8F7 0%, #EBDAF1 100%)',color: '#791FA4', borderRadius: '6px', width: '100%'}}>Top Up</PrimaryBtn2>
                            </NavLink>
                          </div>
                          <div style={{width: '100%'}}>
                            <NavLink to='/payout'>
                                <PrimaryBtn2 style={{backgroundColor: '#9316BF', borderRadius: '6px', width: '100%'}}>Pay Out</PrimaryBtn2>
                            </NavLink>
                          </div>
                        </div> */}
                        </div>
                      </div>
                      )}
            </FormWrap>
  </P2pcontainer>) : <Loader/>}
    
                        
            </FormWrap>
        </Container>
        </MainContent>
        </MainContainer>
        {showAlert && (
        <AlertModal
          isModalOpen={showAlert}
          alertMessage={alertMessage}
          onClose={handleAlertClose}
        />
      )}
    </>
  )
}

export default P2pDisute