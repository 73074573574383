import React, {useState, useEffect} from 'react'
import { InputArea, SelectInput } from '../components/Dashboard/content/dashElements'
import { Container, Form2, FormContent, FormH1, FormH2, FormInput, FormLabel, FormWrap, Icon, PrimaryBtn, PrimaryBtn2, Text, Text2 } from '../components/Form/formElements'
import Paylink from '../assets/Ellipse12.png'
import { useNavigate } from 'react-router-dom'
import slugify from 'react-slugify';
import axios from 'axios'
import AlertModal from './modal'
import BASE_URL from '../apiConfig'

const CreateDonationLink = () => {

  function generateRandomString() {
    let result           = '';
    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for ( let i = 0; i < 4; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }
  
  const [randomString] = useState(generateRandomString());
  const token = localStorage.getItem('bearerToken');
  const [link_title, setLink_title] = useState('')
  const [link_description, setLink_description] = useState('')
  const [link_type] = useState('donation')
  const [phone_number, setPhone_number] = useState('0')  
  const [shipping_address] = useState('no')  
  const [redirect_website, setRedirect_website] = useState('')
  const [payment_success_msg, setPayment_success_msg] = useState('')
  const [product_id]  = useState('0')  
  const slug = slugify(link_title + '-' + randomString)
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
 


  const handleLink_title = (e) => { setLink_title(e.target.value)}
  const handleLink_description = (e) => { setLink_description(e.target.value)}
  const handlePhone_number = (e) => { setPhone_number(e.target.checked ? '1' : '0' )}
  const handleRedirect_website = (e) => { setRedirect_website(e.target.value)}
  const handlePayment_success_msg = (e) => { setPayment_success_msg(e.target.value)}
  let history = useNavigate();

  const CreateDonationLink = (event) => {
    event.preventDefault();
    axios.post(`${BASE_URL}/payment-link`, {
      link_description : link_description,
      link_title: link_title,
      link_type: link_type,
      phone_number: phone_number,
      shipping_address: shipping_address,
      redirect_website: redirect_website,
      payment_success_msg: payment_success_msg, 
      product_id: product_id, 
      slug: slug
    }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }).then(async (result) => {
      setAlertMessage('Successful!');
      setShowAlert(true);
      await new Promise((resolve) => setTimeout(resolve, 3000));
      history("/paymentlink-history")
    })
      .catch(error => {
        const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
        setAlertMessage(jsonString);
        setShowAlert(true);
      })
    }

    const handleAlertClose = () => {
      setShowAlert(false);
    };

  return (
    <>
                    <FormWrap>
                    <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><a className="close" onClick={() => history(-1)}>
                        <b style={{fontSize: '26px', marginLeft: '10px', alignItems: 'center', cursor: 'pointer'}}>&times; | </b>
                        </a> <b>Create Donation Link</b> </div>
                <FormContent>
                    <Icon src={Paylink}  width='140px' style={{marginBottom:'-20px'}} />
                    <FormH2>Donation Link Information</FormH2>
                    <Text2>Details of your donation link</Text2>
                    <Form2 onSubmit={CreateDonationLink}>
                        <FormLabel for='link_title'>Donation link title</FormLabel>
                        <FormInput id='link_title' placeholder='Enter Donation link title' value={link_title} onChange={handleLink_title}/>
                        <FormLabel for='link_description'>Donation link Description</FormLabel>
                        <InputArea id='link_description' type='text' placeholder='Enter donation link Description' value={link_description} onChange={handleLink_description}/>

                        <FormLabel for='donationImage'>Image (optional)</FormLabel>
                        <FormInput id='donationIamge' placeholder='Drop your image here or browse' type='file'  />


                        <FormLabel for='redirect_website'>Your Website URL (Leave empty if you dont have a website URL)</FormLabel>
                        <FormInput id='redirect_website' placeholder='Enter your website address' value={redirect_website} onChange={handleRedirect_website} />

                        <div style={{display:'flex', marginTop: '10px', marginBottom: '10px'}}><FormInput type="checkbox" id='tc' required style={{width: '30px', height:"30px", marginRight:"10px"}}/><div style={{color:'#000', fontFamily:'Poppins', fontSize:"14px"}}>Add social media handles</div></div><br></br>

                        
                        <FormLabel>Extra options</FormLabel>
                        <div style={{display:'flex', marginTop: '10px', marginBottom: '10px'}}><FormInput type="checkbox" id='phone_number' onChange={handlePhone_number} required style={{width: '30px', height:"30px", marginRight:"10px"}}/><div style={{color:'#000', fontFamily:'Poppins', fontSize:"14px"}}>Collect donor's phone number</div></div><br></br>

                        <FormLabel>Confirmation</FormLabel>
                        <div style={{marginBottom: '20px'}} ><FormLabel style={{display: 'inline-flex'}} ><FormInput type="radio" id="confirm" name="confirm" value="true" style={{width: '25px', height: '25px', marginRight: '10px'}} /> Display confirmation page after payment</FormLabel></div>

                        <FormLabel for='customMessage'>Add Custom Message</FormLabel>
                        <InputArea id='customMessage' type='text' value={payment_success_msg} onChange={handlePayment_success_msg} />
                        <div style={{marginBottom: '20px'}} ><FormLabel style={{display: 'inline-flex'}} ><FormInput type="radio" id="redirect" name="redirect" value="true" style={{width: '25px', height: '25px', marginRight: '10px'}} /> Redirect to website after payment</FormLabel></div>

                        <PrimaryBtn2 type='submit'>Create Donation Link</PrimaryBtn2>
                    </Form2>
                </FormContent>
            </FormWrap>
            {showAlert && (
        <AlertModal
          isModalOpen={showAlert}
          alertMessage={alertMessage}
          onClose={handleAlertClose}
        />
      )}
    </>
  )
}

export default CreateDonationLink