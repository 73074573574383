import React, {useState, useEffect} from 'react'
import { FaSearch } from 'react-icons/fa';
import { Table as Tablle, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import DashboardSidebar from '../components/Dashboard/sidebar';
import Navbar from '../components/Navbar';
import { CenterContent, Container, FormWrap, PrimaryBtn, SearchBar, TdRef, Tdreg, ThText } from '../components/Dashboard/content/dashElements';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import axios from 'axios'
import { CancelBtn } from '../components/Dashboard/p2pElements/p2pElements';
import { NavLink, useNavigate } from 'react-router-dom'
import Loader from '../components/loader';
import Nothing from '../assets/nothingtoshow.png'
import Empty from '../assets/empty.svg'
import AlertModal from './modal';
import Sidebar1, { MainContainer, MainContent } from './nav'
import {GrPrevious, GrNext } from 'react-icons/gr';
import BASE_URL from '../apiConfig'


const Products = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
    const navigate = useNavigate()
    const [products, setProducts] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
    const token = localStorage.getItem('bearerToken');

    const fetchProducts = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/products`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
    
          return response.data;
        } catch (error) {
          console.error(error);
        }
      };
      useEffect(() => {
        const getProducts = async () => {
          const data = await fetchProducts();
          setProducts(data);
        };
    
        getProducts();
      }, []);
      const handleDelete = async (id) => {
        try {
          setIsDeleting(true);
          await axios.delete(`${BASE_URL}/products/${id}`,{
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          // Handle successful deletion, e.g. remove the trade from the table data and update the UI
          setAlertMessage('successfully deleted');
          setShowAlert(true);
          await new Promise((resolve) => setTimeout(resolve, 2000));
          navigate(0);
        } catch (error) {
          console.error(error);
          const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
          setAlertMessage(jsonString);
          setShowAlert(true);
          // Handle error, e.g. display an error message to the user
        } finally {
          setIsDeleting(false);
        }
      };

      const handleAlertClose = () => {
        setShowAlert(false);
      };

      const [isEmpty, setIsEmpty] = useState(false);
      const [searchQuery, setSearchQuery] = useState('');
      
      const filteredData = products?.data.filter((prod) =>
      prod.id.toString().includes(searchQuery.toLowerCase())||
      prod.product_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      prod.product_currency.toLowerCase().includes(searchQuery.toLowerCase()) ||
      prod.product_price.toLowerCase().includes(searchQuery.toLowerCase())
      )

      const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // reset to first page when search query changes
        setIsEmpty(filteredData.length === 0);
        if (searchQuery === 0) {
          setSearchQuery(null);
        }
      };


      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);
  
      const pageNumbers = [];
      for (let i = 1; i <= Math.ceil(filteredData?.length / itemsPerPage); i++) {
        pageNumbers.push(i);
      }

     const handlePrevPage = () => {
        setCurrentPage( currentPage - 1);
      };
     const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
      };
      const handleClick = e => {
        setCurrentPage(Number(e.target.id));
      };

      const isLastPage = currentPage === pageNumbers.length;
      const isFirstPage = currentPage === 1;

  return (
    <>
    {showAlert && (
        <AlertModal
          isModalOpen={showAlert}
          alertMessage={alertMessage}
          onClose={handleAlertClose}
        />
      )}
      <Navbar toggleSidebar={toggleSidebar}/>
        <MainContainer isOpen={isOpen}>
            <Sidebar1 isOpen={isOpen} toggleSidebar={toggleSidebar} />
            <MainContent>
        <Container style={{marginLeft: '30px'}}>
        {products ? (
          <>
            <h2>Products</h2>
            <div style={{position:"flex", marginTop: '20px', width: '100%', marginBottom: '20px'}}>
                <SearchBar type="text" value={searchQuery} onChange={handleSearchInputChange} placeholder='search products'  />
            </div>
            <NavLink to='/create-product'>
                <PrimaryBtn style={{width: '170px'}}>Create Product</PrimaryBtn>
            </NavLink>
            <FormWrap>
            {products.data.length  ? (
                <CenterContent>
                {isEmpty ? (
                  <div style={{justifyContent:'center', display: 'grid'}}>
                        <div style={{textAlign: 'center'}}>
                          <img src={Nothing} alt="Empty table" />
                        </div>
                        <div style={{textAlign: 'center'}}>
                          <h5 style={{marginBottom: '10px'}}>No search results found</h5>
                        </div>
                        <div>
                        </div>
                      </div>
                ) : (
                  <>
                  <Tablle style={{ textAlign: 'left', borderCollapse: 'collapse', maxWidth:'95%'}}>
                        <Thead style={{ backgroundColor: "#F3E8F7",
  color: "#1E1E1E",
  fontWeight: "600",
  lineHeight:"21px"}}>
                            <Tr>
                            <Th><ThText>S/N</ThText></Th>
                            <Th><ThText>Product Name</ThText></Th>
                            <Th><ThText>Price</ThText></Th>
                            <Th><ThText>Product Image</ThText></Th>
                            <Th><ThText></ThText></Th>
                            </Tr>
                        </Thead>
                        
                        <Tbody>
                            {currentItems.map((prod, index) => (
                            <Tr key={index} style={{borderBottom: '1px solid #ddd'}}>
                            <Td><Tdreg>{prod.id}</Tdreg></Td>
                            <Td><Tdreg>{prod.product_name}</Tdreg></Td>
                            <Td><Tdreg>{prod.product_currency}{prod.product_price}</Tdreg></Td>
                            <Td>
                              <Tdreg>
                                {prod.product_image ? (
                                  <img src={prod.product_image} style={{width: '50px', height: '50px'}} />
                                ) : (
                                  <p style={{color: '#7416a0'}}>No image</p>
                                )}
                              </Tdreg>
                          </Td>

                            <Td>
                            <CancelBtn onClick={() => handleDelete(prod.id)} disabled={isDeleting} style={{marginRight: '15px'}}>{isDeleting ? 'Deleting...' : 'Delete'}</CancelBtn>
                            </Td>
                            </Tr>
                            ))}                            
                        </Tbody>
                    </Tablle>
                    <div>
                    <ul style={{display: 'flex', listStyle: 'none', margin:'10px'}}>
                    <button onClick={handlePrevPage} disabled={isFirstPage} style={{ cursor: isFirstPage ? 'not-allowed' : 'pointer', backgroundColor: isFirstPage ? '#8080808c' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                          <GrPrevious />
                        </button>
                        
                        {pageNumbers.map(number => (
                          <li key={number}>
                            <button id={number} onClick={handleClick} style={{ backgroundColor: currentPage === number ? '#FCF3FF' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                              {number}
                            </button>
                          </li> 
                        ))}
                        <button onClick={handleNextPage} disabled={isLastPage} style={{ cursor: isLastPage ? 'not-allowed' : 'pointer', backgroundColor: isLastPage ? '#8080808c' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                          <GrNext/>
                        </button> 
                      </ul>
                    </div>
                    </>)}
                </CenterContent>
                ) : (
                        <div style={{justifyContent:'center', display: 'grid'}}>
                        <div>
                          <img src={Empty} alt="Empty table" />
                        </div>
                        <div style={{textAlign: 'center'}}>
                          <h4 style={{marginBottom: '10px'}}>Nothing to display</h4>
                        </div>
                        <div>
                            <NavLink to='/create-product'> 
                                <PrimaryBtn style={{width: '100%'}} Link>Create Product</PrimaryBtn>
                            </NavLink>
                        </div>
                      </div>
                      )}
            </FormWrap>
            </>
            ) : <Loader/>}
        </Container>
        </MainContent>
        </MainContainer>
    </>
  )
}

export default Products