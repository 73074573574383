import React, { useState } from 'react';

const MyForm = () => {
  const [fields, setFields] = useState([{ name: '', email: '' }]);

  const handleChange = (index, field, value) => {
    const updatedFields = [...fields];
    updatedFields[index][field] = value;
    setFields(updatedFields);
  };

  const handleAddField = () => {
    setFields([...fields, { name: '', email: '' }]);
  };

  const handleRemoveField = (index) => {
    const updatedFields = [...fields];
    updatedFields.splice(index, 1);
    setFields(updatedFields);
  };

  return (
    <form>
      {fields.map((field, index) => (
        <div key={index} style={{ display: 'flex', marginTop: '10px' }}>
          <div style={{ width: '50%' }}>
            <label htmlFor={`name-${index}`}>Name</label>
            <input
              id={`name-${index}`}
              type="text"
              value={field.name}
              onChange={(e) => handleChange(index, 'name', e.target.value)}
              placeholder="John Doe"
              style={{ width: '97%' }}
            />
          </div>
          <div style={{ width: '50%' }}>
            <label htmlFor={`email-${index}`}>Email</label>
            <input
              id={`email-${index}`}
              type="text"
              value={field.email}
              onChange={(e) => handleChange(index, 'email', e.target.value)}
              placeholder="johndoe@example.com"
              style={{ width: '97%' }}
            />
          </div>
          {index > 0 && (
            <button type="button" onClick={() => handleRemoveField(index)}>
              Remove
            </button>
          )}
        </div>
      ))}
      <button type="button" onClick={handleAddField}>
        Add more
      </button>
    </form>
  );
};

export default MyForm;
