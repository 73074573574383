import React, {useState, useEffect} from 'react'
import { BiArrowBack } from 'react-icons/bi'
import {GrPrevious, GrNext } from 'react-icons/gr';
import { AiOutlineArrowDown } from 'react-icons/ai'
import { AiOutlineArrowUp } from 'react-icons/ai'
import { RiExchangeCnyLine } from 'react-icons/ri'
import { Form3, Form3A, Form3B, FormW, Span, WalletMobile } from '../components/Form/formElements'
import Navbar from '../components/Navbar'
import { PrimaryBtn, TableRow, TableCell, Tablee, TableBody, ContentDiv, RoundedDiv, FormWrap2 } from '../components/Dashboard/content/dashElements'
import 'react-tabs/style/react-tabs.css';
import axios from 'axios'
import { P2pH1 } from '../components/Dashboard/p2pElements/p2pElements'
import { NavLink } from 'react-router-dom'
import { Table as Tablle, Tbody, Tr, Td } from 'react-super-responsive-table';
import { Container, FormWrap, Tdreg } from '../components/Dashboard/content/dashElements';
import { useNavigate, useLocation } from 'react-router-dom'
import WalletIcon from '../assets/WalletIcon.svg'
import DepositIcon from '../assets/DepositIcon.svg'
import SwapIcon from '../assets/SwapIcon.svg'
import MobileDeposit from '../assets/mobileDeposit.svg'
import MobileSend from '../assets/mobileSend.svg'
import MobileSwap from '../assets/mobileSwap.svg'
import SendIcon from '../assets/SendIcon.svg'
import Empty from '../assets/empty.svg'
import AlertModal from './modal'
import Sidebar1, { MainContainer, MainContent } from './nav'
import BASE_URL from '../apiConfig'
const Wallet = () => {
    const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const handleAlertClose = () => {
    setShowAlert(false);
  }
  const [showBasicTransactions, setShowBasicTransactions] = useState(true);
  const [showP2pTransactions, setShowP2pTransactions] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [transactions, setTransactions] = useState(null);
  const [p2pTransactions, setP2pTransactions] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');
  const token = localStorage.getItem('bearerToken');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [currentPage2, setCurrentPage2] = useState(1);
  const [itemsPerPage2] = useState(5);
  const navigate = useNavigate()

  const fetchTransactions = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/cmd-get-all?currency=${currency}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const getTransactions = async () => {
      const data = await fetchTransactions();
      setTransactions(data);
    };

    getTransactions();
  }, []);
  const fetchP2pTransactions = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/trade/history/buy-sell-history?currency=${currency}&status=success`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const getP2pTransactions = async () => {
      const data = await fetchP2pTransactions();
      setP2pTransactions(data);
    };

    getP2pTransactions();
  }, []);

  const [activeTab, setActiveTab] = useState('basic');
  const [hasBasicTransactions, setHasBasicTransactions] = useState(transactions?.data.data.length > 0);
  const [hasP2pTransactions, setHasP2pTransactions] = useState(p2pTransactions?.data.data.length > 0);
  
  const [isEmpty, setIsEmpty] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
    const filteredTransactions = transactions?.data.data.filter((transaction) =>
      transaction?.reference.toLowerCase().includes(searchQuery.toLowerCase())||
      transaction?.status.toLowerCase().includes(searchQuery.toLowerCase()) ||
      transaction?.customerEmail?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      transaction?.customerName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
      transaction?.order_type.replace('_', ' ').toLowerCase().includes(searchQuery.toLowerCase()) ||
      transaction?.currency.toLowerCase().includes(searchQuery.toLowerCase())
    )
    const filteredData = p2pTransactions?.data.data.filter((trnx) =>
      trnx?.trade_status.toLowerCase().includes(searchQuery.toLowerCase())||
      trnx?.trade_currency.toLowerCase().includes(searchQuery.toLowerCase())||
      trnx?.trade_amount.toLowerCase().includes(searchQuery.toLowerCase())||
      trnx?.trade_type.toLowerCase().includes(searchQuery.toLowerCase())||
      trnx?.payment_method?.payment_info?.method_name.toLowerCase().includes(searchQuery.toLowerCase())||
      trnx?.transaction_id.toLowerCase().includes(searchQuery.toLowerCase()) ||
      new Date(trnx?.updated_at).toLocaleString().toLowerCase().includes(searchQuery.toLowerCase())
      )

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const indexOfLastItem2 = currentPage2 * itemsPerPage2;
  const indexOfFirstItem2 = indexOfLastItem2 - itemsPerPage2;
  const currentItems = filteredTransactions?.slice(indexOfFirstItem, indexOfLastItem);
  const currentItems2 = filteredData?.slice(indexOfFirstItem, indexOfLastItem);

  const pageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredTransactions?.length / itemsPerPage); i++) {
    pageNumbers.push(i);
  }

 const handlePrevPage = () => {
    setCurrentPage( currentPage - 1);
  };
 const handleNextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  const handleClick = e => {
    setCurrentPage(Number(e.target.id));
  };

  const isLastPage = currentPage === pageNumbers.length;
  const isFirstPage = currentPage === 1;

  const pageNumbers2 = [];
  for (let i = 1; i <= Math.ceil(filteredData?.length / itemsPerPage2); i++) {
    pageNumbers2.push(i);
  }

 const handlePrevPage2 = () => {
    setCurrentPage2( currentPage2 - 1);
  };
 const handleNextPage2 = () => {
    setCurrentPage2(currentPage2 + 1);
  };
  const handleClick2 = e => {
    setCurrentPage2(Number(e.target.id));
  };

  const isLastPage2 = currentPage2 === pageNumbers2.length;
  const isFirstPage2 = currentPage2 === 1;

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
    setCurrentPage(1); // reset to first page when search query changes
    setIsEmpty(filteredTransactions.length === 0);
    if (searchQuery === 0) {
      setSearchQuery(null);
    }
  };
  const location = useLocation();
    const currency = location.state.currency;
    const bal = location.state.bal;
    const type = location.state.type;
    const ledger_balance = location.state.ledger_balance;
  return (
    <>
    <Navbar toggleSidebar={toggleSidebar}/>
    <MainContainer isOpen={isOpen}>
        <Sidebar1 isOpen={isOpen} toggleSidebar={toggleSidebar} />
        <MainContent>
    <Container >
        <FormWrap2>
            <div style={{marginTop: '24px', fontWeight: '400', fontSize:'16px', lineHeight:'27px', color:'#4D0B6C', alignItems:'center', display:'flex',cursor:'pointer', fontFamily:'poppins' }} onClick={()=> {navigate(-1)}}><BiArrowBack style={{float:'left',  marginRight:'5px'}} /> dashboard
            </div>
            <Span>{currency} Wallet</Span>
            <FormW style={{}}>
                <div style={{display:'flex'}}>
                    <img src={WalletIcon}/>
                    <div style={{display:'grid', marginLeft:'15px'}}>
                        <span>Balance</span>
                        <span style={{fontWeight: '600', fontSize:'32px', lineHeight:'48px', color:'#4D0B6C',  fontFamily:'poppins'}}>{currency} {type === 'crypto' ? (bal) : Number(bal).toFixed(2) } </span>
                        <span style={{fontWeight: '600', fontSize:'12px', fontFamily:'poppins', lineHeight:'22px'}}>Ledger balance: {currency} {type === 'crypto' ? (bal) : Number(ledger_balance).toFixed(2) } </span>
                    </div>
                </div>
                <div style={{display:'flex'}}>
                <img src={DepositIcon} style={{ marginRight: '8px', cursor: 'pointer' }} onClick={() => {navigate("/topup", { state: { currency: currency } })}} />
                <img src={SwapIcon} style={{ margin: '0 8px', cursor: 'pointer' }} onClick={() => {navigate("/swap", { state: { currency: currency } })}} />
                <img src={SendIcon} style={{ marginLeft: '8px', cursor: 'pointer' }} onClick={() => {navigate("/payout", { state: { currency: currency } })}}/>
                </div>
            </FormW>
            <WalletMobile>
                    <div style={{display:'grid'}}>
                        <span style={{color:'#595959', fontFamily:'poppins', fontWeight:'500', fontSize:'16px',lineHeight:'24px'}}>{currency} Balance</span>
                        <span style={{fontWeight: '600', fontSize:'24px', lineHeight:'48px', color:'#4D0B6C',  fontFamily:'poppins', marginTop:'-8px'}}>{currency} {type === 'crypto' ? (bal) : Number(bal).toFixed(2) } </span>
                        <span style={{fontWeight: '600', fontSize:'12px', fontFamily:'poppins', lineHeight:'12px'}}>Ledger balance: {currency} {type === 'crypto' ? (bal) : Number(ledger_balance).toFixed(2) } </span>
                    </div>
                      <hr style={{color:'#F0F0F0', marginTop:'10px'}}/>
                    
                <div style={{display:'flex', marginTop:'10px'}}>
                    <img src={MobileDeposit} style={{ marginRight: '8px', cursor: 'pointer' }} onClick={() => {navigate("/topup", { state: { currency: currency } })}} />
                    <img src={MobileSwap} style={{ margin: '0 8px', cursor: 'pointer' }} onClick={() => {navigate("/swap", { state: { currency: currency } })}} />
                    <img src={MobileSend} style={{ marginLeft: '8px', cursor: 'pointer' }} onClick={() => {navigate("/payout", { state: { currency: currency } })}}/>
                </div>
            </WalletMobile>
            <Form3A style={{padding:'32px 32px'}}>
            <div style={{display:'flex'}}>
              <div onClick={() => {setActiveTab('basic');setShowBasicTransactions(true); setShowP2pTransactions(false)}} style={{cursor:'pointer', color: activeTab === 'basic' ? '#4D0B6C' : '#595959',borderBottom: activeTab === 'basic' ? '3px solid #4D0B6C' : '',fontFamily:'Poppins', fontWeight:'600', fontSize:'15px', lineHeight:'30px'}}>Basic Transactions</div> 
              {type == "fiat" &&
              <div onClick={() => {setActiveTab('p2p');setShowP2pTransactions(true); setShowBasicTransactions(false)}} style={{cursor:'pointer', color: activeTab === 'p2p' ? '#4D0B6C' : '#595959',borderBottom: activeTab === 'p2p' ? '3px solid #4D0B6C' : '',fontFamily:'Poppins', fontWeight:'600', fontSize:'15px', lineHeight:'30px', marginLeft:'20px'}}>P2P Transactions</div> }
            </div>
            {showBasicTransactions && (
                      <>
                      { transactions?.data.data.length ? (
                        <>
                        <Tablee style={{border:'none',marginTop:'10px', marginBottom:'10px'}}>
                          <TableBody>
                            {currentItems?.map((trnx, index) => (
                              <TableRow key={index}>
                              <TableCell>
                                {trnx?.order_type === "currency_swap" ? (
                                    <span style={{backgroundColor:'#229ED90F', padding:'10px', alignItems:'center'}}><RiExchangeCnyLine color="#229ED9" /></span>
                                ) : trnx?.order_type === "customer_payment" || trnx?.order_type === "crypto_topup" ? (
                                    <span style={{backgroundColor:'#50BD6808', padding:'10px', alignItems:'center'}}><AiOutlineArrowDown color='#50BD68' /></span>
                                ) : (
                                    <span style={{backgroundColor:'#FF4B4B0A', padding:'10px', alignItems:'center'}}><AiOutlineArrowUp color='#FF4B4B' /></span>
                                )}
                              </TableCell>
                              <TableCell>
                                <div style={{display:'grid'}}>
                                  <span style={{fontWeight:'600', lineHeight:'27px', fontSize:'16px', fontFamily:'poppins', color:'#595959'}}>{trnx?.customerName ? (trnx?.customerName): (trnx?.order_type && trnx.order_type.replace('_', ' '))}</span>
                                  <span style={{color:'#979797', fontWeight:'400', lineHeight:'27px'}}>
                                      {new Date(trnx?.created_at).toLocaleString('en-GB', {day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}
                                  </span>
                                </div>
                              </TableCell>
                              <TableCell>
                                <div style={{display:'grid',  textAlign: 'right'}}>
                                    <span style={{fontWeight:'600', lineHeight:'27px', fontSize:'18px', fontFamily:'poppins'}}>{trnx?.currency} {trnx?.fiatAmount}{trnx?.to_amount}</span>
                                    <span><P2pH1 style={{ fontSize: '12px', lineHeight:'27px', color: trnx.status === 'pending' && '#4D0B6C' }}>{trnx.status}</P2pH1></span>
                                </div>
                              </TableCell>   
                              </TableRow>
                            ))}
                          </TableBody>
                      </Tablee>
                      <div>
                    <ul style={{display: 'flex', listStyle: 'none', margin:'10px'}}>
                    <button onClick={handlePrevPage} disabled={isFirstPage} style={{ cursor: isFirstPage ? 'not-allowed' : 'pointer', backgroundColor: isFirstPage ? '#8080808c' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                          <GrPrevious />
                        </button>
                        
                        {pageNumbers.map(number => (
                          <li key={number}>
                            <button id={number} onClick={handleClick} style={{ backgroundColor: currentPage === number ? '#FCF3FF' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                              {number}
                            </button>
                          </li> 
                        ))}
                        <button onClick={handleNextPage} disabled={isLastPage} style={{ cursor: isLastPage ? 'not-allowed' : 'pointer', backgroundColor: isLastPage ? '#8080808c' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                          <GrNext/>
                        </button> 
                      </ul>
                    </div>
                    </>):
                      (
                        <div style={{justifyContent:'center', display: 'grid', marginTop:'10px'}}>
                        <div style={{textAlign: 'center'}}>
                          <img src={Empty} alt="Empty table" />
                        </div>
                        <div style={{textAlign: 'center', marginBottom: '10px'}}>
                          <h4>You don’t have any transaction yet</h4>
                        </div>
                        <div>
                            <NavLink to='/topup'> 
                                <PrimaryBtn style={{width: '100%'}} Link>Top up</PrimaryBtn>
                            </NavLink>
                        </div>
                      </div>
                      )}
                      </>)}
                      {showP2pTransactions  && p2pTransactions && (
                      <>
                      { p2pTransactions?.data.data.length ? (
                        <>
                        <Tablee style={{border:'none',marginTop:'10px', marginBottom:'10px'}}>
                          <TableBody>
                          {currentItems2?.map((trnx, index) => (
                              <TableRow key={index}>
                              <TableCell>
                                {trnx?.order_type === "sell" ? (
                                  <span style={{backgroundColor:'#FF4B4B0A', padding:'10px', alignItems:'center'}}><AiOutlineArrowUp color='#FF4B4B' /></span>
                                ) :  (
                                  <span style={{backgroundColor:'#50BD6808', padding:'10px', alignItems:'center'}}><AiOutlineArrowDown color='#50BD68' /></span>
                                )}
                              </TableCell>
                              <TableCell>
                                <div style={{display:'grid'}}>
                                  <span style={{fontWeight:'600', lineHeight:'27px', fontSize:'16px', fontFamily:'poppins', color:'#595959'}}>{trnx.trade_type === "sell" ? "Payout" : "Deposit"}</span>
                                  <span style={{color:'#979797', fontWeight:'400', lineHeight:'27px'}}>
                                      {new Date(trnx?.created_at).toLocaleString('en-GB', {day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}
                                  </span>
                                </div>
                              </TableCell>
                              <TableCell>
                                <div style={{display:'grid',  textAlign: 'right'}}>
                                    <span style={{fontWeight:'600', lineHeight:'27px', fontSize:'18px', fontFamily:'poppins'}}>{trnx.trade_currency} {trnx.trade_amount}</span>
                                    <span><P2pH1 style={{ fontSize: '12px', lineHeight:'27px', color: trnx.status === 'pending' && '#4D0B6C' }}>{trnx.trade_status}</P2pH1></span>
                                </div>
                              </TableCell>   
                              </TableRow>
                            ))}
                          </TableBody>
                      </Tablee>
                      <div>
                    <ul style={{display: 'flex', listStyle: 'none', margin:'10px'}}>
                    <button onClick={handlePrevPage2} disabled={isFirstPage2} style={{ cursor: isFirstPage2 ? 'not-allowed' : 'pointer', backgroundColor: isFirstPage2 ? '#8080808c' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                          <GrPrevious />
                        </button>
                        
                        {pageNumbers2.map(number => (
                          <li key={number}>
                            <button id={number} onClick={handleClick2} style={{ backgroundColor: currentPage2 === number ? '#FCF3FF' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                              {number}
                            </button>
                          </li> 
                        ))}
                        <button onClick={handleNextPage2} disabled={isLastPage2} style={{ cursor: isLastPage2 ? 'not-allowed' : 'pointer', backgroundColor: isLastPage2 ? '#8080808c' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                          <GrNext/>
                        </button> 
                      </ul>
                    </div>
                      </>
                      ): (
                        <div style={{justifyContent:'center', display: 'grid', marginTop:'10px'}}>
                        <div style={{textAlign: 'center'}}>
                          <img src={Empty} alt="Empty table" />
                        </div>
                        <div style={{textAlign: 'center', marginBottom: '10px'}}>
                          <h4>You don’t have any transaction yet</h4>
                        </div>
                        <div>
                            <NavLink to='/topup'> 
                                <PrimaryBtn style={{width: '100%'}} Link>Top up</PrimaryBtn>
                            </NavLink>
                        </div>
                      </div>
                      )}
                        
                      </>)}
            </Form3A>
            <Form3B style={{maxWidth:'100%', padding:'50px 32px'}}>
            <div style={{display:'flex'}}>
              <div onClick={() => {setActiveTab('basic');setShowBasicTransactions(true); setShowP2pTransactions(false)}} style={{cursor:'pointer', color: activeTab === 'basic' ? '#4D0B6C' : '#595959',borderBottom: activeTab === 'basic' ? '3px solid #4D0B6C' : '',fontFamily:'Poppins', fontWeight:'600', fontSize:'15px', lineHeight:'30px'}}>Basic Transactions</div> 
              {type == "fiat" &&
              <div onClick={() => {setActiveTab('p2p');setShowP2pTransactions(true); setShowBasicTransactions(false)}} style={{cursor:'pointer', color: activeTab === 'p2p' ? '#4D0B6C' : '#595959',borderBottom: activeTab === 'p2p' ? '3px solid #4D0B6C' : '',fontFamily:'Poppins', fontWeight:'600', fontSize:'15px', lineHeight:'30px', marginLeft:'20px'}}>P2P Transactions</div> }
            </div>
            {showBasicTransactions && (
                      <>
                      { transactions?.data.data.length ? (
                        <>
                        <Tablee style={{border:'none'}}>
                          <TableBody style={{cursor: 'pointer'}}>
                            {currentItems?.map((trnx, index) => (
                              <TableRow key={index}>
                              <TableCell>
                                {trnx?.order_type === "currency_swap" ? (
                                    <span style={{backgroundColor:'#229ED90F', padding:'10px', alignItems:'center'}}><RiExchangeCnyLine color="#229ED9" /></span>
                                ) : trnx?.order_type === "customer_payment" || trnx?.order_type === "crypto_topup" ? (
                                    <span style={{backgroundColor:'#50BD6808', padding:'10px', alignItems:'center'}}><AiOutlineArrowDown color='#50BD68' /></span>
                                ) : (
                                    <span style={{backgroundColor:'#FF4B4B0A', padding:'10px', alignItems:'center'}}><AiOutlineArrowUp color='#FF4B4B' /></span>
                                )}
                              </TableCell>
                              <TableCell style={{fontWeight:'600', lineHeight:'27px', fontSize:'18px', fontFamily:'poppins'}}>{trnx?.customerName ? (trnx?.customerName): (trnx?.order_type && trnx.order_type.replace('_', ' '))}</TableCell>
                                  <TableCell style={{display:'inline-block',fontWeight:'600', lineHeight:'27px', fontSize:'18px', fontFamily:'poppins'}}><div>{trnx?.currency} {trnx?.fiatAmount}{trnx?.to_amount}</div></TableCell>
                                  <TableCell><P2pH1 style={{ fontSize: '12px',  textAlign: 'center', padding: '8px', color: trnx.status === 'pending' && '#4D0B6C' }}>{trnx.status}</P2pH1></TableCell>
                                  <TableCell style={{color:'#595959', fontWeight:'400', lineHeight:'27px'}}>
                                {new Date(trnx?.created_at).toLocaleString('en-GB', {day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true })}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                      </Tablee>
                      <div>
                    <ul style={{display: 'flex', listStyle: 'none', margin:'10px'}}>
                    <button onClick={handlePrevPage} disabled={isFirstPage} style={{ cursor: isFirstPage ? 'not-allowed' : 'pointer', backgroundColor: isFirstPage ? '#8080808c' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                          <GrPrevious />
                        </button>
                        
                        {pageNumbers.map(number => (
                          <li key={number}>
                            <button id={number} onClick={handleClick} style={{ backgroundColor: currentPage === number ? '#FCF3FF' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                              {number}
                            </button>
                          </li> 
                        ))}
                        <button onClick={handleNextPage} disabled={isLastPage} style={{ cursor: isLastPage ? 'not-allowed' : 'pointer', backgroundColor: isLastPage ? '#8080808c' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                          <GrNext/>
                        </button> 
                      </ul>
                        
                    </div>
                    </>):
                      (
                        <div style={{justifyContent:'center', display: 'grid'}}>
                        <div style={{textAlign: 'center'}}>
                          <img src={Empty} alt="Empty table" />
                        </div>
                        <div style={{textAlign: 'center', marginBottom: '10px'}}>
                          <h4>You don’t have any transaction yet</h4>
                        </div>
                        <div>
                            <NavLink to='/topup'> 
                                <PrimaryBtn style={{width: '100%'}} Link>Top up</PrimaryBtn>
                            </NavLink>
                        </div>
                      </div>
                      )}
                      </>)}
                      {showP2pTransactions  && p2pTransactions && (
                      <>
                      { p2pTransactions?.data.data.length ? (
                        <>
                        <Tablle style={{borderBottom: '1px solid #00000026', textAlign: 'left', borderCollapse: 'collapse'}}>
                          <Tbody>
                              {currentItems2.map((trnx, index) => (
                              <Tr key={index} style={{borderBottom: '1px solid #DDD', cursor: 'pointer'}}>
                              <Td ><Tdreg><P2pH1 >{trnx.trade_type === "sell" ? (<span style={{backgroundColor:'#FF4B4B0A', padding:'10px', alignItems:'center'}}><AiOutlineArrowUp color='#FF4B4B' /></span>): (<span style={{backgroundColor:'#50BD6808', padding:'10px', alignItems:'center'}}><AiOutlineArrowDown color='#50BD68' /></span>)}</P2pH1></Tdreg></Td>
                              <Td ><Tdreg style={{fontWeight:'600', lineHeight:'27px', fontSize:'18px', fontFamily:'poppins'}}>{trnx.trade_type === "sell" ? "Payout" : "Deposit"}</Tdreg></Td>
                              <Td ><Tdreg style={{fontWeight:'600', lineHeight:'27px', fontSize:'18px', fontFamily:'poppins'}}>{trnx.trade_currency} {trnx.trade_amount}</Tdreg></Td>
                              <Td ><Tdreg><P2pH1 style={{ fontSize: '12px',  textAlign: 'center', padding: '8px', color: trnx.status === 'pending' && '#4D0B6C' }}>{trnx.trade_status}</P2pH1></Tdreg></Td>
                              <Td ><Tdreg>{new Date(trnx.created_at).toLocaleString('en-GB', {
                                      day: 'numeric',
                                      month: 'short',
                                      year: 'numeric',
                                      hour: 'numeric',
                                      minute: 'numeric',
                                      hour12: true
                                  })}</Tdreg></Td>
                              </Tr> ))}
                          </Tbody>
                        </Tablle>
                        <div>
                    <ul style={{display: 'flex', listStyle: 'none', margin:'10px'}}>
                    <button onClick={handlePrevPage2} disabled={isFirstPage2} style={{ cursor: isFirstPage2 ? 'not-allowed' : 'pointer', backgroundColor: isFirstPage2 ? '#8080808c' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                          <GrPrevious />
                        </button>
                        
                        {pageNumbers2.map(number => (
                          <li key={number}>
                            <button id={number} onClick={handleClick2} style={{ backgroundColor: currentPage2 === number ? '#FCF3FF' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                              {number}
                            </button>
                          </li> 
                        ))}
                        <button onClick={handleNextPage2} disabled={isLastPage2} style={{ cursor: isLastPage2 ? 'not-allowed' : 'pointer', backgroundColor: isLastPage2 ? '#8080808c' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                          <GrNext/>
                        </button> 
                      </ul>
                    </div> 
                    </>
                      ): (
                        <div style={{justifyContent:'center', display: 'grid'}}>
                        <div style={{textAlign: 'center'}}>
                          <img src={Empty} alt="Empty table" />
                        </div>
                        <div style={{textAlign: 'center', marginBottom: '10px'}}>
                          <h4>You don’t have any transaction yet</h4>
                        </div>
                        <div>
                            <NavLink to='/topup'> 
                                <PrimaryBtn style={{width: '100%'}} Link>Top up</PrimaryBtn>
                            </NavLink>
                        </div>
                      </div>
                      )}
                        
                      </>)}
            </Form3B>
        </FormWrap2>
        </Container>                
        </MainContent>
        </MainContainer>
        {showAlert && (
        <AlertModal
          isModalOpen={showAlert}
          alertMessage={alertMessage}
          onClose={handleAlertClose}
        />
      )}
    </>
  )
}

export default Wallet