import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import BASE_URL from '../apiConfig'
import AlertModal from './modal';


const TransakPayComponent = () => {
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = localStorage.getItem('bearerToken');

  // Extract query parameters from the URL
  const orderId = queryParams.get('orderId');
  const fiatCurrency = queryParams.get('fiatCurrency');
  const cryptoCurrency = queryParams.get('cryptoCurrency');
  const fiatAmount = queryParams.get('fiatAmount');
  const cryptoAmount = queryParams.get('cryptoAmount');
  const orderStatus = queryParams.get('status');
  const walletAddress = queryParams.get('walletAddress');
  const totalFeeInFiat = queryParams.get('totalFeeInFiat');
  const partnerCustomerId = queryParams.get('partnerCustomerId');
  const partnerOrderId = queryParams.get('partnerOrderId');
  const network = queryParams.get('network');

  useEffect(() => {
    // Make an HTTP request to the desired endpoint with the extracted parameters
    axios
      .post(`${BASE_URL}/transak-pay`, {
        orderId,
        fiatCurrency,
        cryptoCurrency,
        fiatAmount,
        cryptoAmount,
        isBuyorSell: 'Buy',
        status: orderStatus,
        walletAddress,
        totalFeeInFiat,
        partnerCustomerId,
        partnerOrderId,
        network,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        // Handle the response from the API based on orderStatus
        if ( orderStatus.toLowerCase() == 'successful' || 'good' || 'ok' || '200') {
          alert('Successful');
          setAlertMessage('Transaction Successful!')
          setShowAlert(true)
        } else if (orderStatus.toLowerCase() == 'pending' || 'processing') {
          setAlertMessage('Transaction Pending!')
          setShowAlert(true)
        } else if (orderStatus.toLowerCase() == 'error' || 'failed' || 'cancelled') {
          setAlertMessage('Error, transaction not successful!')
          setShowAlert(true)
        } else {
          // Handle other status values here if needed
          setAlertMessage('Error, transaction not successful!')
          setShowAlert(true)
        }

        // Optionally, you can perform further actions based on the response
        console.log('API Response:', response.data);
      })
      .catch((error) => {
        // Handle API request errors
        console.error('API Error:', error);
      });
  }, []); 
  let navigate = useNavigate()
  const handleAlertClose = () => {
    setShowAlert(false);
    navigate('/dashboard')
  };

  return (
    <div>
      <AlertModal 
        isModalOpen={showAlert}
        alertMessage={alertMessage}
        onClose={handleAlertClose}
      />
    </div>
  );
};

export default TransakPayComponent;
