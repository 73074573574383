import React,{useState, useEffect, useRef} from 'react'
import { CenterContent, Container, FormWrap,PayoutCard,PayoutH,PayoutP,PayoutP2,PayoutCardWrapper, SelectInput, RoundedDiv2, RoundedDiv3, P2pCard, P2pInfo, P2pInfo2, DivLeft2, DivRight2, TopUpCard, TopupP, TopupH, TopupImage } from '../components/Dashboard/content/dashElements'
import DashboardSidebar from '../components/Dashboard/sidebar'
import Navbar from '../components/Navbar'
import Wallet from '../assets/wallet.png'
import Flw from '../assets/flutterwave.png'
import P2p from '../assets/p2p.png'
import Card from '../assets/card.png'
import coin from '../assets/coin.png'
import FiatImg from '../assets/fiat.svg'
import Crypto from '../assets/crypto.svg'
import transakImg from '../assets/transakImg.svg'
import Popup from 'reactjs-popup'
import { Form2, Form3, Form4, FormArea, FormH1, FormH2, FormInput, FormLabel, Icon, PrimaryBtn, PrimaryBtn2 } from '../components/Form/formElements'
import styled , { keyframes } from 'styled-components';
import axios from 'axios'
import USDT from '../assets/usdt.png'
import BTC from '../assets/btc.png'
import BCH from '../assets/bch.png'
import LTC from '../assets/LTC.png'
import bank1 from '../assets/bank1.png'
import { useNavigate, useLocation } from 'react-router-dom'
import Loader from '../components/loader'
import {FaRegCopy} from 'react-icons/fa'
import Hourglass from '../assets/Hourglass.png'
import Pin from './pin'
import AlertModal from './modal'
import {MdOutlineReportProblem} from 'react-icons/md'
import Select from 'react-select';
import Sidebar1, { MainContainer, MainContent } from './nav'
import BASE_URL from '../apiConfig'
import { ToastContainer, toast } from 'react-toastify'
import DePayWidgets from '@depay/widgets';
import transakSDK from '@transak/transak-sdk';


const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const Icon9 = styled.img`
    padding: 20px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    animation: ${spin} 6s linear infinite;
    transform-origin: center;
`
const Container1 = styled.div`
  display: flex;
  margin-top: 20px;

`;

const Select1 = styled.select`
padding: 16px 16px;
margin-bottom: 32px;
border: 1px solid #979797;
border-radius: 6px;
background-color: #fff;
color: #000;
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  outline: none;
`;

const Input = styled.input`
  padding: 16px 16px;
  margin-bottom: 32px;
  border: 1px solid #979797;
  border-radius: 6px;
  background-color: transparent;
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  outline: none;
  width:100%;
  @media screen and (max-width: 580px) {
        width: 80%;
    }
`;

const Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PopupCard = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 80%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  position: relative;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  border: none;
  background: transparent;

  &:hover {
    color: red;
  }
`;
export const DivPop = styled.div`
    height: 150px;
    @media screen and (max-width: 580px) {
        height: 195px;
    }
`
const B = styled.div`
    font-size: 17px;
    @media screen and (max-width: 580px) {
        font-size: 12px;
    }
`

const Topup = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
    const [copied, setCopied] = useState(false);
    const [payment_id, setPaymentID] = useState('')
    const [userPayment, setUserPayment] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [description, setDescription] = useState('');
    const [dispute_type, setDisputeType] = useState('');
    const [transaction_id, setTransactionID] = useState('');
    const [success, setSuccess] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showNewDispute, setShowNewDispute] = useState(false);
    const [buttonText, setButtonText] = useState('Sent');
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [status, setStatus] = useState(null);
    const [trnxid, setTrnxId] = useState(null);
    const [binanceUrl, setBinanceUrl] = useState(null);
    const [binanceQR, setBinanceQR] = useState(null);
    const [binanceCurrency, setBinanceCurrency] = useState(null);
    const [binanceTotalfee, setBinanceTotalfee] = useState(null);
    const [binanceFiatCurrency, setBinanceFiatCurrency] = useState(null);
    const [binanceFiatAmount, setBinanceFiatAmount] = useState(null);
    const [transakPaymentMethod, setTransakPaymentMethod] = useState('');
    
    const location = useLocation();
    const  currenzy  = location.state.currency;

    const paymentMethodsByCurrency = {
      USD: [
        { displayName: 'Credit/Debit Card', value: 'credit_debit_card' },
        { displayName: 'Apple Pay', value: 'apple_pay' },
        { displayName: 'Google Pay', value: 'google_pay' },
        { displayName: 'Cash App', value: 'pm_cash_app' },
        { displayName: 'Wire', value: 'wire' },
      ],
      ARS: [
        { displayName: 'Credit/Debit Card', value: 'credit_debit_card' },
        { displayName: 'Astropay', value: 'pm_astropay' },
      ],
      CLP: [
        { displayName: 'Astropay', value: 'pm_astropay' },
      ],
      GBP: [
        { displayName: 'Credit/Debit Card', value: 'credit_debit_card' },
        { displayName: 'Faster Payment Bank Transfer', value: 'gbp_bank_transfer' },
        { displayName: 'Open Banking', value: 'pm_open_banking' },
        { displayName: 'Apple Pay', value: 'apple_pay' },
        { displayName: 'Google Pay', value: 'google_pay' },
      ],
      CAD: [
        { displayName: 'Credit/Debit Card', value: 'credit_debit_card' },
        { displayName: 'Apple Pay', value: 'apple_pay' },
        { displayName: 'Google Pay', value: 'google_pay' },
      ],
      EUR: [
        { displayName: 'Credit/Debit Card', value: 'credit_debit_card' },
        { displayName: 'Sepa bank transfer', value: 'sepa_bank_transfer' },
        { displayName: 'Apple Pay', value: 'apple_pay' },
        { displayName: 'Google Pay', value: 'google_pay' },
      ]
    };

    const handleTransakPaymentMethodChange = (event) => {
      const selectedMethod = event.target.value;
      setTransakPaymentMethod(selectedMethod);
    };

    const handleDescription = (e) => {
      setDescription(e.target.value);
    };

    const handleDisputeType = (selectedOption) => {
      const { value } = selectedOption;
      setDisputeType(value);
    };
    const getOptionValue = (options) => options.value;
    const handleModal = () => {
      setShowModal(!showModal);
    }
    const handleDisputeModal = (ID) => {
      setTransactionID(ID)
      setShowNewDispute(!showNewDispute);
    }

    const NewDispute = (event) => {
      event.preventDefault();
        axios.post(`${BASE_URL}/dispute`, {
          transaction_id : transaction_id,
          dispute_type: dispute_type,
          description: description
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(async (response) => {
            setAlertMessage(response.data.message)
            setShowAlert(true)
            await new Promise((resolve) => setTimeout(resolve, 3000));
            navigate('/p2p')
          })
          .catch(error => {
            if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.error) {
              // Extract error message from response and alert it
              const errorMessage = error.response.data.errors.error;
              const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
              setAlertMessage(jsonString);
              setShowAlert(true)
            } else {
              setAlertMessage('Service Error');
              setShowAlert(true);
            }
          });
    };

  const fetchUserPayment = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/payment-method`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const getUserPayment = async () => {
      const data = await fetchUserPayment();
      setUserPayment(data);
    };

    getUserPayment();
  }, []);

  const handlePaymentID = (e) => {
    setPaymentID(e.target.value)
}

    useEffect(() => {
        let timeoutId;
        if (copied) {
          timeoutId = setTimeout(() => setCopied(false), 3000);
        }
        return () => {
          clearTimeout(timeoutId);
        };
      }, [copied]);

    const handleCopy = async (text) => {
      try {
        await navigator.clipboard.writeText(text);
        setCopied(true);
      } catch (err) {
        console.error('Failed to copy text: ', err);
      }
    };

    const [timeLeft, setTimeLeft] = useState(1820);

  useEffect(() => {
    let intervalId;
    if (timeLeft > 0) {
      intervalId = setInterval(() => setTimeLeft(timeLeft - 1), 1000);
    } else {
      clearInterval(intervalId);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [timeLeft]);

    const coins = [
        { value: 'USDT', label: <div><img src={USDT} height="30px" width="30px"/>USDT</div> },
        { value: 'BTC', label: <div><img src={BTC} height="30px" width="30px"/>BTC</div> },
        { value: 'LTC', label: <div><img src={LTC} height="30px" width="30px"/>LTC</div> },
        { value: 'BCH', label: <div><img src={BCH} height="30px" width="30px"/>BCH</div> },
      ];

    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open3, setOpen3] = useState(false);
    const [open4, setOpen4] = useState(false);
    const [dim, setDim] = useState(false);
    const [error, setError] = useState(null)

    const [open2, setOpen2] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [selectedData, setSelectedData] = useState(null);

    const [fiat, setFiat] = useState(currenzy);
    const [fiat2, setFiat2] = useState('');
    const [currencies, setCurrencies] = useState([]);
    const [cryptoResponse, setResponse] = useState([]);
    const [amount, setAmount] = useState('');
    const [depayAmount, setDepayAmount] = useState('');
    const [wallet_type, setWalletType] = useState('USDT');
    const token = localStorage.getItem('bearerToken');

    const [showPopup, setShowPopup] = useState(false);
    const [showBinanceButton, setShowBinanceButton] = useState(false);
    const [showRave, setShowRave] = useState('');
    const [raveUrl, setRaveUrl] = useState('');
    const [raveAmount, setRaveAmount] = useState('');

    let navigate = useNavigate()

    const fetchUser = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        return response.data;
      } catch (error) {
        console.error(error);
      }
    };
    useEffect(() => {
      const getUser = async () => {
        const data = await fetchUser();
        setUser(data);
      };
  
      getUser();
    }, []);


    const [user, setUser] = useState(null);

    const handlePaid = async (id) => {
      try {
        await axios.post(`${BASE_URL}/trade/paid`,{
          trade_id : id
        },{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // Handle successful deletion, e.g. remove the trade from the table data and update the UI
        setAlertMessage('Marked as paid');
        setShowAlert(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        handleModal()
        CheckStatus(id)
      } catch (error) {
        console.error(error);
        const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
        setAlertMessage(jsonString);
        setShowAlert(true);
        // Handle error, e.g. display an error message to the user
      }
    };

    const CheckStatus = async (id) => {
      try {
        const response = await axios.get(`${BASE_URL}/trade/trade-status?trade=${id}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.data.status === 'SUCCESS') {
        // Handle successful deletion, e.g. remove the trade from the table data and update the UI
        setAlertMessage(response.data.data.status);
        setShowAlert(true);
        await new Promise((resolve) => setTimeout(resolve, 3000));
        navigate(0)
        }
        else if (response.data.data.status === 'PENDING') {
          CheckStatus(id)
        }
      } catch (error) {
        console.error(error);
        const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
        setAlertMessage(jsonString);
        setShowAlert(true);
        // Handle error, e.g. display an error message to the user
      }
    };




    const handleCurrencyChange = (e) => {
      setFiat(e.target.value);
    };
    const handleCurrencyChange2 = (e) => {
      setFiat2(e.target.value);
    };
  
    const handleAmountChange = (e) => {
      setAmount(e.target.value);
    };
    const handleWalletTypeChange = (e) => {
      setWalletType(e.target.value);
    };
    useEffect(() => {
        
        const fetchCurrencies = async () => {
          try {
            const response = await axios.get(`${BASE_URL}/payment-method/sys-currencies`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            });
            setCurrencies(response.data.data);
          } catch (error) {
            setError(error);
          }
        };
        fetchCurrencies();
      }, []);

      const topUpCrypto = (event) => {
        event.preventDefault();
        if (!error) {
        axios.post(`${BASE_URL}/topup/balance`, {
            fiat: fiat,
            amount: amount,
            wallet_type: wallet_type
        }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }).then(response => {
          setResponse(response.data)
          setTrnxId(response.data.data.id)
          setShowPopup(true);
        })
          .catch(error => {
            const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
            setAlertMessage(jsonString);;
            setShowAlert(true);
          })
        }
    }

      const topupBinance = (event) => {
        event.preventDefault();
        if (!error) {
        axios.post(`${BASE_URL}/binance-pay`, {
            fiat: "USD",
            amount: amount,
            wallet_type: wallet_type
        }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }).then(response => {
          setBinanceUrl(response.data.data.checkoutUrl)
          setBinanceQR(response.data.data.qrcodeLink)
          setBinanceCurrency(response.data.data.currency)
          setBinanceTotalfee(response.data.data.totalFee)
          setBinanceFiatCurrency(response.data.data.fiatCurrency)
          setBinanceFiatAmount(response.data.data.fiatAmount)
          setShowBinanceButton(true);
          setTrnxId(response.data.data.deposit_data.id);
          console.log(response.data.data.deposit_data.id);
          if(response.data.data.deposit_data.id){
            fetchStatus();
            console.log("Checking status ...")
          }
          
        })
          .catch(error => {
            const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
            setAlertMessage(jsonString);;
            setShowAlert(true);
          })
        }
    }
      const topupRave = (event) => {
        event.preventDefault();
        if (!error) {
        axios.post(`${BASE_URL}/rave-pay`, {
            fiat: fiat,
            amount: amount,
            wallet_type: wallet_type
        }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }).then(response => {
          setRaveUrl(response.data.data.link)
          setRaveAmount(response.data.data.deposit_data.amount)
          setShowRave(true);
          setTrnxId(response.data.data.deposit_data.id);
          console.log(response.data.data.deposit_data.id);
          if(trnxid != null){
            fetchStatus();
            console.log("Checking status ...")
          }
          
        })
          .catch(error => {
            const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
            setAlertMessage(jsonString);;
            setShowAlert(true);
          })
        }
    }
      const topupDepay = (event) => {
        event.preventDefault();
        if (!error) {
        axios.post(`${BASE_URL}/depay`, {
            fiat: fiat,
            amount: amount,
            wallet_type: wallet_type
        }, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }).then(response => {
            console.log(response.data.data.cryptoAmount);
            let amount1 = Number(response.data.data.cryptoAmount).toFixed(2)
            setDepayAmount(amount1)
            console.log(depayAmount);
            openPaymentWidget()
        })
          .catch(error => {
            const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
            setAlertMessage(jsonString);;
            setShowAlert(true);
          })
        }
    }

    const [trade_amount, setTradeAmount] = useState('');
    const [trade_currency, setTradeCurrency] = useState(currenzy);

    const handleTradeCurrency = (e) => {
      setTradeCurrency(e.target.value);
    };
    const handleTradeAmount = (e) => {
      setTradeAmount(e.target.value);
    };
    
    const topUpFiat = (event) => {
      event.preventDefault();
      if (!error) {
        axios.post(`${BASE_URL}/trade/history/buy-sell-order`, {
          trade_amount: trade_amount,
          payment_id: payment_id,
          trade_type: "buy",
          trade_currency: trade_currency,
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
          .then(response => {
            setSelectedData(response.data.data.data)
            console.log(response.data.data.data.id)
            navigate("/p2p/transaction/details", { state: { id: response.data.data.data.id } });
            //navigate('/p2p/transactions')
            //setOpen2(true);
            //setShowDetails(true);
          })
          .catch(error => {
            if (error.response || error.response.data || error.response.data.errors || error.response.data.errors.error) {
              // Extract error message from response and alert it
              const errorMessage = error.response.data.errors.error;
              const jsonString = JSON.stringify((error.response.data.errors || error.response.errors.msg)).replace(/[\[\]{}'"]+/g, '');
              toast.error(jsonString, {
                position: toast.POSITION.TOP_CENTER
              });
            } else {
              setAlertMessage('Service Error');
              setShowAlert(true);
            }
          });
      }
    };
    const [showPinVerification, setShowPinVerification] = useState(false);

    const handlePaidWithVerification = (id) => {
      setShowPinVerification(true);
      setVerificationCallback(() => handlePaid.bind(null, id));
    };
    
    const [verificationCallback, setVerificationCallback] = useState(null);
    const handleCancelPinVerification = () => {
      setShowPinVerification(false);
    };

    const handleAlertClose = () => {
      setShowAlert(false);
    };

    function handleDisputeClick(transactionId) {
      navigate('/dispute', { state: { id: transactionId } });
    }

    const options = [
      {
        label: 'Sell',
        options: [
          { value: 'Sender name doesn’t tally', label: 'Sender name doesn’t tally' },
          { value: 'Payment not received', label: 'Payment not received' },
          { value: 'User filed chargeback', label: 'User filed chargeback' },
          { value: 'User sent stolen funds', label: 'User sent stolen funds' },
          { value: 'Bad terms and conditions', label: 'Bad terms and conditions' }
        ]
      },
      {
        label: 'Top Up',
        options: [
          { value: 'Delay in release of funds', label: 'Delay in release of funds' },
          { value: 'Incorrect account details', label: 'Incorrect account details' },
          { value: 'Fraudulent Account details', label: 'Fraudulent Account details' },
          { value: 'Agent is unreachable', label: 'Agent is unreachable' },
          { value: 'Bad terms and conditions', label: 'Bad terms and conditions' }
        ]
      },
      {
        label: 'Payout',
        options: [
          { value: 'Sender name doesn’t tally', label: 'Sender name doesn’t tally' },
          { value: 'Payment not received', label: 'Payment not received' },
          { value: 'User filed chargeback', label: 'User filed chargeback' },
          { value: 'User sent stolen funds', label: 'User sent stolen funds' },
          { value: 'Bad terms and conditions', label: 'Bad terms and conditions' },
          { value: 'Agent is unreachable', label: 'Agent is unreachable' }
        ]
      },
      {
        label: 'Buy',
        options: [
          { value: 'Delay in release of funds', label: 'Delay in release of funds' },
          { value: 'Incorrect account details', label: 'Incorrect account details' },
          { value: 'Fraudulent Account details', label: 'Fraudulent Account details' },
          { value: 'User is unreachable', label: 'User is unreachable' }
        ]
      }
    ];
    async function fetchStatus() {
      setButtonText('Checking status ...')
      setButtonDisabled(true)
      try {
        const response = await axios.get(`${BASE_URL}/topup/${trnxid}/status`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = response.data;
        setStatus(data.data.status);
        if (data.data.status !== 'success' || "completed") {
          // If the status is not successful, call fetchStatus again after 5 seconds
          setTimeout(fetchStatus, 5000);
        } else {
          setButtonText('Check Status')
          setButtonDisabled(false)
        }
      } catch (error) {
        console.error(error);
      }
    }
    useEffect(() => {
      if (status && (status === 'success' || status === 'completed')) {
        setShowPopup(false)
        setAlertMessage("Transaction Successful, your balance will be updated!");
        setShowAlert(true);
        clearTimeout(fetchStatus);
      } else if (status && (status === 'pending')){
        fetchStatus();
      } else if (status && (status === 'failed' || status === 'closed')) {
        setShowPopup(false)
        setAlertMessage("Transaction Failed to be completed within time, if you need help contact support.");
        setShowAlert(true);
      }
    }, [status]);
    let unmount

  const openPaymentWidget = async ()=>{
    (
      { unmount } = await DePayWidgets.Payment({
        accept: [
          { // 20 USDT on BSC
            blockchain: 'bsc',
            amount: depayAmount,
            token: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
            receiver: '0x9C2d7ccA1d1023B2038d91196ea420d731226f73'
          },
        ],
          fee: {
            amount: '3%',
            receiver: '0x4e260bB2b25EC6F3A59B478fCDe5eD5B8D783B02'
          },
          track: {
            endpoint: `${BASE_URL}/depay-paid`, // your endpoint to forward the payment tracking to the payments api
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
          style: {
              colors: {
                primary: '#4D0B6C',
                text: '#000',
                buttonText: '#fff',
                icons: '#4D0B6C'
              },
              fontFamily: '"Inter", sans-serif',
              fontFamily: '"Roboto", sans-serif',
              css: `
                @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
          
                .ReactDialogBackground {
                  background: rgba(0,0,0,0.8);
                }
              `
            },
            title: <div style={{color:'#4D0B6C', fontFamily:'Inter', fontSize:'34.21px', fontWeight:'700', lineHeight:'41.4px'}}>ZeenahPay</div>,
      })
    )
  }

  useEffect(() => {
    return ()=>{
      // make sure an open widgets gets closed/unmounted as part of this component
      if(unmount) { unmount() }
    }
  }, [])

    {/*DePayWidgets.Payment({
      accept: [
        { // 20 USDT on ethereum
          blockchain: 'ethereum',
          amount: amount,
          token: '0xdac17f958d2ee523a2206206994597c13d831ec7',
          receiver: '0x4e260bB2b25EC6F3A59B478fCDe5eD5B8D783B02'
        },{ // 20 BUSD on bsc
          blockchain: 'bsc',
          amount: amount,
          token: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
          receiver: '0x552C2a5a774CcaEeC036d41c983808E3c76477e6'
        },{ // 20 USDC on polygon
          blockchain: 'polygon',
          amount: amount,
          token: '0x2791bca1f2de4661ed88a30c99a7a9449aa84174',
          receiver: '0x552C2a5a774CcaEeC036d41c983808E3c76477e6'
        }
      ],
        fee: {
          amount: '3%',
          receiver: '0x4e260bB2b25EC6F3A59B478fCDe5eD5B8D783B02'
        },
        style: {
            colors: {
              primary: '#4D0B6C',
              text: '#000',
              buttonText: '#fff',
              icons: '#4D0B6C'
            },
            fontFamily: '"Inter", sans-serif',
            fontFamily: '"Roboto", sans-serif',
            css: `
              @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
        
              .ReactDialogBackground {
                background: rgba(0,0,0,0.8);
              }
            `
          },
          title: 'ZeenahPay'
    }); */}
    const buttonStyle = {
      margin: '0px',
      appearance: 'none',
      userSelect: 'none',
      cursor: 'pointer',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'inline-flex',
      MozBoxAlign: 'center',
      alignItems: 'center',
      MozBoxPack: 'center',
      justifyContent: 'center',
      boxSizing: 'border-box',
      fontSize: '14px',
      fontFamily: 'inherit',
      fontWeight: '500',
      textAlign: 'center',
      textDecoration: 'none',
      outline: 'none',
      padding: '6px 12px',
      lineHeight: '20px',
      minWidth: '52px',
      wordBreak: 'keep-all',
      color: 'rgb(24, 26, 32)',
      borderRadius: '4px',
      minHeight: '24px',
      border: 'medium none',
      backgroundImage: 'none',
      backgroundColor: 'rgb(252, 213, 53)',
      height: '48px',
      width: '100%',
    };
    const buttonStyle2 = {
      margin: '0px',
      appearance: 'none',
      userSelect: 'none',
      cursor: 'pointer',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      display: 'inline-flex',
      MozBoxAlign: 'center',
      alignItems: 'center',
      MozBoxPack: 'center',
      justifyContent: 'center',
      boxSizing: 'border-box',
      fontSize: '14px',
      fontFamily: 'inherit',
      fontWeight: '500',
      textAlign: 'center',
      textDecoration: 'none',
      outline: 'none',
      padding: '6px 12px',
      lineHeight: '20px',
      minWidth: '52px',
      wordBreak: 'keep-all',
      color: 'rgb(24, 26, 32)',
      borderRadius: '4px',
      minHeight: '24px',
      border: 'medium none',
      backgroundImage: 'none',
      backgroundColor: '#ff9b00',
      height: '48px',
      width: '100%',
    };

    const openRave = () =>{
      window.open(raveUrl, '_blank')
    }

        let transak; // Declare the transak variable outside of the function

        const initializeTransak = () => {
          transak = new transakSDK({
            apiKey: 'd109d236-61b8-4ece-9d93-bd67d8f520e1', // Replace with your actual API key 5a860f83-3402-4226-be5a-1c1d88b56436
            environment: 'PRODUCTION', // Use 'STAGING' or 'PRODUCTION'
            // Other configuration options
            widgetHeight: '90%',
            productsAvailed:'BUY',
            defaultFiatCurrency: 'USD',
            cryptoCurrencyCode: 'USDC',
            networks: 'BSC',
            walletAddress:'0x2FCa57b3856e518c1429fEfE6FD88AA9D1D12EA7',
            disableWalletAddressForm:'true',
            themeColor:'#4D0B6C',
            hideMenu:'true',
            fiatAmount: amount,
            fiatCurrency: fiat,
            paymentMethod: transakPaymentMethod,
            exchangeScreenTitle: 'Top Up your ZeenahPay Balance',
            email: user.data.email,
            hideExchangeScreen: true,
            userData: {
              "firstName": user.data.firstName,
              "lastName": user.data.lastName,
              "email": user.data.email,
              "mobileNumber": user.data.phoneNumber,
            },
            partnerCustomerId: user.id,
            redirectURL: "https://zeenahpay.com/transak",
          });
        
          // Initialize Transak
          transak.init();
        
          // To get all the events
          transak.on(transak.ALL_EVENTS, (data) => {
            console.log(data);
          });
        
          // This will trigger when the user closed the widget
          transak.on(transak.EVENTS.TRANSAK_WIDGET_CLOSE, (orderData) => {
            console.log(orderData)
            transak.close();
          });
        
          // This will trigger when the user marks payment as made
          transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData) => {
            console.log(orderData);
            transak.close();
          });
        };
        // Function to open the Transak widget
const openTransakWidget = () => {
  if (!transak) {
    setOpen4(false)
    setDim(false)
    initializeTransak(); // Initialize Transak if it hasn't been initialized already
  }
  // Open the Transak widget when the button is clicked
  // You can trigger this function in response to a button click event.
  transak.open();
};

const closeTransakButtons = document.querySelectorAll('.transak_close');
closeTransakButtons.forEach((button) => {
  button.addEventListener('click', () => {
    if (transak) {
      transak.close(); // Close the widget when any element with the class is clicked
    }
  });
});
  const openNewTab = (url) => {
    // Open the URL in a new tab
    window.open(url, '_blank');
  };
  return (
    <>
    {showAlert && (
        <AlertModal
          isModalOpen={showAlert}
          alertMessage={alertMessage}
          onClose={handleAlertClose}
        />
      )}
      <Navbar toggleSidebar={toggleSidebar}/>
        <MainContainer isOpen={isOpen}>
            <Sidebar1 isOpen={isOpen} toggleSidebar={toggleSidebar} />
            <MainContent>
        <Container>
        <ToastContainer />
            <FormWrap>
                <CenterContent>
                <PayoutCardWrapper>
                { currenzy !== "USDT" && (<TopUpCard onClick={() => {setOpen1(true); setDim(true)}}>
                    <TopupImage src={P2p} alt='p2p symbol'/>
                    <div>
                      <TopupH>P2P Deposit</TopupH>
                      <TopupP>Deposit using bank transfer, other wallets or mobile money</TopupP>
                    </div>
                    <div>
                    {userPayment && (
                      <Popup open={open1} onClose={() => { setOpen1(false); setDim(false)}} modal closeOnDocumentClick contentStyle={{width: '100%', height: '100%', backgroundColor: 'white', overflow: 'scroll'}} overlayStyle={{background: dim ? 'rgba(0, 0, 0, 0.5)' : 'none'}}>
                        {close => (
                      <div className="content" >
                      <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><a className="close" onClick={close}>
                        <b style={{fontSize: '26px', marginLeft: '10px', alignItems: 'center', cursor: 'pointer'}}>&times; | </b>
                        </a> <b>P2P Deposit</b> </div>
                        <Form2 style={{marginTop: '60px'}} onSubmit={topUpFiat} >
                            <FormH2 style={{color: '#4D0B6C', display: 'flex', lineHeight: 'normal', alignItems : 'center',justifyContent: 'center' }}><img src={P2p} style={{marginRight: '10px'}} />P2P Deposit</FormH2>
                            <Container1>
                                <Select1 value={currenzy} onChange={handleTradeCurrency}>
                                  <option value={currenzy}>
                                    {currenzy}
                                  </option>
                                </Select1>
                                <Input type="text" value={trade_amount} onChange={handleTradeAmount} placeholder='Amount to Topup' />
                            </Container1>
                            <FormLabel style={{marginBottom: 2}}>Select Your preferred Payment Method</FormLabel>
                            <SelectInput id='paymentMethod' value={payment_id} onChange={handlePaymentID}>
                              <option>Select Payment Method</option>
                              {currencies.map((currency, index) => (
                                currency.currency == currenzy && (
                                <option key={index} value={currency.id}>
                                    {currency.method_name}
                                </option>)
                                ))}
                            </SelectInput>
                            <PrimaryBtn style={{borderRadius: '6px'}}>Submit</PrimaryBtn>
                        </Form2> 
                      </div>)}
                      </Popup>  )}
                    </div>
                    {showDetails && (
                    <Popup open={open2} onClose={() => { setOpen2(false); setDim(false)}} modal closeOnDocumentClick contentStyle={{width: '100%', height: '100%', backgroundColor: 'white', overflow: 'scroll'}} overlayStyle={{background: dim ? 'rgba(0, 0, 0, 0.5)' : 'none'}}>
                        {close => (
                      <div className="content" >
                      <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><a className="close" onClick={close}>
                        <b style={{fontSize: '26px', marginLeft: '10px', alignItems: 'center'}}>&times; | </b>
                        </a> <b>Confirm Payment</b> </div>
                          <Form3 style={{backgroundColor: '#FDF9FF'}}>
                            <FormH2 style={{marginTop: '-45px', fontWeight: 'bolder' }}>Make Payment</FormH2>
                            <p style={{margin: '10px', textAlign:'center'}}>Make payment to the payment details below</p>
                            <FormH1>{selectedData.trade_currency} {selectedData.trade_amount}</FormH1>
                            <RoundedDiv3 style={{background: '#fff', marginTop: '20px', marginLeft: '15px'}}>
                            <br></br>
                            <FormLabel style={{fontSize: '16px'}}>{selectedData.payment_method.payment_info.method_name}</FormLabel>
                            <RoundedDiv2 style={{}}>
                            {Object.entries(selectedData.payment_method.payment_info).map(([key, value]) => (
                                <P2pCard>
                                    <DivLeft2>
                                        <P2pInfo style={{marginBottom: '-15px'}}>{key.replace(/_/g, ' ')}</P2pInfo>
                                    </DivLeft2>
                                    <DivRight2>
                                        <P2pInfo2 style={{marginBottom: '-15px'}}>{value}</P2pInfo2>
                                    </DivRight2>
                                    
                                </P2pCard>
                                ))}
                                <P2pCard>
                                <DivLeft2><P2pInfo style={{marginBottom: '-15px'}}>Transaction ID</P2pInfo></DivLeft2>
                                <DivRight2><P2pInfo2 style={{marginBottom: '-15px'}}>{selectedData.transaction_id}</P2pInfo2></DivRight2>
                                </P2pCard>
                            </RoundedDiv2>
                        </RoundedDiv3>
                        <div style={{display:'flex'}}>
                          <div style={{width: '97%', marginRight: '10px'}}>
                            <PrimaryBtn2 onClick={close} style={{background: 'linear-gradient(180deg, #F3E8F7 0%, #EBDAF1 100%)',color: '#791FA4', borderRadius: '6px', width: '100%'}}>Cancel</PrimaryBtn2>
                          </div>
                          <div style={{width: '100%'}}>
                          <PrimaryBtn2 style={{backgroundColor: '#9316BF', borderRadius: '6px', width: '100%'}} onClick={() => handlePaidWithVerification(selectedData.id)}>I have paid</PrimaryBtn2>
                          </div>
                        </div>
                        {/*<PrimaryBtn2 style={{backgroundColor: '#9d9d9d', borderRadius: '6px', width: '100%', marginTop: '10px'}}>Dispute</PrimaryBtn2> */}
                        <div style={{textAlign: 'center', alignItems: 'center', marginTop:'15px'}}><FormLabel style={{color: '#B51010', fontSize: '16px', alignItems: 'center', cursor: 'pointer'}} onClick={() =>  handleDisputeModal(selectedData.trade_id)} ><MdOutlineReportProblem/> Dispute</FormLabel></div>
                        {showPinVerification && (
                          <Pin
                              show={showPinVerification}
                              setShow={setShowPinVerification}
                              onPinVerified={verificationCallback}
                              onCancel={handleCancelPinVerification}
                            />  )}
                          </Form3>
                          {showModal && (
                            <Background className="modal">
                            <PopupCard className="modal-content">
                              <CloseButton onClick={handleModal}>&times;</CloseButton>
                              <h3 style={{margin:'10px', textAlign: 'center'}}>Awaiting agent confirmation</h3>
                              <div style={{margin: '20px', background: 'rgba(227, 180, 244, 0.46)', color:'#791FA4', fontSize: '12px', borderRadius: '6px', padding:'20px'}}>Some transactions may take longer time than  others, you can keep waiting in the background if this may be the case. Your Zeenah account will be topped up automatically once confirmed</div>

                              <DivPop style={{textAlign: 'center', alignItems: 'center', marginBottom:'40px'}} >
                              
                              <Icon9 src={Hourglass} />
                              <p>Estimated Time: <b style={{color: '#791FA4'}}>{Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, '0')}</b></p>
                              </DivPop>
                              <PrimaryBtn2  style={{backgroundColor: '#9316BF', borderRadius: '6px', width: '100%'}} onClick={() => handleDisputeModal(selectedData.trade_id)} >Open Dispute</PrimaryBtn2>
                            </PopupCard>
                          </Background>
                          )}
                          {showNewDispute && (
                            <Background className="modal">
                            <PopupCard className="modal-content">
                              <CloseButton onClick={handleDisputeModal}>&times;</CloseButton>
                              <h3 style={{margin:'10px', textAlign: 'center'}}>Open New Dispute</h3>
                              <Form2 style={{border: 'none', marginBottom: '-20px', marginTop: '-40px'}} onSubmit={NewDispute}>
                                <FormLabel>Select Dispute Type</FormLabel>
                                <Select style={{ borderColor: '#979797' }} value={{ value: dispute_type, label: dispute_type }} onChange={handleDisputeType} options={options} getOptionValue={(option) => option.value}/>                                
                                <FormLabel style={{marginTop: '15px', marginBottom: '0px'}}>Description</FormLabel>    
                                <FormArea style={{marginBottom: ''}} value={description} onChange={handleDescription} />                            
                              <PrimaryBtn2  style={{backgroundColor: '#9316BF', borderRadius: '6px', width: '100%'}} Link>Open Dispute</PrimaryBtn2>
                              </Form2>
                            </PopupCard>
                          </Background>
                          )}
                      </div>)}
                    </Popup>
                    )}
                </TopUpCard>)}
                { currenzy == "USDT" && (<TopUpCard onClick={() => {setOpen(true); setDim(true)}}>
                    <div><TopupImage src={Crypto} alt='wallet symbol' /></div>
                    <div>
                      <TopupH>Crypto Deposit</TopupH>
                      <TopupP>Fund your account with supported stablecoins like USDT</TopupP>
                    </div>
                    <div>
                      <Popup open={open} onClose={() => { setOpen(false); setDim(false)}} modal closeOnDocumentClick contentStyle={{width: '100%', height: '100%', backgroundColor: 'white', overflow: 'scroll'}} overlayStyle={{background: dim ? 'rgba(0, 0, 0, 0.5)' : 'none'}}>
                        {close => (
                      <div className="content" >
                      <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><a className="close" onClick={close}>
                        <b style={{fontSize: '26px', marginLeft: '10px', alignItems: 'center', cursor: 'pointer'}}>&times; | </b>
                        </a> <b>Fund via Crypto</b> </div>
                        <Form3 style={{marginTop: '80px'}} >
                            <FormH2 style={{color: '#4D0B6C', display: 'flex', lineHeight: 'normal', alignItems : 'center',justifyContent: 'center' }}><img src={coin} style={{marginRight: '10px'}} /> Fund via crypto </FormH2>
                            <Container1>
                                <Select1 value={wallet_type} onChange={handleCurrencyChange2}>
                                  <option value='USDT'>
                                    USDT
                                  </option>
                                </Select1>
                                <Input type="text" value={amount} onChange={handleAmountChange} placeholder='Amount to top up' />
                            </Container1>
                            {/*<PrimaryBtn style={{borderRadius: '6px',width: '100%'}}  onClick={topUpCrypto}>Topup</PrimaryBtn>*/}
                            <PrimaryBtn style={{borderRadius: '6px',width: '100%'}}  onClick={topupBinance}><i>Topup with Binance</i></PrimaryBtn>
                            {/*<div style={{margin:'20px'}}>
                              <center>
                                OR
                              </center>
                            </div>
                            <div style={{display:'flex'}}>
                                <div style={{width: '100%', marginRight: '10px'}}>
                                  <PrimaryBtn style={{borderRadius: '6px',width: '100%'}}  onClick={topupBinance}><i>Topup with Binance</i></PrimaryBtn>
                                </div>
                                <div style={{width: '100%'}}>
                                  <PrimaryBtn style={{borderRadius: '6px',width: '100%'}} onClick={topupDepay}><i>Topup with Depay</i></PrimaryBtn>
                                </div>
                            </div> */}
                        </Form3>
                        {showPopup && (
                            <Background>
                            {cryptoResponse && (
                            <PopupCard>
                                <CloseButton onClick={() => setShowPopup(false)}>&times;</CloseButton>
                                <Form4 style={{border : 'none', height: 'inherit', textAlign: 'center'}}>
                                <FormH2>Send Crypto</FormH2>
                                <p>Send crypto with the details below to top up your balance.</p>
                                <FormH1><b style={{fontSize: '20px', fontWeight: 'lighter'}}>Time Left</b> : <b style={{color: '#791FA4'}}>{Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, '0')} M</b></FormH1>
                                <FormLabel><b style={{fontWeight: 'lighter'}}>Amount in Crypto :</b> <b style={{ color: '#791FA4', cursor: 'pointer'}} onClick={() => handleCopy(cryptoResponse.data.cryptoAmount)}>{cryptoResponse.data.cryptoAmount} <FaRegCopy/></b></FormLabel>
                                <FormLabel><b style={{fontWeight: 'lighter'}}>Crypto:</b> <b>{cryptoResponse.data.wallet_type}</b></FormLabel>
                                <FormLabel><b style={{fontWeight: 'lighter'}}>Network:</b><b> {cryptoResponse.data.wallet_type === 'BTC' && 'Bitcoin Network'}{cryptoResponse.data.wallet_type === 'USDT' && 'Binance SmartChain Network'}{cryptoResponse.data.wallet_type === 'LTC' && 'Litecoin Network'}{cryptoResponse.data.wallet_type === 'BNB' && 'Binance SmartChain Network'}</b></FormLabel>
                                <FormLabel><b style={{fontWeight: 'lighter'}}>Wallet Address:</b><br></br> <B  style={{ color: '#791FA4', cursor: 'pointer'}} onClick={() => handleCopy(cryptoResponse.data.wallet_address)}>{cryptoResponse.data.wallet_address} <FaRegCopy/></B></FormLabel>
                                {copied && <p style={{color:'blue'}}>Copied to clipboard!</p>}
                                <FormLabel><b style={{fontWeight: 'lighter'}}>Transaction Status:</b> <b style={{color:'blue'}}>{status}</b></FormLabel>
                                <PrimaryBtn onClick={fetchStatus} style={{ cursor: buttonDisabled ? 'not-allowed' : 'pointer', borderRadius: '12px', backgroundColor: '#791FA4', textAlign: 'center', width: '100%'}}disabled={buttonDisabled}>{buttonText}</PrimaryBtn>
                                <div style={{fontSize: '10px', color: '#A300FF', marginTop: '20px', marginLeft: '10px'}}>* You can close this modal after payment, your balance will be updated </div>
                                </Form4>
                            </PopupCard>
                            )}
                            </Background>
                        )}
                        {showBinanceButton && (
                            <Background>
                            {binanceUrl && (
                            <PopupCard>
                                <CloseButton onClick={() => setShowBinanceButton(false)}>&times;</CloseButton>
                                {/*<div style={{margin:'200px'}}>
                                  <center>
                                    <a style={buttonStyle} href={binanceUrl} target="_blank">Open BinancePay</a>
                                  </center>
                                  <i style={{textAlign:'center', color:'purple', fontSize:'14px'}}>* Binance pay will open in a new tab </i>
                                </div> */}
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    padding: '20px',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      marginBottom: '10px',
                                    }}
                                  >
                                    <div style={{ flex: '1', borderRight: '1px solid #ccc', paddingRight: '10px' }}>
                                      <img
                                          src={binanceQR}
                                          alt="QR Code"
                                          style={{ maxWidth: '100%', height: 'auto', marginTop:'20px' }}
                                        />
                                      <div style={{ fontSize: '8px', marginTop: '-10px', marginBottom:'20px' }}>Scan to pay with Binance App</div>
                                    </div>
                                    <div style={{ flex: '1', paddingLeft: '10px' }}>
                                        <div style={{ fontSize: '14px' }}>Payment Amount</div>
                                        <div style={{ fontSize: '18px', fontWeight:'bolder' }}>{binanceTotalfee} <span style={{ fontSize: '14px' }}>{binanceCurrency}</span></div>
                                        <div style={{ fontSize: '14px' }}>≈ {binanceFiatCurrency} {binanceFiatAmount}</div>
                                    </div>
                                  </div>
                                  <div style={{ height: '1px', backgroundColor: '#ccc', marginBottom: '10px' }}></div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <div>
                                      
                                      <div style={{ fontSize: '12px', marginTop: '5px' }}>OR continue on Binance Pay in new tab</div>
                                    </div>
                                    <div style={{ height: '1px', backgroundColor: '#ccc', marginTop: '10px', marginBottom: '10px' }}></div>
                                    <div>
                                      <button style={buttonStyle} onClick={()=>{openNewTab(binanceUrl)}}>
                                        Open BinancePay
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                
                            </PopupCard>
                            )}
                            </Background>
                        )}
                      </div>)}
                      </Popup>
                    </div>
                </TopUpCard>)}
                { currenzy !== "NGN" && currenzy !== "USDT" && currenzy !== "ARS" &&  (<TopUpCard onClick={() => {setOpen4(true); setDim(true)}}>
                    <TopupImage src={transakImg} alt='Transak'/>
                    <div>
                      <TopupH>Transak</TopupH>
                      <TopupP>Top up your Zeenah USDT wallet using your cards or through bank transfers.</TopupP>
                    </div>
                    <Popup open={open4} onClose={() => { setOpen4(false); setDim(false)}} modal closeOnDocumentClick contentStyle={{width: '100%', height: '100%', backgroundColor: 'white', overflow: 'scroll'}} overlayStyle={{background: dim ? 'rgba(0, 0, 0, 0.5)' : 'none'}}>
                        {close => (
                      <div className="content" >
                      <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><a className="close" onClick={close}>
                        <b style={{fontSize: '26px', marginLeft: '10px', alignItems: 'center', cursor: 'pointer'}}>&times; | </b>
                        </a> <b>Fund via Transak</b> </div>
                        <Form3 style={{marginTop: '80px'}} >
                            <FormH2 style={{color: '#4D0B6C', display: 'flex', lineHeight: 'normal', alignItems : 'center',justifyContent: 'center' }}><img src={transakImg} style={{marginRight: '10px'}} /> Fund via Transak</FormH2>
                            <Container1>
                                <Select1 value={fiat} onChange={handleCurrencyChange}>
                                <option value={currenzy}>{currenzy}</option>
                                </Select1>
                                <Input type="text" value={amount} onChange={handleAmountChange} placeholder='Amount to top up' />
                            </Container1>
                            {/*<div style={{display:'flex', marginTop: '30px'}}>
                            <div>
                                <FormLabel for='firstName'>Your Location</FormLabel>
                                <SelectInput id='paymentMethod' value={payment_id} onChange={handlePaymentID}>
                              <option>Select Payment Method</option>
                              {currencies.map((currency, index) => (
                                currency.currency == trade_currency && (
                                <option key={index} value={currency.id}>
                                    {currency.method_name}
                                </option>)
                                ))}
                            </SelectInput>
                            </div>
                            <div>
                                <FormLabel for='lasstName'>Payment Method</FormLabel>
                                <SelectInput id='paymentMethod' value={payment_id} onChange={handlePaymentID}>
                              <option>Select Payment Method</option>
                              {currencies.map((currency, index) => (
                                currency.currency == trade_currency && (
                                <option key={index} value={currency.id}>
                                    {currency.method_name}
                                </option>)
                                ))}
                            </SelectInput>
                            </div>
                        </div>*/}
                                <FormLabel for='lasstName'>Payment Method</FormLabel>
                                <SelectInput value={transakPaymentMethod} onChange={handleTransakPaymentMethodChange}>
                                  {fiat ?
                                    <>
                                      <option>Select Payment Method</option>
                                      {paymentMethodsByCurrency[fiat].map((method) => (
                                        <option key={method.value} value={method.value}>
                                          {method.displayName}
                                        </option>
                                      ))}
                                    </> : <option>Select a currency first</option>
                                  }
                                </SelectInput>
                            <PrimaryBtn style={{borderRadius: '6px',width: '100%'}}  onClick={openTransakWidget}><i>Deposit</i></PrimaryBtn>
                        </Form3>
                      </div>)}
                      </Popup>
                </TopUpCard> )}
                { currenzy !== "NGN" && currenzy !== "USDT" && currenzy !== "ARS" && currenzy !== "CLP" && (<TopUpCard onClick={() => {setOpen3(true); setDim(true)}}>
                    <TopupImage src={Card} alt='Card symbol'/>
                    <div>
                      <TopupH>Card Deposit</TopupH>
                      <TopupP>Fund your account using Credit or Debit Card.</TopupP>
                      <p style={{color:'red', fontSize:'10px'}}>* This method takes up to 5 days to process</p>
                    </div>
                    <div>
                      <Popup open={open3} onClose={() => { setOpen3(false); setDim(false)}} modal closeOnDocumentClick contentStyle={{width: '100%', height: '100%', backgroundColor: 'white', overflow: 'scroll'}} overlayStyle={{background: dim ? 'rgba(0, 0, 0, 0.5)' : 'none'}}>
                        {close => (
                      <div className="content" >
                      <div className="header" style={{width: '100%', height: '50px', backgroundColor: '#4D0B6C', color: '#fff', alignItems: 'center'}}><a className="close" onClick={close}>
                        <b style={{fontSize: '26px', marginLeft: '10px', alignItems: 'center', cursor: 'pointer'}}>&times; | </b>
                        </a> <b>Fund via Card</b> </div>
                        <Form3 style={{marginTop: '80px'}} >
                            <FormH2 style={{color: '#4D0B6C', display: 'flex', lineHeight: 'normal', alignItems : 'center',justifyContent: 'center' }}><img src={Card} style={{marginRight: '10px'}} /> Fund via Card</FormH2>
                            <Container1>
                                <Select1 value='USD' onChange={handleCurrencyChange}>
                                  <option value='USD'>
                                    USD
                                  </option>
                                </Select1>
                                <Input type="text" value={amount} onChange={handleAmountChange} placeholder='Amount to top up' />
                            </Container1>
                            
                            {/*<PrimaryBtn style={{borderRadius: '6px',width: '100%'}}  onClick={topUpCrypto}>Topup</PrimaryBtn>*/}
                            <PrimaryBtn style={{borderRadius: '6px',width: '100%'}}  onClick={topupRave}><i>Deposit</i></PrimaryBtn>
                            {/*<div style={{margin:'20px'}}>
                              <center>
                                OR
                              </center>
                            </div>
                            <div style={{display:'flex'}}>
                                <div style={{width: '100%', marginRight: '10px'}}>
                                  <PrimaryBtn style={{borderRadius: '6px',width: '100%'}}  onClick={topupBinance}><i>Topup with Binance</i></PrimaryBtn>
                                </div>
                                <div style={{width: '100%'}}>
                                  <PrimaryBtn style={{borderRadius: '6px',width: '100%'}} onClick={topupDepay}><i>Topup with Depay</i></PrimaryBtn>
                                </div>
                            </div> */}
                        </Form3>
                        {showPopup && (
                            <Background>
                            {cryptoResponse && (
                            <PopupCard>
                                <CloseButton onClick={() => setShowPopup(false)}>&times;</CloseButton>
                                <Form4 style={{border : 'none', height: 'inherit', textAlign: 'center'}}>
                                <FormH2>Send Crypto</FormH2>
                                <p>Send crypto with the details below to top up your balance.</p>
                                <FormH1><b style={{fontSize: '20px', fontWeight: 'lighter'}}>Time Left</b> : <b style={{color: '#791FA4'}}>{Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, '0')} M</b></FormH1>
                                <FormLabel><b style={{fontWeight: 'lighter'}}>Amount in Crypto :</b> <b style={{ color: '#791FA4', cursor: 'pointer'}} onClick={() => handleCopy(cryptoResponse.data.cryptoAmount)}>{cryptoResponse.data.cryptoAmount} <FaRegCopy/></b></FormLabel>
                                <FormLabel><b style={{fontWeight: 'lighter'}}>Crypto:</b> <b>{cryptoResponse.data.wallet_type}</b></FormLabel>
                                <FormLabel><b style={{fontWeight: 'lighter'}}>Network:</b><b> {cryptoResponse.data.wallet_type === 'BTC' && 'Bitcoin Network'}{cryptoResponse.data.wallet_type === 'USDT' && 'Binance SmartChain Network'}{cryptoResponse.data.wallet_type === 'LTC' && 'Litecoin Network'}{cryptoResponse.data.wallet_type === 'BNB' && 'Binance SmartChain Network'}</b></FormLabel>
                                <FormLabel><b style={{fontWeight: 'lighter'}}>Wallet Address:</b><br></br> <B  style={{ color: '#791FA4', cursor: 'pointer'}} onClick={() => handleCopy(cryptoResponse.data.wallet_address)}>{cryptoResponse.data.wallet_address} <FaRegCopy/></B></FormLabel>
                                {copied && <p style={{color:'blue'}}>Copied to clipboard!</p>}
                                <FormLabel><b style={{fontWeight: 'lighter'}}>Transaction Status:</b> <b style={{color:'blue'}}>{status}</b></FormLabel>
                                <PrimaryBtn onClick={fetchStatus} style={{ cursor: buttonDisabled ? 'not-allowed' : 'pointer', borderRadius: '12px', backgroundColor: '#791FA4', textAlign: 'center', width: '100%'}}disabled={buttonDisabled}>{buttonText}</PrimaryBtn>
                                <div style={{fontSize: '10px', color: '#A300FF', marginTop: '20px', marginLeft: '10px'}}>* You can close this modal after payment, your balance will be updated </div>
                                </Form4>
                            </PopupCard>
                            )}
                            </Background>
                        )}
                        {showRave && (
                            <Background>
                            {raveUrl && (
                            <PopupCard>
                                <CloseButton onClick={() => setShowRave(false)}>&times;</CloseButton>
                                {/*<div style={{margin:'200px'}}>
                                  <center>
                                    <a style={buttonStyle} href={binanceUrl} target="_blank">Open BinancePay</a>
                                  </center>
                                  <i style={{textAlign:'center', color:'purple', fontSize:'14px'}}>* Binance pay will open in a new tab </i>
                                </div> */}
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                    padding: '20px',
                                  }}
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                      alignItems: 'center',
                                      marginBottom: '10px',
                                    }}
                                  >
                                    <div style={{ flex: '1', paddingLeft: '10px' }}>
                                        <div style={{ fontSize: '14px' }}>Deposit Amount</div>
                                        <div style={{ fontSize: '14px' }}></div>
                                    </div>
                                  </div>
                                  <div style={{ height: '1px', marginBottom: '30px', fontSize:'20px', fontWeight:'bolder' }}>${raveAmount}</div>
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                      alignItems: 'center',
                                      justifyContent: 'center',
                                    }}
                                  >                                    
                                    <div style={{ height: '1px', backgroundColor: '#ccc', marginTop: '10px', marginBottom: '10px' }}></div>
                                    <div>
                                      <button onClick={openRave} style={buttonStyle2}>
                                        Open Flutterwave
                                      </button>
                                    </div>
                                  </div>
                                </div>
                                
                            </PopupCard>
                            )}
                            </Background>
                        )}
                      </div>)}
                      </Popup>
                    </div>
                </TopUpCard>)}
                </PayoutCardWrapper>
                </CenterContent>
            </FormWrap>
        </Container>
        </MainContent>
        </MainContainer>
    </>
  )
}

export default Topup