import styled from "styled-components";

export const Side = styled.div`
    background: #E6DDE8;
    width: 480px;
    height: 100vh;
    display: flex;
    float: left;
    position: fixed;
    justify-content: center;
    align-items: center;
    overflow: hidden; 

    @media screen and (max-width: 960px){
        display: none;
    }
`
export const Card = styled.div`
    position: absolute;
    width: auto;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 4px 2px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
`
export const Img = styled.img`
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 10px;
    width: 300px;
`
export const Text1 = styled.span`
    position: absolute;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 29px;
    text-align: center;
    color: #000000;
    top: 280px;
    margin-left: 10px;
`
export const Text2 = styled.span`
    position: absolute;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #000000;
`