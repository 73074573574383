import React, {useState, useEffect} from 'react'
import { FaSearch } from 'react-icons/fa';
import { Table as Tablle, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import Navbar from '../components/Navbar';
import { CenterContent, Container, FormWrap, PrimaryBtn, SearchBar, TdRef, Tdreg, ThText } from '../components/Dashboard/content/dashElements';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { NavLink, useNavigate } from 'react-router-dom'
import axios from 'axios';
import { CancelBtn } from '../components/Dashboard/p2pElements/p2pElements';
import Loader from '../components/loader';
import Nothing from '../assets/nothingtoshow.png'
import Empty from '../assets/empty.svg'
import AlertModal from './modal';
import Sidebar1, { MainContainer, MainContent } from './nav'
import { PrimaryBtn2 } from '../components/Form/formElements';
import {GrPrevious, GrNext } from 'react-icons/gr';
import BASE_URL from '../apiConfig'

const PaymentLinkH = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [isEmpty, setIsEmpty] = useState(false);
  const [tooltipText, setTooltipText] = useState("Copy to clipboard");
    let textToCopy = "";
    const [searchQuery, setSearchQuery] = useState('');
    function handleCopyClick(event) {
      textToCopy = event.target.innerText;
      navigator.clipboard.writeText(textToCopy).then(() => {
          setAlertMessage('Copied!');
          setShowAlert(true);
      });
    }
    const navigate = useNavigate()
    const [paylinks, setPaylinks] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);

    const token = localStorage.getItem('bearerToken');

    const fetchPaylinks = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/payment-link`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
    
          return response.data;
        } catch (error) {
          console.error(error);
        }
      };
      useEffect(() => {
        const getPaylinks = async () => {
          const data = await fetchPaylinks();
          setPaylinks(data);
        };
    
        getPaylinks();
      }, []);
      const handleDelete = async (id) => {
        try {
          setIsDeleting(true);
          await axios.delete(`${BASE_URL}/payment-link/${id}`,{
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }).then(result => {
                      // Handle successful deletion, e.g. remove the trade from the table data and update the UI
          const msg = JSON.stringify((result.data.data)).replace(/[\[\]{}'"]+/g, '');
          setAlertMessage(msg);
          setShowAlert(true);
          })

        } catch (error) {
          console.error(error);
          const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
          setAlertMessage(jsonString);
          setShowAlert(true);
          // Handle error, e.g. display an error message to the user
        } finally {
          setIsDeleting(false);
        }
      };

      const handleAlertClose = () => {
        setShowAlert(false);
      };
      const filteredPaylinks = paylinks?.data.filter((paylink) =>
      paylink.link_title.toLowerCase().includes(searchQuery.toLowerCase())||
      paylink.link_type.toLowerCase().includes(searchQuery.toLowerCase()) ||
      paylink.slug.toLowerCase().includes(searchQuery.toLowerCase()))

      const indexOfLastItem = currentPage * itemsPerPage;
      const indexOfFirstItem = indexOfLastItem - itemsPerPage;
      const currentItems = filteredPaylinks?.slice(indexOfFirstItem, indexOfLastItem);
  
      const pageNumbers = [];
      for (let i = 1; i <= Math.ceil(filteredPaylinks?.length / itemsPerPage); i++) {
        pageNumbers.push(i);
      }

     const handlePrevPage = () => {
        setCurrentPage( currentPage - 1);
      };
     const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
      };
      const handleClick = e => {
        setCurrentPage(Number(e.target.id));
      };

      const isLastPage = currentPage === pageNumbers.length;
      const isFirstPage = currentPage === 1;

      const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // reset to first page when search query changes
        setIsEmpty(filteredPaylinks.length === 0);
        if (searchQuery === 0) {
          setSearchQuery(null);
        }
      };
      
  return (
    <>
    {showAlert && (
        <AlertModal
          isModalOpen={showAlert}
          alertMessage={alertMessage}
          onClose={handleAlertClose}
        />
      )}
      <Navbar toggleSidebar={toggleSidebar}/>
        <MainContainer isOpen={isOpen}>
            <Sidebar1 isOpen={isOpen} toggleSidebar={toggleSidebar} />
            <MainContent>
        <Container style={{marginLeft: '30px'}}>
        {paylinks ? (
          <>
            <h2>Payment Link History</h2>
            <div style={{position:"flex", marginTop: '20px', width: '100%', marginBottom: '20px'}}>
                <SearchBar type="text"  value={searchQuery} onChange={handleSearchInputChange} placeholder='search payment link'  />
            </div>
            <NavLink to='/paymentlink'>
                <PrimaryBtn style={{width: '170px'}}>Create Payment Links</PrimaryBtn>
            </NavLink>
            <FormWrap>
            {paylinks.data.length  ? (
                <CenterContent>
                {isEmpty ? (
                  <div style={{justifyContent:'center', display: 'grid'}}>
                        <div style={{textAlign: 'center'}}>
                          <img src={Nothing} alt="Empty table" />
                        </div>
                        <div style={{textAlign: 'center'}}>
                          <h5 style={{marginBottom: '10px'}}>No search results found</h5>
                        </div>
                        <div>
                        </div>
                      </div>
                ) : (
                  <>
                  <Tablle style={{ textAlign: 'left', borderCollapse: 'collapse', maxWidth:'95%'}}>
                        <Thead style={{ backgroundColor: "#F3E8F7",
  color: "#1E1E1E",
  fontWeight: "600",
  lineHeight:"21px"}}>
                            <Tr>
                            <Th><ThText>Link title</ThText></Th>
                            <Th><ThText>Link type</ThText></Th>
                            <Th><ThText>Link address</ThText></Th>
                            <Th><ThText></ThText></Th>
                            </Tr>
                        </Thead>
                        
                        <Tbody>
                        {currentItems.map((links, index) => (
                            <Tr key={index} style={{maxWidth: '100%',borderBottom: '1px solid #ddd'}}>
                            <Td><Tdreg>{links.link_title}</Tdreg></Td>
                            <Td><Tdreg>{links.link_type}</Tdreg></Td>
                            <Td><TdRef  onClick={handleCopyClick} onMouseLeave={() => setTooltipText("Copy to clipboard")} style={{cursor: 'copy'}} >www.zeenahpay.com/checkout/{links.slug}</TdRef></Td>
                            <Td>
                            <CancelBtn onClick={() => handleDelete(links.id)} disabled={isDeleting} style={{marginRight: '15px'}}>{isDeleting ? 'Deleting...' : 'Delete'}</CancelBtn>
                            </Td>
                            </Tr>
                            ))}
                        </Tbody>
                    </Tablle>
                    <div>
                    <ul style={{display: 'flex', listStyle: 'none', margin:'10px'}}>
                    <button onClick={handlePrevPage} disabled={isFirstPage} style={{ cursor: isFirstPage ? 'not-allowed' : 'pointer', backgroundColor: isFirstPage ? '#8080808c' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                          <GrPrevious />
                        </button>
                        
                        {pageNumbers.map(number => (
                          <li key={number}>
                            <button id={number} onClick={handleClick} style={{ backgroundColor: currentPage === number ? '#FCF3FF' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                              {number}
                            </button>
                          </li> 
                        ))}
                        <button onClick={handleNextPage} disabled={isLastPage} style={{ cursor: isLastPage ? 'not-allowed' : 'pointer', backgroundColor: isLastPage ? '#8080808c' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                          <GrNext/>
                        </button> 
                      </ul>
                    </div>
                    </>
                    )}
                </CenterContent>
                ) : (
                        <div style={{justifyContent:'center', display: 'grid'}}>
                        <div style={{textAlign: 'center'}}>
                          <img src={Empty} alt="Empty table" />
                        </div>
                        <div style={{textAlign: 'center'}}>
                          <h5 style={{marginBottom: '10px'}}>You have no Payment Link</h5>
                        </div>
                        <div>
                        <div style={{display:'flex'}}>
                          <div style={{width: '100%', marginRight: '10px'}}>
                            <NavLink to='/create-paymentlink'> 
                                <PrimaryBtn2 style={{background: 'linear-gradient(180deg, #F3E8F7 0%, #EBDAF1 100%)',color: '#791FA4', borderRadius: '6px', width: '100%', fontSize: '14px'}}>Create Payment <br/> Link</PrimaryBtn2>
                            </NavLink>
                          </div>
                          <div style={{width: '100%'}}>
                            <NavLink to='/create-donationlink'>
                                <PrimaryBtn2 style={{backgroundColor: '#9316BF', borderRadius: '6px', width: '100%', fontSize: '14px'}}>Create Donation Link</PrimaryBtn2>
                            </NavLink>
                          </div>
                        </div>
                        </div>
                      </div>
                      )}
            </FormWrap>
            </>
            ) : <Loader/>}
        </Container>
        </MainContent>
        </MainContainer>
    </>
  )
}

export default PaymentLinkH