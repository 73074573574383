import React from 'react'
import { Form, Text, Icon, FormContent} from '../components/Form/formElements'
import styled from 'styled-components';
import ZeenahPay from '../assets/ZeenahPay.png'
import { PrimaryBtn } from '../components/Dashboard/content/dashElements';
import { NavLink } from 'react-router-dom';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
`;

const CenteredDiv = styled.div`
  background-color: lightgray;
  padding: 20px;
`;

const NotFound = () => {
  return (
    <Wrapper>
    <FormContent>
            <Icon src={ZeenahPay}  width='200px'/>
            <Form style={{alignItems: 'center', textAlign: 'center', width:'100%' }}>
                <h1 style={{color: '#4D0B6C'}}>404 Error</h1>
                <Text style={{fontSize: '20px'}}>The Page you're looking for no longer exists, or it never did<Text style={{fontSize: '28px'}}>🫣🫠</Text></Text>
                <div style={{marginTop: '20px'}}>
                    <NavLink to='/dashboard' style={{float: ''}}> 
                    <PrimaryBtn style={{background: '#751F9D', width: '200px', fontSize: '18px' }} Link>Home</PrimaryBtn>
                    </NavLink>
                </div>
            </Form>
            </FormContent>
    </Wrapper>
  )
}

export default NotFound