import React, { useState } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import BASE_URL from '../apiConfig'

const Test2 = () => {
  const [directors, setDirectors] = useState([{ position:'', name: '',email:'',country:'',phone:'', address:'', idType:'',proofId:'' }]);
  const [formDataImages, setFormDataImages] = useState([]);
  const token = localStorage.getItem('bearerToken');

  const [formData2, setFormData2] = useState([{ position:'shareholder', name: '',email:'', shareholdingPercentage:'', idType:'',proofId:'' }]);
  const [formData2Images, setFormData2Images] = useState([]);

  // Function to handle form submission for formData
  const handleSubmit = (event) => {
    event.preventDefault();

    // Convert the formData, formData1, and formData2 to JSON strings using JSON.stringify()
    const jsonData = JSON.stringify({ directors, formData2 });

    // Make the API request using axios
    axios.post(`${BASE_URL}/compliance/directors`, [jsonData,formDataImages ,formData2Images], {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        // Handle the response from the server if needed
        console.log(response.data);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error('Error:', error);
      });
    }
  // Function to handle changes in form fields for formData
  const handleChange = (event, index) => {
    const { name, value } = event.target;
    setDirectors((prevFormData) =>
      prevFormData.map((formFields, i) =>
        i === index ? { ...formFields, [name]: value } : formFields
      )
    );
  };

  // Function to handle changes in form fields for formData2
  const handleChange2 = (event, index) => {
    const { name, value } = event.target;
    setFormData2((prevFormData) =>
      prevFormData.map((formFields, i) =>
        i === index ? { ...formFields, [name]: value } : formFields
      )
    );
  };

// Function to handle image upload for formData
const handleImageChange = (event, index) => {
  const { files } = event.target;
  setFormDataImages((prevFormDataImages) =>
    prevFormDataImages.map((image, i) =>
      i === index ? files[0] : image
    )
  );
};

// Function to handle image upload for formData2
const handleImageChange2 = (event, index) => {
  const { files } = event.target;
  setFormData2Images((prevFormData2Images) =>
    prevFormData2Images.map((image, i) =>
      i === index ? files[0] : image
    )
  );
};

  // Function to add a new form for formData
  const handleAddForm = () => {
    setDirectors((prevFormData) => [...prevFormData, { field1: '', field2: '' }]);
    setFormDataImages((prevFormDataImages) => [...prevFormDataImages, null]);
  };

  // Function to add a new form for formData2
  const handleAddForm2 = () => {
    setFormData2((prevFormData) => [...prevFormData, { field1: '', field2: '' }]);
    setFormData2Images((prevFormData2Images) => [...prevFormData2Images, null]);
  };

  // Function to remove a form for formData
  const handleRemoveForm = (index) => {
    setDirectors((prevFormData) => prevFormData.filter((form, i) => i !== index));
    setFormDataImages((prevFormDataImages) => prevFormDataImages.filter((image, i) => i !== index));
  };

  // Function to remove a form for formData2
  const handleRemoveForm2 = (index) => {
    setFormData2((prevFormData) => prevFormData.filter((form, i) => i !== index));
    setFormData2Images((prevFormData2Images) => prevFormData2Images.filter((image, i) => i !== index));
  };

  return (
    <div>
      {/* First set of forms */}
      {directors.map((director, index) => (
        <div key={index}>
          <form>
            <input
              type="text"
              name="name"
              placeholder="Full Name"
              value={director.name}
              onChange={(event) => handleChange(event, index)}
            />
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={director.email}
              onChange={(event) => handleChange(event, index)}
            />
            <input
              type="text"
              name="country"
              placeholder="Country"
              value={director.country}
              onChange={(event) => handleChange(event, index)}
            />
            <input
              type="text"
              name="phone"
              placeholder="Phone"
              value={director.phone}
              onChange={(event) => handleChange(event, index)}
            />
            <input
              type="text"
              name="address"
              placeholder="Address"
              value={director.address}
              onChange={(event) => handleChange(event, index)}
            />
            <input
              type="text"
              name="idType"
              placeholder="ID"
              value={director.idType}
              onChange={(event) => handleChange(event, index)}
            />
            <input
              type="file"
              name="proofId"
              accept="image/*"
              value={director.proofId}
              onChange={(event) => handleImageChange(event, index)}
            />
            {/* Add more form fields here as needed */}
            {directors.length > 1 && (
              <button type="button" onClick={() => handleRemoveForm(index)}>
                Remove
              </button>
            )}
            
          </form>
          <hr />
        </div>
      ))}
      <button type="button" onClick={handleAddForm}>
        Add More (Form Data)
      </button>

      {/* Second set of forms */}
      {formData2.map((formFields, index) => (
        <div key={index}>
          <form>
            <input
              type="text"
              name="field1"
              placeholder="Field 1"
              value={formFields.field1}
              onChange={(event) => handleChange2(event, index)}
            />
            <input
              type="text"
              name="field2"
              placeholder="Field 2"
              value={formFields.field2}
              onChange={(event) => handleChange2(event, index)}
            />
            <input
              type="file"
              name="image"
              accept="image/*"
              value={formFields.image}
              onChange={(event) => handleImageChange2(event, index)}
            />
            {/* Add more form fields here as needed */}
            <button onClick={(event) => handleSubmit(event)}>Submit</button>
            {formData2.length > 1 && (
              <button type="button" onClick={() => handleRemoveForm2(index)}>
                Remove
              </button>
            )}
          </form>
          <hr />
        </div>
      ))}
      <button type="button" onClick={handleAddForm2}>
        Add More (Form Data 2)
      </button>

      {/* Display the collected data for formData */}
      <div>
        <h2>Collected Data (Form Data):</h2>
        <pre>{JSON.stringify(directors, null, 2)}</pre>
      </div>

      {/* Display the collected data for formData2 */}
      <div>
        <h2>Collected Data (Form Data 2):</h2>
        <pre>{JSON.stringify(formData2, null, 2)}</pre>
      </div>

      {/* Display the collected images for formData */}
      <div>
      <h2>Collected Images (Form Data):</h2>
      <ul>
        {formDataImages.map((image, index) => (
          <li key={index}>
            {image ? image.name : 'No image selected'}
          </li>
        ))}
      </ul>
    </div>

      {/* Display the collected images for formData2 */}
      <div>
        <h2>Collected Images (Form Data 2):</h2>
        <ul>
          {formData2Images.map((image, index) => (
            <li key={index}>
              {image ? image.name : 'No image selected'}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Test2;
