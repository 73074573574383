import React, {useState, useEffect} from 'react'
import { FaAngleDoubleRight, FaArrowRight } from 'react-icons/fa'
import {  Form2, Form3, FormContent, FormH1, FormH2, FormInput, FormLabel, Icon, PrimaryBtn2, Text, Text2, FormArea } from '../components/Form/formElements'
import Navbar from '../components/Navbar'
import { Content,H2, TextH2, ContentDiv, RoundedDiv2, P2pCard, P2pH, P2pT, PrimaryBtn, P2pInfo, P2pInfo2, DivLeft2, DivRight2, RoundedDiv3,SelectInput, InputArea,DivContainer, GridDiv, GridDivL, P2pGrid, TabText, PaymentCard, Tdreg1, SearchBar } from '../components/Dashboard/content/dashElements'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import axios from 'axios'
import { CancelBtn, EditBtn, P2pcontainer, P2pH1, P2pTable } from '../components/Dashboard/p2pElements/p2pElements'
import { NavLink,Link } from 'react-router-dom'
import { Table as Tablle, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { CenterContent, Container, FormWrap, Tdreg, ThText } from '../components/Dashboard/content/dashElements';
import { useNavigate } from 'react-router-dom'
import Loader from '../components/loader'
import Popup from 'reactjs-popup'
import Nothing from '../assets/nothingtoshow.png'
import Empty from '../assets/empty.svg'
import userEvent from '@testing-library/user-event'
import Pin from './pin'
import AlertModal from './modal'
import {MdOutlineReportProblem} from 'react-icons/md'
import styled from 'styled-components';
import Select from 'react-select';
import Sidebar1, { MainContainer, MainContent } from './nav'
import {GrPrevious, GrNext } from 'react-icons/gr';
import { useLocation } from 'react-router-dom';
import BASE_URL from '../apiConfig'


const Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PopupCard = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 80%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  border: none;
  background: transparent;
`;
const DivPop = styled.div`
    height: 150px;
    @media screen and (max-width: 580px) {
        height: 195px;
    }
`


const Container1 = styled.div`
  display: flex;
  margin-top: 20px;

`;

const Select1 = styled.select`
padding: 16px 16px;
margin-bottom: 32px;
border: 1px solid #979797;
border-radius: 6px;
background-color: #4D0B6C;
color: #fff;
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  outline: none;
`;

const Input = styled.input`
  padding: 16px 16px;
  margin-bottom: 32px;
  border: 1px solid #979797;
  border-radius: 6px;
  background-color: transparent;
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  outline: none;
  width:100%;
  @media screen and (max-width: 580px) {
        width: 80%;
    }
`;

const P2p = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const navigate = useNavigate()
    const [buy, setBuy] = useState(null);
    const [sell, setSell] = useState(null);
    const [successful, setSuccessful] = useState(null);
    const [failed, setFailed] = useState(null);
    const [disputes, setDisputes] = useState(null);
    const [transactions, setTransactions] = useState(null);
    const [deleted, setDeleted] = useState(null);
    const [isDeleting, setIsDeleting] = useState(false);
    const [selectedData, setSelectedData] = useState({});
    const [showDetails, setShowDetails] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [description, setDescription] = useState('');
    const [dispute_type, setDisputeType] = useState('');
    const [transaction_id, setTransactionID] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(5);
  const [showNewDispute, setShowNewDispute] = useState(false);

  const handleAlertClose = () => {
    setShowAlert(false);
  };

    const token = localStorage.getItem('bearerToken');
    const [user, setUser] = useState(null);

    const fetchUser = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        return response.data;
      } catch (error) {
        console.error(error);
      }
    };
    useEffect(() => {
      const getUser = async () => {
        const data = await fetchUser();
        setUser(data);
      };
  
      getUser();
    }, []);
  
    const fetchBuy = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/trade/buy`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        return response.data;
      } catch (error) {
        console.error(error);
      }
    };
    const fetchSell = async () => {
        const response = await axios.get(`${BASE_URL}/trade/sell`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        return response.data;
      };
  
    useEffect(() => {
      const getTrade = async () => {
        const [buy, sell]= await Promise.all([fetchBuy(), fetchSell()]);
      setBuy(buy);
      setSell(sell);
      setAllItems(buy?.data.data.concat(sell?.data.data))
      };
  
      getTrade();
    }, []);

    const handleDelete = async (id) => {
      try {
        setIsDeleting(true);
        await axios.delete(`${BASE_URL}/trade/delete/${id}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // Handle successful deletion, e.g. remove the trade from the table data and update the UI
        setAlertMessage('successfully deleted');
        setShowAlert(true);
        await new Promise((resolve) => setTimeout(resolve, 2000));
        navigate(0);
      } catch (error) {
        console.error(error);
        const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
        setAlertMessage(jsonString);
        setShowAlert(true);
        // Handle error, e.g. display an error message to the user
      } finally {
        setIsDeleting(false);
      }
    };
    const handleEditAd = (tradeId) => {
      navigate('/edit-ad', {state: {trades: allItems, tradeId: tradeId}})
    }


    const [isEmpty, setIsEmpty] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [allItems, setAllItems] = useState(null)
    const filteredData = allItems?.filter((trade) =>
    trade.tradeType.toLowerCase().includes(searchQuery.toLowerCase())||
    trade.assetName.toLowerCase().includes(searchQuery.toLowerCase()) ||
    trade.totalAmount.toLowerCase().includes(searchQuery.toLowerCase()) ||
    trade.min_amount.toLowerCase().includes(searchQuery.toLowerCase()) ||
    trade.max_amount.toLowerCase().includes(searchQuery.toLowerCase()) ||
    trade.marginPrice.toLowerCase().includes(searchQuery.toLowerCase()) ||
    trade.payment_info.toLowerCase().includes(searchQuery.toLowerCase()) ||
    trade.payment_info?.method_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    trade.trade_currency.toLowerCase().includes(searchQuery.toLowerCase())
    )

    const handleSearchInputChange = (event) => {
      setSearchQuery(event.target.value);
      setCurrentPage(1); // reset to first page when search query changes
      setIsEmpty(filteredData.length === 0);
      if (searchQuery === 0) {
        setSearchQuery(null);
      }
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = allItems?.slice(indexOfFirstItem, indexOfLastItem);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(allItems?.length / itemsPerPage); i++) {
      pageNumbers.push(i);
    }

   const handlePrevPage = () => {
      setCurrentPage( currentPage - 1);
    };
   const handleNextPage = () => {
      setCurrentPage(currentPage + 1);
    };
    const handleClick = e => {
      setCurrentPage(Number(e.target.id));
    };

    const isLastPage = currentPage === pageNumbers.length;
    const isFirstPage = currentPage === 1;

  return (
    <>
        <Navbar toggleSidebar={toggleSidebar}/>
        <MainContainer isOpen={isOpen}>
            <Sidebar1 isOpen={isOpen} toggleSidebar={toggleSidebar} />
            <MainContent>
        <Container style={{backgroundColor: '#fff', borderRadius: '6px'}}>
            <FormWrap>
            <div style={{marginLeft: '20px', marginTop: '10px'}}>
              <h2>Your Active Ads</h2>
              <div style={{position:"flex", marginTop: '10px', width: '100%', marginBottom: ''}}>
                  <SearchBar type="text" value={searchQuery} onChange={handleSearchInputChange} placeholder='search your ads'  />
              </div>
            </div>
    {buy && sell ? (
    <P2pcontainer>
    <PrimaryBtn Link style={{float: 'right'}}><NavLink to='/create-ad' style={{textDecoration: 'none', color: 'white'}}>Create AD</NavLink></PrimaryBtn>
    <FormWrap>
    {buy.data.data.length || sell.data.data.length ? (
                <CenterContent>
                {isEmpty ? (
                  <div style={{justifyContent:'center', display: 'grid'}}>
                        <div style={{textAlign: 'center'}}>
                          <img src={Nothing} alt="Empty table" />
                        </div>
                        <div style={{textAlign: 'center'}}>
                          <h5 style={{marginBottom: '10px'}}>No search results found</h5>
                        </div>
                        <div>
                        </div>
                      </div>
                ) : (
                  <>
                  <Tablle style={{ textAlign: 'left', borderCollapse: 'collapse'}}>
                        <Thead style={{ backgroundColor: "#F3E8F7",
  color: "#1E1E1E",
  fontWeight: "600",
  lineHeight:"21px"}}>
                            <Tr>
                            <Th><ThText>Order</ThText></Th>
                            <Th><ThText>Amount</ThText></Th>
                            <Th><ThText>Limit</ThText></Th>
                            <Th><ThText>Fees</ThText></Th>
                            <Th><ThText>Payment Method</ThText></Th>
                            <Th><ThText>Control</ThText></Th>
                            </Tr>
                        </Thead>
                        
                        <Tbody>
                          {currentItems.map((trade, index) => (
                            <Tr key={index} style={{borderBottom: '1px solid #ddd'}}>
                              <Td><Tdreg><P2pH1 style={{color: trade.tradeType === 'sell' ? '#FF4B4B' : 'inherit'}}>{trade.tradeType} {trade.assetName}</P2pH1></Tdreg></Td>
                              <Td><Tdreg>{trade.trade_currency} {trade.totalAmount}</Tdreg></Td>
                              <Td><Tdreg>{trade.min_amount} ~ {trade.max_amount}</Tdreg></Td>
                              <Td><Tdreg>{trade.trade_currency} {trade.marginPrice}</Tdreg></Td>
                              <Td><Tdreg>{trade.payment_info && trade.payment_info.method_name ? trade.payment_info.method_name : 'Null'}</Tdreg></Td>
                              <Td style={{display: 'flex', marginTop: '10px'}}>
                              <EditBtn onClick={() => handleEditAd(trade.id)}>Edit</EditBtn>
                              <CancelBtn onClick={() => handleDelete(trade.id)} disabled={isDeleting}>{isDeleting ? 'Cancelling...' : 'Cancel'}</CancelBtn>
                              </Td>
                            </Tr>
                          ))}
                        </Tbody>
                    </Tablle>
                    <div>
                    <ul style={{display: 'flex', listStyle: 'none', margin:'10px'}}>
                    <button onClick={handlePrevPage} disabled={isFirstPage} style={{ cursor: isFirstPage ? 'not-allowed' : 'pointer', backgroundColor: isFirstPage ? '#8080808c' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                          <GrPrevious />
                        </button>
                        
                        {pageNumbers.map(number => (
                          <li key={number}>
                            <button id={number} onClick={handleClick} style={{ backgroundColor: currentPage === number ? '#FCF3FF' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                              {number}
                            </button>
                          </li> 
                        ))}
                        <button onClick={handleNextPage} disabled={isLastPage} style={{ cursor: isLastPage ? 'not-allowed' : 'pointer', backgroundColor: isLastPage ? '#8080808c' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                          <GrNext/>
                        </button> 
                      </ul>
                    </div>
                    </>)}
                </CenterContent>
                ) : (
                      <div style={{justifyContent:'center', display: 'grid'}}>
                        <div style={{textAlign: 'center'}}>
                          <img src={Empty} alt="Empty table" />
                        </div>
                        <div style={{textAlign: 'center', marginBottom: '10px'}}>
                          <h4>You have no active Ad</h4>
                        </div>
                        <div>
                            <NavLink to='/create-ad'> 
                                <PrimaryBtn style={{width: '100%'}} Link>Create AD</PrimaryBtn>
                            </NavLink>
                        </div>
                      </div>
                      )}
            </FormWrap>
  </P2pcontainer> 
  ) : <Loader/>}
    
                        
            </FormWrap>
        </Container>
        </MainContent>
        </MainContainer>
        {showAlert && (
        <AlertModal
          isModalOpen={showAlert}
          alertMessage={alertMessage}
          onClose={handleAlertClose}
        />
      )}
    </>
  )
}

export default P2p