import React, { useState, useEffect } from "react";
import { DivLeft2, DivRight2, P2pInfo, P2pInfo2, PaymentCard, PayoutCardWrapper2, PrimaryBtn, TableBody, TableCell, TableContainer, TableHead, TableHeader, TableRow, Tablee } from "../components/Dashboard/content/dashElements";
import { Form3, FormH2, PrimaryBtn2 } from "../components/Form/formElements";
import Loader from "../components/loader";
import { FaSearch } from 'react-icons/fa';
import { Table as Tablle, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { CenterContent, Container, FormWrap, SearchBar, TdRef, Tdreg, ThText } from '../components/Dashboard/content/dashElements';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { NavLink } from 'react-router-dom'
import { CancelBtn } from "../components/Dashboard/p2pElements/p2pElements";
import AlertModal from './modal'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Empty from '../assets/empty.svg'
import {GrPrevious, GrNext } from 'react-icons/gr';
import {IoIosAddCircle} from 'react-icons/io';
import Sidebar1, { MainContainer, MainContent } from "./nav";
import Navbar from "../components/Navbar";
import styled from "styled-components";
import BASE_URL from '../apiConfig'
import Bank from '../assets/bank.png'


const DivPay = styled.div`
  margin-left: 30px;
  display: grid;
  @media screen and (max-width: 760px){
      margin-left: 10px;
      margin-right: 10px
    }
`;
const CenterContent1 = styled.div`
    display: grid;
    align-items: center;
    text-align: left;
    font-family: Poppins;

    @media screen and (max-width: 560px){
        width: 95%;
    }
    @media screen and (min-width: 760px){
        overflow-x: auto;
    }
`

const MyPaymentMethod = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [loading, setLoading] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const [data, setData] = useState([]);
  const token = localStorage.getItem('bearerToken');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(3);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const itemsToDisplay = data.slice(startIndex, endIndex);
  const [isDeleting, setIsDeleting] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const navigate = useNavigate()

  useEffect(() => {
    fetch(`${BASE_URL}/payment-method`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(res => res.json())
      .then(response => {setData(response.data); setLoading(false);})
      .catch(err => {console.error(err); setLoading(false);});
  }, []);
  const totalPages = Math.ceil(data.length / itemsPerPage);

const handlePageChange = page => {
  setCurrentPage(page);
};

const paginationButtons = [];
for (let i = 1; i <= totalPages; i++) {
  paginationButtons.push(
    <PrimaryBtn2 style={{width: '20px', borderRadius: '20px'}} key={i} onClick={() => handlePageChange(i)}>
      {i}
    </PrimaryBtn2>
  );
}
const handleDelete = async (id) => {
  try {
    setIsDeleting(true);
    await axios.get(`${BASE_URL}/payment-method/${id}/delete`,{
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // Handle successful deletion, e.g. remove the trade from the table data and update the UI
    setAlertMessage('successfully deleted');
    setShowAlert(true);
  } catch (error) {
    console.error(error);
    const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
    setAlertMessage(jsonString);
    setShowAlert(true);
    // Handle error, e.g. display an error message to the user
  } finally {
    setIsDeleting(false);
  }
};

const handleAlertClose = () => {
  setShowAlert(false);
  navigate(0)
};

const indexOfLastItem = currentPage * itemsPerPage;
const indexOfFirstItem = indexOfLastItem - itemsPerPage;
const currentItems = data?.slice(indexOfFirstItem, indexOfLastItem);

const pageNumbers = [];
for (let i = 1; i <= Math.ceil(data?.length / itemsPerPage); i++) {
  pageNumbers.push(i);
}

const handlePrevPage = () => {
  setCurrentPage( currentPage - 1);
};
const handleNextPage = () => {
  setCurrentPage(currentPage + 1);
};
const handleClick = e => {
  setCurrentPage(Number(e.target.id));
};

const isLastPage = currentPage === pageNumbers.length;
const isFirstPage = currentPage === 1;
  return(
    ( !loading ? (
    <>
    {showAlert && (
        <AlertModal
          isModalOpen={showAlert}
          alertMessage={alertMessage}
          onClose={handleAlertClose}
        />
      )}
      <Navbar toggleSidebar={toggleSidebar}/>
        <MainContainer isOpen={isOpen}>
            <Sidebar1 isOpen={isOpen} toggleSidebar={toggleSidebar} />
            <MainContent>
        <Container>
            {data.length === 0 ? (
              <div style={{justifyContent:'center', display: 'grid'}}>
                        <div>
                          <img src={Empty} alt="Empty table" />
                        </div>
                        <div style={{textAlign: 'center'}}>
                          <h4 style={{marginBottom: '10px'}}>Nothing to display</h4>
                        </div>
                        <div>
                            <NavLink to='/add-payment'> 
                                <PrimaryBtn style={{width: '100%'}} Link>Add Payment Method</PrimaryBtn>
                            </NavLink>
                        </div>
                  </div>
              ) : ( 
                <DivPay>
                <div style={{margin:'10px', display:'grid'}}>
                  <h3 style={{marginLeft: '25px', float: 'left'}}>Payment Method</h3>
                  <PaymentCard style={{border:'none'}}>
                    <DivLeft2 style={{fontSize:'12px'}}>Add the details of your bank account or wallet where you want your withdrawals to be sent to. This can be your own account, that of your friends, family or suppliers.</DivLeft2>
                    <DivRight2>
                      <PrimaryBtn style={{borderRadius: '12px', padding: '10px', width:'150px', height:'64px', background:'transparent',  border:'1px solid #4D0B6C'}} Link><NavLink to='/add-payment' style={{textDecoration: 'none', color: '#4D0B6C', fontWeight:'600', fontSize:'12px'}}> <IoIosAddCircle /> Beneficiary</NavLink></PrimaryBtn>
                    </DivRight2>
                  </PaymentCard>
                </div>
                    <>
                      {currentItems.map(paymentMethod => (
                      <Form3 style={{maxWidth:'800px', padding:'32px 32px'}}>
                              <div style={{margin: '10px'}}>
                                  <div style={{float: 'left'}}><img src={Bank} alt='logo' /></div>
                                      <div style={{color: 'red', fontSize: '16px', float: 'right', cursor:'pointer'}} onClick={() =>  handleDelete(paymentMethod.id)} disabled={isDeleting}>{isDeleting ? 'Deleting...' : 'Delete'}</div>
                              </div>
                              {Object.entries(paymentMethod.payment_info).map(([key, value]) => (
                              <div>
                                  <DivLeft2 style={{marginBottom:''}}>
                                      <P2pInfo key={key} style={{textTransform:'capitalize'}}>{key.replace(/_/g, ' ')}</P2pInfo>
                                  </DivLeft2>
                                  <DivRight2 style={{marginBottom:''}}>
                                      <P2pInfo2 key={key}>{value}</P2pInfo2>
                                  </DivRight2>
                              </div>
                              ))}
                      </Form3>
                      ))}
                    </>   
                <div>
                    <ul style={{display: 'flex', listStyle: 'none', margin:'10px'}}>
                    <button onClick={handlePrevPage} disabled={isFirstPage} style={{ cursor: isFirstPage ? 'not-allowed' : 'pointer', backgroundColor: isFirstPage ? '#8080808c' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                          <GrPrevious />
                        </button>
                        
                        {pageNumbers.map(number => (
                          <li key={number}>
                            <button id={number} onClick={handleClick} style={{ backgroundColor: currentPage === number ? '#FCF3FF' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                              {number}
                            </button>
                          </li> 
                        ))}
                        <button onClick={handleNextPage} disabled={isLastPage} style={{ cursor: isLastPage ? 'not-allowed' : 'pointer', backgroundColor: isLastPage ? '#8080808c' : 'transparent', padding: '10px', border: '2px #FCF3FF solid', margin:'4px'}}>
                          <GrNext/>
                        </button> 
                      </ul>
                    </div>
                </DivPay>
          )}
          </Container>
        </MainContent>
        </MainContainer>
    </>
  ) : (<Loader/>)))
};

export default MyPaymentMethod;
