import styled from "styled-components";

export const Nav = styled.nav`
    background: #fff;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 10;
    box-shadow: 0 10px 10px -5px rgba(179, 179, 179, 0.16);
    @media screen and (max-width: 960px){
        background: #fff;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        position: sticky;
        top: 0;
        width: 100%;
        z-index: 10;
        box-shadow: 0;
    }
`

export const NavContainer = styled.div`
    display: flex;
    justify-content: space-between;
    height: 90px;
    z-index: 1;
    width:100%;
     @media screen and (max-width: 960px){
        display: none;
    }
`
export const MobileNavContainer = styled.div`
    display: none;
     @media screen and (max-width: 960px){
        display: flex;
        justify-content: space-between;
        height: 90px;
        z-index: 1;
        width:100%;
    }
`

export const NavLeft = styled.div`
    display: flex;
    float: left;
    margin-left: 20px;
    align-items: center;
`

export const NavRight = styled.div`
    display: flex;
    float: right;
    align-items: center;
`

export const NavLabel = styled.label`
    font-size: 15px;
    font-family: Poppins;
    font-weight: 400;
    color: #6C6363;
    font-style: normal;
    line-height: 33px;
`

export const MobileIcon = styled.div`
    display: none;

    @media screen and (max-width: 960px){
        display: grid;
        cursor: pointer;
        background: rgba(0, 0, 0, 0.03);
        border-radius: 10px; 
        align-items: center;
        align-content: center;
        margin-right: 20px;
        margin-left: 10px;
        padding:15px;
    }
`
export const MobileMessage = styled.div`
    display: none;

    @media screen and (max-width: 960px){
        display: flex;
        font-family: Inter;
        font-size: 20px;
        font-weight: 700;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;

    }
`
export const MenuIcon = styled.div`
    display: none;

    @media screen and (min-width: 960px){
        display: flex;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        cursor: pointer;
        float: left;
        border: 2px solid;
        border-color: rgba(238,238,238);
        border-radius: 4px; 
        align-items: center;
        margin-left: 50px;
        padding:10px;
        margin-bottom: 50px;
    }
`
export const MobSide = styled.div`
    display: flex;
    @media screen and (min-width: 960px){
        display: none;
       
    }
`
export const DeskSide = styled.div`
    display: none;

    @media screen and (min-width: 960px){
        display: flex;
    }
`
export const NavMenu = styled.ul`
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;
    margin-right: -22px;
`