import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr;

  @media (min-width: 768px) {
    display: none;
  }
`;

export const Title = styled.div`
  font-weight: bold;
  display: block;

  @media (min-width: 768px) {
    margin-right: 10px;
    margin-bottom: 0;
  }
`;

export const DropdownContent = styled.div`
  display: block;
  margin-top: 15px;
`;

export const EmailContainer = styled.div`
  display: flex;
  flex-direction: column;
`;