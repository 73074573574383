import React, {useState, useEffect} from 'react'
import { FaBars } from 'react-icons/fa'
import {Nav, NavContainer, NavLabel, NavLeft, NavRight, MobileIcon, MenuIcon, MobSide, MobileNavContainer, MobileMessage} from './navElements'
import { BsBell } from 'react-icons/bs'
import {IoMdArrowDropdown} from 'react-icons/io'
import {IoBusiness} from 'react-icons/io5'
import {GrCompliance} from 'react-icons/gr'
import Sidebar, { onClickOutside } from 'react-sidebar' 
import {RxDashboard} from 'react-icons/rx'
import {AiOutlineSetting,AiOutlineWallet,AiOutlineCloseCircle,AiOutlineInteraction,AiOutlineLineChart} from 'react-icons/ai'
import {MdProductionQuantityLimits} from 'react-icons/md'
import {HiLink, HiOutlineUserGroup} from 'react-icons/hi'
import {FiLogOut} from 'react-icons/fi'
import {RiLockPasswordLine} from 'react-icons/ri'
import {FaExchangeAlt} from 'react-icons/fa'
import {FcAdvertising,FcProcess} from 'react-icons/fc'
import {RiExchangeCnyLine} from 'react-icons/ri'
import {GoReport} from 'react-icons/go'
import {BsPersonGear} from 'react-icons/bs'
import {MdOutlinePayments} from 'react-icons/md'
import Bizimg from '../../assets/bizimg.png'
import { BizTitle, BusinessImg, CompanyInfo, DashSide, PolygonDown, PolygonUp, SubMenuText,SubMenuItem, SubMenuTitle, WalletAddress, SubMenu } from './mobileSidebarElements'
import axios from 'axios'
import '../Dashboard/sidebar/side.css'
import { NavLink } from 'react-router-dom';
import { Menu, MenuItem,SubMenu as SubMen, Sidebar as Xide } from 'react-pro-sidebar';
import {Menu as Men1} from './mobileSidebarElements'
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import AuthContext from '../../context/AuthProvider'
import styled from 'styled-components'
import DashboardSidebar from '../Dashboard/sidebar'
import { Container, FormH1, Icon } from '../Form/formElements'
import { useLocation } from 'react-router-dom'
import ZeenahPay from '../../assets/z.png'
import DashboardIcon from '../../assets/DashboardIcon.svg'
import AlertModal from '../../pages/modal'
import BASE_URL from '../../apiConfig'
import GoogleTranslateWidget from '../googleTranslate'

const DropdownContainer = styled.div`
  position: relative;
  margin-right: 25px;
`;

const DropdownMenu = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  z-index: 1;
  display: ${props => (props.open ? 'block' : 'none')};
  min-width: 200px;
  padding: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
`;

const DropdownOption = styled.a`
  display: block;
  padding: 5px 10px;
  color: black;
  text-decoration: none;
  &:hover {
    background-color: #f5f5f5;
    color: #7416A0;
  }
`;

const DropdownButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
`;


const Navbar = ({ toggleSidebar }) => {
    const { setAuth } = useContext(AuthContext);
    const navigate = useNavigate();
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    const [open, setOpen] = useState(false)
    const [openside, setOpenSide] = useState(true)
    const [openside1, setOpenSide1] = useState(false)
    const [user, setUser] = useState(null);

    const token = localStorage.getItem('bearerToken');
    const location = useLocation();
    const [isActive5, setIsActive5] = useState(false);
    const [isActive1, setIsActive1] = useState(false);
    const [isActive2, setIsActive2] = useState(false);
    const [isActive3, setIsActive3] = useState(false);
    const [isActive4, setIsActive4] = useState(false);
    const [isActive0, setIsActive0] = useState(false);
    const [isActive01, setIsActive01] = useState(false);
    const [isActive02, setIsActive02] = useState(false);
    const [isActive03, setIsActive03] = useState(false);
    const [isActive00, setIsActive00] = useState(false);

     useEffect(() => {
      if (location.pathname === '/p2p-dispute') {
        setIsActive5(true);
      } else {
        setIsActive5(false);
      }
    }, [location]);
     useEffect(() => {
      if (location.pathname === '/p2p-closed') {
        setIsActive1(true);
      } else {
        setIsActive1(false);
      }
    }, [location]);
     useEffect(() => {
      if (location.pathname === '/p2p-transactions') {
        setIsActive2(true);
      } else {
        setIsActive2(false);
      }
    }, [location]);
     useEffect(() => {
      if (location.pathname === '/p2p-success') {
        setIsActive3(true);
      } else {
        setIsActive3(false);
      }
    }, [location]);
     useEffect(() => {
      if (location.pathname === '/p2p-failed') {
        setIsActive4(true);
      } else {
        setIsActive4(false);
      }
    }, [location]);
     useEffect(() => {
      if (location.pathname === '/p2p') {
        setIsActive0(true);
      } else {
        setIsActive0(false);
      }
    }, [location]);
     useEffect(() => {
      if (location.pathname === '/settings/business-details') {
        setIsActive01(true);
      } else {
        setIsActive01(false);
      }
    }, [location]);
     useEffect(() => {
      if (location.pathname === '/settings/compliance') {
        setIsActive02(true);
      } else {
        setIsActive02(false);
      }
    }, [location]);
     useEffect(() => {
      if (location.pathname === '/settings/paymentmethod') {
        setIsActive03(true);
      } else {
        setIsActive03(false);
      }
    }, [location]);
     useEffect(() => {
      if (location.pathname === '/settings/personal-details') {
        setIsActive00(true);
      } else {
        setIsActive00(false);
      }
    }, [location]);
    const [transactions, setTransactions] = useState(null);
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        return response.data;
      } catch (error) {
        console.error(error);
      }
    };
  
    useEffect(() => {
      const getUser = async () => {
        const data = await fetchUser();
        setUser(data);
      };
  
      getUser();
    }, []);
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
      setIsOpen(!isOpen);
    };

    const logout = async () => {
        // if used in more components, this should be in context 
        // axios to /logout endpoint 
        setAuth({});
        localStorage.removeItem('email');
        localStorage.removeItem('bearerToken');
        localStorage.removeItem('password');
        navigate('/login');
    }

    const [isOpen11, setIsOpen11] = useState(true);

  const toggleSidebar11 = () => {
    setIsOpen11(!setIsOpen11);
  };
  const fetchTransactions = async () => {
    try {
      const response = await axios.get(`${BASE_URL}/trade/history/buy-sell-history?status=pending`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      return response.data;
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const getTransactions = async () => {
      const data = await fetchTransactions();
      setTransactions(data);
    };
  
    const interval = setInterval(getTransactions, 5000000);
  
    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  const handleAlertClose = () => {
    setShowAlert(false);
  };

  const handleBetaLinkClick = () => {
    setAlertMessage(<>
      <h5>Coming Soon</h5>
      <p style={{fontSize:'12px'}}>This feature is under development and will be available soon.</p>
    </>);
    setShowAlert(true);
  };
  return (
    <>
        <Nav>{user &&
            <NavContainer>
                <NavLeft>
                    <MobileIcon onClick={() => {setOpen(open ? false : true); setOpenSide1(openside1 ? false : true)}}>
                        <FaBars style={{fontSize: '20px'}} />
                    </MobileIcon>
                    <MenuIcon onClick={() => {setOpenSide(openside ? false : true); toggleSidebar()}}>
                        <FaBars style={{fontSize: '20px'}} />
                    </MenuIcon>
                    {/*<div className='topRight'><NavLabel>Default Currency:</NavLabel>&nbsp; 
                        <select style={{background: '#fff', borderRadius: '12px', height: '40px', border: '1px solid #a19e9d', color: '#6C6363', textAlign: 'center'}}>
                            <option>USD</option>
                            <option>GBP</option>
                            <option>NGN</option>
                            <option>EURO</option>
                        </select>
                    </div> */}
                </NavLeft>
                <Icon src={ZeenahPay} />
                <NavRight>
                <div style={{display:'flex', display: 'flex', textAlign: 'center', alignContent: 'center', alignSelf: 'center'}}>
                      <GoogleTranslateWidget/>
                    </div>
                    <BsBell style={{margin: '15px', fontSize: '20px'}} />
                    <div><img src={user?.data?.profile_image} alt={user?.data?.firstName} style={{width:'51px', height:'50px', borderRadius:'50%'}} /></div>
                    <DropdownContainer>
                        <div>
                            <DropdownButton onClick={toggleDropdown}><IoMdArrowDropdown style={{ fontSize: '20px',color: '#6C6363'}}/></DropdownButton>
                            <DropdownMenu open={isOpen}>
                            <DropdownOption href='/settings'><AiOutlineSetting /> Settings</DropdownOption>
                            <DropdownOption href="/updatepin"><RiLockPasswordLine/> Update Pin</DropdownOption>
                            <DropdownOption onClick={logout} style={{cursor: 'pointer'}}><FiLogOut/> Logout</DropdownOption>
                            </DropdownMenu>
                        </div>
                    </DropdownContainer>
                </NavRight>
            </NavContainer>}
            <MobileNavContainer>
              <NavLeft>
                <div><img src={user?.data?.profile_image} alt={user?.data?.firstName} style={{width:'51px', height:'50px', borderRadius:'50%'}} /></div>
                <MobileMessage style={{paddingLeft:'10px'}}>Welcome, {user?.data?.firstName}</MobileMessage>
              </NavLeft>
              <NavRight>
                <div style={{borderRadius:'10px', background:'rgba(0, 0, 0, 0.03)', display:'grid'}}>
                  <BsBell style={{margin: '15px', fontSize: '20px'}} />
                </div>          
                <MobileIcon onClick={() => {setOpen(open ? false : true); setOpenSide1(openside1 ? false : true)}}>
                  <FaBars style={{fontSize: '20px'}} />
                </MobileIcon>
              </NavRight>
            </MobileNavContainer>
        </Nav>
        {openside1 && 
          <MobSide>
        
          <Sidebar
          sidebar={
            <>
                    <CompanyInfo>
                <BusinessImg>
                    <img src={Bizimg} style={{marginLeft:'16px'}} alt='business logo'/>
                </BusinessImg>{user && (
                <BizTitle>{user.data.businessName}</BizTitle>)} 
                {/*<WalletAddress>0xB837917.....F3B70</WalletAddress>
                <PolygonUp />
                <PolygonDown /> */}
            </CompanyInfo>
            <Men1 style={{padding: '0px'}}>
            <Menu
          menuItemStyles={{
            button: ({ level, active, disabled, isSubmenu }) => {
              if (level === 0) {
                return {
                  icon: ({marginRight: "0px"}),
                  "&:hover": {
                     backgroundColor: "#4D0B6C !important",
                     color: "white !important",
                     borderRadius: "8px !important",
                     fontWeight: "bold !important"
                   },
                };
              }
            },
          }}
           >
                        <SubMenuTitle>HOME</SubMenuTitle>
                        <NavLink to='/dashboard' style={({ isActive }) => isActive ? { color: '#4D0B6C',  textDecoration: 'none' }: { color: '#6C6363',  textDecoration: 'none' }}>
                            <MenuItem icon={<RxDashboard/>}> <SubMenuText> Dashboard</SubMenuText></MenuItem>
                        </NavLink>
                        <SubMenu>
                        <SubMenuTitle>Payments</SubMenuTitle>
                        <SubMen icon={<AiOutlineInteraction/>} style={{color: isActive5 || isActive1 || isActive2 || isActive3 || isActive4 || isActive0 ? '#fff' : '#4D0B6c', background: isActive5 || isActive1 || isActive2 || isActive3 || isActive4 || isActive0 ? '#4D0B6c' : '#fff', borderRadius: '8px', margin:'10px', marginLeft:'0px'}} label={<div style={{ marginLeft: '20px' }}>
                            P2P{' '}
                            {transactions?.data.data.length > 0 && (
                              <sup
                                style={{
                                  fontSize: '14px',
                                  marginLeft: '2px',
                                  fontWeight: '600',
                                  color: 'white',
                                  backgroundColor: '#f009',
                                  padding: '5px',
                                  borderRadius: '20%'
                                }}
                              >
                                {transactions?.data.data.length}
                              </sup>
                            )}
                          </div>}
                          >
                          <div style={{background: '#FCF3FF', borderRadius: '8px', display:'block', overflow:'hidden', marginRight:'10px', maxWidth: '90%', width: '100%', margin: '0 auto'}}>
                            <NavLink to='/p2p' style={{ color: '#4D0B6c', background:isActive0 ? '#07f3ff24' : 'none', textDecoration: 'none', padding: '10px', marginLeft:'0' }}>
                                <MenuItem style={{marginLeft:'-22px'}}  routerLink={<NavLink to="/p2p" activeClassName="active"/>} icon={<FcAdvertising/>}> <SubMenuText style={{fontSize: '13px', marginLeft: '2px'}}> Active Ads <SubMenuText style={{display: isActive0 ? 'contents' : 'none', marginLeft: '5px', color: '#167e16' }}>•</SubMenuText></SubMenuText></MenuItem>
                            </NavLink>
                            <NavLink to='/p2p/closed' style={{ color: '#4D0B6c', background:isActive1 ? '#07f3ff24' : 'none', textDecoration: 'none', padding: '10px' }}>
                                <MenuItem style={{marginLeft:'-22px'}} routerLink={<NavLink to="/p2p-closed" activeClassName="active"/>} icon={<AiOutlineCloseCircle style={{color: 'red'}}/>}> <SubMenuText style={{fontSize: '13px', marginLeft: '2px'}}> Closed Ads <SubMenuText style={{display: isActive1 ? 'contents' : 'none', marginLeft: '5px', color:'#167e16' }}>•</SubMenuText></SubMenuText></MenuItem>
                            </NavLink>
                            <NavLink to='/p2p/transactions' style={{ color: '#4D0B6c', background:isActive2 ? '#07f3ff24' : 'none', textDecoration: 'none', padding: '10px' }}>
                                <MenuItem style={{marginLeft:'-22px'}} routerLink={<NavLink to="/p2p-transactions" activeClassName="active"/>} icon={<FcProcess color='blue' />}> <SubMenuText style={{fontSize: '13px', marginLeft: '2px'}}> Ongoing Transactions {transactions?.data.data.length > 0 && (
                                    <sup style={{ fontSize: '14px', marginLeft: '2px', fontWeight:'600', color:'white', backgroundColor:'#f009', padding:'5px', borderRadius:'20%' }}>{transactions?.data.data.length}</sup>)}<SubMenuText style={{display: isActive2 ? 'contents' : 'none', marginLeft: '5px', color:'#167e16' }}>•</SubMenuText></SubMenuText></MenuItem>
                            </NavLink>
                            <NavLink to='/p2p/success' style={{ color: '#4D0B6c', background:isActive3 ? '#07f3ff24' : 'none', textDecoration: 'none', padding: '10px' }}>
                                <MenuItem style={{marginLeft:'-22px'}} routerLink={<NavLink to="/p2p-success" activeClassName="active"/>} icon={<FaExchangeAlt style={{color: 'green'}} />}> <SubMenuText style={{fontSize: '13px', marginLeft: '2px'}}> Successful Transactions <SubMenuText style={{display: isActive3 ? 'contents' : 'none', marginLeft: '5px', color:'#167e16' }}>•</SubMenuText></SubMenuText></MenuItem>
                            </NavLink>
                            <NavLink to='/p2p/failed' style={{ color: '#4D0B6c', background:isActive4 ? '#07f3ff24' : 'none', textDecoration: 'none', padding: '10px' }}>
                                <MenuItem style={{marginLeft:'-22px'}} routerLink={<NavLink to="/p2p-failed" activeClassName="active"/>} icon={<FaExchangeAlt color='red'/>}> <SubMenuText style={{fontSize: '13px', marginLeft: '2px'}}> Failed Transactions <SubMenuText style={{display: isActive4 ? 'contents' : 'none', marginLeft: '5px', color:'#167e16' }}>•</SubMenuText></SubMenuText></MenuItem>
                            </NavLink>
                            <NavLink to='/p2p/dispute' style={{ color: '#4D0B6c', background: isActive5 ? '#07f3ff24' : 'none', textDecoration: 'none', padding: '10px' }}>
                                <MenuItem style={{marginLeft:'-22px'}} routerLink={<NavLink to="/p2p-dispute" activeClassName="active"/>} icon={<GoReport color='#ea9208'/>}> <SubMenuText style={{fontSize: '13px', marginLeft: '2px'}}> Dispute <SubMenuText style={{display: isActive5 ? 'contents' : 'none', marginLeft: '5px', color:'#167e16' }}>•</SubMenuText></SubMenuText></MenuItem>
                            </NavLink>
                          </div>
                        </SubMen>
                        {/*<NavLink to='/topup' style={({ isActive }) => isActive ? { color: '#fff', background: '#4D0B6C',  textDecoration: 'none', borderRadius: '8px',  fontWeight: 'bold' }: { color: '#4D0B6c', background: 'none', textDecoration: 'none' }}>
                            <MenuItem icon={<AiOutlineWallet/>}> <SubMenuText> Deposit</SubMenuText></MenuItem>
                        </NavLink>
                        
                        <NavLink to='/payout' style={({ isActive }) => isActive ? { color: '#fff', background: '#4D0B6C',  textDecoration: 'none', borderRadius: '8px',  fontWeight: 'bold' }: { color: '#4D0B6c', background: 'none', textDecoration: 'none' }}>
                            <MenuItem icon={<AiOutlineWallet/>}> <SubMenuText> Send Money</SubMenuText></MenuItem>
                        </NavLink> */}
                        <NavLink to='/swap' style={({ isActive }) => isActive ? { color: '#fff', background: '#4D0B6C',  textDecoration: 'none', borderRadius: '8px',  fontWeight: 'bold' }: { color: '#4D0B6c', background: 'none', textDecoration: 'none' }}>
                            <MenuItem icon={<RiExchangeCnyLine/>}> <SubMenuText> Swap</SubMenuText></MenuItem>
                        </NavLink>
                        <NavLink to='/transactions' style={({ isActive }) => isActive ? { color: '#fff', background: '#4D0B6C',  textDecoration: 'none', borderRadius: '8px',  fontWeight: 'bold' }: { color: '#4D0B6c', background: 'none', textDecoration: 'none' }}>
                            <MenuItem icon={<AiOutlineLineChart/>}> <SubMenuText> Transactions</SubMenuText></MenuItem>
                        </NavLink>
                        {/*<NavLink to='/customers' style={({ isActive }) => isActive ? { color: '#fff', background: '#4D0B6C',  textDecoration: 'none', borderRadius: '8px',  fontWeight: 'bold' }: { color: '#4D0B6c', background: 'none', textDecoration: 'none' }}>*/}
                            <MenuItem style={{ color: '#4D0B6C' }} onClick={handleBetaLinkClick}  icon={<HiOutlineUserGroup/>}> <SubMenuText>Beneficiaries <sup style={{ fontSize: '10px', borderRadius: '16px', textAlign: 'center', padding: '8px', color: '#4D0B6C'}}>BETA</sup></SubMenuText></MenuItem>
                        {/*</NavLink>*/}
                    </SubMenu>
                    <SubMenu>
                        <SubMenuTitle>Cards</SubMenuTitle>
                        {/*<NavLink to='/products' style={({ isActive }) => isActive ? { color: '#fff', background: '#4D0B6C',  textDecoration: 'none', borderRadius: '8px',  fontWeight: 'bold' }: { color: '#4D0B6c', background: 'none', textDecoration: 'none' }}> */}
                            <MenuItem style={{ color: '#4D0B6C' }} onClick={handleBetaLinkClick} icon={<MdProductionQuantityLimits/>}> <SubMenuText> Gift Cards <sup style={{ fontSize: '10px', borderRadius: '16px', textAlign: 'center', padding: '8px', color: '#4D0B6C'}}>BETA</sup></SubMenuText></MenuItem>
                        {/*</NavLink>*/}
                        {/*<NavLink to='/paymentlink-history' style={({ isActive }) => isActive ? { color: '#fff', background: '#4D0B6C',  textDecoration: 'none', borderRadius: '8px',  fontWeight: 'bold' }: { color: '#4D0B6c', background: 'none', textDecoration: 'none' }}>*/}
                            <MenuItem style={{ color: '#4D0B6C' }} onClick={handleBetaLinkClick}  icon={<HiLink/>}> <SubMenuText> Virtual Card<sup style={{ fontSize: '10px', borderRadius: '16px', textAlign: 'center', padding: '8px', color: '#4D0B6C'}}>BETA</sup></SubMenuText></MenuItem>
                        {/*</NavLink>*/}
                        {/*<NavLink to='/create-paymentlink' style={({ isActive }) => isActive ? { color: '#4D0B6C', background: 'none',  textDecoration: 'none' }: { color: '#6C6363', background: 'none', textDecoration: 'none' }}>
                            <MenuItem icon={<TfiViewListAlt></TfiViewListAlt>}> <SubMenuText> Payment Button</SubMenuText></MenuItem>
                        </NavLink> */}
                    </SubMenu>
                    {/*<SubMenu>
                        <SubMenuTitle>Language</SubMenuTitle>
                        <MenuItem>
                            <GoogleTranslateWidget/>
                        </MenuItem>
                    </SubMenu> */}
                    <SubMenu>
                        <SubMenuTitle>Account</SubMenuTitle>
                        <SubMen icon={<AiOutlineSetting />} style={{color: isActive01 || isActive02 || isActive03 || isActive00 ? '#fff' : '#4D0B6c', background: isActive01 || isActive02 || isActive03 || isActive00 ? '#4D0B6c' : '#fff', borderRadius: '8px', margin:'10px', marginLeft:'0px'}} label={<div style={{ marginLeft: '20px' }}>
                            Settings{' '}
                          </div>}>
                        <div style={{background: '#FCF3FF', borderRadius: '8px', display:'block', overflow:'hidden', marginRight:'10px', maxWidth: '90%', width: '100%', margin: '0 auto'}}>
                          <NavLink to='/settings/personal-details' style={{ color: '#4D0B6c', background:isActive00 ? '#07f3ff24' : 'none', textDecoration: 'none', padding: '10px' }}>
                              <MenuItem  routerLink={<NavLink to="/settings/personal-details" activeClassName="active"/>} icon={<BsPersonGear color=''/>}> <SubMenuText style={{fontSize: '13px', marginLeft: '2px'}}> Personal Details <SubMenuText style={{display: isActive00 ? 'contents' : 'none', marginLeft: '5px', color: '#167e16' }}>•</SubMenuText></SubMenuText></MenuItem>
                          </NavLink>
                          <NavLink to='/settings/business-details' style={{ color: '#4D0B6c', background:isActive01 ? '#07f3ff24' : 'none', textDecoration: 'none', padding: '10px' }}>
                              <MenuItem routerLink={<NavLink to="/settings/business-details" activeClassName="active"/>} icon={<IoBusiness />}> <SubMenuText style={{fontSize: '13px', marginLeft: '2px'}}> Business Details <SubMenuText style={{display: isActive01 ? 'contents' : 'none', marginLeft: '5px', color:'#167e16' }}>•</SubMenuText></SubMenuText></MenuItem>
                          </NavLink>
                          <NavLink to='/settings/compliance' style={{ color: '#4D0B6c', background:isActive02 ? '#07f3ff24' : 'none', textDecoration: 'none', padding: '10px' }}>
                              <MenuItem routerLink={<NavLink to="/settings/compliance" activeClassName="active"/>} icon={<GrCompliance />}> <SubMenuText style={{fontSize: '13px', marginLeft: '2px'}}> Compliance <SubMenuText style={{display: isActive02 ? 'contents' : 'none', marginLeft: '5px', color:'#167e16' }}>•</SubMenuText></SubMenuText></MenuItem>
                          </NavLink>
                          <NavLink to='/settings/paymentmethod' style={{ color: '#4D0B6c', background:isActive03 ? '#07f3ff24' : 'none', textDecoration: 'none', padding: '10px' }}>
                              <MenuItem routerLink={<NavLink to="/settings/paymentmethod" activeClassName="active"/>} icon={<MdOutlinePayments />}> <SubMenuText style={{fontSize: '13px', marginLeft: '2px'}}> Beneficiaries <SubMenuText style={{display: isActive03 ? 'contents' : 'none', marginLeft: '5px', color:'#167e16' }}>•</SubMenuText></SubMenuText></MenuItem>
                          </NavLink>
                        </div>
                        </SubMen>
                        <MenuItem style={{color: 'red'}} onClick={logout} icon={<FiLogOut/>}><SubMenuText> Logout</SubMenuText></MenuItem>
                    </SubMenu>
                    </Menu>  
                    </Men1>                     
            </>
          }
          open={open}
          onClickOutside
          styles={{ sidebar: {background:"#fff", width: '70%', zIndex: '1000', top: '90px',position: 'fixed' }}}
        >
        </Sidebar>
        
          
        </MobSide> }

        {/*{openside && (
        <DashboardSidebar isOpen11={isOpen11} toggleSidebar11={toggleSidebar11} /> )} */}
        {showAlert && (
                      <AlertModal
                        isModalOpen={showAlert}
                        alertMessage={alertMessage}
                        onClose={handleAlertClose}
                      />
                    )} 
    </>
  )
}

export default Navbar