import styled from "styled-components";

export const Container = styled.main`
    height: auto;
    bottom: 0;
    right: 0;
    top: 100px;
    z-index: 0;
    flex: 1;
    margin-left: ${(props) => (props.isOpen ? '30px' : '0px')};
    @media screen and (max-width: 960px) {
        left: 0px;
    }
`
const MainContainer = styled.div`
  display: flex;
  flex: 1;
  margin-left: ${(props) => (props.isOpen ? '0px' : '0px')};
`;

const MainContent = styled.div`
  flex: 1;
`;

export const FormWrap = styled.div`
    height: fit-content;
    display: flex;
    flex-direction: column;
`
export const FormWrap2 = styled.div`
    height: fit-content;
    display: flex;
    flex-direction: column;
    padding: 20px;
    @media screen and (max-width: 650px) {
        padding: 10px;
    }
`

export const H2 = styled.p`
    font-weight: 700;
    font-size: 26px;

    @media screen and (max-width: 560px){
        font-size: 18px;
    }
`
export const Content = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    color: #6C6363;


    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`
export const NonMobile = styled.div`
    @media screen and (max-width: 926px) {
        display: none;
    }
`
export const MobileOnly = styled.div`
    display: none;
    @media screen and (max-width: 926px) {
        display: block;
    }
`
export const CenterContent = styled.div`
    display: grid;
    align-items: center;
    text-align: left;
    font-family: Poppins;
    width: 100%;

    @media screen and (max-width: 560px){
        width: auto;
    }
`

export const ContentDiv = styled.div`
    height: auto;
    align-items: center;
    text-align: center;
`
export const RoundedDiv = styled.div`
    border: 0px solid #979797;
    padding-top: 10px;
    border-radius: 6px;
    height: auto;
    margin: 20px;
    margin-left: 10px;
    margin-right: 10px;
    box-shadow: 0px 4px 50px 8px rgba(0, 0, 0, 0.06);
    background: #fff;
    display: grid; 
`
export const RoundedDiv2 = styled.div`
    height: auto;
`
export const RoundedDiv3 = styled.div`
    border: 1px solid #D9D9D9;
    border-radius: 6px;
    height: auto;
    margin: 20px;
    margin-bottom: 40px;
    margin-top: -20px;
    
    margin-left: 0px;
    background-color: #FDF9FF;

    @media screen and (max-width: 580px){
        margin: 20px;
        margin-bottom: 0px;
    }
`
export const DivContainer = styled.div`
    display: flex;
    justify-content: space-between;
    z-index: 1;
    width:100%;
    border-bottom: 0px solid #D0CFCE;
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px
`
export const DivContainer2 = styled.div`
  display: flex;
  justify-content: space-between;
  z-index: 1;
  width: 100%;
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  position: relative; 

  &::before {
    content: '';
    display: block;
    border-bottom: 1px solid #F3E8F7; 
    position: absolute;
    bottom: 0; 
    left: 20px; 
    right: 20px;
  }
`;

export const TextH2 = styled.div`
    font-size: 28px;
    color: #000;
    font-weight: 600;
`

export const DivLeft = styled.div`
    display: flex;
    float: left;
    margin-left: 10px;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
    @media screen and (max-width: 600px) {
        font-size: 13px;
        margin-right: 6px
    }
`

export const Image = styled.img`
    width: 85px;
    height: 85px;
    @media screen and (max-width: 600px) {
        width: 50px; 
        height: 50px;
        objectFit: cover;
    }
`

export const DivRight = styled.div`
    display: flex;
    float: right;
    align-items: center;
    margin-right: 15px;
    margin-bottom: 20px;
    @media screen and (max-width: 600px) {
        font-size: 13px;
        font-weight: 700
    }
`
export const DivLeft2 = styled.div`
    display: block;
    float: left;
    margin-left: 15px;
    align-items: center;
    margin-bottom: 20px;
    text-align: left;
`

export const DivRight2 = styled.div`
    display: block;
    float: right;
    align-items: center;
    margin-right: 15px;
    text-align: right;
`
export const PrimaryBtn = styled.button`
    background: #4D0B6C;
    border-radius: 12px;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 10px 0;
    font-size: 12px;
    margin-bottom: 10px;
    margin-right: 5px;
    width: 80px;
`

export const PayoutCard = styled.div`
    color: #4D0B6C;
    width: 300px;
    margin-left: 20px;
    margin-top: 60px;
    text-align: left;
    height: 350px;
    border-radius: 6px;
    cursor: pointer;
    background: white;
    box-shadow: 4px 4px 4px 4px rgba(77, 11, 108, 0.25);

    &:hover{
        background: #4D0B6C;
        color: #fff;
        box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.25);
    }`
export const TopUpCard = styled.div`
    color: #4D0B6C;
    margin: 10px;
    margin-right: 10px;
    text-align: left;
    border-radius: 6px;
    cursor: pointer;
    background: white;
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.09);
;
    height:144px;
    display: flex;
    width: auto; 
    align-items:center;
    padding:  10px;

    &:hover{
        background: #4D0B6C;
        color: #fff;
        box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.25);
    }

    @media screen and (max-width: 1000px) {
        
    }

    @media screen and (max-width: 768px) {
        width: auto;
        height: auto;
        margin-left: 0px;
        margin-right: 0px;
        margin: 5px;
        border-radius: 6px;
    }
`
export const PayoutCard2 = styled.div`
    color: #4D0B6C;
    width: 341px;
    margin: 0 20px 30px; /* Added margin to create spacing between cards */
    text-align: left;
    border-radius: 6px;
    background: white;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.18);
    overflow: hidden; /* Ensure content doesn't overflow on mobile */

    @media screen and (max-width: 768px) {
        width: 100%; /* Use the full width on mobile */
        margin: 0 10px 30px; /* Adjust margins for better spacing on mobile */
        height: auto; /* Allow height to adjust based on content */
    }
`;
export const TopupH = styled.div`
    font-family: 'poppins';
    line-height: 45px;
    font-size: 20px;
    font-weight: 600;

    @media screen and (max-width: 768px) {
        font-size: 16px;
    }
`
export const PayoutH = styled.div`
    font-family: 'poppins';
    line-height: 45px;
    font-size: 20px;
    font-weight: 600;
    margin: 20px;

    @media screen and (max-width: 768px) {
        font-size: 20px;
        margin: 10px;
    }
`
export const PayoutP = styled.p`
    line-height: 24px;
    font-size: 16px;
    font-weight: 400;
    margin: 18px;

    @media screen and (max-width: 768px) {
        font-size: 16px;
        margin: 20px;
    }
`
export const TopupImage = styled.img`
    margin: 20px;
    margin-bottom:10px; 
    border-radius: 8px;
    width: 60px;
    height: 60px;

    @media screen and (max-width: 768px) {
        width: 48px;
        height: 48px;
    }
`
export const TopupP = styled.p`
    font-family: 'poppins';
    line-height: 24px;
    font-size: 16px;
    font-weight: 400;

    @media screen and (max-width: 768px) {
        font-size: 14px;
        margin-top: -10px;
    }
`
export const PayoutP2 = styled.p`
    font-family: 'poppins';
    line-height: 24px;
    font-size: 16px;
    font-weight: 600;
    margin: 16px;
    margin-top: 50px;
    color: #6C6363;

    @media screen and (max-width: 768px) {
        font-size: 12px;
        margin-top: 10px;
        margin: 10px;
    }
`

export const ThText = styled.p`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    margin: 20px;
    display: block ruby;

    @media screen and (max-width: 560px){
        margin: 0px;
        font-size: 12px;
    }
`

export const TdRef = styled.p`
    ont-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-decoration-line: underline;
    margin: 20px;    
    color: #4D0B6C;
    @media screen and (max-width: 560px){
        margin: 0px;
        font-size: 12px;
    }
`
export const Tdreg = styled.p`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    margin: 20px;
    display: block ruby;
    @media screen and (max-width: 560px){
        margin: 0px;
        font-size: 12px;
    }
`
export const Tdreg1 = styled.div`
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #000000;
    margin: 20px;
    display: block ruby;
    
    @media screen and (max-width: 560px){
        margin: 0px;
        font-size: 11px;
        word-wrap: break-all;
        display: block

    }
`

export const SearchBar = styled.input`
    height: 40px;
    outline: none;
    padding: 16px 16px;
    background: transparent;
    border-radius: 10px; 
    border: 1px #0f0f0f solid;
    @media screen and (max-width: 926px) {
        width: 90%;
    }
`
export const PayContent = styled.div`
    display: block;
    grid-template-columns: 1fr 1fr;
    align-items: center;


    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`
export const PayoutCardWrapper = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;

    @media screen and (max-width: 1400px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
        margin:10px;
    }
`;
export const PayoutCardWrapper2 = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: center;
    grid-column-gap: 10px;

    @media screen and (max-width: 1000px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 768px) {
        grid-template-columns: 1fr;
    }
`
export const SelectInput = styled.select`
    padding: 16px 16px;
    margin-bottom: 32px;
    border: 1px solid #BCBCBC;
    border-radius: 6px;
    background-color: transparent;
`
export const InputArea = styled.textarea`
    padding: 16px 16px;
    margin-bottom: 32px;
    border: 1px solid #979797;
    border-radius: 6px;
    background-color: #fff;
    width: 99%;
`

export const P2pCard = styled.div`
    display: flex;
    justify-content: space-between;
    z-index: 1;
    width:100%;
    margin-top: 20px;
`

export const P2pH = styled.p`
    font-size: 16px;
    font-weight: 700;
    color: #51CB6C;
    font-family: 'Inter';
`
export const P2pT = styled.p`
    font-size: 14px;
    font-weight: 500;
    color: #791FA4;
    font-family: 'Inter';
    margin-right: 5px;
`
export const P2pInfo = styled.p`
    font-size: 12px;
`
export const P2pInfo2 = styled.p`
    font-size: 12px;
    font-weight: 700;
`

export const P2pGrid = styled.div`
    display: grid;
    grid-template-columns: 65% auto;
    grid-gap: 5px;
    padding: 10px;
    border-top: 1px solid #D0CFCE;

    @media screen and (max-width: 560px){
        grid-template-columns: 1fr;
    }
`
export const GridDiv = styled.div`
    padding: 20px 0;
    font-size: 30px;
    border-right: 1px solid #D0CFCE;
    align-items: center;
    text-align: center;

    @media screen and (max-width: 560px){
        border: none;
    }
`
export const GridDivL = styled.div`
    padding: 20px 0;
    font-size: 30px;
    align-items: center;
    text-align: center;
    height: auto;
    width: auto;
`

export const TabText = styled.p`
    font-size: 14px;
    font-weight: 700;
`
export const PaymentCard = styled.div`
    width: auto;
    border: 1px solid #791FA4;
    border-radius: 12px;
    margin: 10px;
`
export const TableContainer = styled.div`
  display: flex;
  justify-content: center;
  overflow-x: auto;
  max-width: 100%
`;

export const DesktopDiv = styled.div`
     @media (max-width: 650px) {
    display: none
  }
`

export const Tablee = styled.table`
  border-collapse: collapse;
  font-family: 'inter';
  width: 100%;
  text-align: left;

  @media (min-width: 601px) and (max-width: 1024px) {
    font-size: 16px;
  }

  @media (min-width: 1025px) {
    font-size: 16px;
  }
`;

export const TableHead = styled.thead`
  background-color: #F3E8F7;
  color: #1E1E1E;
  font-weight: 600;
  line-height:21px;
`;

export const TableHeader = styled.th`
  padding: 12px;
`;

export const TableBody = styled.tbody``;

export const TableRow = styled.tr`
  border-bottom: 1px solid #F0F0F0;
`;

export const TableCell = styled.td`
  padding: 25px 12px;

`;