import React, {useState, useEffect,useRef} from 'react'
import { BiArrowBack } from 'react-icons/bi'
import { BsFillChatDotsFill } from 'react-icons/bs'
import {  Form2, Form3,  FormH1, FormH2, FormLabel,  PrimaryBtn2, FormArea } from '../components/Form/formElements'
import Navbar from '../components/Navbar'
import { P2pCard,P2pInfo, P2pInfo2, DivLeft2, DivRight2, TableRow, TableCell, RoundedDiv, } from '../components/Dashboard/content/dashElements'
import 'react-tabs/style/react-tabs.css';
import axios from 'axios'
import { Container, FormWrap } from '../components/Dashboard/content/dashElements';
import { useNavigate, useLocation } from 'react-router-dom'
import Pin from './pin'
import AlertModal from './modal'
import styled from 'styled-components';
import Select from 'react-select';
import Sidebar1, { MainContainer, MainContent } from './nav'
import BASE_URL from '../apiConfig'
import Countdown from '../components/countdown'
import { ToastContainer, toast } from 'react-toastify'
import AwaitingConfirmation from './awaiting_confirmation'
import Penguin from '../assets/penguin.svg'
import Loader from '../components/loader'
import PdfDocument from './pdfdocument'
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useReactToPrint } from 'react-to-print';
import Chat from './chat'
import { CancelBtn } from '../components/Dashboard/p2pElements/p2pElements'

const Background = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: auto; /* Allow scrolling within the background */
`;

const PopupCard = styled.div`
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 80%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  position: relative;

  @media (max-width: 768px) {
    max-width: 90%;
    padding: 15px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  }
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  font-size: 20px;
  cursor: pointer;
  border: none;
  background: transparent;
`;
const DivPop = styled.div`
    height: 150px;
    @media screen and (max-width: 580px) {
        height: 195px;
    }
`


const Container1 = styled.div`
  display: flex;
  margin-top: 20px;

`;

const Select1 = styled.select`
padding: 16px 16px;
margin-bottom: 32px;
border: 1px solid #979797;
border-radius: 6px;
background-color: #4D0B6C;
color: #fff;
  border-right: none;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  outline: none;
`;

const Input = styled.input`
  padding: 16px 16px;
  margin-bottom: 32px;
  border: 1px solid #979797;
  border-radius: 6px;
  background-color: transparent;
  border-left: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  outline: none;
  width:100%;
  @media screen and (max-width: 580px) {
        width: 80%;
    }
`;
const Div9 = styled.div`
@media (max-width: 600px) {
    font-size: 10px;
  }
`
export const Spinner = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #7416a0;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

const P2pTransactionDetails = () => {
  const [isOpen, setIsOpen] = useState(true);
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  const navigate = useNavigate()
    const [details, setDetails] = useState(null);
    const [transactions, setTransactions] = useState(null);
    const [selectedData, setSelectedData] = useState({});
    const [showDetails, setShowDetails] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [description, setDescription] = useState('');
    const [dispute_type, setDisputeType] = useState('');
    const [chatName, setChatName] = useState('');
    const [transaction_id, setTransactionID] = useState('');
    const [showNewDispute, setShowNewDispute] = useState(false);
    const location = useLocation();
    const id = location.state.id;
    const [trnxid, setTrnxId] = useState(null);
    const [isChatModalOpen, setIsChatModalOpen] = useState(false);

  const handleDescription = (e) => {
    setDescription(e.target.value);
  };

  const handleDisputeType = (selectedOption) => {
    const { value } = selectedOption;
    setDisputeType(value);
  };
  const handleDisputeModal = (ID) => {
    setTransactionID(ID)
    setShowNewDispute(!showNewDispute);
  }

  const NewDispute = (event) => {
    event.preventDefault();
      axios.post(`${BASE_URL}/dispute`, {
        transaction_id : transaction_id,
        dispute_type: dispute_type,
        description: description
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(async (response) => {
          setAlertMessage(response.data.message)
          setShowAlert(true)
          await new Promise((resolve) => setTimeout(resolve, 3000));
          navigate('/p2p/dispute')
        })
        .catch(error => {
          if (error.response && error.response.data && error.response.data.errors && error.response.data.errors.error) {
            // Extract error message from response and alert it
            const errorMessage = error.response.data.errors.error;
            const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
            setAlertMessage(jsonString);;
            setShowAlert(true)
          } else {
            setAlertMessage('Service Error');
            setShowAlert(true);
          }
        });
  };

  const handleAlertClose = () => {
    setShowAlert(false);
  };

    const token = localStorage.getItem('bearerToken');
    const userId = localStorage.getItem('userId');
    const [user, setUser] = useState(null);
    const [isLoadingUser, setIsLoadingUser] = useState(true);
    useEffect(() => {
      const getUser = async () => {
        setIsLoadingUser(true);
        const data = await fetchUser();
        setUser(data);
        setIsLoadingUser(false);
      };
  
      getUser();
    }, []);
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        return response.data;
      } catch (error) {
        console.error(error);
      }
    };

    const fetchTransactions = async () => {
        try {
          const response = await axios.get(`${BASE_URL}/trade/history/buy-sell-history?status=pending`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
    
          return response.data;
        } catch (error) {
          console.error(error);
        }
      };
      useEffect(() => {
        const getTransactions = async () => {
          const data = await fetchTransactions();
          setTransactions(data);
          fetchUser()
          fetchDetails()
        };
    
        getTransactions();
      }, []);
      const fetchDetails = async () => {
         axios.get(`${BASE_URL}/trade/history/get-order/${id}`,{
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then(res => {
            setDetails(res.data.data);
            setSelectedData(res.data.data);
            setTrnxId(res.data.data.id);
            if(res.data.data.is_paid == 0){
              setTimeout(fetchDetails, 1000);
              if(res.data.data.agent_id != userId){
                setShowDetails(true)
              }
            } else if(res.data.data.is_paid == 1  && res.data.data.agent_id != userId){
              setShowDetails(true)
              setAwaitingConfirmation(false)
            }
            if(res.data.data.is_received == 1){
              setShowDetails(false)
              setAwaitingConfirmation(false)
              setSuccessTrnx(true)
            }
          })
          .catch(error => {
            console.error(error);
          });
      };
      
  
    const CheckStatus = async (trnxid) => {
      try {
        const response = await axios.get(`${BASE_URL}/trade/trade-status?trade=${trnxid}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response.data.data.status !== 'success' || response.data.data.status !== 'completed') {
          // If the status is not successful, call fetchStatus again after 5 seconds
          setTimeout(CheckStatus, 3000);
        } else if (response.data.data.status === 'success' || response.data.data.status === 'completed') {
          setAwaitingConfirmation(false)
          setSuccessTrnx(true)
        }
      } catch (error) {
        const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
        CheckStatus(trnxid)
        // Handle error, e.g. display an error message to the user
      }
    };
    const handleReceived = async (id) => {
      try {
        await axios.post(`${BASE_URL}/trade/received`,{
          trade_id : id
        },{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        // Handle successful deletion, e.g. remove the trade from the table data and update the UI
        setShowPinVerification(false)
        setAwaitingConfirmation(false);
        setShowDetails(false)
        setSuccessTrnx(true);
      } catch (error) {
        console.error(error);
        const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
        setAlertMessage(jsonString);
        setShowAlert(true);
        // Handle error, e.g. display an error message to the user
      }
    };
    const handlePaid = async (id) => {
      try {
        await axios.post(`${BASE_URL}/trade/paid`,{
          trade_id : id
        },{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setShowPinVerification(false)
        toast.success('Marked as paid')
        CheckStatus(trnxid)
        setShowDetails(false)
        setAwaitingConfirmation(true)
      } catch (error) {
        console.error(error);
        const jsonString = JSON.stringify((error.response.data.errors)).replace(/[\[\]{}'"]+/g, '');
        setAlertMessage(jsonString);
        setShowAlert(true);
        // Handle error, e.g. display an error message to the user
      }
    };

    const [showPinVerification, setShowPinVerification] = useState(false);

    const handlePaidWithVerification = (id) => {
        if(!checked){
            toast.error("You must tick the Checkbox to proceed", {
                position: toast.POSITION.TOP_CENTER
            })
        }else{
            setShowPinVerification(true);
            setVerificationCallback(() => handlePaid.bind(null, id));
        }
      
    };
    
    const handleReceivedWithVerification = (id) => {
        if (!checked){
            toast.error("You must tick the Checkbox to proceed",{
                position: toast.POSITION.TOP_CENTER
            })
        }else{
            setShowPinVerification(true);
            setVerificationCallback(() => handleReceived.bind(null, id));
        }

    };
    
    const [verificationCallback, setVerificationCallback] = useState(null);
    const handleCancelPinVerification = () => {
      setShowPinVerification(false);
    };

    function handleDisputeClick(transactionId) {
      navigate('/dispute', { state: { id: transactionId } });
    }
    const options = [
      {
        label: 'Sell',
        options: [
          { value: 'Sender name doesn’t tally', label: 'Sender name doesn’t tally' },
          { value: 'Payment not received', label: 'Payment not received' },
          { value: 'User filed chargeback', label: 'User filed chargeback' },
          { value: 'User sent stolen funds', label: 'User sent stolen funds' },
          { value: 'Bad terms and conditions', label: 'Bad terms and conditions' }
        ]
      },
      {
        label: 'Top Up',
        options: [
          { value: 'Delay in release of funds', label: 'Delay in release of funds' },
          { value: 'Incorrect account details', label: 'Incorrect account details' },
          { value: 'Fraudulent Account details', label: 'Fraudulent Account details' },
          { value: 'Agent is unreachable', label: 'Agent is unreachable' },
          { value: 'Bad terms and conditions', label: 'Bad terms and conditions' }
        ]
      },
      {
        label: 'Payout',
        options: [
          { value: 'Sender name doesn’t tally', label: 'Sender name doesn’t tally' },
          { value: 'Payment not received', label: 'Payment not received' },
          { value: 'User filed chargeback', label: 'User filed chargeback' },
          { value: 'User sent stolen funds', label: 'User sent stolen funds' },
          { value: 'Bad terms and conditions', label: 'Bad terms and conditions' },
          { value: 'Agent is unreachable', label: 'Agent is unreachable' }
        ]
      },
      {
        label: 'Buy',
        options: [
          { value: 'Delay in release of funds', label: 'Delay in release of funds' },
          { value: 'Incorrect account details', label: 'Incorrect account details' },
          { value: 'Fraudulent Account details', label: 'Fraudulent Account details' },
          { value: 'User is unreachable', label: 'User is unreachable' }
        ]
      }
    ];
    const [isEmpty, setIsEmpty] = useState(false);

    const [checked, setChecked] = useState(false);

    const handleCheckboxChange = () => {
      setChecked(!checked);
    };
    const [awaiting_confirmation, setAwaitingConfirmation] = useState(false)
    const [success_trnx, setSuccessTrnx] = useState(false)
    if (isLoadingUser) {
      // Render a loading indicator or skeleton screen
      return <Loader/> ;
    }
    
    const openChatModal = () => {
          if (selectedData.agent_id != user.data.id) {
            setChatName(selectedData?.agent.businessName);
          } else {
            setChatName(selectedData?.user.businessName);
          }
          setIsChatModalOpen(true);
          setShowDetails(false)
    };
  
    const closeChatModal = () => {
      setIsChatModalOpen(false);
      setShowDetails(true)
    };

    
  
  return user && (
    <div style={{overflowX:'clip'}}>
        <Navbar toggleSidebar={toggleSidebar}/>
        <MainContainer isOpen={isOpen}>
            <Sidebar1 isOpen={isOpen} toggleSidebar={toggleSidebar} style={{overflowX:'clip'}} />
            <ToastContainer />
            <Chat isChatOpen={isChatModalOpen} onClose={closeChatModal} transaction_id={trnxid} chatName={chatName} />
                    {showDetails && (
                        <RoundedDiv style={{padding:'20px'}}>
                      <div className="content" style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                          <div style={{ width:''}} >
                          {selectedData.trade_type == 'buy' ? 
                            <>
                            {selectedData.agent_id == user.data.id ? (
                                <>
                                <Div9 id='form2' style={{margin:'10px', marginTop:'10px', marginBottom:'10px'}}>
                                <P2pCard>
                                  <div style={{borderRadius:'12px', padding :'10px'}}>
                                      <FormH2 style={{marginTop: '24px', fontWeight: '700', color:'#1E1E1E', fontSize:'24px', lineHeight:'29.05px', paddingLeft:'15px', paddingRight:'15px' }}>
                                          <BiArrowBack style={{float:'left',marginRight:'25px', cursor:'pointer'}} onClick={()=> {navigate(-1)}}/>
                                          Confirm payment
                                      </FormH2>
                                      <div style={{margin: '10px', display:'flex', justifyContent:'center', marginTop:'20px'}}>
                                      <CancelBtn onClick={openChatModal} style={{color:'purple', borderColor:'purple', borderRadius:'16px', marginLeft:'25px', float:'right'}}><BsFillChatDotsFill /> <span style={{marginLeft:'2px'}}>Chat</span></CancelBtn>
                                      </div>
                                      <p style={{margin: '10px', textAlign:'center', color:'#4D0B6C', fontSize:'16px', fontWeight:'500'}}>
                                          Please confirm you have received the correct amount in your payment platform before clicking ‘i have received’
                                      </p>
                                  </div>
                                  </P2pCard>
                                </Div9>
                                
                                <p style={{textAlign: 'center', lineHeight: '19.36px', fontWeight: '500',fontSize: '16px', display: 'flex', alignItems: 'center',justifyContent: 'center'}}>
                            <span style={{ marginRight: '4px', textAlign:'center' }}>{Number(selectedData.is_paid) !== 1 ? 'Estimated time to receive this amount' : 'Expected time for Payment Verification'} :</span>
                            <Countdown endDate={new Date(selectedData.updated_at).getTime() + Number(selectedData?.trade.time_limit) * 60 * 1000} />
                            </p>
                            <FormH1 style={{color:'#1E1E1E', lineHeight:'38.73px', fontWeight:'700',fontSize:'28px', marginTop:'10px'}}>{selectedData.trade_currency} {Number(selectedData.trade_amount)}</FormH1>
                            <p style={{textAlign:'center', color:'purple', fontSize:'12px', lineHeight:'21.78px', paddingLeft:'10px', paddingRight:'15px'}}>
                                        Your Zeenah wallet will be debited : <b>{selectedData.trade_currency} {selectedData.agent_gets}</b>
                                    </p>
                            </>
                            ) : (
                                <>
                                <Div9 id='form2' style={{margin:'10px', marginTop:'10px', marginBottom:'10px'}}>
                                <P2pCard>
                                  <div style={{borderRadius:'12px', padding :'10px'}}>
                                    <FormH2 style={{marginTop: '24px', fontWeight: '700', fontSize:'24px', lineHeight:'29.05px', paddingLeft:'15px', paddingRight:'15px' }}>
                                        <BiArrowBack style={{float:'left', cursor:'pointer'}} onClick={()=> {navigate(-1)}}/>
                                        Make payment
                                    </FormH2>
                                    <div style={{margin: '10px', display:'flex', justifyContent:'center', marginTop:'20px'}}>
                                      <CancelBtn onClick={openChatModal} style={{color:'purple', borderColor:'purple', borderRadius:'16px', marginLeft:'25px', float:'right'}}><BsFillChatDotsFill /> <span style={{marginLeft:'2px'}}>Chat</span></CancelBtn>
                                      </div>
                                    <p style={{margin: '10px', textAlign:'center', color:'#4D0B6C', fontSize:'16px', fontWeight:'500'}}>
                                        Using any payment platform with an Account name that matches your Zeenah Account verified name, Transfer the funds to the agent using the details provided below.
                                    </p>
                                    </div>
                                  </P2pCard>
                                </Div9>
                                <p style={{textAlign: 'center', lineHeight: '19.36px', fontWeight: '500',fontSize: '16px', display: 'flex', alignItems: 'center',justifyContent: 'center'}}>
                            <span style={{ marginRight: '4px', textAlign:'center' }}>{Number(selectedData.is_paid) !== 1 ? 'You must pay the user within' : 'Expected time for Payment Verification'} :</span>
                            <Countdown endDate={new Date(selectedData.updated_at).getTime() + Number(selectedData?.trade.time_limit) * 60 * 1000} />
                            </p>
                            <FormH1 style={{color:'#1E1E1E', lineHeight:'38.73px', fontWeight:'700',fontSize:'28px', marginTop:'10px'}}>{selectedData.trade_currency} {Number(selectedData.trade_amount)}</FormH1>
                            <p style={{ textAlign:'center', color:'purple', fontSize:'12px', lineHeight:'21.78px', paddingLeft:'10px', paddingRight:'15px'}}>
                                        Your Zeenah Wallet will be credited : <b>{selectedData.trade_currency} {selectedData.customer_gets}</b>
                                    </p>
                                    
                            </>
                            )}
                            </> :
                            <>
                            {selectedData.agent_id == user.data.id ? (
                                <>
                                <Div9 id='form2' style={{margin:'10px', marginTop:'10px', marginBottom:'10px'}}>
                                <P2pCard>
                                  <div style={{borderRadius:'12px', padding :'10px'}}>
                                    <FormH2 style={{marginTop: '24px', fontWeight: '700', fontSize:'24px', lineHeight:'29.05px', paddingLeft:'15px', paddingRight:'15px' }}>
                                        <BiArrowBack style={{float:'left', cursor:'pointer'}} onClick={()=> {navigate(-1)}}/>
                                        Make payment
                                    </FormH2>
                                    <div style={{margin: '10px', display:'flex', justifyContent:'center', marginTop:'20px'}}>
                                      <CancelBtn onClick={openChatModal} style={{color:'purple', borderColor:'purple', borderRadius:'16px', marginLeft:'25px', float:'right'}}><BsFillChatDotsFill /> <span style={{marginLeft:'2px'}}>Chat</span></CancelBtn>
                                      </div>
                                    <p style={{margin: '10px', textAlign:'center', color:'#4D0B6C', fontSize:'16px', fontWeight:'500'}}>
                                        Using any payment platform with an Account name that matches your Zeenah Account verified name, Transfer the funds to the agent using the details provided below.
                                    </p>
                                    </div>
                                  </P2pCard>
                                </Div9>
                                <p style={{textAlign: 'center', lineHeight: '19.36px', fontWeight: '500',fontSize: '16px', display: 'flex', alignItems: 'center',justifyContent: 'center'}}>
                            <span style={{ marginRight: '4px', textAlign:'center' }}>{Number(selectedData.is_paid) !== 1 ? 'You must pay the user within' : 'Expected time for Payment Verification'}:</span>
                            <Countdown endDate={new Date(selectedData.updated_at).getTime() + Number(selectedData?.trade.time_limit) * 60 * 1000} />
                            </p>
                            <FormH1 style={{color:'#1E1E1E', lineHeight:'38.73px', fontWeight:'700',fontSize:'28px', marginTop:'10px'}}>{selectedData.trade_currency} {selectedData.customer_gets}</FormH1>
                            <p style={{margin: '20px', textAlign:'center', color:'purple', fontSize:'12px', lineHeight:'21.78px', paddingLeft:'10px', paddingRight:'15px'}}>
                                        Your Zeenah Wallet will be credited : <b>{selectedData.trade_currency} {selectedData.agent_gets}</b>
                                    </p>
                            </>
                            ) : (
                                <>
                                <Div9 id='form2' style={{margin:'10px', marginTop:'10px', marginBottom:'10px'}}>
                                <P2pCard>
                                  <div style={{borderRadius:'12px', padding :'10px'}}>
                                      <FormH2 style={{marginTop: '24px', fontWeight: '700', color:'#1E1E1E', fontSize:'24px', lineHeight:'29.05px', paddingLeft:'15px', paddingRight:'15px' }}>
                                          <BiArrowBack style={{float:'left',marginRight:'25px', cursor:'pointer'}} onClick={()=> {navigate(-1)}}/>
                                          Confirm payment
                                      </FormH2>
                                      <div style={{margin: '10px', display:'flex', justifyContent:'center', marginTop:'20px'}}>
                                      <CancelBtn onClick={openChatModal} style={{color:'purple', borderColor:'purple', borderRadius:'16px', marginLeft:'25px', float:'right'}}><BsFillChatDotsFill /> <span style={{marginLeft:'2px'}}>Chat</span></CancelBtn>
                                      </div>
                                      <p style={{margin: '10px', textAlign:'center', color:'#4D0B6C', fontSize:'16px', fontWeight:'500'}}>
                                          Please confirm you have received the correct amount in your payment platform before clicking ‘i have received’
                                      </p>
                                  </div>
                                  </P2pCard>
                                </Div9>
                                <p style={{textAlign: 'center', lineHeight: '19.36px', fontWeight: '500',fontSize: '16px', display: 'flex', alignItems: 'center',justifyContent: 'center'}}>
                            <span style={{ marginRight: '4px', textAlign:'center' }}>{Number(selectedData.is_paid) !== 1 ? 'Estimated time to receive this amount' : 'Expected time for Payment Verification'} :</span>
                            <Countdown endDate={new Date(selectedData.updated_at).getTime() + Number(selectedData?.trade.time_limit) * 60 * 1000} />
                            </p>
                            <FormH1 style={{color:'#1E1E1E', lineHeight:'38.73px', fontWeight:'700',fontSize:'28px', marginTop:'10px'}}>{selectedData.trade_currency} {selectedData.customer_gets}</FormH1>
                            <p style={{margin: '20px', textAlign:'center', color:'purple', fontSize:'12px', lineHeight:'21.78px', paddingLeft:'10px', paddingRight:'15px'}}>
                                Your Zeenah Wallet will be debited : <b>{selectedData.trade_currency} {selectedData.customer_get_charged}</b>
                            </p>
                            </>
                            )}
                            </>
                           }
                            <Div9 id='form2' style={{margin:'20px', marginTop:'10px'}}>
                            <P2pCard>
                                <DivLeft2><P2pInfo style={{marginBottom: '-15px'}}>Transaction ID</P2pInfo></DivLeft2>
                                <DivRight2><P2pInfo2 style={{marginBottom: '-15px'}}>{selectedData.transaction_id}</P2pInfo2></DivRight2>
                                </P2pCard>
                            <FormLabel style={{fontSize: '16px'}}></FormLabel>
                            <div style={{backgroundColor: '#FAFAFA', borderRadius:'12px', padding :'10px'}}>
                            <FormLabel style={{fontSize: '16px', marginBottom:'20px', color:'purple'}}>Payment Details</FormLabel>
                            {selectedData?.payment_method?.payment_info && (
                              <>
                            {Object.entries(selectedData?.payment_method?.payment_info).map(([key, value]) => (
                              <TableRow>
                                  <TableCell style={{color:'#595959', fontWeight:'500', fontSize:'16px',lineHeight:'22px'}}>{key.replace(/_/g, ' ') === 'accountHolderName' ? 'Account Name' : key.replace(/_/g, ' ')}</TableCell>
                                  <TableCell style={{color:'#1E1E1E', fontWeight:'600', fontSize:'16px',lineHeight:'22px'}}><b>{value}</b></TableCell>
                              </TableRow>
                            ))}</>)}
                            </div>
                            {selectedData.trade_type == 'buy' ?
                                <>
                                {selectedData.agent_id == user.data.id ? (
                                    <div style={{backgroundColor: '#FAFAFA', borderRadius:'12px', padding :'10px',marginTop: '30px', marginBottom:'20px'}}>
                            <FormLabel style={{fontSize: '16px', marginBottom:'20px', color:'purple'}}>Customer's Details</FormLabel>
                              <TableRow>
                                  <TableCell>Business Name</TableCell>
                                  <TableCell style={{color:'#4D0B6C'}}><b>{selectedData?.user.businessName}</b></TableCell>
                              </TableRow>
                              <TableRow>
                                  <TableCell>Customer's Name</TableCell>
                                  <TableCell style={{color:'#4D0B6C'}}><b>{selectedData?.user.firstName} {selectedData?.user.lastName}</b></TableCell>
                              </TableRow>
                            </div>
                                ) : (
                                    <div style={{backgroundColor: '#FAFAFA', borderRadius:'12px', padding :'10px',marginTop: '30px', marginBottom:'20px'}}>
                            <FormLabel style={{fontSize: '16px', marginBottom:'20px', color:'purple'}}>Agent's Details</FormLabel>
                              <TableRow>
                                  <TableCell>Business Name</TableCell>
                                  <TableCell style={{color:'#4D0B6C'}}><b>{selectedData?.agent.businessName}</b></TableCell>
                              </TableRow>
                              <TableRow>
                                  <TableCell>Customer's Name</TableCell>
                                  <TableCell style={{color:'#4D0B6C'}}><b>{selectedData?.agent.firstName} {selectedData?.agent.lastName}</b></TableCell>
                              </TableRow>
                            </div>
                                )}
                                </> :
                                <>
                                {selectedData.agent_id == user.data.id ? (
                                    <div style={{backgroundColor: '#FAFAFA', borderRadius:'12px', padding :'10px',marginTop: '30px', marginBottom:'20px'}}>
                            <FormLabel style={{fontSize: '16px', marginBottom:'20px', color:'purple'}}>Customer's Details</FormLabel>
                              <TableRow>
                                  <TableCell>Business Name</TableCell>
                                  <TableCell style={{color:'#4D0B6C'}}><b>{selectedData?.user.businessName}</b></TableCell>
                              </TableRow>
                              <TableRow>
                                  <TableCell>Customer's Name</TableCell>
                                  <TableCell style={{color:'#4D0B6C'}}><b>{selectedData?.user.firstName} {selectedData?.user.lastName}</b></TableCell>
                              </TableRow>
                            </div>
                                ) : (
                                    <div style={{backgroundColor: '#FAFAFA', borderRadius:'12px', padding :'10px',marginTop: '30px', marginBottom:'20px'}}>
                            <FormLabel style={{fontSize: '16px', marginBottom:'20px', color:'purple'}}>Agent's Details</FormLabel>
                              <TableRow>
                                  <TableCell>Business Name</TableCell>
                                  <TableCell style={{color:'#4D0B6C'}}><b>{selectedData?.agent.businessName}</b></TableCell>
                              </TableRow>
                              <TableRow>
                                  <TableCell>Customer's Name</TableCell>
                                  <TableCell style={{color:'#4D0B6C'}}><b>{selectedData?.agent.firstName} {selectedData?.agent.lastName}</b></TableCell>
                              </TableRow>
                            </div>
                                )}
                                </>
                            }
                            {selectedData?.trade?.terms &&
                                <div style={{backgroundColor: '#FAFAFA', borderRadius:'12px', padding :'10px',marginTop: '20px'}}>
                                  <FormLabel style={{fontSize: '16px', marginBottom:'20px', color:'purple'}}>Terms</FormLabel>
                                  <p style={{color:'#1E1E1E', fontWeight:'600', fontSize:'18px',lineHeight:'22px', marginLeft:'10px'}}><b>{selectedData?.trade?.terms}</b></p>
                                </div>
                            }
                            <div style={{ display: 'flex', alignItems: 'center', marginTop:'20px' }}>
                        <div
                            className={`custom-checkbox ${checked ? 'checked' : ''}`}
                            onClick={handleCheckboxChange}
                            style={{
                            width: '24px',
                            height: '24px',
                            backgroundColor: '#F3E8F7',
                            borderRadius: '4px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            }}
                        >
                            {checked && <span style={{ color: '#000' }}>✓</span>}
                        </div>
                        <label htmlFor="checkboxLabel" style={{ marginLeft: '8px' }}>
                        {selectedData.trade_type == 'buy' ?
                            <>
                            {selectedData.agent_id == user.data.id ? (
                                <span id="checkboxLabel">I have received the correct amount from the user</span>
                            ) : (
                                <span id="checkboxLabel">I have sent the correct amount from the user</span>
                            )}
                            </> :
                            <>
                            {selectedData.agent_id == user.data.id ? (
                                <span id="checkboxLabel">I have sent the correct amount from the user</span>
                            ) : (
                                <span id="checkboxLabel">I have received the correct amount from the user</span>
                            )}
                            </>
                           }
                        </label>
                        </div>
                        </Div9>
                        <Div9 style={{margin:'20px', marginTop:'10px'}}>
                        <div style={{display:'flex', marginLeft: '40px', marginRight:'40px', marginBottom:'40px'}}>
                          <div style={{width: '97%', marginRight: '10px'}}>
                            <PrimaryBtn2 style={{background: 'linear-gradient(180deg, #F3E8F7 0%, #EBDAF1 100%)',color: '#791FA4', borderRadius: '6px', width: '100%'}} onClick={() =>  handleDisputeModal(selectedData.id)}>Dispute</PrimaryBtn2>
                          </div>
                          <div style={{width: '100%'}}>
                          {selectedData.trade_type == 'buy' ?
                          <>
                          {selectedData.agent_id == user.data.id ? (
                            <PrimaryBtn2 style={{backgroundColor: Number(selectedData.is_paid) !== 1 ? 'grey' : '#9316BF', borderRadius: '6px', width: '100%',cursor: Number(selectedData.is_paid) !== 1 ? 'not-allowed' : 'pointer',opacity: Number(selectedData.is_paid) !==1 ? 0.5 : 1}} onClick={() => handleReceivedWithVerification(selectedData.id)} disabled={Number(selectedData.is_paid) !== 1 ? true : false}>{Number(selectedData.is_paid) !== 1 ? <span style={{fontSize:'10px'}}>Buyer Not marked as paid</span> : 'Payment Received'}</PrimaryBtn2>
          ) : <PrimaryBtn2 style={{backgroundColor: Number(selectedData.is_paid) == 1 ? 'grey' : '#9316BF', borderRadius: '6px', width: '100%',cursor: Number(selectedData.is_paid) == 1 ? 'not-allowed' : 'pointer',opacity: Number(selectedData.is_paid) == 1 ? 0.5 : 1}} onClick={() => handlePaidWithVerification(selectedData.id)} disabled={Number(selectedData.is_paid) == 1 ? true : false}>{Number(selectedData.is_paid) == 1 ? (<p style={{fontSize:'16px', margin:'2px'}}>Marked As Paid Already </p>): 'I have Paid'}</PrimaryBtn2>}</> :
          <>
          {selectedData.agent_id == user.data.id ? (
                            
                            <PrimaryBtn2 style={{backgroundColor: Number(selectedData.is_paid) == 1 ? 'grey' : '#9316BF', borderRadius: '6px', width: '100%',cursor: Number(selectedData.is_paid) == 1 ? 'not-allowed' : 'pointer',opacity: Number(selectedData.is_paid) == 1 ? 0.5 : 1}} onClick={() => handlePaidWithVerification(selectedData.id)} disabled={Number(selectedData.is_paid) == 1 ? true : false}>{Number(selectedData.is_paid) == 1 ? <p style={{fontSize:'16px', margin:'2px'}}>Marked As Paid Already</p>: 'I have Paid'}</PrimaryBtn2>
          ) : <PrimaryBtn2 style={{backgroundColor: Number(selectedData.is_paid) !== 1 ? 'grey' : '#9316BF', borderRadius: '6px', width: '100%',cursor: Number(selectedData.is_paid) !== 1 ? 'not-allowed' : 'pointer',opacity: Number(selectedData.is_paid) !==1 ? 0.5 : 1}} onClick={() => handleReceivedWithVerification(selectedData.id)} disabled={Number(selectedData.is_paid) !== 1 ? true : false}>{Number(selectedData.is_paid) !== 1 ? <p style={{fontSize:'16px', margin:'2px'}}>Buyer Not marked as paid</p> : 'Payment Received'}</PrimaryBtn2>}
          </>
          }
                          </div>
                        </div>
                        <div style={{justifyContent:'center', display:'flex'}}>
                          <CancelBtn onClick={openChatModal} style={{color:'purple', borderColor:'purple', borderRadius:'16px'}}><BsFillChatDotsFill /> <span style={{marginLeft:'2px'}}>Chat</span></CancelBtn>
                        </div>
                        </Div9>
                        {showNewDispute && (
                            <Background className="modal">
                            <PopupCard className="modal-content">
                              <CloseButton onClick={handleDisputeModal}>&times;</CloseButton>
                              <h3 style={{margin:'10px', textAlign: 'center'}}>Open New Dispute</h3>
                              <Form2 style={{border: 'none', marginBottom: '-20px', marginTop: '-40px'}} onSubmit={NewDispute}>
                                <FormLabel>Select Dispute Type</FormLabel>
                                <Select style={{ borderColor: '#979797' }} value={{ value: dispute_type, label: dispute_type }} onChange={handleDisputeType} options={options} getOptionValue={(option) => option.value}/>                                
                                <FormLabel style={{marginTop: '15px', marginBottom: '0px'}}>Description</FormLabel>    
                                <FormArea style={{marginBottom: ''}} value={description} onChange={handleDescription} />                            
                              <PrimaryBtn2  style={{backgroundColor: '#9316BF', borderRadius: '6px', width: '100%'}} Link>Open Dispute</PrimaryBtn2>
                              </Form2>
                            </PopupCard>
                          </Background>
                          )}
                          </div>
                          {showPinVerification && (
                          <Pin
                              show={showPinVerification}
                              setShow={setShowPinVerification}
                              onPinVerified={verificationCallback}
                              onCancel={handleCancelPinVerification}
                            />  )}
                      </div></RoundedDiv>)}
                      {awaiting_confirmation && (
                        <AwaitingConfirmation />
                      )}
                      {success_trnx &&
                        (
                          <Background className="modal">
                              <PopupCard className="modal-content" style={{overflow:'auto'}} >
                                  
                                  <center ref={componentRef}>
                                    <center style={{margin:'50px', marginBottom:'-5px'}}>
                                        <img src={Penguin} />
                                    </center>
                                  {selectedData.trade_type == 'buy' ?
                                    <>
                                    {selectedData.agent_id == user.data.id ? (
                                      <>
                                      <h2>Payout Complete</h2>
                                      <p>Received <b style={{color:'#4D0B6C'}}>{selectedData.trade_currency} {selectedData.trade_amount}</b></p>
                                      <p>From <b style={{color:'#4D0B6C'}} >{selectedData?.user.firstName} {selectedData?.user.lastName}</b></p>
                                      </>
                                    ) : (
                                      <>
                                      <h2>Deposit Successful</h2>
                                      <p>Transferred <b style={{color:'#4D0B6C'}}>{selectedData.trade_currency} {selectedData.trade_amount}</b></p>
                                      <p>To <b style={{color:'#4D0B6C'}} >{selectedData?.agent.firstName} {selectedData?.agent.lastName}</b></p>
                                      </>
                                    )}
                                    </> :
                                    <>
                                    {selectedData.agent_id == user.data.id ? (
                                      <>
                                      <h2>Deposit Successful</h2>
                                      <p>Transferred <b style={{color:'#4D0B6C'}}>{selectedData.trade_currency} {selectedData.trade_amount}</b></p>
                                      <p>To <b style={{color:'#4D0B6C'}} >{selectedData?.agent.firstName} {selectedData?.agent.lastName}</b></p>
                                      </>
                                    ) : (
                                      <>
                                      <h2>Payout Complete</h2>
                                      <p>Received <b style={{color:'#4D0B6C'}}>{selectedData.trade_currency} {selectedData.trade_amount}</b></p>
                                      <p>From <b style={{color:'#4D0B6C'}} >{selectedData?.user.firstName} {selectedData?.user.lastName}</b></p>
                                      </>
                                    )}
                                    </>
                                  }
                                  </center>
                                  <div style={{width: '100%', display:'flex', padding:'20px', fontFamily:'Inter'}}>
                                  <PrimaryBtn2 onClick={PDFDownloadLink} style={{backgroundColor:'#F3E8F7', borderRadius: '6px', width: '100%',cursor: 'pointer', color:'#4D0B6C', marginRight:'10px',fontSize:'15px' }}>
                                      <PDFDownloadLink style={{textDecoration:'none', color:'#4D0B6C'}} document={<PdfDocument selectedData={selectedData} />} fileName={`Zeenahpay-${selectedData.transaction_id}.pdf`}>
                                      {({ blob, url, loading, error }) => (
                                        <div>
                                          {loading ? (
                                            <div style={{textAlign:'center', display:'flex', fontSize:'12px', margin:'5px'}}>
                                              Generating PDF <Spinner style={{marginLeft:'1px'}} />
                                            </div>
                                          ) : (
                                            'Download Receipt'
                                          )}
                                        </div>
                                      )}
                                      </PDFDownloadLink>
                                      </PrimaryBtn2>
                                      <PrimaryBtn2 style={{backgroundColor:'#4D0B6C', borderRadius: '6px', width: '100%',cursor: 'pointer', marginleft:'10px', fontSize:'15px'}} onClick={() => navigate('/dashboard')}>View Wallet</PrimaryBtn2>
                                  </div>
                              </PopupCard>
                          </Background>
                        )
                      }
        </MainContainer>
        {showAlert && (
        <AlertModal
          isModalOpen={showAlert}
          alertMessage={alertMessage}
          onClose={handleAlertClose}
        />
      )}
    </div>
  )
}

export default P2pTransactionDetails